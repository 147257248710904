import { callAPIAsync } from '../libs/API';

export interface AgencyGroup {
  id: number;
  name: string | null;
  sub_group_ids: number[] | null;
  agency_id: number;
  created_at: Date;
  updated_at: Date;
  subGroups?: AgencyGroup[];
}

interface V2AgencyGroupsResponse {
  data: AgencyGroup[];
}

export async function getGroups(queryId?: string) {
  try {
    const queryParam = queryId ? `?queryId=${queryId}` : ''
    return ((await callAPIAsync(`/v2/agencyGroups${queryParam}`)) as V2AgencyGroupsResponse)
      .data;
  } catch (e) {
    console.error(e);
    return [];
  }
}
