/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
// see cognito notes in Cognito.js
// TODO: add local cognito to local sam setup
import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { authenticateUser, signOut } from '../libs/Cognito';
import { Page, LoadingContainer } from '../components-new';
import mixpanel from '../libs/Mixpanel.ts';
// eslint-disable-next-line import/no-named-as-default
import NewPassword from '../components/NewPassword';
import { getDecryptedUserAndStoreCachedUser } from '../libs/CognitoTs.ts';
import { callInitialApi } from '../libs/API';
import { clearStorage } from '../utils/LogOutUtils.ts';

const REGEX_FOR_EMAIL = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const REGEX_FOR_AUGINTEL = /.*(demo@augintel.us)/;

export const Login = ({ history, handleAuthentication }) => {
  const [signInForm, setSignInForm] = useState({
    email: '',
    password: '',
    loading: false,
  });
  const [errorMessage, setErrorMessage] = useState();
  const [signInTypeForm, setSignInTypeForm] = useState({
    email: '',
  });
  const [isPasswordCustomer, setIsPasswordCustomer] = useState(false);
  const [sourcePath, setSourcePath] = useState();
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [userAttributes, setUserAttributes] = useState();
  const [cognitoUser, setCognitoUser] = useState();
  const [identityProvider, setIdentityProvider] = useState();
  const [calledApi, setCalledApi] = useState(false);

  useEffect(() => {
    handleAuthentication(false, '');
    clearStorage();
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const query = window.location.search.startsWith('?')
      ? window.location.search.substring(1).split('&')
      : [];

    const errorParam = query
      .filter((queryString) => queryString.startsWith('errorMessage='))[0]
      ?.split('=')[1];
    const sourcePathParam = query
      .filter((queryString) => queryString.startsWith('sourcePath='))[0]
      ?.split('=')[1];
    if (typeof errorParam !== 'undefined') {
      setErrorMessage(decodeURIComponent(errorParam));
      setSourcePath(decodeURIComponent(sourcePathParam));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  const handleUserAuthentication = async (result) => {
    const user = getDecryptedUserAndStoreCachedUser(result);
    await handleAuthentication(true, user);
    if (sourcePath) history.push(sourcePath);
    else history.push('/');
  };

  useEffect(() => {
    setSignInTypeForm({ email: signInForm.email });
    if (signInForm.email.match(REGEX_FOR_AUGINTEL)) setIsPasswordCustomer(true);
    else setIsPasswordCustomer(calledApi && !identityProvider);
  }, [signInForm]);

  useEffect(() => {
    if (identityProvider) {
      let url = `${process.env.REACT_APP_SSO_AUTHORIZE_URI}&identity_provider=${identityProvider}`;
      if (sourcePath) {
        const encodedPiece = encodeURIComponent(
          `{"sourcePath":"${sourcePath}"}`
        );
        url = `${url}&state=${encodedPiece}`;
      }
      window.location.replace(url);
    }
  }, [identityProvider]);

  const handleGetLoginInfo = () => {
    mixpanel.track('login-enter-username', { email: signInForm.email });
    if (!signInForm.email || !REGEX_FOR_EMAIL.test(signInForm.email)) {
      setErrorMessage('Email is required to proceed!');
      return;
    }
    setSignInForm({ ...signInForm, loading: true });

    callInitialApi(
      '/v2/loginOptions',
      signInTypeForm,
      (resp) => {
        setCalledApi(true);
        if (!resp.identityProvider) {
          setIsPasswordCustomer(true);
          setSignInForm({ ...signInForm, loading: false });
        } else setIdentityProvider(resp.identityProvider);
      },
      (err) => {
        setErrorMessage(err.body);
        setCalledApi(true);
      }
    );
  };

  const handleSigninSubmit = () => {
    if (!signInForm.email || !signInForm.password) {
      setErrorMessage('Credentials are required to log in');
      return;
    }
    setSignInForm({ ...signInForm, loading: true });
    authenticateUser(
      signInForm.email,
      signInForm.password,
      async (err, userAttributesTemp, result) => {
        if (err) {
          setSignInForm({ ...signInForm, loading: false });
          setErrorMessage(err.message);
          handleAuthentication(false, '');
        } else if (userAttributesTemp) {
          setCognitoUser(result);
          setUserAttributes(userAttributesTemp);
          setIsFirstLogin(true);
        } else {
          await handleUserAuthentication(result);
        }
      }
    );
  };

  useEffect(() => {
    setSignInForm({
      ...signInForm,
      password: '',
    });
  }, [calledApi]);

  const handleFormInput = (key, value) => {
    if (errorMessage) setErrorMessage('');

    if (key === 'email') {
      setCalledApi(false);
    }

    setSignInForm({
      ...signInForm,
      [key]: value,
    });
  };

  const handleSubmitEnter = (e) => {
    if (e.keyCode === 13 && isPasswordCustomer) handleSigninSubmit(e);
  };

  const handleContinueEnter = (e) => {
    if (e.keyCode === 13 && !isPasswordCustomer) handleGetLoginInfo(e);
  };

  const footer = (
    <div className='grid links'>
      <div className='center col' hidden={!isPasswordCustomer}>
        <div
          className='aLink'
          onClick={(e) => {
            e.metaKey || e.ctrlKey
              ? window.open('/forgotpassword', '_blank')
              : history.push('/forgotpassword');
          }}
        >
          Forgot Password
        </div>
      </div>
    </div>
  );

  if (isFirstLogin) {
    return (
      <NewPassword
        userAttributes={userAttributes}
        cognitoUser={cognitoUser}
        handleUserAuthentication={handleUserAuthentication}
      />
    );
  }

  return (
    <Page pageTitle='Sign In | Augintel'>
      <div className='grid justify-content-center login'>
        <div className='lg:col-5 md:col-6 col-12'>
          <LoadingContainer isLoaded={!signInForm.loading}>
            <Card title='Please Sign In' footer={footer}>
              {errorMessage && (
                <span className='errorMessage'>
                  {errorMessage}
                  <br />
                  <br />
                </span>
              )}
              <InputText
                id='username'
                keyfilter={/^[A-Za-z0-9_.\-@+]+$/}
                placeholder='Email'
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                defaultValue={signInForm.email}
                onChange={(e) =>
                  handleFormInput('email', e.target.value.trim())
                }
                onKeyUp={handleContinueEnter}
              />
              <label className='accessibility' htmlFor='username'>
                Username
              </label>
              <br />
              <br />
              <div className='mb-4' hidden={!isPasswordCustomer}>
                <Password
                  id='password'
                  placeholder='Password'
                  onChange={(e) => handleFormInput('password', e.target.value)}
                  onKeyUp={handleSubmitEnter}
                  feedback={false}
                />
                <label className='accessibility' htmlFor='password'>
                  Password
                </label>
              </div>
              <Button
                label={isPasswordCustomer ? 'Sign In' : 'Continue'}
                onClick={
                  isPasswordCustomer ? handleSigninSubmit : handleGetLoginInfo
                }
              />
            </Card>
          </LoadingContainer>
        </div>
      </div>
    </Page>
  );
};
