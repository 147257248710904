/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Chip } from 'primereact/chip';
import mixpanel from '../../libs/Mixpanel';
import { BubbleTimelineChart } from './BubbleTimelineChart';
import { IRiskInterface } from './CaseGraphs';
import { CaseFactor, SearchNotesFunc } from '../../shared/types/caseTypes';

interface ICaseRiskBubbleChartProps {
  factor: IRiskInterface;
  monthSpan: number;
  searchNotes: SearchNotesFunc;
  shown: boolean;
  newRisks: string[];
}

// TODO: elegant way of adding popup as a whole here instead of just contents?
export const CaseRiskBubbleChart = ({
  factor,
  monthSpan,
  searchNotes,
  shown,
  newRisks,
}: ICaseRiskBubbleChartProps) => {
  const showRiskNotes = () => {
    mixpanel.track('case-filter-risk', {
      factor_type: factor.factor.type,
      factor_code: factor.factor.code,
      factor_label: factor.factor.label,
    });
    searchNotes(false, '', factor.factor, '');
  };

  const bubbleClick = (factorMonthYear: string) => {
    mixpanel.track('case-filter-risk', {
      factor_type: factor.factor.type,
      factor_code: factor.factor.code,
      factor_label: factor.factor.label,
      month: factorMonthYear,
    });
    searchNotes(false, factorMonthYear, factor.factor, '');
  };

  const isRiskNew = (risk: CaseFactor) => newRisks?.includes(risk.code);

  if (!factor || !shown) return <></>;
  if (factor.factor && factor.factor.label && factor.factor.code) {
    const colors =
      factor.factor.type === 'negative'
        ? { stroke: '#df7878', fill: '#fbd3da' }
        : { stroke: '#219a73', fill: '#e8ffe8' };
    return (
      <div className='bubbleTimeline' key={`factor-${factor.factor.code}`}>
        <div className='aLink entityLink' onClick={showRiskNotes}>
          {factor.factor.label}{' '}
          {isRiskNew(factor.factor) && (
            <Chip
              label='New'
              className='text-sm text-color font-semibold bg-pink-100'
            />
          )}
        </div>
        <BubbleTimelineChart
          data={factor.data}
          monthSpan={monthSpan}
          colors={colors}
          onClick={bubbleClick}
          width='100%'
        />
      </div>
    );
  }
  return <></>;
};
