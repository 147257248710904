export function calculateQueryTrend(scores) {
    let trend = {
                    dir: "up",
                    val: 0,
                    color: "green"
                }
    // note: 'scores[scores.length - 2]' is an incomplete month. So we use the prev two as the trend.
    const cur = scores[scores.length - 2]
    const prev = scores[scores.length - 3]
    let pct
    if (prev !== undefined && prev.val > 0) {
        pct = (cur.val - prev.val) / prev.val
        if (pct > 0) {
            trend = {
                dir: "up",
                val: Math.round(pct * 100),
                color: "red"
            }
        } else {
            trend = {
                dir: "down",
                val: Math.abs(Math.round(pct * 100)),
                color: "green"
            }
        }
    }
    return trend
}
