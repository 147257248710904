import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import styled from 'styled-components/macro';
import { SentenceLabels } from '../../shared/types/labelingTypes';

const StyledButton = styled(Button)`
  padding: 0.5em;
  overflow: visible;
`;

const SentenceDiv = styled.div`
  padding: 0.5em 0.5em 0.5em 0em;
`;

const StyledSpan = styled.span`
  width: 90px;
`;

type SentenceProps = {
  sentence: { id: string; text: string };
  labels: SentenceLabels;
  setLabels: Dispatch<SetStateAction<SentenceLabels>>;
  currentLabel: string;
  isProposalSentence: boolean;
  isModelProposalSentence: boolean;
  proposalSentLabels?: SentenceLabels;
  setProposalSentLabels?: Dispatch<SetStateAction<SentenceLabels>>;
};

export default function ReviewSentence({
  sentence,
  labels,
  setLabels,
  currentLabel,
  isProposalSentence,
  isModelProposalSentence,
  proposalSentLabels,
  setProposalSentLabels,
}: SentenceProps) {
  const overlay = useRef<OverlayPanel>(null);
  const [selectedLabel, setSelectedLabel] = useState(currentLabel);
  const labelOptions = Object.keys(labels).map((label) => ({
    label,
    value: label,
  }));

  useEffect(() => {
    labelOptions.push({ label: 'DELETE', value: 'DELETE' });
  }, []);

  const removeSentence = (sentenceLabels: SentenceLabels, label: string) => {
    const tempSentLabels = sentenceLabels;
    let tempSentences = sentenceLabels[label];
    tempSentences = tempSentences.filter((sent) => sent.id !== sentence.id);
    tempSentLabels[label] = tempSentences;
    return tempSentLabels;
  };

  const moveSentence = (newLabel: string) => {
    setSelectedLabel(newLabel);
    const tempSentenceLabels = removeSentence(labels, currentLabel);
    if (newLabel !== 'DELETE') {
      tempSentenceLabels[newLabel] = [
        ...labels[newLabel],
        { id: sentence.id, text: sentence.text },
      ];
    }
    setLabels({ ...tempSentenceLabels });

    if (isProposalSentence && proposalSentLabels && setProposalSentLabels) {
      const tempProposalSentLabels = removeSentence(
        proposalSentLabels,
        currentLabel
      );
      setProposalSentLabels({ ...tempProposalSentLabels });
    }
  };

  const proposalSentenceButtons = isProposalSentence || isModelProposalSentence ? (
    <StyledSpan>
      {isProposalSentence ?
          (
              <Button
                  icon='pi pi-check'
                  className='p-button-success p-button-rounded proposal-sent-button'
                  onClick={(e) => moveSentence(currentLabel)}
              />
          ) : ''
      }
      <Button
        icon='pi pi-times'
        className='p-button-danger p-button-rounded proposal-sent-button'
        onClick={(e) => moveSentence('DELETE')}
      />
    </StyledSpan>
  ) : (
    ''
  );

  return (
    <SentenceDiv
      key={`review-sentence-${sentence.id}`}
      className='flex align-content-start justify-content-start'
    >
      <span className='align-items-center flex'>
        <span
          className={`showInline ${isProposalSentence ? 'bg-orange-100' : ''}`}
        >
          {sentence.text}
        </span>
      </span>
      <StyledButton
        type='button'
        label='Move'
        onClick={(e) => overlay.current?.toggle(e)}
        className='p-button-text label-button'
      />
      <OverlayPanel ref={overlay}>
        <Dropdown
          value={selectedLabel}
          options={labelOptions}
          onChange={(e: { value: string }) => moveSentence(e.value)}
        />
      </OverlayPanel>
      {proposalSentenceButtons}
    </SentenceDiv>
  );
}
