import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Page } from '../../../components-new';
import { Factors } from '../../../shared/types/appTypes';
import ComponentsJson from '../../../libs/components.json';
import { SentenceSearch } from '../../../components/labeling/SentenceSearch';
import './SentenceLabeling.scss';
import {
  SentenceLabels,
  LabeledSentence,
  EmbeddingFilters,
} from '../../../shared/types/labelingTypes';
import { SentenceReview } from '../../../components/labeling/SentenceReview';
import { callAPIAsync } from '../../../libs/API';
import { ActiveLearning } from '../../../components/labeling/ActiveLearning';

const componentsJson: {
  [key: string]: Record<string, string>;
} = ComponentsJson;

type Props = {
  profile: { id: number; user_type: string };
  impersonation: boolean;
  history: string[];
  factors: Factors;
};

export const SentenceLabeling = ({
  profile,
  impersonation,
  history,
  factors,
}: Props) => {
  const [isAIAdmin] = useState(profile.user_type === 'augintel_admin');
  const [components, setComponents] = useState<Record<string, string>[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [sentenceLabels, setSentenceLabels] = useState<SentenceLabels>({});
  const [proposalSentenceLabels, setProposalSentenceLabels] =
    useState<SentenceLabels>({});
  const [embeddingSearchError, setEmbeddingSearchError] = useState('');
  const [sentenceIdGroupSearched, setSentenceIdGroupSearched] = useState<{
    [label: string]: string[][];
  }>({});
  const [embeddingFilters, setEmbeddingFilters] = useState<EmbeddingFilters>({
    noComponents: false,
    noWeakComponents: true,
  });

  const getComponents = () => {
    const componentsArr: string[] = [];
    const componentsList: Record<string, string>[] =
      Object.values(componentsJson);

    componentsList.forEach((component: Record<string, string>) => {
      componentsArr.push(...Object.values(component));
    });
    const tempComponents = componentsArr.map((component: string) => ({
      label: component,
    }));
    setComponents(tempComponents);
  };

  useEffect(() => {
    if (!isAIAdmin || impersonation) history.push('/');
    getComponents();
  }, [history, impersonation, isAIAdmin, profile.id]);

  const addProposalSentencesWithoutDuplicates = (
    label: string,
    existingProposalSentences: LabeledSentence[],
    sentencesToAdd: LabeledSentence[]
  ) => {
    const updatedSentences: LabeledSentence[] = [];
    // Do not add proposal sentences which are already labeled sentences
    const sentenceIdAlreadyAdded = sentenceLabels[label].map((sent) => sent.id);

    // Do not add existing proposal sentences
    existingProposalSentences.forEach((sent) => {
      if (!sentenceIdAlreadyAdded.includes(sent.id)) {
        updatedSentences.push(sent);
      }
    });
    sentenceIdAlreadyAdded.push(
      ...existingProposalSentences.map((sent) => sent.id)
    );

    sentencesToAdd.forEach((sent) => {
      if (!sentenceIdAlreadyAdded.includes(sent.id)) {
        updatedSentences.push(sent);
      }
    });

    return updatedSentences;
  };

  const setSentenceResults = (results: [], label: string) => {
    const tempProposalSentLabels = proposalSentenceLabels;
    const tempSentences = tempProposalSentLabels[label];
    const sentencesToAdd = results.map((result: Record<string, string>) => ({
      id: result.id,
      text: result.text,
      score: result.score,
    }));
    if (!tempSentences) {
      tempProposalSentLabels[label] = sentencesToAdd;
    } else {
      const updatedSentences = addProposalSentencesWithoutDuplicates(
        label,
        tempSentences,
        sentencesToAdd
      );
      tempProposalSentLabels[label] = updatedSentences;
    }
    setProposalSentenceLabels({ ...tempProposalSentLabels });
  };

  const pollForResults = async (
    key: string,
    label: string,
    counter: number,
    isApiResponse: boolean,
    waitMs: number
  ) => {
    if (counter < 15) {
      const response = await callAPIAsync('/admin/pollS3ActiveLearning', {
        key,
        isApiResponse,
      });
      if (response === 202) {
        const newCounter = counter + 1;
        setTimeout(() => {
          void pollForResults(key, label, newCounter, isApiResponse, waitMs);
        }, waitMs);
      } else if (
        response === undefined ||
        response.length === 0 ||
        typeof response === 'number'
      ) {
        setEmbeddingSearchError('Error doing embedding search.');
        clearTimeout(undefined);
      } else {
        setSentenceResults(response, label);
        clearTimeout(undefined);
      }
    } else {
      setEmbeddingSearchError('Error doing embedding search: max retries.');
    }
  };

  const callSentenceEmbeddingSearch = async (
    sentences: LabeledSentence[],
    label: string
  ) => {
    setEmbeddingSearchError('');
    const request = {
      lambdaName: 'sentenceEmbeddingSearch',
      noComponents: embeddingFilters.noComponents,
      noWeakComponents: embeddingFilters.noWeakComponents,
      sentences: sentences.map((sentence) => sentence.text),
    };
    await callAPIAsync('/admin/invokeLambda', request)
      .then((response: { key: string }) => {
        setTimeout(() => {
          void pollForResults(
            `sentenceEmbeddingSearch-${response.key}`,
            label,
            0,
            true,
            2000
          );
        }, 500);
      })
      .catch((err: unknown) => {
        console.log(err);
        setEmbeddingSearchError('Error doing embedding search.');
      });
  };

  const shuffleSentenceArray = (sentences: LabeledSentence[]) => {
    const tempSentences = sentences;
    // Fisher-Yates shuffle
    for (let i = tempSentences.length - 1; i > 0; i -= 1) {
      // random index from 0 to i
      const j = Math.floor(Math.random() * (i + 1));

      // swap elements array[i] and array[j]
      [tempSentences[i], tempSentences[j]] = [
        tempSentences[j],
        tempSentences[i],
      ];
    }
    return tempSentences;
  };

  const areSentencesSent = (label: string, sentenceIds: string[]) => {
    // checks if this group of sentences has already been sent to api to get proposal sentences
    const sentenceIdsAlreadySent = sentenceIdGroupSearched[label];
    if (sentenceIdsAlreadySent) {
      let haveBeenSent = false;
      sentenceIdsAlreadySent.forEach((sentSentenceIds: string[]) => {
        if (sentenceIds.every((id) => sentSentenceIds.includes(id))) {
          haveBeenSent = true;
        }
      });
      return haveBeenSent;
    }
    return false;
  };

  const storeSentenceIdsSent = (
    sentences: LabeledSentence[],
    label: string
  ) => {
    const tempSentenceIdGroupSearched = sentenceIdGroupSearched;
    if (!tempSentenceIdGroupSearched[label]) {
      tempSentenceIdGroupSearched[label] = [];
    }
    tempSentenceIdGroupSearched[label].push(
      sentences.map((sentence) => sentence.id)
    );
  };

  const getSimilarSentencesForLabel = (label: string) => {
    const { length } = sentenceLabels[label];
    if (length > 2) {
      // randomize sentence order
      const shuffledSentences = shuffleSentenceArray(sentenceLabels[label]);
      // iterate over sentences and send in batches of 3
      let i = 3;
      while (length >= i) {
        const sentences = shuffledSentences.slice(i - 3, i);
        if (
          !areSentencesSent(
            label,
            sentences.map((sent) => sent.id)
          )
        ) {
          storeSentenceIdsSent(sentences, label);
          setTimeout(() => {
            void callSentenceEmbeddingSearch(sentences, label);
          }, 1000);
        }
        i += 1;
      }
    }
  };

  const getSimilarSentences = () => {
    // new search for proposal sentences
    setProposalSentenceLabels({});
    setSentenceIdGroupSearched({});
    Object.keys(sentenceLabels).forEach((label) => {
      getSimilarSentencesForLabel(label);
    });
  };

  return (
    <Page
      title='Sentence Labeling'
      pageTitle='Sentence Labeling Admin | Augintel'
    >
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
        className='labeling-tabs'
        renderActiveOnly={false}
      >
        <TabPanel header='Search'>
          <SentenceSearch
            factors={factors}
            components={components}
            sentenceLabels={sentenceLabels}
            setSentenceLabels={setSentenceLabels}
            getSimilarSentencesForLabel={getSimilarSentencesForLabel}
            embeddingFilters={embeddingFilters}
            setEmbeddingFilters={setEmbeddingFilters}
          />
        </TabPanel>
        <TabPanel header='Review & Label'>
          <SentenceReview
            sentenceLabels={sentenceLabels}
            setSentenceLabels={setSentenceLabels}
            embeddingSearchError={embeddingSearchError}
            proposalSentences={proposalSentenceLabels}
            setProposalSentences={setProposalSentenceLabels}
            getSimilarSentences={getSimilarSentences}
          />
        </TabPanel>
        <TabPanel header='Train'>
          <ActiveLearning
            sentenceLabels={sentenceLabels}
            components={components}
            labeledSentences={sentenceLabels}
            proposalSentences={proposalSentenceLabels}
          />
        </TabPanel>
      </TabView>
    </Page>
  );
};
