import { ReactElement } from 'react';
import { Card } from 'primereact/card';
import { Avatar } from 'primereact/avatar';
import { Tooltip } from 'primereact/tooltip';
import { AreaChart } from '../graphs/AreaChart';
import '../../pages/Cases.scss';
import {
  StaffMember,
  TimeSeriesPoint,
} from '../../repositories/caseRepository';
import './CaseCard.scss';

export interface ICaseCardProps {
  id: number;
  name: string;
  sourceId: string;
  assignedUsers: StaffMember[];
  caseDuration: string;
  lastContactDate?: string;
  alerts: ReactElement[];
  riskCounts: TimeSeriesPoint[];
  protectiveCounts: TimeSeriesPoint[];
}

export default function CaseCard({
  name,
  sourceId,
  assignedUsers,
  caseDuration,
  lastContactDate,
  alerts,
  riskCounts,
  protectiveCounts,
  id,
}: ICaseCardProps) {
  return (
    <Card className='my-3 case-card-new' key={`case-card-${id}`}>
      <div className='flex flex-column md:flex-row card-content-container justify-content-between'>
        <div className='graphic-wrapper relative md:h-6rem sm:h-7rem'>
          {riskCounts.length || protectiveCounts.length ? (
            <div
              className='absolute top-auto md:right-0 md:w-13rem'
              style={{
                display: 'flex',
                width: '125%',
                left: '-1.5rem',
              }}
            >
              <AreaChart
                keys={['risks', 'strengths']}
                data={[riskCounts, protectiveCounts]}
                colors={[
                  { area: 'url(#red-gradient)', line: '#F14E4E' },
                  { area: 'url(#green-gradient)', line: '#1E7200' },
                ]}
                forcedHeight='136'
              />
            </div>
          ) : (
            <Avatar
              className={'lg:w-11rem sm:mb-2'}
              label={'No Notes'}
              size={'xlarge'}
              icon={'pi pi-info-circle'}
            />
          )}
        </div>
        <div className='flex flex-row flex-wrap w-full justify-content-between'>
          <div className='flex flex-column justify-content-end ml-1'>
            <h3 className='text-lg mb-1 font-xbold'>
              {name}&nbsp;&nbsp;<span className='font-bold'>#{sourceId}</span>
            </h3>
            <div className='font-semibold text-sm min-h-2'>
              {assignedUsers?.map((user) => (
                <div className={'inline assignedCw'} key={user.profileId}>
                  <span>{user.name}</span>
                  {user.position ? (
                    <>
                      &emsp;
                      <span>{user.position}</span>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </div>
            <div className='text-sm'>
              <div>Last Contact: {lastContactDate}</div>
              <div>Case Duration: {caseDuration}</div>
            </div>
          </div>
          <Tooltip target='.alert-tootip-target' position='top' />
          <div className='flex flex-column justify-content-between'>
            <div className='alerts-container my-1 flex flex-row-reverse flex-wrap-reverse align-content-start h-full'>
              {alerts.map((a) => (
                <span title='' key={`alert-${a.key || ''}`}>
                  {a}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
