import { Button } from 'primereact/button';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import {
  FiltersQueryState,
  FiltersQueryStateWithPagination,
} from '../../libs/FilterService';
import { ScopeOption } from '../../repositories/caseRepository';
import './ClearFilters.scss';

interface ClearFiltersProps {
  queryState: FiltersQueryStateWithPagination | FiltersQueryState;
}
export default function ClearFilters({ queryState }: ClearFiltersProps) {
  const history = useHistory();
  const location = useLocation();

  const query = queryString.parse(location.search, {
    arrayFormat: 'comma',
  });
  const newQuery = { page: '1' } as Partial<FiltersQueryState>;
  if (query.scope) {
    newQuery.scope = query.scope as ScopeOption;
  }
  if (
    query.sort &&
    (query.sort === 'datetime' || query.sort === 'importance')
  ) {
    newQuery.sort = query.sort;
  }
  const clearFilters = () => {
    history.push({
      search: queryString.stringify(newQuery, { arrayFormat: 'comma' }),
    });
  };

  const filtersSelected =
    queryState &&
    ((queryState.groupIds && queryState.groupIds.length > 0) ||
      (queryState.caseworkerIds && queryState.caseworkerIds.length > 0) ||
      (queryState.factor && queryState.factor.length > 0));

  return !filtersSelected ? (
    <div></div>
  ) : (
    <Button
      label='Clear'
      className='p-button-link'
      onClick={() => clearFilters()}
    />
  );
}
