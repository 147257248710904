import React, {
  useState,
  useEffect,
  SyntheticEvent,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { OverlayPanel } from 'primereact/overlaypanel';
import styled from 'styled-components/macro';
import { callAPIAsync } from '../../libs/API';
import LabelingFilterOverlay from './LabelingFilterOverlay';
import { Factors, Factor } from '../../shared/types/appTypes';
import {
  Phrase,
  Sentence,
  SentenceLabels,
  EmbeddingFilters,
  LabeledSentence,
} from '../../shared/types/labelingTypes';
import LabelingSentence from './LabelingSentence';
import PhraseFilters from './PhraseFilters';
import EmbeddingSearchFilters from './EmbeddingSearchFilters';

const StyledChip = styled(Chip)`
  margin: 0.25em;
`;

type Props = {
  factors: Factors;
  components: Record<string, string>[];
  sentenceLabels: SentenceLabels;
  setSentenceLabels: Dispatch<SetStateAction<SentenceLabels>>;
  getSimilarSentencesForLabel: (label: string) => void;
  embeddingFilters: EmbeddingFilters;
  setEmbeddingFilters: Dispatch<SetStateAction<EmbeddingFilters>>;
};

export const SentenceSearch = ({
  factors,
  components,
  sentenceLabels,
  setSentenceLabels,
  getSimilarSentencesForLabel,
  embeddingFilters,
  setEmbeddingFilters,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [searchSentence, setSearchSentence] = useState<string>('');
  const [similarPhrases, setSimilarPhrases] = useState<Phrase[]>([]);
  const [sentences, setSentences] = useState<Sentence[]>([]);
  const factorOverlay = useRef<OverlayPanel>(null);
  const weakFactorOverlay = useRef<OverlayPanel>(null);
  const componentOverlay = useRef<OverlayPanel>(null);
  const weakComponentOverlay = useRef<OverlayPanel>(null);
  const [factorsPositive, setFactorsPositive] = useState<Factor[]>([]);
  const [factorsNegative, setFactorsNegative] = useState<Factor[]>([]);
  const [factorsWLPositive, setFactorsWLPositive] = useState<Factor[]>([]);
  const [factorsWLNegative, setFactorsWLNegative] = useState<Factor[]>([]);
  const [componentsPositive, setComponentsPositive] = useState<
    Record<string, string>[]
  >([]);
  const [componentsNegative, setComponentsNegative] = useState<
    Record<string, string>[]
  >([]);
  const [componentsWLPositive, setComponentsWLPositive] = useState<
    Record<string, string>[]
  >([]);
  const [componentsWLNegative, setComponentsWLNegative] = useState<
    Record<string, string>[]
  >([]);
  const [searchParams, setSearchParams] = useState<{
    useScoreThreshold: boolean;
    scoreThreshold: string;
    numSimPhrases: string;
  }>({ useScoreThreshold: false, scoreThreshold: '2.75', numSimPhrases: '10' });
  const embeddingSearchFiltersOverlay = useRef<OverlayPanel>(null);

  const pollForResults = async (
    key: string,
    counter: number,
    isApiResponse: boolean,
    waitMs: number
  ) => {
    if (counter < 7) {
      const response = await callAPIAsync('/admin/pollS3ActiveLearning', {
        key,
        isApiResponse,
      });
      if (response === 202) {
        const newCounter = counter + 1;
        setTimeout(() => {
          void pollForResults(key, newCounter, isApiResponse, waitMs);
        }, waitMs);
      } else if (
        response === undefined ||
        response.length === 0 ||
        typeof response === 'number'
      ) {
        setError('Could not retrieve sentences');
        clearTimeout(undefined);
        setIsLoading(false);
      } else {
        setSentences(response);
        clearTimeout(undefined);
        setIsLoading(false);
      }
      setIsLoading(false);
    } else {
      setError('Could not retrieve sentences: max retries');
      setIsLoading(false);
    }
  };

  const sentenceEmbeddingSearch = async () => {
    const request = {
      lambdaName: 'sentenceEmbeddingSearch',
      noComponents: embeddingFilters.noComponents,
      noWeakComponents: embeddingFilters.noWeakComponents,
      sentences: [searchSentence],
    };
    await callAPIAsync('/admin/invokeLambda', request)
      .then((response: { key: string }) => {
        setTimeout(() => {
          void pollForResults(
            `sentenceEmbeddingSearch-${response.key}`,
            0,
            true,
            2000
          );
        }, 500);
      })
      .catch((err: unknown) => {
        console.log(err);
        setError('Error doing embedding search.');
      });
  };

  const sentencePhraseSearch = async () => {
    let tempSimilarPhrases = similarPhrases;
    if (!searchPhrase || searchPhrase.length === 0) {
      setSimilarPhrases([]);
      tempSimilarPhrases = [];
    }
    const request = {
      lambdaName: 'sentencePhraseSearch',
      searchPhrases: tempSimilarPhrases.map((phrase: Phrase) => phrase.phrase),
      factors: factorsPositive.map((factor: Factor) => factor.code),
      factorsNot: factorsNegative.map((factor: Factor) => factor.code),
      factorsWL: factorsWLPositive.map((factor: Factor) => factor.code),
      factorsWLNot: factorsWLNegative.map((factor: Factor) => factor.code),
      components: componentsPositive.map(
        (component: Record<string, string>) => component.label
      ),
      componentsNot: componentsNegative.map(
        (component: Record<string, string>) => component.label
      ),
      componentsWL: componentsWLPositive.map(
        (component: Record<string, string>) => component.label
      ),
      componentsWLNot: componentsWLNegative.map(
        (component: Record<string, string>) => component.label
      ),
    };
    await callAPIAsync('/admin/invokeLambda', request)
      .then((response: { key: string }) => {
        setTimeout(() => {
          setError('');
          void pollForResults(
            `sentencePhraseSearch-${response.key}`,
            0,
            true,
            3000
          );
        }, 4000);
      })
      .catch((err: unknown) => {
        setIsLoading(false);
        console.log(err);
        setError('Failed to find sentences.');
      });
  };

  const getSentences = async () => {
    if (searchSentence && searchSentence.length > 0) {
      await sentenceEmbeddingSearch();
    } else {
      await sentencePhraseSearch();
    }
  };

  useEffect(() => {
    if (similarPhrases.length > 0) {
      setError('');
      setIsLoading(true);
      void getSentences();
    }
  }, [similarPhrases]);

  const getSimilarPhrases = async () => {
    setIsLoading(true);
    setError('');
    setSimilarPhrases([]);
    setSentences([]);
    if (!searchPhrase || searchSentence) {
      await getSentences();
    } else {
      const request = {
        searchPhrase,
        scoreThreshold: searchParams.useScoreThreshold
          ? searchParams.scoreThreshold
          : '',
        limit: !searchParams.useScoreThreshold
          ? searchParams.numSimPhrases
          : '',
        offset: 0,
      };
      await callAPIAsync('/admin/phraseSearch', request)
        .then((response: []) => {
          if (response.length > 0) {
            setSimilarPhrases(response);
          } else {
            setIsLoading(false);
            setError('No similar phrases found.');
          }
        })
        .catch((err: unknown) => {
          setIsLoading(false);
          console.log(err);
          setError('Failed to search for phrases.');
        });
    }
  };

  const removePhrase = (event: SyntheticEvent, id: string) => {
    const updatedSimPhrases = similarPhrases.filter(
      (phrase) => phrase.id !== id
    );
    setSimilarPhrases(updatedSimPhrases);
  };

  const similarPhrasesDisplay = similarPhrases.map((phrase: Phrase) => (
    <StyledChip
      key={phrase.id}
      label={phrase.phrase}
      removable
      onRemove={(e) => removePhrase(e, phrase.id)}
    />
  ));

  const sentenceDisplay = sentences.map((sentence: Sentence) => (
    <LabelingSentence
      key={`sentence-${sentence.id}`}
      sentence={sentence}
      phrases={similarPhrases.map((phrase: Phrase) => phrase.phrase)}
      labels={sentenceLabels}
      setLabels={setSentenceLabels}
      getSimilarSentencesForLabel={getSimilarSentencesForLabel}
    ></LabelingSentence>
  ));

  const errorMessage = error ? (
    <p className='col-12 center errorMessage'>{error}</p>
  ) : (
    ''
  );

  const noFactorSelected =
    factorsNegative.length === 0 && factorsPositive.length === 0;
  const noFactorWLSelected =
    factorsWLPositive.length === 0 && factorsWLNegative.length === 0;
  const noComponentSelected =
    componentsPositive.length === 0 && componentsNegative.length === 0;
  const noComponentWLSelected =
    componentsWLPositive.length === 0 && componentsWLNegative.length === 0;
  const noFiltersSelected =
    noFactorSelected &&
    noFactorWLSelected &&
    noComponentSelected &&
    noComponentWLSelected;

  return (
    <div className='sentenceLabelingBody'>
      <div className='p-fluid formgrid grid'>
        <div className='field col-12'>
          <label htmlFor='searchSentence'>Search Sentence</label>
          <InputText
            id='sentenceSearch'
            value={searchSentence}
            keyfilter={/^[^<>@%$]*$/}
            onChange={(e) => {
              setSearchSentence(e.target.value);
            }}
            placeholder='Search Sentence'
          />
        </div>
        <br />
        <div className='field col-3'>
          <label htmlFor='searchPhrase'>Search Phrase</label>
          <InputText
            id='searchPhase'
            value={searchPhrase}
            keyfilter={/^[^<>@%$]*$/}
            onChange={(e) => {
              setSearchPhrase(e.target.value);
            }}
            placeholder='Search Phrase'
          />
        </div>
        <PhraseFilters
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </div>
      <h2>Filters</h2>
      <div className='p-fluid formgrid grid'>
        <div className='field col-2'>
          <Button
            type='button'
            label='Factors'
            className={noFactorSelected ? 'no-filter-selected' : ''}
            onClick={(e) => factorOverlay.current?.toggle(e)}
          />
        </div>
        <div className='field col-2'>
          <Button
            type='button'
            label='Weak Factors'
            className={noFactorWLSelected ? 'no-filter-selected' : ''}
            onClick={(e) => weakFactorOverlay.current?.toggle(e)}
          />
        </div>
        <div className='field col-2'>
          <Button
            type='button'
            label='Components'
            className={noComponentSelected ? 'no-filter-selected' : ''}
            onClick={(e) => componentOverlay.current?.toggle(e)}
          />
        </div>
        <div className='field col-2'>
          <Button
            type='button'
            label='Weak Components'
            className={noComponentWLSelected ? 'no-filter-selected' : ''}
            onClick={(e) => weakComponentOverlay.current?.toggle(e)}
          />
        </div>
      </div>
      <div className='buttonPanel'>
        <Button
          label='Search'
          disabled={
            isLoading || !(searchPhrase || !noFiltersSelected || searchSentence)
          }
          className='p-button'
          onClick={() => {
            void getSimilarPhrases();
          }}
        />
        <Button
          label='Embedding Search Options'
          className='p-button'
          onClick={(e) => embeddingSearchFiltersOverlay.current?.toggle(e)}
        />
        <EmbeddingSearchFilters
          reference={embeddingSearchFiltersOverlay}
          filters={embeddingFilters}
          setFilters={setEmbeddingFilters}
        />
        <LabelingFilterOverlay
          reference={factorOverlay}
          options={Object.values(factors)}
          positive={factorsPositive}
          setPositive={setFactorsPositive}
          negative={factorsNegative}
          setNegative={setFactorsNegative}
        />
        <LabelingFilterOverlay
          reference={weakFactorOverlay}
          options={Object.values(factors)}
          positive={factorsWLPositive}
          setPositive={setFactorsWLPositive}
          negative={factorsWLNegative}
          setNegative={setFactorsWLNegative}
        />
        <LabelingFilterOverlay
          reference={componentOverlay}
          options={components}
          positive={componentsPositive}
          setPositive={setComponentsPositive}
          negative={componentsNegative}
          setNegative={setComponentsNegative}
        />
        <LabelingFilterOverlay
          reference={weakComponentOverlay}
          options={components}
          positive={componentsWLPositive}
          setPositive={setComponentsWLPositive}
          negative={componentsWLNegative}
          setNegative={setComponentsWLNegative}
        />
      </div>
      {errorMessage}
      {isLoading ? <ProgressBar mode='indeterminate' /> : ''}
      <div className='phraseChips'>{similarPhrasesDisplay}</div>
      {sentenceDisplay}
    </div>
  );
};
