import { callAPI } from '../libs/API';

const REGEX_FOR_EMAIL = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

const getDomains = async (agencyId, email) =>
    new Promise ((resolve, reject) => {
        callAPI(
            `/admin/agencies/${agencyId}/domains`,
            null,
            (resp) => {
                if (typeof resp.domains !== 'undefined') {
                    const emailDomain = email.substr(email.indexOf("@") + 1);
                    const allDomains = resp.domains.map(domain => domain.domain);
                    resolve(allDomains.includes(emailDomain));
                } else {
                    reject();
                }
            },
            () => {
                reject();
            }
        );
    });


export const verifyEmail = async (agencyId, email) => {
    if (REGEX_FOR_EMAIL.test(email)) {
        const isEmailVerified = await getDomains(agencyId, email);
        if (isEmailVerified) {
            return true;
        }
    }
    return false;
}
