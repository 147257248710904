// see cognito notes in Cognito.js
import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { setNewPassword } from '../libs/Cognito';
import { LoadingContainer, Page } from '../components-new';

export const NewPassword = ({
  handleUserAuthentication,
  cognitoUser,
  userAttributes,
}) => {
  const [newPasswordForm, setNewPasswordForm] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const [loading, setLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const handleNewPassword = () => {
    const { password, passwordConfirmation } = newPasswordForm;
    if (password.length < 8) {
      setErrorMessage('Password is too short');
    } else if (password !== passwordConfirmation) {
      setErrorMessage('Passwords does not match confirmation');
    } else {
      setLoading(true);
      setNewPassword(
        userAttributes,
        cognitoUser,
        password,
        async (err, result) => {
          if (err) {
            setLoading(false);
            setErrorMessage(err.message);
          } else {
            handleUserAuthentication(result);
          }
        }
      );
    }
  };

  const handleFormInput = (key, value) => {
    errorMessage && setErrorMessage('');
    setNewPasswordForm({
      ...newPasswordForm,
      [key]: value,
    });
  };

  const error = errorMessage ? (
    <span
      className='errorMessage'
      style={{ marginBottom: 16, display: 'block' }}
    >
      {errorMessage}
    </span>
  ) : null;

  return (
    <Page pageTitle='New Password | Augintel'>
      <LoadingContainer isLoaded={!loading}>
        <div className='grid justify-content-center forgotPassword'>
          <div className='lg:col-5 md:col-6 col-12'>
            <Card
              title='New Password Required'
              subTitle='Please create a strong password.'
            >
              {error}
              <Password
                id='password'
                placeholder='Password'
                defaultValue={newPasswordForm.password}
                onChange={(e) => handleFormInput('password', e.target.value)}
              />
              <label className='accessibility' htmlFor='password'>
                Password
              </label>
              <br />
              <br />
              <Password
                id='passwordConfirmation'
                placeholder='Password Confirmation'
                defaultValue={newPasswordForm.passwordConfirmation}
                onChange={(e) =>
                  handleFormInput('passwordConfirmation', e.target.value)
                }
              />
              <label className='accessibility' htmlFor='passwordConfirmation'>
                Password Confirmation
              </label>
              <br />
              <br />
              <Button label='Change Password' onClick={handleNewPassword} />
            </Card>
          </div>
        </div>
      </LoadingContainer>
    </Page>
  );
};

export default NewPassword;
