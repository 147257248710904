import { Chip } from 'primereact/chip';
import { createElement } from 'react';
import { ICaseCardProps } from '../components/caseCard/CaseCard';
import { CasesInfo } from '../repositories/caseRepository';
import { parseDates } from '../libs/CaseDataService';

function summarizeDuration(msecDuration: number) {
  const totalDays = Math.round(msecDuration / (1000 * 3600 * 24));
  const totalMonths = Math.round(msecDuration / (1000 * 3600 * 24 * 30));
  const totalYears = Math.round(msecDuration / (1000 * 3600 * 24 * 365));

  if (totalDays < 30) {
    if (totalDays === 1) return `${totalDays} day`;
    return `${totalDays} days`;
  }
  if (totalMonths < 12) {
    if (totalMonths === 1) return `${totalMonths} month`;
    return `${totalMonths} months`;
  }

  if (totalYears === 1) return `${totalYears} year`;

  return `${totalYears} years`;
}

function parseDuration(
  startDate: string | null,
  endDate: string | null,
  is_active: boolean
) {
  const dateRange: { startDate?: Date; endDate?: Date } = {};
  try {
    const today = new Date();
    if (startDate) {
      dateRange.startDate = new Date(startDate);
      dateRange.endDate =
        endDate && !is_active ? new Date(endDate || today) : today;
    }
    if (dateRange.startDate && dateRange.endDate) {
      const msecDuration =
        dateRange.endDate.getTime() - dateRange.startDate.getTime();
      return summarizeDuration(msecDuration);
    }
    return '';
  } catch (e) {
    console.error('ERROR CATCH: ', e);
    return '';
  }
}

function createCaseDurationLabel(
  startDate: string | null,
  endDate: string | null,
  isActive: boolean
) {
  const e = parseDates(startDate, endDate, isActive);
  return `${e.startDate} - ${e.endDate} ${parseDuration(
    startDate,
    endDate,
    isActive
  )}`;
}

function createLastContactLabel(lastContactDateString: string | null) {
  const lastContactDate =
    lastContactDateString && lastContactDateString.length
      ? new Date(lastContactDateString)
      : null;
  const dateStr = lastContactDate
    ? lastContactDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: '2-digit',
      })
    : 'NA';
  let duration;
  if (lastContactDate) {
    const now = new Date();
    duration = summarizeDuration(now.getTime() - lastContactDate.getTime());
    return `${dateStr} - ${duration} ago`;
  }
  return dateStr;
}

function abbrieviateList(list: string[], limit: number) {
  if (list.length <= limit) return list;
  return `${list.slice(0, limit).join(', ')}, and ${
    list.length - limit
  } more...`;
}

export const BuildCaseCardProps = (response: CasesInfo) => {
  const { offset, totalCount } = response;
  const cases: ICaseCardProps[] = response.cases.map((c) => ({
    id: c.caseId,
    name: c.name,
    sourceId: c.sourceId,
    assignedUsers:
      c.staff?.filter((s) => s.assignmentType !== 'Secondary-NoDisplay') || [],
    caseDuration: createCaseDurationLabel(c.start_date, c.end_date, c.isActive),
    lastContactDate: createLastContactLabel(c.lastContactDate),
    alerts: [
      c.queryResults.length > 0
        ? createElement(Chip, {
            label: `${c.queryResults.length} Recent Alert${
              c.queryResults.length === 1 ? '' : 's'
            }`,
            className: 'mx-1 bg-orange-100 text-xs alert-tootip-target',
            key: 'query-notifications',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore data-pr-tooltip is only understood to be a valid prop within tsx
            'data-pr-tooltip': abbrieviateList(
              c.queryResults.map((q) => q.title).filter((q) => q),
              3
            ),
          })
        : createElement('div', { key: 'query-notifications' }),
      c.newRisks?.length > 0
        ? createElement(Chip, {
            label: `${c.newRisks.length} New Risk${
              c.newRisks.length === 1 ? '' : 's'
            }`,
            className: 'mx-1 bg-pink-100 text-xs alert-tootip-target',
            key: 'risk-counts',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore data-pr-tooltip is only understood to be a valid prop within tsx
            'data-pr-tooltip': abbrieviateList(
              c.newRiskFactors.map((f) => f.label).filter((f) => f),
              3
            ),
          })
        : createElement('div', { key: 'risk-counts' }),
    ].filter((n) => n),
    riskCounts: c.riskCounts,
    protectiveCounts: c.protectiveCounts,
  }));
  return { cases, offset, totalCount };
};
