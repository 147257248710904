export class ExpiredSessionError extends Error {
  forceSignOut: boolean;
  message: string;

  constructor(forceSignOut: boolean) {
    super();
    this.forceSignOut = forceSignOut;
    this.message = 'Session has expired.  Please sign in again.';
  }
}
