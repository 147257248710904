import { useEffect, useRef, useState } from 'react';
import {
  FiltersQueryState,
  FiltersQueryStateWithPagination,
  TreeNode,
} from '../../libs/FilterService';
import { IListBoxFilterOption } from '../../repositories/caseRepository';
import ClearFilters from '../elements/ClearFilters';
import './SelectedFiltersDisplay.scss';
import { DisplayFactor } from '../../shared/types/appTypes';

interface ClearFiltersProps {
  loading: boolean;
  queryState: FiltersQueryStateWithPagination | FiltersQueryState;
  groupData?: TreeNode[];
  caseworkerData?: IListBoxFilterOption[];
  factorData?: DisplayFactor[];
}
export default function SelectedFiltersDisplay({
  loading,
  queryState,
  groupData,
  caseworkerData,
  factorData,
}: ClearFiltersProps) {
  const MAX_ITEMS_DISPLAY = 7;
  const filtersDisplayRef = useRef<HTMLSpanElement | null>(null);
  const [overflowActive, setOverflowActive] = useState<boolean>(false);
  const filtersSelected =
    queryState &&
    ((queryState.groupIds && queryState.groupIds.length > 0) ||
      (queryState.caseworkerIds && queryState.caseworkerIds.length > 0) ||
      (queryState.factor && queryState.factor.length > 0));

  const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
    if (textContainer)
      return (
        textContainer.offsetHeight < textContainer.scrollHeight ||
        textContainer.offsetWidth < textContainer.scrollWidth
      );
    return false;
  };

  useEffect(() => {
    if (checkOverflow(filtersDisplayRef.current)) {
      setOverflowActive(true);
      return;
    }

    setOverflowActive(false);
  }, [queryState.groupIds, queryState.caseworkerIds]);

  const getSelectedGroups = (groupParam: TreeNode): JSX.Element[] | void => {
    const result: JSX.Element[] = [];
    let groupCount = 0;
    function getChildren(group: TreeNode) {
      if (
        queryState.groupIds?.includes(Number(group.key)) &&
        groupCount <= MAX_ITEMS_DISPLAY
      ) {
        // if top level group matches selected, only display root level
        groupCount += 1;
        result.push(
          <div key={`selectedOrg-${group.key}`} className='searchLabel'>
            {group.label}
          </div>
        );
      } else if (group.children && group.children.length > 0) {
        group.children.forEach((child) => getChildren(child));
      }
    }
    getChildren(groupParam);
    return result;
  };

  let caseworkerCount = 0;
  const selectedCaseworkers =
    queryState &&
    queryState.caseworkerIds &&
    queryState.caseworkerIds.length > 0
      ? caseworkerData?.map((caseworker) => {
          if (
            queryState.caseworkerIds?.includes(Number(caseworker.value)) &&
            caseworkerCount <= MAX_ITEMS_DISPLAY
          ) {
            caseworkerCount += 1;
            return (
              <div
                key={`selectedCw-${caseworker.value}`}
                className='searchLabel'
              >
                {caseworker.label}
              </div>
            );
          }
          return '';
        })
      : '';

  let factorCount = 0;
  const selectedFactors =
    queryState && queryState.factor && queryState.factor.length > 0
      ? factorData?.map((factor) => {
          if (
            queryState.factor === factor.code &&
            factorCount <= MAX_ITEMS_DISPLAY
          ) {
            factorCount += 1;
            return (
              <div
                key={`selectedFactor-${factor.code}`}
                className='searchLabel'
              >
                {factor.label}
              </div>
            );
          }
          return '';
        })
      : '';

  return (
    <div className='filters'>
      {loading || !filtersSelected ? (
        <div></div>
      ) : (
        <div className='flex wrapper'>
          <span className='displayedFilters' ref={filtersDisplayRef}>
            {selectedCaseworkers}
            {groupData?.map((group) => getSelectedGroups(group))}
            {selectedFactors}
          </span>
          {overflowActive ? <span>...</span> : ''}
          <ClearFilters queryState={queryState} />
        </div>
      )}
    </div>
  );
}
