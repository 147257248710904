/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// TODO refactor to not have unsafe type any
import { ReactElement } from 'react';
import { Note, RelevanceHit } from '../shared/types/noteTypes';
import { CaseNoteTable } from '../components/CaseNoteTable';

export const notePageLimit = 100

export const combinePhrases = (
  currentMatches: RelevanceHit[],
  newSearchMatches: RelevanceHit[]
) => {
  let combinedMatches = [];
  if (currentMatches.length > 0) {
    const currentLength = currentMatches.length;
    const lastPhrase = currentMatches[currentLength - 1];
    const firstNewPhrase = newSearchMatches[0];
    if (lastPhrase.phrase === firstNewPhrase.phrase) {
      const combinedSentences = [
        ...lastPhrase.sentences,
        ...firstNewPhrase.sentences,
      ];
      lastPhrase.sentences = combinedSentences;

      combinedMatches = [
        ...currentMatches.slice(0, currentLength - 1),
        lastPhrase,
        ...newSearchMatches.slice(1),
      ];
    } else {
      combinedMatches = [...currentMatches, ...newSearchMatches];
    }
    return combinedMatches;
  }
  return newSearchMatches;
};

export function guardUndefinedOrNull<T>(item: T, fallback: T): T {
  return typeof item === 'undefined' || item === null ? fallback : item;
}

export const serializeDateForDisplay = (event_datetime: string) => {
  const noteDate = new Date(event_datetime);
  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    hour12: true,
  });
  const [
    { value: weekday },
    ,
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
  ] = dateTimeFormat.formatToParts(noteDate);
  return `${weekday} ${month} ${day}, ${year}`;
};

export const formatTable = (note: Note, sentencesParam: ReactElement[]) => {
  const layouts = note.note_text_parsed.layout;
  let newSentences: ReactElement[] = [];
  if (layouts) {
    layouts.forEach((layout) => {
      if (layout.type === 'paragraph') {
        const paragraphI = layout.paragraphs;
        newSentences = [
          ...newSentences,
          ...sentencesParam.filter(
            (sentence) =>
              paragraphI.includes(sentence.props.paragraphI) ||
              paragraphI.includes(sentence.props['data-paragraphi'])
          ),
        ];
      }
      if (layout.type === 'table') {
        newSentences = [
          ...newSentences,
          <CaseNoteTable layout={layout} sentences={sentencesParam} />,
        ];
      }
    });
  } else {
    return sentencesParam;
  }
  return newSentences;
};
