import React from 'react'
import { makeStyles } from '@mui/styles'
import { ProgressSpinner } from 'primereact/progressspinner'
import { fade, palette } from '../../styles'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    maxWidth: '100%'
  },
  spinner: ({ useWindowWidth }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    width: useWindowWidth ? '100vw' : 'unset',
    right: useWindowWidth ? 'unset' : 0,
    bottom: 0,
    left: 0,
    backgroundColor: fade(palette.white, 0.3),
    zIndex: 99,
  })
}))

export const LoadingContainer = ({
  isLoaded,
  useWindowWidth,
  children
}) => {
  const classes = useStyles({ useWindowWidth })

  return (
    <div className={classes.container}>
      {!isLoaded && (
        <div className={classes.spinner}>
          <ProgressSpinner className="custom-spinner" />
        </div>
      )}
      {children}
    </div>
  )
}
