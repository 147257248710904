import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { setupCache } from 'axios-cache-interceptor';
import { ConnectedCaseResponseRecord } from '../components/connectedCases/ConnectedCasesDataService';
import { LocalStorageUser } from '../shared/types/appTypes';
import { refreshTokenAsync } from './Cognito';

const AUTH_HEADER = 'X-AuthInfo';

const onRequest = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  const user = JSON.parse(
    localStorage.getItem('user') || '{}'
  ) as LocalStorageUser;
  const impersonatedUser = JSON.parse(
    localStorage.getItem('impersonation') || '{}'
  ) as LocalStorageUser;

  await refreshTokenAsync(
    user.username,
    user.email,
    user.idToken.jwtToken,
    user.accessToken.jwtToken,
    user.refreshToken.token,
    user.tokenExpiration,
    user.signInExpiration
  );

  const refreshedUser = JSON.parse(
    localStorage.getItem('user') || '{}'
  ) as LocalStorageUser;
  config.headers.set(
    AUTH_HEADER,
    JSON.stringify({
      email: refreshedUser.email,
      impersonatedEmail: impersonatedUser.email,
    })
  );
  config.headers.setAuthorization(refreshedUser.idToken.jwtToken);
  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  const { method, url } = response.config;
  const { status } = response;
  // Set Loading End Here
  // Handle Response Data Here
  // Error Handling When Return Success with Error Code Here
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  console.log(`🚀 [API] ${method?.toUpperCase()} ${url} | Response ${status}`);

  return response;
};

const onErrorResponse = (error: AxiosError | Error): Promise<AxiosError> => {
  if (!Axios.isAxiosError(error)) {
    console.log(`🚨 [API] | Error ${error.message}`);
  } else {
    const { message } = error;
    const { method, url } = error.config as AxiosRequestConfig;
    const { headers, status } = (error.response as AxiosResponse) ?? {};

    datadogRum.addError({
      message,
      method,
      url,
      status,
      authInfo: headers[AUTH_HEADER] as string,
    });

    switch (status) {
      case 401: {
        // "Login required"
        window.location.href = `/login?errorMessage=Session timed out.  Please log in.&sourcePath=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`;
        break;
      }
      case 403: {
        // "Permission denied"
        break;
      }
      case 404: {
        window.location.href = '/NotFound';
        break;
      }
      case 500: {
        // "Server error"
        break;
      }
      default: {
        // "Unknown error occurred"
        break;
      }
    }

    if (status === 401) {
      // Delete Token & Go To Login Page if you required.
      sessionStorage.removeItem('user');
    }
  }

  return Promise.reject(error);
};

const setupInterceptors = (instance: AxiosInstance): AxiosInstance => {
  instance.interceptors.request.use(onRequest);
  instance.interceptors.response.use(onResponse, onErrorResponse);

  return instance;
};

const axios = setupInterceptors(
  setupCache(Axios.create({ baseURL: process.env.REACT_APP_API_URL }))
);
const adminAxios = setupInterceptors(
  setupCache(Axios.create({ baseURL: process.env.REACT_APP_ADMIN_API_URL }))
);

export default {
  getConnectedCases: (caseId: number): Promise<ConnectedCaseResponseRecord[]> =>
    axios.get<{ data: ConnectedCaseResponseRecord[] }>(
      `/v2/cases/${caseId || ''}/connectedCases`
    ).then(value => value.data.data),

  setFlags: (flags: Record<string, any>) =>
      axios.post('/setFlags', flags).then(value => value)
};
