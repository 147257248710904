import React, {
  Dispatch,
  FormEvent,
  RefObject,
  SetStateAction,
  useState,
} from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import styled from 'styled-components/macro';
import { Sentence, SentenceLabels } from '../../shared/types/labelingTypes';

const CheckboxDiv = styled.div`
  display: grid;
`;
const StyledCheckbox = styled(Checkbox)`
  margin: 0.25em;
`;
const AddCategoryForm = styled.form`
  margin: 0.5em 0em;
`;
type Props = {
  reference: RefObject<OverlayPanel>;
  sentence: Sentence;
  labels: SentenceLabels;
  setLabels: Dispatch<SetStateAction<SentenceLabels>>;
  getSimilarSentencesForLabel: (label: string) => void;
};

export default function LabelingOverlay({
  reference,
  sentence,
  labels,
  setLabels,
  getSimilarSentencesForLabel,
}: Props) {
  const [newLabel, setNewLabel] = useState<string>('');

  const addCategory = () => {
    const tempLabels = labels;
    tempLabels[newLabel] = [{ id: sentence.id, text: sentence.text }];
    setLabels({ ...tempLabels });
  };

  const addCategoryWrapper = (e: FormEvent) => {
    addCategory();
    e.preventDefault();
  };

  const sentenceMatchesLabel = (label: string) => {
    const sentencesMatchingLabel = labels[label];
    const matchingSentence = sentencesMatchingLabel.find(
      (sent) => sent.id === sentence.id
    );
    if (matchingSentence !== undefined) {
      return true;
    }
    return false;
  };

  const labelSentence = (e: CheckboxChangeParams) => {
    const tempLabels = labels;
    if (typeof e.value === 'string') {
      if (e.checked) {
        tempLabels[e.value].push({ id: sentence.id, text: sentence.text });
        getSimilarSentencesForLabel(e.value);
      } else {
        let tempSentences = tempLabels[e.value];
        tempSentences = tempSentences.filter((sent) => sent.id !== sentence.id);
        tempLabels[e.value] = tempSentences;
      }
      setLabels({ ...tempLabels });
    }
  };

  const labelDisplay =
    Object.keys(labels).length > 0 ? (
      <CheckboxDiv className='labeling-checkboxes'>
        {Object.keys(labels).map((label, i) => (
          <div
            key={['category-', i].join()}
            className='inline-flex align-items-center'
          >
            <StyledCheckbox
              checked={sentenceMatchesLabel(label)}
              value={label}
              onChange={(e) => labelSentence(e)}
            />
            <label htmlFor='checkbox'>{label}</label>
          </div>
        ))}
      </CheckboxDiv>
    ) : (
      ''
    );

  return (
    <OverlayPanel ref={reference}>
      <div>
        <b>Factors:</b>{' '}
        {sentence.factors.map((factor, index) => (index ? ', ' : '') + factor)}
      </div>
      <div>
        <b>Factor Weak Labels:</b>{' '}
        {sentence.factor_weak_labels.map(
          (factor, index) => (index ? ', ' : '') + factor
        )}
      </div>
      <div>
        <b>Components:</b>{' '}
        {sentence.components.map(
          (component, index) => (index ? ', ' : '') + component
        )}
      </div>
      <div>
        <b>Component Weak Labels:</b>{' '}
        {sentence.component_weak_labels.map(
          (component, index) => (index ? ', ' : '') + component
        )}
      </div>
      <br />
      <b>Add sentence to category?</b>
      <AddCategoryForm
        className='p-inputgroup'
        onSubmit={(e) => addCategoryWrapper(e)}
      >
        <Button label='Add Category' onClick={(e) => addCategory()} />
        <InputText
          placeholder='Category'
          value={newLabel}
          onChange={(e) => setNewLabel(e.target.value)}
        />
      </AddCategoryForm>
      {labelDisplay}
    </OverlayPanel>
  );
}
