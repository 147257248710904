/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { callAPI } from '../libs/API';
import { CaseNote } from './CaseNote.tsx';
import { CaseNotePopup } from './CaseNotePopup';
import { CaseNoteFeedbackPopup } from './CaseNoteFeedbackPopup';
import { SearchBox } from './notes/SearchBox.tsx';
import mixpanel from '../libs/Mixpanel.ts';
import ExportButton from './elements/exportButton/ExportButton.tsx';
import { DateRange } from './notes/DateRange.tsx';
import {
  buildDateRange,
  buildSearchDate,
  buildSearchPerson,
} from '../utils/QueryParamBuilder.ts';
import './CaseNotes.css';
import {
  combinePhrases,
  notePageLimit,
  serializeDateForDisplay,
} from '../utils/NoteUtils.tsx';
import { formatSentences } from '../utils/SentenceUtils.tsx';
import { NoteCount } from './notes/NoteCount.tsx';
import { DateSort } from './notes/DateSort.tsx';
import { SearchTags } from './notes/SearchTags.tsx';
import { NoteDisplay } from './notes/NoteDisplay.tsx';

function scrollToTop() {
  const elementsByClassName = document.querySelectorAll(
    '.notes-datascroller .p-datascroller-content'
  );
  if (elementsByClassName.length > 1) elementsByClassName[1].scrollTop = 0;
  else if(elementsByClassName.length !== 0) elementsByClassName[0].scrollTop = 0;
}

class CaseNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      notesLoaded: true,
      notesLastRequested: new Date(),
      caseNotePopup: {
        visible: false,
        noteID: 0,
        noteI: 0,
        sourceID: '',
        dateString: '',
        sentences: [],
      },
      caseID: 0,
      notes: [],
      queries: [],
      searchMatches: [],
      downloadingCSV: false,
      timelineNotes: [],
      // if there is a search phrase, rely on count of search matches for pagination
      totalNotes: 0,
      totalSearchMatches: 0,
      uniquePhraseCount: 0,
      scrollingNoteCount: 0,
      searchPhrase: '',
      searchPerson: '',
      searchFactor: '',
      searchMonth: '',
      searchYear: '',
      searchDateDisplay: '',
      searchQueryID: '',
      searchQueryName: '',
      searchDateRange: {
        startMonth: '',
        startYear: '',
        endMonth: '',
        endYear: '',
      },
      searchQueryHideResults: false,
      searchQueryHideResultsMessage: '',
      dateSort: 'newest',
      expandNotes: false,
      taggingEnabled: false,
      powerSearchHit: {},
      dateRangeRef: React.createRef(),
    };
    this.retryCount = 0;
    this.getNotes = this.getNotes.bind(this);
    this.getNewNotes = this.getNewNotes.bind(this);
    this.sortNotes = this.sortNotes.bind(this);
    this.getMoreNotes = this.getMoreNotes.bind(this);
    this.setNotes = this.setNotes.bind(this);
    this.searchNotes = this.searchNotes.bind(this);
    this.getQueryNotes = this.getQueryNotes.bind(this);
    this.getFactorNotes = this.getFactorNotes.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.getTaggingEnabled = this.getTaggingEnabled.bind(this);
    this.updateSearchDateRange = this.updateSearchDateRange.bind(this);
    this.countSentenceMatches = this.countSentenceMatches.bind(this);
    this.handleSearchError = this.handleSearchError.bind(this);
  }

  getQueryNotes = (
    caseID,
    _totalNotes,
    _caseNotes,
    searchQueryID,
    caseQueries
  ) => {
    this.setState(
      {
        isLoaded: true,
        caseID,
        searchQueryID,
        queries: caseQueries,
      },
      () => {
        let matchQuery;
        this.state.queries.forEach((queryGroup) => {
          const temp = queryGroup.queries?.find(
            (query) => query.id.toString() === this.state.searchQueryID
          );
          if (temp !== undefined) {
            matchQuery = temp;
          }
        });
        this.searchNotes(false, '', '', '', '', {
          id: this.state.searchQueryID,
          name: matchQuery?.title || 'Query',
          hideResults: matchQuery?.hide_results || false,
          hideResultsMessage: matchQuery?.hide_results_message || '',
        });
      }
    );
  };

  getFactorNotes = (caseID, searchFactor, factors) => {
    this.setState(
      {
        isLoaded: true,
        caseID,
        searchFactor,
      },
      () => {
        this.searchNotes(false, '', '', factors[searchFactor], '', {
          id: '',
          name: '',
          hideResults: false,
        });
      }
    );
  };

  getTaggingEnabled = () => this.state.taggingEnabled;

  // TODO: rethink function names/definitions.  is there a bug with not clearing out case.notes?
  // TODO: animate scroll, define object for scrolling by classname
  getNewNotes = () => {
    if (this.state.isLoaded) {
      if (!this.state.searchQueryHideResults) {
        scrollToTop();
        this.setState({ notes: [], searchMatches: [] }, this.getNotes());
      } else {
        this.setState({
          notes: [],
          totalNotes: 0,
          totalSearchMatches: 0,
          uniquePhraseCount: 0,
          scrollingNoteCount: 0,
          timelineNotes: [],
        });
      }
    }
  };

  getMoreNotes = () => {
    let moreNotes = this.state.totalNotes > this.state.notes.length;
    if (!this.disableRelevance()) {
      moreNotes =
        this.state.totalSearchMatches >
        this.countSentenceMatches(this.state.searchMatches);
    }
    if (
      this.state.notes &&
      this.state.isLoaded &&
      moreNotes &&
      this.state.notesLoaded
    ) {
      this.getNotes();
    }
  };

  sortNotes = (sort) => {
    this.setState(
      {
        dateSort: sort,
        notes: [],
      },
      this.getNewNotes
    );
    mixpanel.track('sort-notes-toggle', {
      direction: sort,
    });
  };

  countSentenceMatches = (sentenceSets) => {
    let count = 0;
    sentenceSets.forEach((sentenceSet) => {
      count += sentenceSet.sentences.length;
    });
    return count;
  };

  handleSearchError = (message) => {
    if (this.retryCount === 0) {
      this.retryCount = 1;
      this.getNotes();
    } else {
      this.retryCount = 0;
      this.props.displayToastError({
        severity: 'error',
        summary: message,
        detail: '',
      });
      this.setNotes();
    }
  };

  getNotes = () => {
    const rightNow = new Date();
    this.setState({ notesLoaded: false, notesLastRequested: rightNow }, () => {
      let noteStartI = this.state.notes.length;
      if (this.state.searchMatches.length > 0) {
        noteStartI = this.countSentenceMatches(this.state.searchMatches);
      }
      const searchPhrase =
        this.state.searchPhrase !== ''
          ? `&searchPhrase=${this.state.searchPhrase}`
          : '';
      const searchPerson = buildSearchPerson(this.state.searchPerson);
      const searchFactor =
        this.state.searchFactor !== ''
          ? `&searchFactor=${this.state.searchFactor}`
          : '';
      const searchDate = buildSearchDate(
        this.state.searchMonth,
        this.state.searchYear
      );
      const searchDateRange = buildDateRange(this.state.searchDateRange);
      const agencyFilter = `&agencyId=${this.props.agencyId}`;
      const sort = this.disableRelevance() ? this.state.dateSort : 'relevance';
      const sortParam = `&sort=${sort}`;
      // for now, we still load 10 notes at start so user sees some notes.
      const queryURL =
        this.state.searchQueryID !== ''
          ? `/v2/noteSearch?searchQuery=${this.state.searchQueryID}&searchCase=${this.state.caseID}${searchPhrase}&limit=${notePageLimit}&offset=${noteStartI}${sortParam}${searchDateRange}`
          : `/v2/noteSearch?searchCase=${this.state.caseID}&limit=${notePageLimit}&offset=${noteStartI}${searchPhrase}${searchPerson}${searchFactor}${searchDate}${sortParam}${agencyFilter}${searchDateRange}`;
      callAPI(
        queryURL,
        null,
        (resp) => {
          // eslint-disable-next-line no-param-reassign
          if (this.state.notesLastRequested === rightNow) {
            let currentNotes = this.state.notes;
            let totalNotes = 0;
            let totalSearchMatches = 0;
            let uniquePhraseCount = 0;
            let scrollingNoteCount = 0;
            let phraseCounts = {};
            let currentSearchMatches = this.state.searchMatches;
            // TODO: event_datetime is showing +0 GMT time.  probably needs to be corrected in backend?  need to confirm date conversion in backend.
            // TODO: make the sentence formatter into a component/function for reuse
            if (resp.error) {
              this.handleSearchError(resp.error);
            } else {
              if (typeof resp.tokens?.tokens !== 'undefined') {
                // phrase search
                totalSearchMatches = resp.tokens.total_matches;
                uniquePhraseCount = resp.tokens.uniquePhraseCount;
                scrollingNoteCount = resp.tokens.scrollingNoteCount;
                phraseCounts = resp.tokens.phraseCounts;
                currentSearchMatches = combinePhrases(
                  currentSearchMatches,
                  resp.tokens.tokens
                );
                if (this.disableRelevance()) {
                  for (
                    let insertJ = 0;
                    insertJ < resp.tokens?.tokens?.length;
                    insertJ += 1
                  ) {
                    try {
                      const orderedNote = resp.notes.notes.filter(
                        (note) =>
                          note.note_id.toString() ===
                          resp.tokens.tokens[insertJ].note_id.toString()
                      );
                      orderedNote[0].token = resp.tokens.tokens[insertJ];
                      // eslint-disable-next-line prefer-destructuring
                      currentNotes[insertJ + noteStartI] = orderedNote[0];
                    } catch (ex) {
                      console.log(
                        'note not present with token filter',
                        resp.tokens.tokens[insertJ].note_id.toString()
                      );
                    }
                  }
                } else {
                  const allSentences = currentSearchMatches.reduce(
                    (acc, tokenSet) => [
                      ...acc,
                      ...tokenSet.sentences.map((sent) => sent),
                    ],
                    []
                  );
                  const allNotes = [...currentNotes, ...resp.notes.notes];
                  const newNotes = [];
                  let previousNoteId = -1;
                  let previousPhrase = '';
                  for (
                    let insertJ = 0;
                    insertJ < allSentences.length;
                    insertJ += 1
                  ) {
                    const currentNoteId = allSentences[insertJ].note_id;
                    const matchingNote = allNotes.filter(
                      (note) =>
                        note.note_id.toString() === currentNoteId.toString()
                    );

                    const orderedNote = matchingNote[0];
                    if (!orderedNote) {
                      const error = new Error('Note missing in search results');
                      datadogRum.addError(error, {
                        message: `Note id ${currentNoteId} is missing`,
                        caseId: this.state.caseID,
                      });
                    }
                    // Step 1- check if same note as previous, if yes -> add tokens to existing note and continue
                    else if (
                      previousNoteId === currentNoteId &&
                      previousPhrase === allSentences[insertJ].phrase
                    ) {
                      const previousNote = newNotes.pop();
                      previousNote.token.sentences = [
                        ...previousNote.token.sentences,
                        allSentences[insertJ],
                      ];
                      allSentences[insertJ].index = newNotes.length;
                      newNotes.push(previousNote);
                    } else {
                      // Step 2 check if note already has tokens, if yes ->  duplicate it and override tokens, add to note list
                      // if note has been ordered already for a different phrase result, duplicate note and add it to list of notes
                      const duplicateNote = JSON.parse(
                        JSON.stringify(orderedNote)
                      );
                      duplicateNote.token = {
                        sentences: [allSentences[insertJ]],
                      };
                      allSentences[insertJ].index = newNotes.length;
                      newNotes.push(duplicateNote);
                      previousNoteId = currentNoteId;
                      previousPhrase = allSentences[insertJ].phrase;
                    }
                  }
                  currentNotes = newNotes;
                }
              } else {
                for (
                  let insertJ = 0;
                  insertJ < resp.notes.notes?.length;
                  insertJ += 1
                ) {
                  currentNotes[insertJ + noteStartI] =
                    resp.notes.notes[insertJ];
                }
              }
              totalNotes = parseInt(
                resp.notes.note_total.value || resp.notes.note_total,
                10
              );

              this.setState(
                {
                  notes: currentNotes,
                  totalNotes,
                  totalSearchMatches,
                  uniquePhraseCount,
                  scrollingNoteCount,
                  phraseCounts,
                  searchMatches: currentSearchMatches,
                },
                this.setNotes
              );
              this.retryCount = 0;
            }
          }
        },
        () => {
          this.handleSearchError('Could not retrieve messages');
        }
      );
    });
  };

  // cached pagination will have trouble if jumping to page.  need to use hash
  // TODO: optimize this
  setNotes = () => {
    let timelineNotes = (this.state.notes || []).map((caseNote, noteI) =>
      !(
        typeof this.state.timelineNotes[noteI] !== 'undefined' &&
        this.state.timelineNotes[noteI].key ===
          `caseNote-${this.state.caseID}` &&
        caseNote.expanded === this.state.expandNotes
      ) ? (
        <CaseNote
          key={`case-note-${caseNote.note_id}-${noteI}`}
          caseID={this.state.caseID}
          note={caseNote}
          showTags={this.disableRelevance()}
          agencyId={this.props.agencyId}
          noteI={noteI}
          searchPerson={this.state.searchPerson}
          searchFactor={this.state.searchFactor}
          searchPhrase={this.state.searchPhrase}
          searchQueryID={this.state.searchQueryID}
          displayToastError={this.props.displayToastError}
          getTaggingEnabled={this.getTaggingEnabled}
          displaySentenceFeedback={this.displaySentenceFeedback}
          toggleLabeling={this.toggleLabeling}
          displayEntityPopupByID={this.props.displayEntityPopupByID}
          expanded={this.state.expandNotes}
          openCaseNote={this.openCaseNote}
          factors={this.props.factors}
        />
      ) : (
        ''
      )
    );

    timelineNotes = timelineNotes.filter((e) => e);

    this.setState({
      notesLoaded: true,
      timelineNotes,
    });

    if (timelineNotes.length < 10 && this.state.searchQueryHideResults) {
      this.getMoreNotes();
    }
  };

  loadInitialNoteState = (
    caseID,
    totalNotes,
    caseNotes,
    searchQueryID,
    searchFactor,
    caseQueries
  ) => {
    this.setState(
      {
        isLoaded: true,
        totalNotes,
        caseID,
        notes: caseNotes,
        searchQueryID,
        searchFactor,
        queries: caseQueries,
      },
      this.setNotes
    );
  };

  filterNotes = (wait, searchMonthYear, searchFactor, searchPerson, query) => {
    document.getElementById('noteSearch').value = '';
    this.setState({ searchPhrase: '' });
    if (query || this.state.searchQueryID !== '')
      this.setState(
        { searchQueryID: query?.id || this.state.searchQueryID },
        this.searchNotes(
          wait,
          '',
          searchMonthYear,
          searchFactor,
          searchPerson,
          query
        )
      );
    else
      this.setState(
        { searchQueryID: '' },
        this.searchNotes(
          wait,
          '',
          searchMonthYear,
          searchFactor,
          searchPerson,
          query
        )
      );
  };

  // TODO: break into different functions instead of checking null?
  // we add a slight wait for search to allow for user to finish typing
  // TODO: debounce function?
  searchNotes = (
    wait,
    searchPhrase,
    searchMonthYear,
    searchFactor,
    searchPerson,
    query
  ) => {
    // clears previous timer jobs for search keydowns
    clearTimeout(this.noteSearchTimer);
    this.noteSearchTimer = setTimeout(
      () => {
        if (wait && searchPhrase)
          mixpanel.track('case-filter-keyword', {
            case_id: this.state.caseID,
            search_phrase: searchPhrase,
          });
        // clear previous notes
        const noteState = {
          searchPhrase: searchPhrase?.trim(),
          notes: [],
          timelineNotes: [],
          searchMatches: [],
        };

        if (searchFactor === '') {
          noteState.searchFactor = '';
          noteState.searchFactorDisplay = '';
        } else if (
          searchFactor !== null &&
          typeof searchFactor !== 'undefined'
        ) {
          noteState.searchFactor = searchFactor.code;
          noteState.searchFactorDisplay = searchFactor.label;
        }

        if (searchPerson !== null && typeof searchPerson !== 'undefined') {
          noteState.searchPerson = searchPerson;
        }
        // augment search based on bubble chart filter
        if (searchMonthYear === '') {
          noteState.searchMonth = '';
          noteState.searchYear = '';
          noteState.searchDateDisplay = '';
        } else if (
          searchMonthYear !== null &&
          typeof searchMonthYear !== 'undefined' &&
          searchMonthYear !== ''
        ) {
          const searchDateTimeFormat = new Intl.DateTimeFormat('en', {
            month: '2-digit',
            year: 'numeric',
          });
          const [{ value: searchMonth }, , { value: searchYear }] =
            searchDateTimeFormat.formatToParts(new Date(searchMonthYear));

          const displayDateTimeFormat = new Intl.DateTimeFormat('en', {
            month: 'short',
            year: 'numeric',
          });
          const [{ value: displayMonth }, , { value: displayYear }] =
            displayDateTimeFormat.formatToParts(new Date(searchMonthYear));
          noteState.searchMonth = searchMonth;
          noteState.searchYear = searchYear;
          noteState.searchDateDisplay = `${displayMonth} ${displayYear}`;
          noteState.searchDateRange = {
            endMonth: searchMonth,
            endYear: searchYear,
            startMonth: searchMonth,
            startYear: searchYear,
          };
        }

        if (typeof query !== 'undefined') {
          noteState.searchQueryID = query.id;
          noteState.searchQueryName = query.name;
          noteState.searchQueryHideResults = query.hideResults;
          noteState.searchQueryHideResultsMessage = query.hideResultsMessage;
        } else {
          noteState.searchQueryID = '';
          noteState.searchQueryName = '';
          noteState.searchQueryHideResults = false;
          noteState.searchQueryHideResultsMessage = '';
        }

        this.setState(noteState, this.getNewNotes);

        // NOTE: setTimeout runs once on the specified time and doesn’t run again unlike setInterval,
        // but its handler still remains active and in memory, so its outside dependencies are not released for GC.
        // Using clearTimeout will deactivate and clear the setTimeout handler and free its dependencies.
        clearTimeout(this.noteSearchTimer);
      },
      wait ? 300 : 0
    );
  };

  clearSearch = () => {
    mixpanel.track('case-clear-analysis', { case_id: this.state.caseID });
    this.state.dateRangeRef.current.clearDate();
    this.setState(
      {
        searchFactor: '',
        searchPerson: '',
        searchMonth: '',
        searchYear: '',
        searchDateDisplay: '',
        searchQueryID: '',
        searchQueryName: '',
        notes: [],
        timelineNotes: [],
        searchMatches: [],
        searchQueryHideResults: false,
        searchQueryHideResultsMessage: '',
        powerSearchHit: {},
        searchDateRange: {
          startMonth: '',
          startYear: '',
          endMonth: '',
          endYear: '',
        },
      },
      this.getNewNotes
    );
  };

  toggleLabeling = () => {
    this.setState({ taggingEnabled: !this.state.taggingEnabled });
    mixpanel.track('feedback-enabled-toggle', {
      setting_enabled: !this.state.taggingEnabled,
    });
  };

  goToCaseNote = (index) => {
    const note = this.state.timelineNotes[index];
    if (index < 0 || index >= this.state.timelineNotes.length || !note) return;
    if (index >= this.state.timelineNotes.length - 10) this.getMoreNotes();
    const direction = index > this.state.caseNotePopup.noteI ? 'next' : 'prev';
    mixpanel.track(`case-note-page-${direction}`, {
      case_id: this.state.caseID,
      note_id: note.props.id,
    });
    this.setState({
      caseNotePopup: {
        visible: true,
        noteID: note.props.id,
        sourceID: note.props.source_id,
        dateString: serializeDateForDisplay(note.props.note.event_datetime),
        sentences: formatSentences(
          note.props.note,
          this.props.factors,
          index,
          this.state.caseID,
          this.state.searchQueryID,
          this.displaySentenceFeedback,
          this.props.displayEntityPopupByID,
          this.getTaggingEnabled,
          this.state.expandNotes
        ),
        noteI: index,
        isRelevanceView: !this.disableRelevance(),
      },
    });
  };

  // Passing index in here allows case note popup to "know where it is" in
  // the list of notes. Then loadNoteByIndex can be used to load next/prev
  openCaseNote = (noteID, sourceID, dateString, sentences, i) => {
    if (i >= this.state.timelineNotes.length - 10) this.getMoreNotes();
    mixpanel.track('case-note-view', {
      case_id: this.state.caseID,
      note_id: noteID,
    });
    this.setState({
      caseNotePopup: {
        visible: true,
        noteID,
        sourceID,
        dateString,
        sentences,
        noteI: i,
      },
    });
  };

  hideCaseNote = () => {
    this.setState({
      caseNotePopup: {
        ...this.state.caseNotePopup,
        visible: false,
      },
    });
  };

  displaySentenceFeedback = (
    caseID,
    noteID,
    sourceID,
    formattedSentence,
    selectedRisk,
    sentence,
    sentenceDetails,
    classNames,
    riskKey
  ) => {
    this.caseNoteFeedbackPopup.showModal(
      caseID,
      noteID,
      sourceID,
      formattedSentence,
      selectedRisk,
      sentence,
      sentenceDetails,
      classNames,
      riskKey
    );
  };

  powerSearchClick() {
    document.getElementById('noteSearch').value = '';
    this.props.switchTab(3);
    this.setState({ searchPhrase: '' }, () => {
      this.filterNotes(false, '', '', '', {
        id: this.state.powerSearchHit.id,
        name: this.state.powerSearchHit.name,
        hideResults: this.state.powerSearchHit.hide_results,
        hideResultsMessage: this.state.powerSearchHit.hide_results_message,
      });
      this.setState({ powerSearchHit: {} });
    });
  }

  disableRelevance() {
    return (
      this.state.searchPerson !== '' ||
      this.state.searchQueryID !== '' ||
      this.state.searchFactor !== '' ||
      this.state.searchPhrase === '' ||
      this.state.searchPhrase.startsWith('"')
    );
  }

  updateSearchDateRange(searchDateRange) {
    this.setState(
      {
        searchDateRange,
        searchMonth: '',
        searchYear: '',
        searchDateDisplay: '',
        notes: [],
      },
      this.getNewNotes()
    );
    if (searchDateRange.endYear || searchDateRange.startYear) {
      mixpanel.track('date-range-notes', {
        dateRange: searchDateRange,
      });
    }
  }

  render() {
    const handleSearchPhrase = (searchPhrase) => {
      let powerSearchHit;
      let foundPowerSearch = false;
      this.props.powerSearches?.forEach((queryGroup) => {
        queryGroup.power_search.forEach((powerSearch) => {
          if (powerSearch.power_search_phrases?.includes(searchPhrase)) {
            powerSearchHit = {
              id: powerSearch.id,
              name: powerSearch.title,
              hideResults: powerSearch.hide_results,
              hideResultsMessage: powerSearch.hide_results_message,
              heading: queryGroup.heading,
              headingId: queryGroup.query_group_id,
            };
            this.setState({ powerSearchHit });
            foundPowerSearch = true;
          }
        });
      });
      if (!foundPowerSearch) {
        this.setState({ powerSearchHit: {} });
      }
      this.searchNotes(
        true,
        searchPhrase,
        this.state.searchDateDisplay,
        { code: this.state.searchFactor, name: this.state.searchFactorDisplay },
        this.state.searchPerson,
        { id: this.state.searchQueryID, name: this.state.searchQueryName }
      );
      this.props.showSearchSurvey();
    };

    const searchBox = (
      <SearchBox
        updateSearchPhrase={handleSearchPhrase}
        savedSearchPhrase={this.state.searchPhrase}
      />
    );

    const tags = (
      <SearchTags
        searchFactor={{
          code: this.state.searchFactor,
          name: this.state.searchFactorDisplay,
        }}
        searchPerson={this.state.searchPerson?.entityName}
        searchDateDisplay={this.state.searchDateDisplay}
        searchQuery={{
          id: this.state.searchQueryID,
          name: this.state.searchQueryName,
        }}
        clearSearch={this.clearSearch}
        factors={this.props.factors}
      />
    );

    const powerSearchSuggestion =
      Object.keys(this.state.powerSearchHit).length !== 0 ? (
        <div className='power-search-suggestion'>
          {' '}
          See results for:
          <div
            className='aLink'
            onClick={() => {
              mixpanel.track('case-filter-power-search', {
                query_id: this.state.powerSearchHit.id,
                query_title: this.state.powerSearchHit.name,
              });
              this.powerSearchClick();
            }}
          >
            {} {this.state.powerSearchHit.heading} -{' '}
            {this.state.powerSearchHit.name}
          </div>
        </div>
      ) : (
        ''
      );

    // dateData={{
    //             searchDateRange: this.state.searchDateRange,
    //             dateRange: this.state.dateRange,
    //             startDateDisplay: this.state.startDateDisplay,
    //             endDateDisplay: this.state.endDateDisplay,
    //             totalMonths: this.state.totalMonths,
    //           }}
    //           setDateData={this.updateSearchDateRange}

    const filters = (
      <span id='note-filters'>
        <DateRange
          ref={this.state.dateRangeRef}
          noteDates={this.props.noteDates}
          searchDateRange={this.state.searchDateRange}
          setSearchDateRange={this.updateSearchDateRange}
        />
        <div className='flex mb-2'>
          <NoteCount
            notesLoaded={this.state.notesLoaded}
            totalNotes={this.state.totalNotes}
            totalSearchMatches={this.state.totalSearchMatches}
            isRelevance={!this.disableRelevance()}
          />
          {tags}
          <DateSort
            sortNotes={this.sortNotes}
            sort={this.state.dateSort}
            disabled={!this.disableRelevance()}
          />
          <ExportButton
            showLabel={false}
            alignLeft={!this.disableRelevance()}
            downloadingCSV={this.state.downloadingCSV}
            setDownloadingCSV={(value) =>
              this.setState({ downloadingCSV: value })
            }
            searchParams={{
              caseID: this.state.caseID,
              agencyID: this.props.agencyId,
              queryID: this.state.searchQueryID,
              isQuery: false,
              sort: this.disableRelevance() ? this.state.dateSort : 'relevance',
              searchPhrase: this.state.searchPhrase,
              searchFactor: this.state.searchFactor,
              searchMonth: this.state.searchMonth,
              searchYear: this.state.searchYear,
              searchDateRange: this.state.searchDateRange,
              searchPerson:
                this.state.searchPerson === '' ? {} : this.state.searchPerson,
            }}
            displayToastError={this.props.displayToastError}
          />
        </div>
      </span>
    );

    const noteResults = (
      <div
        className={
          !this.state.notesLoaded && this.state.notes.length === 0
            ? 'loading results'
            : 'results'
        }
      >
        {this.state.searchQueryHideResults ? (
          <div>
            {' '}
            <br /> {this.state.searchQueryHideResultsMessage}{' '}
          </div>
        ) : (
          <NoteDisplay
            disableRelevance={this.disableRelevance()}
            getMoreNotes={this.getMoreNotes}
            timelineNotes={this.state.timelineNotes}
            searchMatches={this.state.searchMatches}
            totalMatches={this.state.totalSearchMatches}
            getMoreNotes={this.getMoreNotes}
            notesLoaded={this.state.notesLoaded}
            uniquePhraseCount={this.state.uniquePhraseCount}
            phraseCounts={this.state.phraseCounts}
          />
        )}
      </div>
    );

    const timelineClass = this.state.taggingEnabled
      ? 'lg:col-6 col-12 timeline taggingEnabled'
      : 'lg:col-6 col-12 timeline';

    const caseNotePopup = (
      <CaseNotePopup
        noteI={this.state.caseNotePopup.noteI}
        isVisible={this.state.caseNotePopup.visible}
        sentences={this.state.caseNotePopup.sentences}
        dateString={this.state.caseNotePopup.dateString}
        toggleLabeling={this.toggleLabeling}
        taggingEnabled={this.state.taggingEnabled}
        onHide={this.hideCaseNote}
        goToCaseNote={this.goToCaseNote}
        totalNotes={this.state.totalNotes}
        isRelevanceView={!this.disableRelevance()}
        scrollingNoteCount={this.state.scrollingNoteCount}
      />
    );

    const caseNoteFeedbackPopup = (
      <CaseNoteFeedbackPopup
        profile={this.props.profile}
        impersonation={this.props.impersonation}
        displayToastError={this.props.displayToastError}
        factors={this.props.factors}
        ref={(el) => {
          this.caseNoteFeedbackPopup = el;
        }}
      />
    );

    return (
      <div className={timelineClass}>
        <Toast
          ref={(el) => {
            this.toast = el;
          }}
        />
        {caseNotePopup}
        {caseNoteFeedbackPopup}
        {searchBox}
        {filters}
        {powerSearchSuggestion}
        {noteResults}
        {this.state.notesLoaded ? '' : <ProgressBar mode='indeterminate' />}
      </div>
    );
  }
}

export { CaseNotes };
