interface CountProps {
  notesLoaded: boolean;
  totalNotes: number;
  totalSearchMatches: number;
  isRelevance: boolean;
}

export const NoteCount = ({
  notesLoaded,
  totalNotes,
  totalSearchMatches,
  isRelevance,
}: CountProps) => {
  const totalNotesNum = Number.isNaN(totalNotes) ? 0 : totalNotes;
  const noteCount =
    totalNotesNum.toString() + (totalNotes === 1 ? ' Note' : ' Notes');
  const resultsCount =
    totalSearchMatches.toString() + (totalNotes === 1 ? ' Match' : ' Matches');
  const countDisplay = isRelevance
    ? `${resultsCount}, ${noteCount}`
    : noteCount;

  return (
    <small className='count'>{!notesLoaded ? 'Loading' : countDisplay}</small>
  );
};
