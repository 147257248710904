/* eslint-disable no-throw-literal */
// using https://www.npmjs.com/package/amazon-cognito-identity-js/v/2.0.30
// example code pulled from: https://github.com/patmood/react-aws-cognito-example/
// Raw API Reference: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
// if amazon-cognito-identity-js becomes deprecated, can use raw api calls
// TODO: expose email in username on cognito user list
// TODO: javascript doesn't support client secret.  assess if we in fact need this or if we just lock things down from referrer.

import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { ExpiredSessionError } from '../shared/Error.ts';
import { forceSignOut } from './Utils';
import { clearStorage } from '../utils/LogOutUtils.ts';

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID,
});

// TODO: fix passwordregex to avoid need for backend check
// export const PASSWORD_REGEX = new RegExp("/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^$*.[]{}()?-“!@#%&/,><’:;|_~`])S{8,99}$/")

export const createUser = (email, password, firstName, lastName, callback) => {
  const attributeList = [
    new CognitoUserAttribute({
      Name: 'email',
      Value: email,
    }),
    new CognitoUserAttribute({
      Name: 'custom:firstName',
      Value: firstName,
    }),
    new CognitoUserAttribute({
      Name: 'custom:lastName',
      Value: lastName,
    }),
  ];

  // Username must be unique in a pool, and cant be a valid email format
  // To log in with email, make sure it is set as an alias attribute in Cognito
  // More info: http://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html#user-pool-settings-usernames
  userPool.signUp(email, password, attributeList, null, callback);
};

export const createUserEmailOnly = (email, password, callback) => {
  const attributeList = [
    new CognitoUserAttribute({
      Name: 'email',
      Value: email,
    }),
  ];

  userPool.signUp(email, password, attributeList, null, callback);
};

export const verifyUser = (username, verifyCode, callback) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmRegistration(verifyCode, true, callback);
};

// TODO: implement this and test
export const resendConfirmation = (username, callback) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.resendConfirmationCode(callback);
};

export const changePassword = (
  username,
  oldPassword,
  newPassword,
  callback
) => {
  const cognitoUser = userPool.getCurrentUser();
  try {
    cognitoUser.getSession((sessionErr) => {
      if (sessionErr) {
        console.log(sessionErr);
      }
    });
  } catch (ex) {
    console.log('Could not get current session');
  }
  cognitoUser.changePassword(oldPassword, newPassword, callback);
};

// forgot password will send email verification code to email prompted for
// regardless of valid email or not, we will prompt for valid input to secure the fact that an email is valid or not
// after
export const sendResetPasswordCode = (username, callback) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  // this will send a verification code to email
  cognitoUser.forgotPassword({
    onSuccess() {},
    onFailure(err) {
      // for security reasons, we will prompt for security question
      console.log('failed', err);
      if (err.code === 'LimitExceededException')
        callback(
          {
            message:
              'Too many attempts to reset password.  Please try again in an hour.',
          },
          null
        );
      else callback({ message: 'Password could not be reset' }, null);
    },
    inputVerificationCode() {
      callback(false, null);
    },
  });
};

export const resetPassword = (
  username,
  verificationCode,
  newPassword,
  callback
) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  cognitoUser.confirmPassword(verificationCode, newPassword, {
    onSuccess() {
      callback(null, { message: 'Password is reset' });
    },
    onFailure(err) {
      if (err.code === 'LimitExceededException')
        callback(
          {
            message:
              'Too many attempts to reset password.  Please try again in an hour.',
          },
          null
        );
      else
        callback(
          {
            message: err.message ? err.message : 'Password could not be reset',
          },
          null
        );
    },
  });
};

export const authenticateUser = (email, password, callback) => {
  if (
    email === '' ||
    password === '' ||
    typeof email === 'undefined' ||
    typeof password === 'undefined'
  )
    callback({ message: 'Please enter credentials' });
  else {
    const authData = {
      Username: email,
      Password: password,
    };
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    const authDetails = new AuthenticationDetails(authData);

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        callback(null, null, result);
      },
      onFailure: (err) => {
        callback(err);
      },
      newPasswordRequired: (userAttributes) => {
        // User was signed up by an admin and must provide new password
        callback(null, userAttributes, cognitoUser);
      },
    });
  }
};

export const setNewPassword = (
  userAttributes,
  cognitoUser,
  newPassword,
  callback
) => {
  // the api doesn't accept this field back
  // eslint-disable-next-line no-param-reassign
  delete userAttributes.email_verified;

  cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
    onSuccess: (result) => {
      callback(null, result);
    },
    onFailure: (err) => {
      callback(err);
    },
  });
};

export const signOut = () => {
  if (userPool.getCurrentUser()) {
    userPool.getCurrentUser().signOut();
  }
  clearStorage();
};

// this is used for users who are logged in and need to keep their session alive
// TODO: look into changing throw error to callback with error
export const refreshToken = (
  username,
  _email,
  _idToken,
  _accessToken,
  existingRefreshToken,
  tokenExpiration,
  signInExpiration,
  callback
) => {
  // TODO: double check now againt token expiration timezone.  revise buffer
  // TODO: refresh is unauthorized {code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Invalid Refresh Token."}
  // token is valid for 1 hour
  const nowTimestamp = Math.floor(new Date() / 1000);

  if (tokenExpiration < nowTimestamp) {
    if (signInExpiration < nowTimestamp) {
      console.log('expired');
      // user has been signed in for 10 hours
      signOut();
      throw new ExpiredSessionError(true);
    } else if (tokenExpiration + 3600 < nowTimestamp) {
      console.log('idled');
      // user has been inactive for at least an hour
      signOut();
      throw new ExpiredSessionError(true);
    } else {
      // renew token
      const userData = {
        Username: username,
        Pool: userPool,
      };
      new CognitoUser(userData).refreshSession(
        new CognitoRefreshToken({ RefreshToken: existingRefreshToken }),
        (err, session) => {
          if (err) {
            console.log('token denied');
            signOut();
            forceSignOut();
          } else {
            const user = JSON.parse(localStorage.getItem('user'));
            user.idToken.jwtToken = session.getIdToken().getJwtToken();
            user.refreshToken.token = session.getRefreshToken().getToken();
            user.tokenExpiration = JSON.parse(
              atob(session.idToken.jwtToken.split('.')[1])
            ).exp;
            localStorage.setItem('user', JSON.stringify(user));
            callback();
          }
        }
      );
    }
  } else {
    callback();
  }
};

/** Wraps the refreshToken function in a promise
 *
 * @param {string} username - username of for user who's token is to be refreshed
 * @param {string} _email = unused
 * @param {string} _idToken = unused
 * @param {string} _accessToken = unused
 * @param {string} existingRefreshToken - user's refresh token
 * @param {number} tokenExpiration - timestamp of when the existing token expires
 * @param {number} signInExpiration - timestamp of when user needs to signin again
 *
 * @returns {Promise<void>} - an empty promise
 */
export const refreshTokenAsync = async (...args) =>
  new Promise((resolve) => {
    refreshToken(...args, resolve);
  });

// TODO: replace console logs for error with error responses
// TODO: userPool.getCurrentUser() doesn't seem to work.  is there a missing set? or variable pass
export const getCurrentUser = (callback) => {
  const cognitoUser = userPool.getCurrentUser();
  //    if (!cognitoUser) return false

  try {
    cognitoUser.getSession((sessionErr) => {
      if (sessionErr) {
        console.log(sessionErr);
      } else {
        cognitoUser.getUserAttributes((attributeErr, attributes) => {
          if (attributeErr) {
            console.log(attributeErr);
          } else {
            callback(attributes);
          }
        });
      }
    });
  } catch (ex) {
    console.log('user does not exist');
  }
};
