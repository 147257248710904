import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IListBoxFilterOption } from '../../repositories/caseRepository';
import './CaseworkerAssigneeCasesFilter.scss';

export default function CaseworkerAssigneeCasesFilter({
  caseworkerIdOptions,
  caseworkerIds,
  mixpanelCallback,
}: {
  caseworkerIdOptions: IListBoxFilterOption[];
  caseworkerIds: number[];
  mixpanelCallback: (eventType: string, incomingScopeChange: object) => void;
}) {
  const selections = useMemo(() => caseworkerIds?.map((id) => id.toString()), [
    caseworkerIds,
  ]);

  const [options, setOptions] = useState<IListBoxFilterOption[]>(
    caseworkerIdOptions
  );
  const [selectedSubGroups, setSelectedSubGroups] = useState<string[]>(
    selections
  );
  const [search, setSearch] = useState<string>('');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setSelectedSubGroups(selections);
  }, [caseworkerIds, selections]);

  useEffect(() => {
    setOptions(caseworkerIdOptions);
    if (caseworkerIds && caseworkerIds.length > 0) {
      // if we had other caseworkers selected, but now filter is incompatible
      // ex: I am caseworker A, have caseworker B selected in filters and select 'My cases' - caseworker B should be cleared from selection
      const filteredCaseworkerIds: (string | null)[] = [];
      const caseworkerOptions = caseworkerIdOptions.map(
        (option) => option.value
      );
      selections.forEach((caseworkerId) => {
        if (caseworkerOptions.includes(caseworkerId.toString())) {
          filteredCaseworkerIds.push(caseworkerId);
        }
      });
      const query = queryString.parse(location.search, {
        arrayFormat: 'comma',
      });
      const queryObject = {
        ...query,
        caseworkerIds: filteredCaseworkerIds,
      };
      history.replace({
        search: queryString.stringify(queryObject, { arrayFormat: 'comma' }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseworkerIdOptions]);

  const onFilterChange = (e: CheckboxChangeParams) => {
    const selectedFilters = [...selectedSubGroups];
    if (e.checked) {
      selectedFilters.push(e.value as string);
    } else {
      selectedFilters.splice(selectedFilters.indexOf(e.value as string), 1);
    }
    const query = queryString.parse(location.search, { arrayFormat: 'comma' });
    const queryObject = {
      ...query,
      page: '1',
      caseworkerIds: selectedFilters,
    };
    history.push({
      search: queryString.stringify(queryObject, { arrayFormat: 'comma' }),
    });
    setSelectedSubGroups(selectedFilters);
    mixpanelCallback('caseworker-assignee-filter-change', selectedFilters);
  };

  useEffect(() => {
    const matches = search.length
      ? caseworkerIdOptions.filter((cw) =>
          cw.label.toLocaleLowerCase().includes(search.toLowerCase())
        )
      : caseworkerIdOptions;
    setOptions(matches);
  }, [search]);

  return (
    <div className={'caseworker-filter'}>
      <fieldset className='border-none'>
        <legend>
          <h5>
            CASEWORKERS{' '}
            {selectedSubGroups.length > 0
              ? `(${selectedSubGroups.length})`
              : ''}
          </h5>
        </legend>
        <span className='p-input-icon-right'>
          <i className='pi pi-search text-sm' />
          <InputText
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className='mb-2'
          />
        </span>{' '}
        <div className='caseworker-list pt-2 pl-3'>
          {options.map((cw: { value: string; label: string }) => (
            <div key={cw.value} className='field-checkbox'>
              <Checkbox
                inputId={cw.value}
                name='filter'
                value={cw.value}
                onChange={onFilterChange}
                checked={selectedSubGroups.includes(cw.value)}
                multiple
              />
              <label htmlFor={cw.value}>
                <p>{cw.label}</p>
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
