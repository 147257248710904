/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Select from 'react-select';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { RiskLabel } from './RiskLabel';
import mixpanel from '../libs/Mixpanel.ts';
import { callAPI } from '../libs/API';
import getRiskFactorPopup from './getRiskFactorPopup';

// TODO: elegant way of adding popup as a whole
export const CaseNoteFeedbackPopup = forwardRef(
  ({ shown, factors, displayToastError, impersonation }, ref) => {
    const INITIAL_STATE = {
      shown,
      sending: false,
      sent: false,
      feedback: '',
      error: '',
    };
    const [feedbackPopup, setFeedbackPopup] = useState(INITIAL_STATE);
    const [status, setStatus] = useState();
    const [theCase, setCase] = useState({
      caseID: null,
      noteID: null,
      sourceID: '',
      selectedFactors: [],
      originalFactors: [],
      overrideFactors: false,
      sentence: {},
      formattedSentence: {},
      sentenceDetails: [],
      riskKey: '',
    });

    useImperativeHandle(ref, () => ({
      showModal(
        caseID,
        noteID,
        sourceID,
        formattedSentence,
        selectedFactors,
        sentence,
        sentenceDetails,
        classNames,
        riskKey
      ) {
        const sortedSelectedFactors = selectedFactors
          ?.filter((factorKey) => factors[factorKey])
          ?.map((factorKey) => {
            const riskLabel = (
              <RiskLabel
                riskKey={factorKey}
                addIcons={false}
                key={`factor-label-${factorKey}`}
                factors={factors}
              />
            );
            return {
              value: factorKey,
              label: riskLabel,
              labelText: factors[factorKey]?.label,
              description: factors[factorKey]?.description,
            };
          });

        setCase({
          caseID,
          noteID,
          sourceID,
          originalFactors: selectedFactors,
          overrideFactors: sortedSelectedFactors.length === 0,
          selectedFactors: sortedSelectedFactors,
          formattedSentence,
          sentence,
          sentenceDetails,
          riskKey,
        });
        setStatus('');
        setFeedbackPopup({
          ...feedbackPopup,
          shown: true,
          sending: false,
        });
      },
    }));

    const hideModal = () => {
      setFeedbackPopup({
        ...feedbackPopup,
        shown: false,
        sending: false,
      });
      setStatus('');
    };

    const sendFeedback = () => {
      if (!impersonation) {
        setFeedbackPopup({
          ...feedbackPopup,
          sending: true,
        });
        // NOTE: this used to have multiple sentences, and now is sentence by sentence.  Leaving as is in case feature is requested later
        let factorUpdates = [];
        // new or existing factors
        (theCase.selectedFactors || []).forEach((selectedFactor) => {
          const sentiment =
            theCase?.originalFactors?.filter(
              (originalFactor) =>
                originalFactor === (selectedFactor?.value || selectedFactor)
            )?.length === 1
              ? 0
              : 2;
          if (selectedFactor?.value || selectedFactor) {
            // TODO: this syntax shouldn't exist.  object should be normalized
            factorUpdates = [
              ...factorUpdates,
              {
                code: selectedFactor?.value || selectedFactor,
                sentiment,
              },
            ];
          }
        });

        // removed factors
        (theCase?.originalFactors || []).forEach((originalFactor) => {
          const sentiment =
            theCase.selectedFactors?.filter(
              (selectedFactor) =>
                originalFactor === (selectedFactor?.value || selectedFactor)
            )?.length === 1
              ? 0
              : -1;
          if (sentiment !== 0) {
            factorUpdates = [
              ...factorUpdates,
              {
                code: originalFactor,
                sentiment,
              },
            ];
          }
        });

        const feedback = {
          case_id: theCase.caseID,
          note_id: theCase.noteID,
          source_id: theCase.sourceID,
          selection: theCase.sentenceDetails,
          factors: factorUpdates,
        };

        callAPI(
          `/cases/${theCase.caseID}/notes/feedback`,
          feedback,
          (resp) => {
            if (resp.success) {
              setStatus('success');
            } else {
              displayToastError({
                severity: 'error',
                summary: 'Could not send note feedback',
                detail: '',
              });
            }
          },
          () => {
            displayToastError({
              severity: 'error',
              summary: 'Could not send note feedback',
              detail: '',
            });
          }
        );
      } else {
        displayToastError({
          severity: 'error',
          summary: 'Feedback not allowed with impersonation',
          detail: '',
        });
      }
    };

    if (!feedbackPopup.shown) return '';
    const sortedFactors = Object.keys(factors).sort((factorKey1, factorKey2) =>
      factors[factorKey1].label > factors[factorKey2].label ? 1 : -1
    );

    const factorArray = [
      {
        label: 'Risk Factors',
        options: sortedFactors
          .filter((factorKey) => factors[factorKey].type === 'negative')
          .map((factorKey) => {
            const riskLabel = (
              <RiskLabel
                riskKey={factorKey}
                addIcons={false}
                key={`factor-label-${factorKey}`}
                factors={factors}
              />
            );
            return {
              value: factorKey,
              label: riskLabel,
              labelText: factors[factorKey].label,
              description: factors[factorKey].description,
            };
          }),
      },
      {
        label: 'Protective Factors',
        options: sortedFactors
          .filter((factorKey) => factors[factorKey].type === 'positive')
          .map((factorKey) => {
            const riskLabel = (
              <RiskLabel
                riskKey={factorKey}
                addIcons={false}
                key={`factor-label-${factorKey}`}
                factors={factors}
              />
            );
            return {
              value: factorKey,
              label: riskLabel,
              labelText: factors[factorKey].label,
              description: factors[factorKey].description,
            };
          }),
      },
    ];

    const factorSelector = (
      <Select
        id='factorSelector'
        defaultValue={theCase.selectedFactors}
        options={factorArray}
        onChange={(e) => {
          setCase({
            ...theCase,
            selectedFactors: e?.map((factor) => factor.value),
          });
        }}
        filterOption={(option, searchText) =>
          option.data.labelText
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          option.data.description
            .toLowerCase()
            .includes(searchText.toLowerCase())
        }
        isMulti
      />
    );

    const feedbackProgress = feedbackPopup.sending ? (
      <div className='col-12'>
        <br />
        <ProgressBar mode='indeterminate' />
      </div>
    ) : (
      ''
    );
    let feedbackPanel;
    let popoutHeader;

    if (status === 'success') {
      popoutHeader = 'Thank You!';
      feedbackPanel = (
        <div className='grid'>
          <div className='col-12'>
            We appreciate your feedback. Our team will review your input
            shortly.
          </div>
          <div className='col-12 right'>
            <Button
              label='Close'
              onClick={() => {
                setStatus('');
                setFeedbackPopup({
                  ...feedbackPopup,
                  sending: false,
                });
                hideModal();
              }}
            />
          </div>
        </div>
      );
    } else if (
      theCase.originalFactors.length === 0 ||
      theCase.overrideFactors ||
      (theCase.originalFactors.length !== 0 &&
        theCase.selectedFactors.length === 0)
    ) {
      // new sentence selected
      mixpanel.track('case-note-factor-tooltip-open-untagged');
      popoutHeader = 'Which risks or strengths does this text contain?';
      feedbackPanel = (
        <div className='grid'>
          <div className='col-2'>
            <svg viewBox='0 0 24 24' height='50' width='50' className='quotes'>
              <path d='M0 0h24v24H0V0z' fill='none' />
              <path d='M18.62 18h-5.24l2-4H13V6h8v7.24L18.62 18zm-2-2h.76L19 12.76V8h-4v4h3.62l-2 4zm-8 2H3.38l2-4H3V6h8v7.24L8.62 18zm-2-2h.76L9 12.76V8H5v4h3.62l-2 4z' />
            </svg>
          </div>
          <div className='col-10'>
            <i>{theCase.formattedSentence}</i>
          </div>
          <div className='col-12'>
            <b>Help us improve accuracy by correctly labeling this sentence</b>
          </div>
          <div className='col-12'>{factorSelector}</div>
          <div className='col-12 right'>
            <Button
              label='Submit'
              onClick={() => {
                mixpanel.track('case-note-label-feedback', {
                  'human label': 'false negative',
                });
                sendFeedback();
              }}
            />
          </div>
          {feedbackProgress}
        </div>
      );
    } else {
      // i icon clicked
      mixpanel.track('case-note-factor-tooltip-open');
      const results = getRiskFactorPopup({ factors, theCase, setCase });
      popoutHeader = results.popoutHeader;
      feedbackPanel = results.feedbackPanel;
    }

    return (
      <Dialog
        header={popoutHeader}
        visible={feedbackPopup.shown}
        modal
        closeOnEscape
        dismissableMask
        onHide={hideModal}
        className='caseNoteFeedbackPopup'
      >
        {feedbackPanel}
      </Dialog>
    );
  }
);
