import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import { callAPI } from '../../libs/API';
import { Page, LoadingContainer } from '../../components-new';
import { verifyEmail } from '../../utils/VerifyEmail';

// TODO: manager column or org view
// TODO: create a middle component to extend for simple functions like displayError
export default class AdminUsersAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //            profile: this.props.profile,
      impersonation: this.props.impersonation,
      isLoaded: false,
      adding: false,
      email: '',
      sourceId:'',
      firstName: '',
      lastName: '',
      userType: '',
      managerName: '',
      managerId: '',
      agencyId: '',
      groupId: '',
      staffPosition: '',
      grants: '',
      error: '',
      errorMessage: '',
      isAIAdmin: this.props.profile.user_type === 'augintel_admin',
    };

    if (!this.state.isAIAdmin || this.state.impersonation)
      this.props.history.push('/');

    this.toast = React.createRef();
    this.addItem = this.addItem.bind(this);
    this.displayError = this.displayError.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    callAPI(
      '/admin/groups',
      null,
      (resp) => {
        if (typeof resp.agencies !== 'undefined') {
          this.setState({
            isLoaded: true,
            agencies: resp.agencies !== null ? resp.agencies.filter(agency => agency.allow_add_user) : [],
          });
        } else {
          this.setState({ isLoaded: true, error: 'Agencies not found' });
        }
      },
      (error) => {
        this.setState({ isLoaded: true, error: error.message });
      }
    );
  }

  searchManagers(name, agencyId) {
    callAPI(
      `/admin/users?searchAgency=${agencyId}&searchFullName=${name}`,
      null,
      (resp) => {
        if (typeof resp.users !== 'undefined') {
          this.setState({
            managerSuggestions: resp.users !== null ? resp.users : [],
          });
        } else {
          this.displayError({ message: 'Agencies not found' });
        }
      },
      () => {
        this.displayError({ message: 'Failed to search managers' });
      }
    );
  }

  async addItem() {
    if (await verifyEmail(this.state.agencyId, this.state.email)) {
      if (
        !this.state.adding &&
        this.state.email !== '' &&
        this.state.userType !== '' &&
        this.state.agencyId !== '' &&
        this.state.groupId !== ''
      ) {
        try {
          this.setState({ adding: true, errorMessage: '' }, () => {
            callAPI(
              '/admin/users/add',
              {
                email: this.state.email,
                source_id: this.state.sourceId,
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                user_type: this.state.userType,
                agency_id: this.state.agencyId,
                group_id: this.state.groupId,
                manager_id: this.state.managerId,
                staff_position: this.state.staffPosition,
                grant_array: this.state.grants
              },
              (resp) => {
                if (resp.success) {
                  this.props.history.push('/admin/users');
                } else if (resp.sourceIdError) {
                  this.displayError({ message: 'Profile already exists' });
                } else {
                  this.displayError({ message: 'User could not be added' });
                }
              },
              (error) => {
                this.setState({
                  adding: false,
                  error: error.message,
                });
              }
            );
          });
        } catch (ex) {
          this.setState({ adding: false, error: 'Unknown error...' });
        }
      } else {
        this.setState({
          adding: false,
          errorMessage: 'Please complete add form',
        });
      }
    } else {
      this.setState({
        adding: false,
        errorMessage: 'Check email domain or format'
      })
    }
  }

  displayError(error) {
    if (error.message.startsWith('Session')) {
      this.props.history.push(
        `/login?errorMessage=${error.message}&sourcePath=${window.location.path}`
      );
    } else {
      this.setState({
        adding: false,
        errorMessage: error.message,
      });
      this.toast.current.show({ severity: 'error', summary: error.message });
    }
  }

  render() {
    const userTypeOptions = [
      { enumCode: 'normal', label: 'Normal' },
      { enumCode: 'agency_admin', label: 'Agency Admin' }
    ];

    const grantOptions = [
      { enumCode: 'all_access', label: 'Access to all cases' },
      { enumCode: 'my_division', label: 'Access to cases in their group and child groups' },
      { enumCode: 'my_team', label: 'Access to their cases and any cases granted to members in their team' },
      { enumCode: 'my_cases', label: 'Accesss to cases directly assigned' },
      { enumCode: 'my_connected_cases', label: 'Accesss to any cases connected to their cases' },
      { enumCode: 'group_connected_cases', label: 'Access to all cases connected to cases in their group or below' }
    ];

    const groupOptions =
      this.state.agencies && this.state.agencyId !== ''
        ? this.state.agencies.filter(
            (item) => item.id === this.state.agencyId
          )[0].groups
        : [{ id: '', heading: 'Agency selection required' }];

    return (
      <Page
        title='Add User'
        pageTitle='Users Admin | Augintel'
        isLoaded={this.state.isLoaded}
        toast={this.toast}
        error={this.state.error}
        errorMessage={this.state.errorMessage}
        maxWidth={600}
      >
        <LoadingContainer isLoaded={!this.state.adding}>
          <div className='adminUsersAddBody grid adminInputForm'>
            <div className='col-12 input'>
              <InputText
                id='firstName'
                value={this.state.firstName}
                keyfilter={/^[^<>@%$]*$/}
                onChange={(e) => {
                  this.setState({ firstName: e.target.value });
                }}
                placeholder='First Name (required)'
              />
              <label className='accessibility' htmlFor='firstName'>
                First Name
              </label>
            </div>
            <div className='col-12 input'>
              <InputText
                id='lastName'
                value={this.state.lastName}
                keyfilter={/^[^<>@%$]*$/}
                onChange={(e) => {
                  this.setState({ lastName: e.target.value });
                }}
                placeholder='Last Name (required)'
              />
              <label className='accessibility' htmlFor='lastName'>
                Last Name
              </label>
            </div>
            <div className='col-12 input'>
              <Dropdown
                id='agencyId'
                value={this.state.agencyId}
                options={this.state.agencies}
                optionLabel='name'
                optionValue='id'
                onChange={(e) => {
                  this.setState({ agencyId: e.value });
                }}
                placeholder='Agency (required)'
              />
              <label className='accessibility' htmlFor='agencyId'>
                Agency
              </label>
            </div>
            <div className='col-12 input'>
              <InputText
                id='email'
                value={this.state.email}
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
                placeholder='Email Address (required)'
              />
              <label className='accessibility' htmlFor='email'>
                Email
              </label>
            </div>
            <div className='col-12 input'>
              <InputText
              id='sourceId'
              value={this.state.sourceId}
              onChange={(e) => {
                this.setState({ sourceId: e.target.value });
              }}
              placeholder='Worker Id (required)'
              />
              <label className='accessibility' htmlFor='sourceId'>
                Source ID
              </label>
            </div>
            <div className='col-12 input'>
              <InputText
              id='staffPosition'
              value={this.state.staffPosition}
              onChange={(e) => {
                this.setState({ staffPosition: e.target.value });
              }}
              placeholder='Staff Position (optional)'
              />
              <label className='accessibility' htmlFor='staffPosition'>
                Staff Position
              </label>
            </div>
            <div className='col-12 input'>
              <MultiSelect
                id='grants'
                value={this.state.grants}
                options={grantOptions}
                display="chip"
                optionLabel='label'
                optionValue='enumCode'
                onChange={(e) => {
                  this.setState({ grants: e.value });
                }}
                placeholder='Grant Type (required)'
              />
              <label className='accessibility' htmlFor='grants'>
                Grant Type
              </label>
            </div>
            <div className='col-12 input'>
              <Dropdown
                id='userType'
                value={this.state.userType}
                options={userTypeOptions}
                optionLabel='label'
                optionValue='enumCode'
                onChange={(e) => {
                  this.setState({ userType: e.value });
                }}
                placeholder='User Type (optional)'
              />
              <label className='accessibility' htmlFor='userType'>
                User Type
              </label>
            </div>
            <div className='col-12 input'>
              <Dropdown
                id='groupId'
                value={this.state.groupId}
                options={groupOptions}
                optionLabel='name'
                optionValue='id'
                onChange={(e) => {
                  this.setState({ groupId: e.value });
                }}
                placeholder='Group (required)'
              />
              <label className='accessibility' htmlFor='groupId'>
                Group
              </label>
            </div>
            <div className='col-12 input'>
              <AutoComplete
                id='managerId'
                placeholder='Manager (optional)'
                value={this.state.managerName}
                onChange={(e) => {
                  this.setState({
                    managerName: e.value,
                    managerId: e.value.profile_id,
                  });
                }}
                itemTemplate={(searchEntry) =>
                  `${searchEntry?.first_name} ${searchEntry?.last_name}`
                }
                selectedItemTemplate={(searchEntry) =>
                  `${searchEntry?.first_name} ${searchEntry?.last_name}`
                }
                completeMethod={(e) => {
                  this.searchManagers(e.query, this.state.agencyId);
                }}
                suggestions={this.state.managerSuggestions}
              />
              <label className='accessibility' htmlFor='managerId'>
                Manager Profile ID
              </label>
            </div>
            <div className='col-12 buttonPanel right'>
              <Button
                label='Add User'
                className='p-button-danger'
                onClick={() => {
                  this.addItem();
                }}
              />
              <Button
                label='Cancel'
                className='p-button'
                onClick={() => {
                  this.props.history.push('/admin/users');
                }}
              />
            </div>
          </div>
        </LoadingContainer>
      </Page>
    );
  }
}
