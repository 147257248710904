import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';
import { palette } from '../../styles/palette';
import useDimensionsDeprecated from '../../utils/useDimensionsDeprecated';

const useStyles = makeStyles(() => ({
  container: ({ maxWidth }) => ({
    padding: '20px 30px',
    maxWidth: maxWidth || 'unset',
  }),
  header: ({ headerSpacing }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: headerSpacing > -1 ? headerSpacing : 10,
  }),
  heading: ({ width }) => ({
    fontSize: width < 800 ? '1.5rem' : '38px',
  }),
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    marginRight: 16,
    '&:last-of-type': {
      margin: 0,
    },
  },
  error: {
    padding: '8px 16px',
    backgroundColor: palette.grey.lighter,
    borderRadius: 4,
    border: `solid 1px ${palette.grey.normal}`,
    color: palette.error.darker,
    marginTop: 16,
    marginBottom: 10,
  },
  errorMessage: {
    color: palette.error.darker,
    marginBottom: 10,
    marginTop: 10,
  },
}));

export const Page = ({
  title,
  pageTitle,
  toast,
  actions,
  isLoaded = true,
  error = '',
  errorMessage = '',
  maxWidth,
  headerSpacing,
  children,
  componentClassName,
}) => {
  const { width } = useDimensionsDeprecated();
  const classes = useStyles({ maxWidth, headerSpacing, width });

  useEffect(() => {
    if (pageTitle) {
      document.title = pageTitle;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {toast && <Toast ref={toast} />}
      {!isLoaded ? (
        <ProgressBar mode='indeterminate' />
      ) : (
        <div
          className={[componentClassName, classes.container].join(' ')}
          data-testid='pageBody'
        >
          <div className={`${classes.header} headerContainer`}>
            {title && (
              <h1 className={classes.heading} data-testid='pageHeader'>
                {title}
              </h1>
            )}
            {actions && !error && (
              <div className={classes.actions}>
                {actions.map((action, idx) => (
                  <div key={`action-${idx}`} className={classes.action}>
                    {action}
                  </div>
                ))}
              </div>
            )}
          </div>
          {error ? (
            <h4 className={classes.error} data-testid='error'>
              {error}
            </h4>
          ) : (
            <>
              {errorMessage && (
                <h4 className={classes.errorMessage}>{errorMessage}</h4>
              )}
              {children}
            </>
          )}
        </div>
      )}
    </>
  );
};
