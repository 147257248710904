export function formatDate(date: Date) {
  // we are creating dates by passing in new Date(yyyy-mm-dd) - this is automatically set in UTC
  return date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    timeZone: 'UTC',
  });
}

export function parseDates(
  startDate: string | null,
  endDate: string | null,
  isActive: boolean
) {
  const dateRange = { startDate: '', endDate: '' };
  try {
    if (startDate) {
      dateRange.startDate = formatDate(new Date(startDate));
      dateRange.endDate =
        endDate && !isActive ? formatDate(new Date(endDate || '')) : 'Active';
    } else {
      dateRange.startDate = '';
      dateRange.endDate = isActive ? 'Active' : 'Inactive';
    }
  } catch (e) {
    console.error('ERROR CATCH: ', e);
    dateRange.startDate = '';
    dateRange.endDate = isActive ? 'Active' : 'Inactive';
  }
  return dateRange;
}
