import React from 'react';

const getRiskFactorPopup = ({ factors, theCase, setCase }) => {
  const popoutHeader = `AugIntel has detected a ${
    factors[theCase.riskKey]?.type === 'negative'
      ? 'risk'
      : 'protective'
  } factor in this text`;

  const feedbackPanel = (
    <div className='grid'>
      <div className='col-2'>
        <svg viewBox='0 0 24 24' height='50' width='50' className='quotes'>
          <path d='M0 0h24v24H0V0z' fill='none' />
          <path d='M18.62 18h-5.24l2-4H13V6h8v7.24L18.62 18zm-2-2h.76L19 12.76V8h-4v4h3.62l-2 4zm-8 2H3.38l2-4H3V6h8v7.24L8.62 18zm-2-2h.76L9 12.76V8H5v4h3.62l-2 4z' />
        </svg>
      </div>
      <div className='col-10'>
        <i>{theCase.formattedSentence}</i>
      </div>
      <div className='col-12'>
        <b>
          {factors[theCase.riskKey]?.type === 'negative'
            ? 'Risk'
            : 'Protective'}{' '}
          Factor: {factors[theCase.riskKey]?.label}
        </b>
        <br />
      </div>
      <div className='col-12'>
        Definition: {factors[theCase.riskKey]?.description}
      </div>
      <div className='col-12'>
        If this looks wrong,{' '}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className='aLink'
          onClick={() => {
            setCase({ ...theCase, overrideFactors: true });
          }}
        >
          let us know
        </div>
        .
      </div>
    </div>
  );

  return {popoutHeader, feedbackPanel};
};

export default getRiskFactorPopup;