/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import mixpanel from '../../libs/Mixpanel.ts';
import { BubbleTimelineChart } from './BubbleTimelineChart';

// TODO: elegant way of adding popup as a whole here instead of just contents?
export const CasePeopleBubbleChart = ({
  monthSpan,
  searchNotes,
  person,
  displayEntityPopup,
  shown,
}) => {
  const showPersonNotes = () => {
    mixpanel.track('case-filter-person', { person: 'PHI' });
    searchNotes(false, '', '', { entityName: person.name, id: person.id });
  };

  const handleEntityPopup = () => {
    displayEntityPopup(person);
  };

  const bubbleClick = (personMonthYear) => {
    mixpanel.track('case-filter-person', {
      person: 'PHI',
      month: personMonthYear,
    });
    searchNotes(false, personMonthYear, '', {
      entityName: person.name,
      id: person.id,
    });
  };

  if (!person || !shown) return '';
  const color = { stroke: '#585858', fill: '#e3e3e3' };
  const relationKeys = Object.keys(person.relations); // e.g.: age, case_role, phone_number
  const allRelations = relationKeys
    .map((relationKey) => person.relations[relationKey])
    .flat();
  const confidentRelationLabels = allRelations.map(
    (relationEntry) => relationEntry.label
  );

  const relationPopupLink =
    confidentRelationLabels.length > 0 || person?.mentions.length ? (
      <div
        className='aLink iconLink entityLabel infoIcon'
        onClick={handleEntityPopup}
      >
        <svg viewBox='0 0 13 13'>
          <path d='M5.85 9.75H7.15V5.85H5.85V9.75ZM6.5 0C2.925 0 0 2.925 0 6.5C0 10.075 2.925 13 6.5 13C10.075 13 13 10.075 13 6.5C13 2.925 10.075 0 6.5 0ZM6.5 11.7C3.64 11.7 1.3 9.36 1.3 6.5C1.3 3.64 3.64 1.3 6.5 1.3C9.36 1.3 11.7 3.64 11.7 6.5C11.7 9.36 9.36 11.7 6.5 11.7ZM5.85 4.55H7.15V3.25H5.85V4.55Z' />
        </svg>
      </div>
    ) : (
      ''
    );

  const relation =
    confidentRelationLabels.length > 0 ? (
      <div className='entityLabel '>{confidentRelationLabels.join(', ')}</div>
    ) : (
      ''
    );

  return (
    <div className='bubbleTimeline' key={`people-${person.id}`}>
      <div className='aLink entityLink' onClick={showPersonNotes}>
        {person.name}
      </div>
      {relationPopupLink}
      {relation}
      <BubbleTimelineChart
        data={person.data}
        monthSpan={monthSpan}
        colors={color}
        onClick={bubbleClick}
        label={false}
        width='100%'
      />
    </div>
  );
};
