/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect, ReactElement, ReactNode } from 'react';
import { RiskLabel } from './RiskLabel';
import {
  CaseEntity,
  NoteSentence,
  SearchMatch,
} from '../shared/types/noteTypes';
import { Factors } from '../shared/types/appTypes';

interface ICaseNoteSentenceProps {
  text: string;
  caseID: number;
  noteID: number;
  sourceID: string;
  tokens: SearchMatch[];
  factors: Factors;
  entities: CaseEntity[];
  sentence: NoteSentence;
  sentenceI: number;
  paragraphI: number;
  displayClass: string;
  searchQueryID?: string | number;
  getTaggingEnabled: () => boolean;
  displaySentenceFeedback?: (
    caseID: number,
    noteID: number,
    sourceID: string,
    formattedSentence: ReactNode[], // Result of formatSearch()
    sentenceFactorsKeys: string[],
    sentence: NoteSentence,
    sentenceDetails: { sentenceI: number; paragraphI: number; text: string }[],
    classNames?: string,
    riskKey?: string
  ) => void;
  displayEntityPopupByID?: (id: number) => void;
}
export const CaseNoteSentence = ({
  text,
  caseID,
  noteID,
  sourceID,
  tokens,
  factors,
  entities,
  sentence,
  sentenceI,
  paragraphI,
  displayClass,
  searchQueryID,
  getTaggingEnabled,
  displaySentenceFeedback,
  displayEntityPopupByID,
}: ICaseNoteSentenceProps) => {
  const [classNames, setClassNames] = useState<string>();
  const [formattedSentence, setFormattedSentence] = useState<ReactNode[]>([]);

  // Find entities in sentence and bold them
  const formatEntities = (
    sentenceText: string,
    entitiesParam: CaseEntity[]
  ) => {
    if (entitiesParam && entitiesParam.length > 0) {
      let fragments = [] as ReactNode[];
      let previousEntityEndChar = 0;
      if (entitiesParam.find((e: CaseEntity) => e.case_entity_id)) {
        entitiesParam
          .filter((e) => e.case_entity_id)
          .forEach((entity, entityI) => {
            const relationPopupLink =
              displayEntityPopupByID && !getTaggingEnabled() ? (
                <div
                  className='aLink iconLink entityLabel infoIcon'
                  onClick={() => displayEntityPopupByID(entity.case_entity_id)}
                  key={`entity-i-${noteID}-${entityI}`}
                >
                  <svg viewBox='0 0 13 13'>
                    <path d='M5.85 9.75H7.15V5.85H5.85V9.75ZM6.5 0C2.925 0 0 2.925 0 6.5C0 10.075 2.925 13 6.5 13C10.075 13 13 10.075 13 6.5C13 2.925 10.075 0 6.5 0ZM6.5 11.7C3.64 11.7 1.3 9.36 1.3 6.5C1.3 3.64 3.64 1.3 6.5 1.3C9.36 1.3 11.7 3.64 11.7 6.5C11.7 9.36 9.36 11.7 6.5 11.7ZM5.85 4.55H7.15V3.25H5.85V4.55Z' />
                  </svg>
                </div>
              ) : (
                ''
              );

            let startChar = entity.start_char;
            if (previousEntityEndChar > entity.start_char) {
              startChar = previousEntityEndChar;
            }

            const textBeforeEntity = sentenceText.substring(
              previousEntityEndChar,
              startChar
            );

            previousEntityEndChar = entity.end_char;

            const entityText = (
              <span className='entity' key={`entity-u-${noteID}-${entityI}`}>
                {sentenceText.substring(startChar, entity.end_char)}
              </span>
            );
            const textAfterEntity = sentenceText.substring(entity.end_char);

            fragments = [
              ...fragments.slice(0, fragments.length - 1),
              textBeforeEntity,
              entityText,
              relationPopupLink,
              textAfterEntity,
            ];
          });
      } else {
        fragments = [sentenceText || ''];
      }
      return fragments;
    }
    return [sentenceText || ''];
  };

  const sentenceClick = () => {
    if (displaySentenceFeedback && getTaggingEnabled() && formattedSentence) {
      displaySentenceFeedback(
        caseID,
        noteID,
        sourceID,
        formattedSentence,
        sentence.factors,
        sentence,
        [{ paragraphI, sentenceI, text: sentence.text }],
        classNames
      );
    }
  };

  const labelIClick = (riskKey: string) => {
    if (displaySentenceFeedback) {
      displaySentenceFeedback(
        caseID,
        noteID,
        sourceID,
        formattedSentence,
        sentence.factors,
        sentence,
        [{ paragraphI, sentenceI, text: sentence.text }],
        classNames,
        riskKey
      );
    }
  };

  let riskFactorsTranslated = [] as ReactElement[];
  let highLightColorClass: string[] = [];

  if (sentence.queries?.find((q) => q.query_id === Number(searchQueryID))) {
    highLightColorClass = [...highLightColorClass, 'queryHighlight'];
  }

  if (tokens.length) {
    highLightColorClass = [...highLightColorClass, 'searchMatch'];
  }

  sentence.factors?.forEach((riskKey) => {
    riskFactorsTranslated = [
      ...riskFactorsTranslated,
      <RiskLabel
        riskKey={riskKey}
        addIcons
        key={`caseNoteRisk${paragraphI}-${sentenceI}${riskKey}`}
        factors={factors}
        iClick={() => labelIClick(riskKey)}
        highLightColorClass={highLightColorClass}
      />,
    ];
    if (factors[riskKey]?.type === 'negative')
      highLightColorClass = [...highLightColorClass, 'riskSentence'];
    else if (factors[riskKey]?.type === 'positive')
      highLightColorClass = [...highLightColorClass, 'protectiveSentence'];
  });

  useEffect(() => {
    const formattedEntities = formatEntities(text, entities);
    setFormattedSentence(formattedEntities);
    setClassNames(
      ['noteSentence', highLightColorClass.join(' '), displayClass].join(' ')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span
      className={classNames}
      key={`caseNoteHighlighted-${noteID}-${paragraphI}-${sentenceI}`}
      onClick={sentenceClick}
    >
      {formattedSentence} <span>{riskFactorsTranslated}</span>
    </span>
  );
};
