/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDebounce } from '../../utils';
import { callAPI } from '../../libs/API';
import { Page, LoadingContainer } from '../../components-new';


export const AdminGroups = ({ profile }) => {
  const toast = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const [pageError, setPageError] = useState();
  const [availableGroups, setAvailableGroups] = useState();
  const [availableAgencies, setAvailableAgencies] = useState();
  const [filterByGroup, setFilterByGroup] = useState();
  const [filterByAgency, setFilterByAgency] = useState();
  const debouncedGroupFilter = useDebounce(filterByGroup, 1000);
  const debouncedAgencyFilter = useDebounce(filterByAgency, 1000);
  const [first, setFirst] = useState(0);
  const [expandedRows, setExpandedRows] = useState();
  const [tableOpts, setTableOpts] = useState({
    filterLoaded: false,
    groups: [],
    displayGroups: [],
    groupCount: 0,
    sortField: 'name',
    sortOrder: 1,
    pageLimit: 100,
    queryLimit: 100,
  });

  const loadGroups = (clear, setAvailable, offset, sortField, sortOrder) => {
    setTableOpts({
      ...tableOpts,
      filterLoaded: false,
    });

    const sort = sortField || tableOpts.sortField;
    const order = sortOrder || tableOpts.sortOrder;
    const sortQuery = sort ? `&sortField=${sort}` : '';
    const orderQuery = order ? `&sortOrder=${order}` : '';
    const searchGroup = filterByGroup ? `&searchGroup=${filterByGroup}` : '';
    const searchAgency = filterByAgency
      ? `&searchAgency=${filterByAgency}`
      : '';

    callAPI(
      `/admin/agency_groups?limit=${tableOpts.queryLimit}&offset=${
        offset || 0
      }${sortQuery}${orderQuery}${searchGroup}${searchAgency}`,
      null,
      (resp) => {
        if (resp.status === 'error') {
          toast.current?.show({
            severity: 'error',
            summary: resp.error,
            detail: 'Please reload the page',
          });
          setPageError('Failed to Fetch');
        } else if (resp.data && !clear) {
          setTableOpts({
            ...tableOpts,
            groups: [...tableOpts.groups, ...resp.data],
            displayGroups: resp.data,
            groupCount: resp.total,
            sortField: sort,
            sortOrder: order,
            filterLoaded: true,
          });
          if (!availableGroups || setAvailable) {
            setAvailableGroups([...tableOpts.groups, ...resp.data]);
          }
          if (profile.user_type === 'agency_admin') {
            setAvailableAgencies([
              { id: resp.data[0].agencyId, name: resp.data[0].agency },
            ]);
          }
        } else {
          setFirst(0);
          setTableOpts({
            ...tableOpts,
            groups: resp.data,
            displayGroups: resp.data,
            groupCount: resp.total,
            sortField: sort,
            sortOrder: order,
            filterLoaded: true,
          });
          if (setAvailable) {
            setAvailableGroups(resp.data);
          }
        }
        setIsLoaded(true);
      },
      (error) => {
        toast.current?.show({
          severity: 'error',
          summary: error.message,
          detail: '',
        });
        setPageError('Failed to Fetch');
        setIsLoaded(true);
      }
    );
  };

  const loadAgencies = () => {
    callAPI(
      '/admin/agencies',
      null,
      (resp) => {
        if (resp?.agencies?.length) {
          setAvailableAgencies(resp.agencies);
        }
      },
      (error) => {
        toast.current?.show({
          severity: 'error',
          summary: error.message,
          detail: '',
        });
        setIsLoaded(true);
        setPageError('Failed to Fetch');
      }
    );
  };

  useEffect(() => {
    if (!tableOpts.groupCount) {
      loadGroups();
    }
    if (!availableAgencies && profile.user_type === 'augintel_admin') {
      loadAgencies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tableOpts.filterLoaded) {
      loadGroups(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedGroupFilter, debouncedAgencyFilter]);

  const handlePagination = (e) => {
    setFirst(e.first);
    const hasNextPage = tableOpts.groups.slice(
      e.first,
      e.first + tableOpts.pageLimit
    );
    if (hasNextPage.length) {
      setTableOpts({
        ...tableOpts,
        displayGroups: tableOpts.groups.slice(
          e.first,
          e.first + tableOpts.pageLimit
        ),
      });
    } else {
      loadGroups(false, true, e.first);
    }
  };

  const handleSort = (e) => {
    loadGroups(true, false, 0, e.sortField, e.sortOrder);
  };

  const handleRowToggle = (e) => {
    setExpandedRows(e.data);
  };

  const rowExpansionTemplate = (dataRow) => (
    <div style={{ paddingLeft: 24 }}>
      <p>
        <label>
          <b>Agency: </b>
        </label>
        <span>{dataRow.agency}</span>
      </p>
      <p>
        <label>
          <b>Subgroups: </b>
        </label>{' '}
        {dataRow.subGroups ? (
          dataRow.subGroups.map((el, idx) => (
            <p key={el.id}>
              {idx + 1}. {el.name}
            </p>
          ))
        ) : (
          <span>No subgroups</span>
        )}
      </p>
      <p>
        <label>
          <b>Parent Groups: </b>
        </label>{' '}
        {dataRow.parentGroups ? (
          dataRow.parentGroups.map((el, idx) => (
            <p key={el.id}>
              {idx + 1}. {el.name}
            </p>
          ))
        ) : (
          <span>No parent groups</span>
        )}
      </p>
    </div>
  );

  const groupNameFilter = (
    <InputText
      disabled={!tableOpts.filterLoaded}
      defaultValue={filterByGroup}
      onChange={(e) => {
        setFilterByGroup(e.target.value);
      }}
      placeholder='Filter Group Name'
    />
  );

  const agencyFilter = (
    <InputText
      disabled={!tableOpts.filterLoaded}
      defaultValue={filterByAgency}
      onChange={(e) => {
        setFilterByAgency(e.target.value);
      }}
      placeholder='Filter Agency'
    />
  );

  return (
    <Page
      pageTitle='Groups Admin | Augintel'
      isLoaded={isLoaded}
      toast={toast}
      error={pageError}
    >
      <div className='kpis'>
        <span className='kpi'>
          <div className='count'>{tableOpts.groupCount}</div>
          <div className='label'>Total Groups</div>
        </span>
        {profile.user_type === 'augintel_admin' && (
          <span className='kpi'>
            <div className='count'>{availableAgencies?.length}</div>
            <div className='label'>Total Agencies</div>
          </span>
        )}
      </div>
      <LoadingContainer isLoaded={tableOpts.filterLoaded}>
        <DataTable
          lazy
          dataKey='id'
          value={tableOpts.displayGroups}
          paginator={tableOpts.groupCount > tableOpts.pageLimit}
          rows={tableOpts.pageLimit}
          totalRecords={tableOpts.groupCount}
          first={first}
          onPage={handlePagination}
          sortField={tableOpts.sortField}
          sortOrder={tableOpts.sortOrder}
          onSort={handleSort}
          filterDisplay='row'
          expandedRows={expandedRows}
          onRowToggle={handleRowToggle}
          rowExpansionTemplate={rowExpansionTemplate}
          breakpoint='800px'
        >
          <Column style={{ width: '4em' }} expander />
          <Column
            header='Group Name'
            filterElement={groupNameFilter}
            showFilterMenu={false}
            placeholder='Filter Group Name'
            field='name'
            filter
            sortable
          />
          <Column
            field='subGroupsTotal'
            header='Subgroups'
            style={{ textAlign: 'center' }}
            sortable
          />
          <Column
            header='Agency'
            filterElement={agencyFilter}
            showFilterMenu={false}
            placeholder='Filter Agency'
            field='agency'
            filter
            sortable
          />
        </DataTable>
      </LoadingContainer>
    </Page>
  );
};
