import React, { useState, useEffect, useRef } from 'react';
import { Toast, ToastMessage } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import mixpanel from '../libs/Mixpanel';
import { Page } from '../components-new';
import './EmailSettings.scss';
import { PageProps } from '../shared/types/appTypes';
import { callAPIAsync } from '../libs/API';

type SubscriptionOptions = 'newCaseAlertsEnabled' | 'workerSafetyAlertsEnabled';

export const EmailSettings = ({ profile, impersonation }: PageProps) => {
  const toast = useRef<Toast>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [subscriptions, setSubscriptions] = useState<SubscriptionOptions[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const displayToastMessage = (toastConfig: ToastMessage) =>
    toast.current && toast.current.show(toastConfig);

  const getEmailSubscriptions = async () => {
    try {
      const currentSubscriptions = (await callAPIAsync(
        '/emailSettings'
      )) as Record<SubscriptionOptions, boolean>;
      const settings: SubscriptionOptions[] = [];
      Object.keys(currentSubscriptions).forEach((subscription) => {
        const subscriptionOption = subscription as SubscriptionOptions;
        if (currentSubscriptions[subscriptionOption]) {
          settings.push(subscription as SubscriptionOptions);
        }
      });
      setSubscriptions(settings);
      setIsLoaded(true);
    } catch (e) {
      setIsLoaded(true);
      setErrorMessage('Could not load email settings');
    }
  };

  useEffect(() => {
    void getEmailSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdatingError = (formerSubscriptions: SubscriptionOptions[]) => {
    setSubscriptions(formerSubscriptions);
    displayToastMessage({
      severity: 'error',
      summary: 'Could not update email settings',
      detail: '',
    });
  };

  const updateEmailSubscriptions = async (
    subscription: SubscriptionOptions,
    isEnabled: boolean,
    formerSubscriptions: SubscriptionOptions[]
  ) => {
    try {
      const updateMessage = impersonation
        ? `Updating email settings for ${impersonation.email}`
        : 'Updating email settings';
      displayToastMessage({
        severity: 'info',
        summary: updateMessage,
        detail: '',
      });
      mixpanel.track('email-settings-update', {
        subscriptionType: subscription,
        isEnabled,
      });
      const response = await callAPIAsync('/updateEmailSettings', {
        [subscription]: isEnabled,
      });
      if (response.success) {
        displayToastMessage({
          severity: 'success',
          summary: 'Successfully updated email settings',
          detail: '',
        });
      } else {
        handleUpdatingError(formerSubscriptions);
      }
    } catch (e) {
      console.error(e);
      handleUpdatingError(formerSubscriptions);
    }
  };

  const onSubscriptionChange = (e: {
    value: SubscriptionOptions;
    checked: boolean;
  }) => {
    const formerSubscriptions = [...subscriptions];
    const selectedSubscriptions = [...subscriptions];

    if (e.checked) selectedSubscriptions.push(e.value);
    else
      selectedSubscriptions.splice(selectedSubscriptions.indexOf(e.value), 1);

    setSubscriptions(selectedSubscriptions);
    void updateEmailSubscriptions(e.value, e.checked, formerSubscriptions);
  };

  const impersonationMessage = impersonation ? (
    <h4 className='impersonationMessage'>
      Email Settings for {impersonation.email}
    </h4>
  ) : (
    ''
  );

  return (
    <Page
      title='Email Subscriptions'
      pageTitle='Email Settings | Augintel'
      isLoaded={isLoaded}
      toast={toast}
      error={errorMessage}
      componentClassName='emailPreferences'
      headerSpacing={20}
    >
      {impersonationMessage}
      <div className='field-checkbox'>
        <Checkbox
          inputId='newCase'
          value='newCaseAlertsEnabled'
          name='subscription'
          onChange={onSubscriptionChange}
          checked={subscriptions.indexOf('newCaseAlertsEnabled') !== -1}
        />
        <span className='subscriptionLabel'>
          <label className='alertTitle' htmlFor='newCase'>
            New Case/Investigation Alert
          </label>
          <h5 className='alertDescription'>
            Alerts that help caseworkers see connected cases, top risks, etc.
          </h5>
        </span>
      </div>

      <div className='field-checkbox'>
        <Checkbox
          inputId='workerSafety'
          value='workerSafetyAlertsEnabled'
          name='subscription'
          onChange={onSubscriptionChange}
          checked={subscriptions.indexOf('workerSafetyAlertsEnabled') !== -1}
        />
        <span className='subscriptionLabel'>
          <label className='alertTitle' htmlFor='workerSafety'>
            Worker Safety Alert
          </label>
          <h5 className='alertDescription'>
            Alerts that identify when new cases have worker safety concerns
            (guns, violence, large animals, etc.)
          </h5>
        </span>
      </div>
    </Page>
  );
};
