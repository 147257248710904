import React, { Dispatch, RefObject, SetStateAction } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import {
  EmbeddingFilters
} from '../../shared/types/labelingTypes';

type Props = {
  reference: RefObject<OverlayPanel>;
  filters: EmbeddingFilters;
  setFilters: Dispatch<SetStateAction<EmbeddingFilters>>;
};

export default function EmbeddingSearchFilters({
  reference,
  filters,
  setFilters,
}: Props) {
  const setChecked = (e: { checked: boolean, value: string }) => {
    if(e.value === 'noComponents' || e.value === 'noWeakComponents'){
      const tempFilters = { ...filters };
      tempFilters[e.value] = e.checked
      setFilters(tempFilters);
    }
  };

  return (
    <OverlayPanel ref={reference}>
      <div>
        Embedding search happens in the background while labeling sentences in
        the Search tab.
        <br />
        You can choose to search for sentences without any weak
        labeled components or without any components.
      </div>
      <br />
      <Checkbox
        value='noComponents'
        checked={filters.noComponents}
        onChange={(e: { checked: boolean, value: string }) => setChecked(e)}
      />
      <label htmlFor='noComponents'>No Components</label>
      <br />
      <Checkbox
        value='noWeakComponents'
        checked={filters.noWeakComponents}
        onChange={(e: { checked: boolean, value: string }) => setChecked(e)}
      />
      <label htmlFor='noWeakComponents'>No weak labeled components</label>
    </OverlayPanel>
  );
}
