import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { ListBox } from 'primereact/listbox';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import {
  LabeledSentence,
  SentenceLabels,
} from '../../shared/types/labelingTypes';
import { callAPIAsync } from '../../libs/API';
import { ActiveLearningReview } from './ActiveLearningReview';

type Props = {
  sentenceLabels: SentenceLabels;
  components: Record<string, string>[];
  labeledSentences: SentenceLabels;
  proposalSentences: SentenceLabels;
};

export const ActiveLearning = ({
  sentenceLabels,
  components,
  labeledSentences,
  proposalSentences,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [isPrePolling, setIsPrePolling] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState<string>('');
  const [extraComponents, setExtraComponents] = useState<string[]>([]);
  const [s3Key, setS3Key] = useState<string>('');

  const trainModel = async () => {
    if (Object.keys(sentenceLabels).length > 0) {
      setIsLoading(true);
      setError('');
      setSuccess('');
      const labelSentenceIds: { [label: string]: string[] } = {};
      Object.entries(labeledSentences).forEach(
        ([label, sentences]: [string, LabeledSentence[]]) => {
            if(sentences.length > 0) {
                labelSentenceIds[label] = sentences.map((sentence) => sentence.id);
            }
        }
      );
      const proposalSentenceIds: {
        [label: string]: { [id: string]: string };
      } = {};
      Object.entries(proposalSentences).forEach(
        ([label, sentences]: [string, LabeledSentence[]]) => {
          const idScoreMap: { [id: string]: string } = {};
          sentences.forEach((sentence) => {
              idScoreMap[sentence.id] = sentence.score ? sentence.score : ''
          });
          proposalSentenceIds[label] = idScoreMap;
        }
      );
      const request = {
        labelSentenceIds,
        extraComponents,
        proposalSentenceIds,
      };
      await callAPIAsync('/admin/activeLearning', request)
        .then((res: { key: string }) => {
          setIsLoading(false);
          setS3Key(res.key);
          setSuccess('Training kicked off successfully.');
          setIsPrePolling(true);
          // wait 5 min to being polling
          setTimeout(() => {
            setIsPolling(true);
            setIsPrePolling(false);
          }, 300000);
        })
        .catch((err: unknown) => {
          setIsLoading(false);
          console.log(err);
          setError('Error with kicking off training.');
        });
    }
  };

  const anyLabels = () => {
    let anyLabelValues = false;
    Object.values(sentenceLabels).forEach((sentenceLabel) => {
      if (sentenceLabel.length > 0) {
        anyLabelValues = true;
      }
    });
    return Object.keys(sentenceLabels).length > 0 && anyLabelValues;
  };

  const errorMessage = error ? (
    <p className='col-12 center errorMessage'>{error}</p>
  ) : (
    ''
  );

  const successMessage = success ? (
    <p className='col-12 center'>{success}</p>
  ) : (
    ''
  );

  return (
    <div className='sentenceReview'>
      {errorMessage}
      {successMessage}
      {isPrePolling || isPolling ? (
        <ProgressSpinner className='custom-spinner flexRowCenter' />
      ) : (
        ''
      )}
      {isLoading ? <ProgressBar mode='indeterminate' /> : ''}
      <div>Please select any additional components for training:</div>
      <ListBox
        optionLabel='label'
        optionValue='label'
        value={extraComponents}
        options={components}
        onChange={(e: { value: string[] }) => setExtraComponents(e.value)}
        filter
        multiple
        style={{ width: '15rem' }}
        listStyle={{ maxHeight: '250px' }}
      />
      <br />
      <Button
        label='Train model'
        disabled={!anyLabels()}
        className='p-button'
        onClick={() => {
          void trainModel();
        }}
      />
      <ActiveLearningReview
        setError={setError}
        setSuccess={setSuccess}
        setIsLoading={setIsLoading}
        s3Key={s3Key}
        isPolling={isPolling}
        setIsPolling={setIsPolling}
        setIsPrePolling={setIsPrePolling}
      />
    </div>
  );
};
