/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import { Button } from 'primereact/button';
import { signOut } from '../libs/Cognito';
import { Page } from '../components-new';
import { getDecryptedUserAndStoreCachedUser } from '../libs/CognitoTs.ts';

const ERROR_MESSAGE =
  'Error. Please login again. If this issue persists please contact your Customer Success Manager.';

export const SSOLogin = ({ history, handleAuthentication }) => {
  const [loading, setLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [sourcePath, setSourcePath] = useState();
  const [setStatePath] = useState();

  const handleError = (errMessage) => {
    console.log('login error', errMessage);
    setLoading(false);
    setErrorMessage(ERROR_MESSAGE);
    handleAuthentication(false, '');
    signOut();
  };

  const handleOnPageLoad = () => {
    if (window.location.search.includes('?code')) {
      const authData = {
        ClientId: process.env.REACT_APP_CLIENT_ID,
        AppWebDomain: process.env.REACT_APP_TOKEN_DOMAIN,
        TokenScopesArray: ['openid', 'email'],
        RedirectUriSignIn: process.env.REACT_APP_REDIRECT_URI,
        RedirectUriSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT_URI,
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        AdvancedSecurityDataCollectionFlag: false,
      };
      const auth = new CognitoAuth(authData);
      auth.userhandler = {
        onSuccess: async (result) => {
          const user = getDecryptedUserAndStoreCachedUser(result);
          setLoading(true);
          await handleAuthentication(true, user);
          setLoading(false);
          const stateParam = JSON.parse(
            decodeURIComponent(result.state || '{}')
          );
          if (stateParam.sourcePath) {
            history.push(stateParam.sourcePath);
          } else if (stateParam.type) {
            let queryBuild = `/cases/${stateParam.id}?type=${stateParam.type}`;
            if (stateParam.query) {
              queryBuild += `&query=${stateParam.query}`;
            } else if (stateParam.factor) {
              queryBuild += `&factor=${stateParam.factor}`;
            }
            if (stateParam.source) {
              queryBuild += '&utm_source=integration';
            }
            history.push(queryBuild);
          } else if (sourcePath) history.push(sourcePath);
          else history.push('/');
        },
        onFailure: (err) => {
          handleError(err);
        },
      };
      auth.useCodeGrantFlow();
      const curUrl = window.location.href;
      auth.parseCognitoWebResponse(curUrl);
    } else {
      setErrorMessage(ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    const query = window.location.search.startsWith('?')
      ? window.location.search.substring(1).split('&')
      : [];

    const errorParam = query
      .filter((queryString) => queryString.startsWith('errorMessage='))[0]
      ?.split('=')[1];
    const sourcePathParam = query
      .filter((queryString) => queryString.startsWith('sourcePath='))[0]
      ?.split('=')[1];
    const stateParam = query
      .filter((queryString) => queryString.startsWith('state='))[0]
      ?.split('=')[1];
    if (typeof errorMessage !== 'undefined') {
      setErrorMessage(decodeURIComponent(errorParam));
      setSourcePath(decodeURIComponent(sourcePathParam));
      setStatePath(decodeURIComponent(stateParam));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  useEffect(() => {
    handleOnPageLoad();
    handleAuthentication(false, '');
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page isLoaded={!loading} errorMessage={errorMessage}>
      {errorMessage ? (
        <>
          <br />
          <Button
            label='Return to Login'
            onClick={() => history.push('/login')}
          />
        </>
      ) : (
        ''
      )}
    </Page>
  );
};
