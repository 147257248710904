import React, { RefObject, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import {
  AutoComplete,
  AutoCompleteCompleteMethodParams,
} from 'primereact/autocomplete';

type Props = {
  reference: RefObject<OverlayPanel>;
  options: Record<string, unknown>[];
  positive: Record<string, unknown>[];
  negative: Record<string, unknown>[];
  setPositive: (...args: any[]) => void;
  setNegative: (...args: any[]) => void;
};

export default function LabelingFilterOverlay({
  reference,
  options,
  positive,
  negative,
  setPositive,
  setNegative,
}: Props) {
  const [positiveOptions, setPositiveOptions] = useState<Record<string, any>[]>(
    []
  );
  const [negativeOptions, setNegativeOptions] = useState<Record<string, any>[]>(
    []
  );

  const searchOptions = (
    event: AutoCompleteCompleteMethodParams,
    isPositive: boolean
  ) => {
    setTimeout(() => {
      let updatedOptions;
      if (!event.query.trim().length) {
        updatedOptions = [...options];
      } else {
        updatedOptions = options.filter(
          (option: { [key: string]: unknown }) => {
            if (typeof option.label === 'string') {
              return option.label
                .toLowerCase()
                .includes(event.query.toLowerCase());
            }
            return false;
          }
        );
        // If query does not match existing list, allow it to be manually entered
        updatedOptions.push({ label: event.query.toLowerCase() });
      }
      if (isPositive) {
        setPositiveOptions(updatedOptions);
      } else {
        setNegativeOptions(updatedOptions);
      }
    }, 150);
  };

  return (
    <OverlayPanel ref={reference}>
      <div>
        Must Have:
        <AutoComplete
          value={positive}
          suggestions={positiveOptions}
          onChange={(e: { value: string[] }) => setPositive(e.value)}
          dropdown
          multiple
          completeMethod={(e: AutoCompleteCompleteMethodParams) =>
            searchOptions(e, true)
          }
          field='label'
        />
      </div>
      <div>
        Must Not Have:
        <AutoComplete
          value={negative}
          suggestions={negativeOptions}
          onChange={(e: { value: string[] }) => setNegative(e.value)}
          dropdown
          multiple
          completeMethod={(e: AutoCompleteCompleteMethodParams) =>
            searchOptions(e, false)
          }
          field='label'
        />
      </div>
    </OverlayPanel>
  );
}
