/* eslint-disable no-param-reassign */
// TODO refactor to not have to disable no-param-reassign
import { ReactElement } from 'react';
import {
  Note,
  NoteSentence,
  Paragraph,
  SearchMatch,
  SearchProps,
} from '../shared/types/noteTypes';
import { Factors } from '../shared/types/appTypes';
import { CaseNoteSentence } from '../components/CaseNoteSentence';
import { formatTable } from './NoteUtils';

const getMatchingPhrase = (
  paragraphI: number,
  sentenceI: number,
  tokenMatchSentences: SearchMatch[]
) => {
  const matchingPhrases: SearchMatch[] = [];
  tokenMatchSentences.forEach((tokenMatchSent) => {
    if (
      tokenMatchSent.paragraph_id === paragraphI &&
      tokenMatchSent.sentence_id === sentenceI
    ) {
      matchingPhrases.push(tokenMatchSent);
    }
  });
  return matchingPhrases;
};

export const formatSentences = (
  note: Note,
  factors: Factors,
  noteI: number,
  caseID: number,
  searchQueryID: number | undefined,
  displaySentenceFeedback: () => void,
  displayEntityPopupByID: () => void,
  getTaggingEnabled: () => boolean,
  isExpanded: boolean
) => {
  let sentencesArr: (ReactElement)[] = [];
  let skippedLastSentence = false;
  note.note_text_parsed.paragraphs.forEach(
    (paragraph: Paragraph, paragraphI: number) => {
      const isLastParagraph =
        paragraphI + 1 === note.note_text_parsed.paragraphs.length;

      let displayedAnySentsInParagraph = false;

      paragraph.sentences.forEach((sentence, sentenceI) => {
        const tokens =
          typeof note.token !== 'undefined'
            ? getMatchingPhrase(paragraphI, sentenceI, note.token.sentences)
            : [];

        const displayClass = sentence.isDisplayed ? 'showInline' : 'hide ';

        if (skippedLastSentence) {
          const displayDividerClass = sentence.isDisplayed
            ? 'showInline'
            : 'hide';
          sentencesArr = [
            ...sentencesArr,
            <span
              className={['sentenceDivider', displayDividerClass].join(' ')}
              data-paragraphi={paragraphI}
              key={`caseNoteSentenceDivider-${note.note_id}-${paragraphI}-${sentenceI}`}
            >
              {' '}
              &hellip;{' '}
            </span>,
          ];
        }

        // 'displayedAnySentsInParagraph' will determine whether to put a paragraph break after this set of sentences.
        // we'll do so if and only if we displayed any sentences in this paragraph
        displayedAnySentsInParagraph =
          displayedAnySentsInParagraph ||
          (sentence.isDisplayed ? sentence.isDisplayed : false);

        // 'skippedLastSentence' will determine whether to show an ellipsis before the next displayed sentence
        skippedLastSentence = !sentence.isDisplayed;
        // FIXME: Bug? What if a sentence gets !sentence.isDisplayed, but then next gets sentence.isDisplayed.
        // Does that reset skippedLastSentence = false?
        // Maybe a more accurate variable name would be 'skippedAPriorSentence' and it should be ORed against prior value?

        sentencesArr = [
          ...sentencesArr,
          <CaseNoteSentence
            caseID={caseID}
            noteID={note.note_id}
            sourceID={note.source_id}
            paragraphI={paragraphI}
            sentenceI={sentenceI}
            sentence={sentence}
            text={sentence.text}
            displayClass={displayClass}
            searchQueryID={searchQueryID}
            tokens={tokens}
            entities={sentence.entities}
            displaySentenceFeedback={displaySentenceFeedback}
            getTaggingEnabled={getTaggingEnabled}
            displayEntityPopupByID={displayEntityPopupByID}
            key={`CaseNoteSentence-${note.note_id}-${paragraphI}-${sentenceI}`}
            factors={factors}
          />,
        ];
      });

      // inter-paragraph divider (skip on last paragraph)
      if (!isLastParagraph) {
        const displayClass = displayedAnySentsInParagraph
          ? 'showInline'
          : 'hide ';
        sentencesArr = [
          ...sentencesArr,
          <span
            className={
              !isExpanded
                ? 'hide caseNoteParagraphDivider'
                : `${displayClass} caseNoteParagraphDivider`
            }
            data-paragraphi={paragraphI}
            key={`caseNoteParagraphDivider-${note.note_id}-${paragraphI}`}
          />,
        ];
      }
    }
  );
  return formatTable(note, sentencesArr);
};

const chooseSentencesWithAnyEntity = (
  note: Note,
  sentencesLeft: number,
  paragraphsWithTables: number[]
) => {
  let numSentsLeft = sentencesLeft;
  note.note_text_parsed.paragraphs.forEach((paragraph, paragraphI) => {
    if (!paragraphsWithTables.includes(paragraphI)) {
      paragraph.sentences.forEach((sentence, sentenceI) => {
        if (
          sentence.isDisplayed === false &&
          numSentsLeft > 0 &&
          sentence?.entities?.length > 0
        ) {
          numSentsLeft -= 1;
          note.note_text_parsed.paragraphs[paragraphI].sentences[
            sentenceI
          ].isDisplayed = true;
        }
      });
    }
  });
  return numSentsLeft;
};

export const findParagraphsWithTables = (note: Note) => {
  const layouts = note.note_text_parsed.layout;
  const paragraphsWithTables: number[] = [];
  layouts?.forEach((layout) => {
    if (layout.type === 'table') {
      layout.rows?.forEach((row) => {
        row.cols.forEach((col) => {
          paragraphsWithTables.push(...col.paragraphs);
        });
      });
    }
  });
  return paragraphsWithTables;
};

const chooseSentencesWithAnyFactor = (
  note: Note,
  factors: Factors,
  sentencesLeft: number,
  paragraphsWithTables: number[]
) => {
  let numSentsLeft = sentencesLeft;
  note.note_text_parsed.paragraphs.forEach((paragraph, paragraphI) => {
    if (!paragraphsWithTables.includes(paragraphI)) {
      paragraph.sentences.forEach((sentence, sentenceI) => {
        if (
          sentence.isDisplayed === false &&
          numSentsLeft > 0 &&
          sentence?.factors?.length > 0
        ) {
          if (
            sentence.factors.some((sentenceFactor) => factors[sentenceFactor])
          ) {
            numSentsLeft -= 1;
            note.note_text_parsed.paragraphs[paragraphI].sentences[
              sentenceI
            ].isDisplayed = true;
          }
        }
      });
    }
  });
  return numSentsLeft;
};

export const sentenceMatchesToken = (
  paragraphI: number,
  sentenceI: number,
  tokenMatchSentences: SearchMatch[]
) => {
  const matchingSentences = tokenMatchSentences.filter(
    (sent) => sent.paragraph_id === paragraphI && sent.sentence_id === sentenceI
  );
  return matchingSentences.length > 0;
};

export const sentenceMatchesQuery = (
  sentence: NoteSentence,
  queryId: number
) => {
  const queryIdStr = queryId.toString();
  if (sentence.queries) {
    const guardedSentenceQueries =
      sentence.queries.length > 0 ? sentence.queries : [];
    const matchingSentenceQueries = guardedSentenceQueries.filter(
      (query) => query.query_id.toString() === queryIdStr
    );
    return matchingSentenceQueries.length > 0;
  }
  return false;
};

export const sentenceMatchesSearchEntity = (
  sentence: NoteSentence,
  searchEntityId: number
) => {
  const matchingSentenceEntities = sentence.entities.filter(
    (entity) => entity.case_entity_id === searchEntityId
  );
  return matchingSentenceEntities.length > 0;
};

export const sentenceMatchesSearchFactor = (
  sentence: NoteSentence,
  searchFactorParam: string
) => {
  const matchingSentenceFactors = sentence.factors.filter(
    (factor) => factor === searchFactorParam
  );
  return matchingSentenceFactors.length > 0;
};

export const unChooseAllSentences = (note: Note) => {
  note.note_text_parsed.paragraphs.forEach((paragraph, paragraphI) => {
    paragraph.sentences.forEach((_sentence, sentenceI) => {
      // TODO: it can just be _sentence.isDisplayed = false... right?
      note.note_text_parsed.paragraphs[paragraphI].sentences[
        sentenceI
      ].isDisplayed = false;
    });
  });
};

export const chooseAnySentences = (
  note: Note,
  sentencesLeft: number,
  paragraphsWithTables: number[]
) => {
  let numSentsLeft = sentencesLeft;
  note.note_text_parsed.paragraphs.forEach((paragraph, paragraphI) => {
    if (!paragraphsWithTables || !paragraphsWithTables.includes(paragraphI)) {
      paragraph.sentences.forEach((sentence, sentenceI) => {
        if (sentence.isDisplayed === false && numSentsLeft > 0) {
          numSentsLeft -= 1;
          note.note_text_parsed.paragraphs[paragraphI].sentences[
            sentenceI
          ].isDisplayed = true;
        }
      });
    }
  });
  return numSentsLeft;
};

const chooseAlertMatches = (
  note: Note,
  sentencesLeft: number,
  searchQueryIDParam: number
) => {
  let numSentsLeft = sentencesLeft;
  note.note_text_parsed.paragraphs.forEach((paragraph, paragraphI) => {
    paragraph.sentences.forEach((sentence, sentenceI) => {
      if (
        sentence.isDisplayed === false &&
        numSentsLeft > 0 &&
        sentenceMatchesQuery(sentence, searchQueryIDParam)
      ) {
        numSentsLeft -= 1;
        note.note_text_parsed.paragraphs[paragraphI].sentences[
          sentenceI
        ].isDisplayed = true;
      }
    });
  });
  return numSentsLeft;
};

const chooseFactorMatches = (
  note: Note,
  sentencesLeft: number,
  searchFactorParam: string
) => {
  let numSentsLeft = sentencesLeft;
  note.note_text_parsed.paragraphs.forEach((paragraph, paragraphI) => {
    paragraph.sentences.forEach((sentence, sentenceI) => {
      if (
        sentence.isDisplayed === false &&
        numSentsLeft > 0 &&
        sentenceMatchesSearchFactor(sentence, searchFactorParam)
      ) {
        numSentsLeft -= 1;
        note.note_text_parsed.paragraphs[paragraphI].sentences[
          sentenceI
        ].isDisplayed = true;
      }
    });
  });
  return numSentsLeft;
};

const chooseEntityMatches = (
  note: Note,
  sentencesLeft: number,
  searchPersonParam: number
) => {
  let numSentsLeft = sentencesLeft;
  note.note_text_parsed.paragraphs.forEach((paragraph, paragraphI) => {
    paragraph.sentences.forEach((sentence, sentenceI) => {
      if (
        sentence.isDisplayed === false &&
        numSentsLeft > 0 &&
        sentenceMatchesSearchEntity(sentence, searchPersonParam)
      ) {
        numSentsLeft -= 1;
        note.note_text_parsed.paragraphs[paragraphI].sentences[
          sentenceI
        ].isDisplayed = true;
      }
    });
  });
  return numSentsLeft;
};

const chooseTokenMatches = (
  note: Note,
  sentencesLeft: number,
  tokenMatchSentences: SearchMatch[]
) => {
  let numSentsLeft = sentencesLeft;
  note.note_text_parsed.paragraphs.forEach((paragraph, paragraphI) => {
    paragraph.sentences.forEach((sentence, sentenceI) => {
      if (
        sentence.isDisplayed === false &&
        numSentsLeft > 0 &&
        sentenceMatchesToken(paragraphI, sentenceI, tokenMatchSentences)
      ) {
        numSentsLeft -= 1;
        note.note_text_parsed.paragraphs[paragraphI].sentences[
          sentenceI
        ].isDisplayed = true;
      }
    });
  });
  return numSentsLeft;
};

export const chooseSentences = (
  note: Note,
  isExpanded: boolean,
  factors: Factors,
  props: SearchProps
) => {
  const paragraphsWithTables = findParagraphsWithTables(note);
  let sentencesLeft = 0;
  unChooseAllSentences(note); // reset all sentences to hidden
  if (isExpanded) {
    // we're in a note popup - show all sentences
    sentencesLeft = 1e9; // some absurdly high number that means "display ALL sentences"
    chooseAnySentences(note, sentencesLeft, paragraphsWithTables);
  } else {
    // we're in the timeline - show a preview with just N sentences
    sentencesLeft = 2; // this is the max number of sentences we'll show per note preview
    const isFilteringByPhrase = typeof note.token !== 'undefined';
    const isFilteringByQuery = props.searchQueryID;
    const isFilteringByFactor = props.searchFactor !== '';
    const isFilteringByEntity = props.searchPerson !== '';
    if (sentencesLeft > 0 && isFilteringByPhrase && note.token?.sentences) {
      sentencesLeft = chooseTokenMatches(
        note,
        sentencesLeft,
        note.token?.sentences
      );
    }
    if (sentencesLeft > 0 && !isFilteringByPhrase && isFilteringByQuery) {
      sentencesLeft = chooseAlertMatches(
        note,
        sentencesLeft,
        props.searchQueryID!
      );
    }
    if (sentencesLeft > 0 && !isFilteringByPhrase && isFilteringByFactor) {
      sentencesLeft = chooseFactorMatches(
        note,
        sentencesLeft,
        props.searchFactor!
      );
    }
    if (
      sentencesLeft > 0 &&
      !isFilteringByPhrase &&
      isFilteringByEntity &&
      typeof props.searchPerson === 'object'
    ) {
      sentencesLeft = chooseEntityMatches(
        note,
        sentencesLeft,
        props.searchPerson.id
      );
    }
    // when not filtering by anything, just grab filler sentences, according to sequence of rules.
    // if we're filtering, don't include any filler -> such results would be distracting and confusing.
    if (
      !isFilteringByPhrase &&
      !isFilteringByQuery &&
      !isFilteringByFactor &&
      !isFilteringByEntity
    ) {
      if (sentencesLeft > 0) {
        sentencesLeft = chooseSentencesWithAnyFactor(
          note,
          factors,
          sentencesLeft,
          paragraphsWithTables
        );
      }
      if (sentencesLeft > 0) {
        sentencesLeft = chooseSentencesWithAnyEntity(
          note,
          sentencesLeft,
          paragraphsWithTables
        );
      }
      if (sentencesLeft > 0) {
        sentencesLeft = chooseAnySentences(
          note,
          sentencesLeft,
          paragraphsWithTables
        );
      }
    }
  }
};
