/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { Paginator } from 'primereact/paginator';

export const CaseNotePopup = ({
  noteI,
  isVisible,
  sentences,
  dateString,
  toggleLabeling,
  taggingEnabled,
  onHide,
  goToCaseNote,
  totalNotes,
  scrollingNoteCount,
  isRelevanceView,
}) => {
  const handleHide = () => {
    onHide();
  };

  const labelIcon = (
    <div
      className='aLink iconLink'
      onClick={toggleLabeling}
      style={{ marginLeft: 12, paddingTop: 1 }}
    >
      <svg viewBox='0 0 24 24'>
        <title>{taggingEnabled ? 'Disable' : 'Enable'} Feedback</title>
        {taggingEnabled ? (
          <path d='M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7v10c0 1.1.9 1.99 2 1.99L16 19c.67 0 1.27-.33 1.63-.84L22 12l-4.37-6.16zM16 17H5V7h11l3.55 5L16 17z' />
        ) : (
          <path d='M16 7l3.55 5-1.63 2.29 1.43 1.43L22 12l-4.37-6.16C17.27 5.33 16.67 5 16 5l-7.37.01 2 1.99H16zM2 4.03l1.58 1.58C3.22 5.96 3 6.46 3 7v10c0 1.1.9 1.99 2 1.99L16 19c.28 0 .55-.07.79-.18L18.97 21l1.41-1.41L3.41 2.62 2 4.03zM14.97 17H5V7.03L14.97 17z' />
        )}
      </svg>
    </div>
  );

  const onKeyPress = (event) => {
    if (event.keyCode === 37 && isVisible) {
      goToCaseNote((noteI || 0) - 1);
    }

    if (event.keyCode === 39 && isVisible) {
      goToCaseNote((noteI || 0) + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [noteI, isVisible]);

  const loadingBar = !sentences ? (
    <div className='loadingBar'>
      <ProgressBar mode='indeterminate' />
    </div>
  ) : (
    ''
  );

  const paginator = (
    <Paginator
      first={noteI}
      className='p-0'
      rows={1}
      totalRecords={isRelevanceView ? scrollingNoteCount : totalNotes}
      onPageChange={(e) => {
        goToCaseNote(e.first);
      }}
      template={{
        layout: 'PrevPageLink CurrentPageReport NextPageLink',
        CurrentPageReport: (options) => (
          <div>
            <strong>{options.currentPage}</strong> of
            <strong> {options.totalRecords}</strong>
          </div>
        ),
      }}
    />
  );

  const header = (
    <div className='flex justify-content-between pb-0 pt-0'>
      <div className='flexRowCenter iconLink'>
        {dateString} {labelIcon}
      </div>
      <div className='xs:hidden md:flex lg:flex xl:flex sm:flex p-sm-hide'>
        {paginator}
      </div>
    </div>
  );

  return sentences && isVisible ? (
    <Dialog
      header={header}
      visible={isVisible}
      modal={true}
      draggable={false}
      closeOnEscape={true}
      dismissableMask={true}
      onHide={handleHide}
      className='caseNotePopup'
      position='top'
    >
      {loadingBar}
      {sentences}
    </Dialog>
  ) : null;
};
