/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Chip } from 'primereact/chip';
import { TabPanelHeaderTemplateOptions } from 'primereact/tabview';
import mixpanel from '../libs/Mixpanel';
import { Query, QueryGroup } from '../shared/types/queryTypes';

const thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30));

const olderThan30Days = (queries: QueryGroup[]) =>
  queries
    .map((queryGroup) => ({
      ...queryGroup,
      queries: queryGroup.queries?.filter(
        (q) => new Date(q.event_datetime ?? '') < thirtyDaysAgo
      ),
    }))
    .filter((qg) => qg.queries?.length && qg.queries.length > 0) ?? [];

const fromLast30Days = (queries: QueryGroup[]) =>
  queries
    .map((queryGroup) => ({
      ...queryGroup,
      queries: queryGroup.queries?.filter(
        (q) => new Date(q.event_datetime ?? '') >= thirtyDaysAgo
      ),
    }))
    .filter((qg) => qg.queries?.length && qg.queries.length > 0) ?? [];

export const getQualityAlertsHeader = ({
  queries,
}: {
  queries: QueryGroup[] | null;
}) => {
  const newAlertsCount = fromLast30Days(queries || [])?.reduce(
    (acc, queryGroup) => acc + (queryGroup.queries?.length ?? 0),
    0
  );

  return (options: TabPanelHeaderTemplateOptions) => (
    <div
      role='tab'
      className='p-tabview-nav-link'
      id='pr_id_3_header_3'
      aria-controls='pr_id_3_content_3'
      aria-selected='false'
      onClick={(e) => options.onClick(e)}
      tabIndex={0}
    >
      <span className='p-tabview-title'>
        Alerts{' '}
        {newAlertsCount > 0 && (
          <Chip
            label={newAlertsCount.toString()}
            className='vertical-align-top bg-orange-100 font-semibold'
          ></Chip>
        )}
      </span>
    </div>
  );
};

interface QualityAlertsProps {
  queries: QueryGroup[];
  searchNotes: (...args: any[]) => void;
  displayAlertsPopup: (...args: any[]) => void;
  noReulstsMessage?: string;
}

const qualityAlerts = ({
  queries,
  searchNotes,
  displayAlertsPopup,
  noReulstsMessage,
}: QualityAlertsProps) =>
  queries?.length > 0 ? (
    queries?.map((queryGroup) => (
      <div key={`querygroup-${queryGroup.query_group_id}`}>
        <span className='alert-query-group'> {queryGroup.heading}: </span>
        {queryGroup.queries?.map((alert: Query) => (
          <span key={`alert-${alert.id}`} className='alert-list'>
            <div
              className='aLink entityLink'
              onClick={() => {
                mixpanel.track('case-filter-quality-alert', {
                  query_id: alert.id,
                  query_title: alert.title,
                });
                searchNotes(false, '', '', '', {
                  id: alert.id,
                  name: alert.title,
                  hideResults: alert.hide_results,
                  hideResultsMessage: alert.hide_results_message,
                });
              }}
            >
              {alert.title}
            </div>
            <div
              className='aLink iconLink entityLabel infoIcon'
              onClick={() => displayAlertsPopup(alert)}
            >
              <svg viewBox='0 0 13 13'>
                <path
                  d={
                    'M5.85 9.75H7.15V5.85H5.85V9.75ZM6.5 0C2.925 0 0 2.925 \
                0 6.5C0 10.075 2.925 13 6.5 13C10.075 13 13 10.075 13 6.5C13 \
                2.925 10.075 0 6.5 0ZM6.5 11.7C3.64 11.7 1.3 9.36 1.3 6.5C1.3 \
                3.64 3.64 1.3 6.5 1.3C9.36 1.3 11.7 3.64 11.7 6.5C11.7 9.36 \
                9.36 11.7 6.5 11.7ZM5.85 4.55H7.15V3.25H5.85V4.55Z'
                  }
                />
              </svg>
            </div>
          </span>
        ))}
      </div>
    ))
  ) : (
    <span className='text-xs font-semibold'>{noReulstsMessage}</span>
  );

export const QualityAlertsSection = (props: QualityAlertsProps) => (
  <div>
    <div className='pb-2'>
      <div className='text-xl font-bold'>Last 30 days</div>
      {qualityAlerts({
        ...props,
        queries: fromLast30Days(props.queries),
        noReulstsMessage: 'No alerts in the last 30 days',
      })}
    </div>
    <div>
      <div className='text-xl font-bold'>Older</div>
      {qualityAlerts({
        ...props,
        queries: olderThan30Days(props.queries),
        noReulstsMessage: 'No older alerts',
      })}
    </div>
  </div>
);
