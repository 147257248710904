import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { callAPI } from '../../libs/API';
import { Page, LoadingContainer } from '../../components-new';

// TODO: manager column or org view
// TODO: create a middle component to extend for simple functions like displayError
export default class AdminAgencyDomainsAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //            profile: this.props.profile,
      impersonation: this.props.impersonation,
      agencyID: props.match.params.agencyID,
      adding: false,
      //            name: '',
      domain: '',
      error: '',
      errorMessage: '',
      isAIAdmin: this.props.profile.user_type === 'augintel_admin',
    };

    if (!this.state.isAIAdmin || this.state.impersonation)
      this.props.history.push('/');

    this.addItem = this.addItem.bind(this);
    this.displayError = this.displayError.bind(this);
  }

  addItem() {
    if (this.state.domain !== '') {
      if (
        // eslint-disable-next-line prefer-regex-literals
        !new RegExp(
          /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g
        ).test(this.state.domain)
      ) {
        this.setState({ errorMessage: 'Please enter a valid domain' });
      } else {
        this.setState({ adding: true, error: '' }, () => {
          callAPI(
            `/admin/agencies/${this.state.agencyID}/domains/add`,
            {
              domain: this.state.domain,
            },
            (resp) => {
              if (resp.success) {
                this.props.history.push(
                  `/admin/agencies/${this.state.agencyID}/domains`
                );
              } else {
                this.setState({ errorMessage: 'Domain could not be added' });
              }
            },
            (error) => {
              this.displayError(error);
            }
          );
        });
      }
    } else {
      this.setState({ errorMessage: 'Please complete add form' });
    }
  }

  displayError(error) {
    this.setState({
      error: error.message,
    });
  }

  render() {
    return (
      <Page
        title='Add Agency Domain'
        pageTitle='Agency Domains Admin | Augintel'
        toast={this.toast}
        error={this.state.error}
        errorMessage={this.state.errorMessage}
        maxWidth={600}
      >
        <LoadingContainer isLoaded={!this.state.adding}>
          <div
            className='adminAgencyDomainsAddBody grid adminInputForm'
            style={{ marginTop: 10 }}
          >
            <div className='col-12 input'>
              <InputText
                id='agencyDomain'
                value={this.state.domain}
                keyfilter={/^[a-z A-Z-_.]+$/}
                onChange={(e) => {
                  this.setState({ domain: e.target.value });
                }}
                placeholder='Primary Email Domain'
              />
              <label className='accessibility' htmlFor='agencyDomain'>
                Primary Email Domain
              </label>
            </div>
            <div className='col-12 buttonPanel right'>
              <Button
                label='Add Domain'
                className='p-button-danger'
                onClick={() => {
                  this.addItem();
                }}
              />
              <Button
                label='Cancel'
                className='p-button'
                onClick={() => {
                  this.props.history.push('/admin/agencies');
                }}
              />
            </div>
          </div>
        </LoadingContainer>
      </Page>
    );
  }
}
