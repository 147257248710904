import { CheckboxChangeParams } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './FactorFilter.scss';
import { DisplayFactor } from '../../shared/types/appTypes';

export default function FactorFilter({
  factors,
  selectedFactor,
  mixpanelCallback,
}: {
  factors: DisplayFactor[];
  selectedFactor?: string;
  mixpanelCallback: (eventType: string, incomingScopeChange: object) => void;
}) {
  const [options, setOptions] = useState<DisplayFactor[]>(
    factors.sort((factorA, factorB) =>
      factorA.label.localeCompare(factorB.label)
    )
  );
  const [search, setSearch] = useState<string>('');
  const history = useHistory();
  const location = useLocation();

  const onFilterChange = (e: CheckboxChangeParams) => {
    const query = queryString.parse(location.search, {
      arrayFormat: 'comma',
    });
    if (e.checked) {
      const queryObject = {
        ...query,
        page: '1',
        factor: e.value as string,
      };
      history.push({
        search: queryString.stringify(queryObject, { arrayFormat: 'comma' }),
      });
      mixpanelCallback('factor-filter-change', { factor: e.value as string });
    } else {
      const queryObject = {
        ...query,
        page: '1',
        factor: undefined,
      };
      history.push({
        search: queryString.stringify(queryObject, { arrayFormat: 'comma' }),
      });
    }
  };

  useEffect(() => {
    const matches = search.length
      ? factors.filter((factor) =>
          factor.label.toLocaleLowerCase().includes(search.toLowerCase())
        )
      : factors;
    setOptions(matches);
  }, [search]);

  return (
    <div className={'factor-filter'}>
      <fieldset className='border-none'>
        <legend>
          <h5>RISKS & STRENGTHS </h5>
        </legend>
        <span className='p-input-icon-right'>
          <i className='pi pi-search text-sm' />
          <InputText
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className='mb-2'
          />
        </span>{' '}
        <div className='factor-list pt-2 pl-3'>
          {options?.map((factor) => (
            <div key={factor.code} className='field-radiobutton'>
              <RadioButton
                inputId={factor.code}
                value={factor.code}
                onChange={onFilterChange}
                checked={selectedFactor === factor.code}
              />
              <label htmlFor={factor.code}>
                <p>{factor.label}</p>
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
