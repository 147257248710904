import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { ListBox } from 'primereact/listbox';
import styled, { keyframes } from 'styled-components';
import AnimateHeight from 'react-animate-height';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import SearchItemTemplate from './SearchItemTemplate';
import mixpanel from '../../libs/Mixpanel';
import {
  ICaseSearchResult,
  ISearchResultTemplateProps,
  TransformSearchResultsToProps,
  searchCases,
} from './BroadSearchDataService';
import './BroadSearch.css';
import { useDebounce } from '../../libs/UseDebounce';
import { Profile } from '../../shared/types/appTypes';

const fadeIn = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0);
  }

  to {
    background: rgba(143, 150, 174, .5);
  }
`;

const fadeOut = keyframes`
  from {
    background: rgba(143, 150, 174, .5);
  }

  to {
    background-color: rgba(0, 0, 0, 0);
  }
`;

const Overlay = styled.div<{ out: boolean }>`
  display: inline-block;
  visibility: ${(props) => (props.out ? 'hidden' : 'visible')};
  animation: ${(props) => (props.out ? fadeOut : fadeIn)} 0.15s linear;
  transition: visibility 0.15s linear;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(143, 150, 174, 0.5);
  z-index: 1;
`;
export const Badge = styled.div`
  transform: rotate(90deg);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;

  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  background: #1975d2;
  border-radius: 28px;
  width: 24px;
  height: 16px;
`;

const LegendArea = styled.div`
  width: 100%;
  height: 47px;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const Seperator = styled.div`
  height: 0;
  border-top: 1px solid #ebebeb;
`;

const ResultsContainer = styled.div`
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background-color: #fff;
`;
const ResultsBox = styled(ListBox)`
  background: linear-gradient(
    to top,
    rgba(107, 114, 128, 0.08) 0%,
    rgba(107, 114, 128, 0.02) 20.31%,
    rgba(107, 114, 128, 0) 42.71%
  );
  border-radius: 8px 8px 0 0;
  max-width: 400px;
  margin-top: 8px;
  border: none;
`;

const NoResultsBox = styled.div`
  background: linear-gradient(
    to top,
    rgba(107, 114, 128, 0.08) 0%,
    rgba(107, 114, 128, 0.02) 20.31%,
    rgba(107, 114, 128, 0) 42.71%
  );
  border-radius: 8px 8px 0 0;
  max-width: 400px;
  height: 500px;
  margin-top: 8px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
`;

const NoResultsHeading = styled.h4`
  padding: 10px;
`;

const NoResultsDetail = styled.p`
  padding: 20px;
  margin: 0;
`;

const LegendRow = styled.div`
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
`;

export const Caption2 = styled.p`
  /* Text/Light/200 - Secondary */
  color: rgba(0, 0, 0, 0.6);
  font-family: Source Serif Pro, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 8px;
`;

const SpinnerWrapper = styled.span<{ $loading: boolean }>`
  position: absolute;
  right: 13px;
  display: ${({ $loading }) => ($loading ? 'block' : 'none')};
`;

const CancelWrapper = styled.span<{ $loading: boolean }>`
  position: absolute;
  display: ${({ $loading }) => ($loading ? 'none' : 'block')};
  right: 0;
`;

const InputContainer = styled.span`
  max-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
interface BroadSearchProps {
  toast: RefObject<Toast>;
  profile: Profile | null;
}

const SearchContainer = styled.div`
  z-index: 2;
  padding: 10px 10px 10px 10px;
`;

const BroadSearchAutoComplete = ({ toast, profile }: BroadSearchProps) => {
  const [caseSuggestions, setCaseSuggestions] = useState<ICaseSearchResult[]>(
    []
  );
  const [
    selectedCase,
    setSelectedCase,
  ] = useState<ISearchResultTemplateProps | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState<number | string>(0);
  const [serverResponded, setServerResponded] = useState(false);
  const [focused, setFocused] = useState(false);
  const inputTextRef = useRef<HTMLInputElement | null>(null);
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 300);

  useEffect(() => {
    if (debouncedSearchTerm.length > 1) {
      setLoading(true);
      searchCases(debouncedSearchTerm)
        .then((results) => {
          setLoading(false);
          setServerResponded(true);
          height === 0 && setHeight('auto');
          setCaseSuggestions(results);
        })
        .catch((error: { message: string }) => {
          setLoading(false);
          setServerResponded(true);
          toast?.current &&
            toast.current.show({
              severity: 'error',
              summary: 'Could not search',
              detail: `There's been a problem, ${error.message}`,
            });
        });
    } else {
      setCaseSuggestions([] as ICaseSearchResult[]);
    }
  }, [debouncedSearchTerm]);

  const escFunction = useCallback((event: { key: string }) => {
    if (event.key === 'Escape') {
      setFocused(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const options =
    caseSuggestions?.length &&
    serverResponded &&
    TransformSearchResultsToProps(caseSuggestions, profile);

  // "case" is a reserved word
  const handleCaseSelect = useCallback(
    (_case: ISearchResultTemplateProps) => {
      setSelectedCase(_case);
      if (options) {
        mixpanel.track('cases-autocompleter-click-result', {
          searchPhrase: searchTerm,
          selectedSuggestion: _case,
          options,
          selectionRank:
            (options?.findIndex((value) => value.case_id === _case.case_id) ??
              -1) + 1,
          totalOptions: options.length,
          currentUrl: window.location.pathname,
        });
      }
      window.location.href = `/cases/${_case.case_id}`;
    },
    [options, searchTerm]
  );

  const handleKeystroke = useCallback(
    (event: { key: string }) => {
      switch (event.key) {
        case 'Enter':
          if (selectedCase) {
            handleCaseSelect(selectedCase);
          }
          break;
        case 'ArrowDown':
          if (options && options.length > 0) {
            const nextIndex =
              options.findIndex(
                (value) => value.case_id === selectedCase?.case_id
              ) + 1;
            if (nextIndex < options.length) {
              setSelectedCase(options[nextIndex]);
            }
          }
          break;
        case 'ArrowUp':
          if (options && options.length > 0) {
            const nextIndex =
              options.findIndex(
                (value) => value.case_id === selectedCase?.case_id
              ) - 1;
            if (nextIndex >= 0) {
              setSelectedCase(options[nextIndex]);
            }
          }
          break;
        case 'Escape':
          setFocused(false);
          setHeight(0);
          inputTextRef.current?.blur();
          break;
        default:
      }
    },
    [selectedCase, options, handleCaseSelect]
  );

  const handleBlur = useCallback(() => {
    mixpanel.track('cases-autocompleter-blur', {
      current_page: window.location.pathname,
      search_phrase: searchTerm,
      serverResponded,
    });
    setFocused(false);
    setHeight(0);
  }, [searchTerm, serverResponded]);

  const handleFocus = useCallback(() => {
    mixpanel.track('cases-autocompleter-focus', {
      current_page: window.location.pathname,
    });
    setFocused(true);
    if (serverResponded) setHeight('auto');
  }, [serverResponded]);

  return (
    <>
      <Overlay out={!focused} onClick={handleBlur} />
      <SearchContainer className='lg:w-26rem md:m-w-20rem'>
        <InputContainer className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            ref={() => inputTextRef}
            role={'searchbox'}
            placeholder='Search People, Cases'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeystroke}
            onFocus={handleFocus}
            style={{ borderRadius: '8px' }}
          />
          <CancelWrapper $loading={loading}>
            <Button
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                display: searchTerm.length > 0 ? 'block' : 'none',
              }}
              icon='pi pi-times'
              className='p-button-rounded p-button-text p-button-plain'
              onClick={() => {
                mixpanel.track('cases-autocompleter-cancel', {
                  current_page: window.location.pathname,
                  search_phrase: searchTerm,
                  serverResponded,
                });
                setFocused(false);
                setSearchTerm('');
                setHeight(0);
                setTimeout(() => {
                  setServerResponded(false);
                  setCaseSuggestions([]);
                }, 300);
              }}
            />
          </CancelWrapper>
          <SpinnerWrapper $loading={loading}>
            <i className='pi pi-spin pi-spinner' />
          </SpinnerWrapper>
        </InputContainer>
        <AnimateHeight
          id={'animated-search-list'}
          duration={300}
          height={height}
        >
          {options ? (
            <ResultsContainer tabIndex={0}>
              <ResultsBox
                value={selectedCase}
                itemTemplate={SearchItemTemplate}
                options={options}
                onChange={(e: { value: ISearchResultTemplateProps }) =>
                  handleCaseSelect(e.value)
                }
                listStyle={{ height: '500px' }}
              />
              <Seperator />
              <LegendArea>
                <LegendRow>
                  <Badge />
                  <Caption2>Cases</Caption2>
                </LegendRow>
              </LegendArea>
            </ResultsContainer>
          ) : (
            <ResultsContainer>
              <NoResultsBox>
                <NoResultsHeading>No Cases found</NoResultsHeading>
                <NoResultsDetail>
                  {searchTerm && (
                    <>&#8220;{searchTerm}&#8221; returned no results</>
                  )}
                </NoResultsDetail>
              </NoResultsBox>
            </ResultsContainer>
          )}
        </AnimateHeight>
      </SearchContainer>
    </>
  );
};

export default BroadSearchAutoComplete;
