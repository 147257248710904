import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';
import { Paginator, PaginatorPageState } from 'primereact/paginator';
import { Toast, ToastMessage } from 'primereact/toast';
import mixpanel from '../libs/Mixpanel';
import { AreaChart } from '../components/graphs/AreaChart';
import { calculateQueryTrend } from '../utils/QueryHelper';
import { Page } from '../components-new';
import { PageProps } from '../shared/types/appTypes';
import CaseCard, { ICaseCardProps } from '../components/caseCard/CaseCard';
import { BuildCaseCardProps } from '../services/CasePreviewDataService';
import {
  ICasesPageDataState,
  IV2CaseResponse,
  ScopeOption,
} from '../repositories/caseRepository';
import ExportButton, {
  QueryPageSearchParams,
} from '../components/elements/exportButton/ExportButton';
import {
  FiltersQueryStateWithPagination,
  mapToNumberArray,
  setUpInitialLoad,
} from '../libs/FilterService';
import CaseAssignmentFilter from '../components/filters/CaseAssigneeFilter';
import useGet from '../libs/UseGet';
import AgencyOrgCasesFilter from '../components/filters/AgencyOrgCasesFilter';
import CaseworkerAssigneeCasesFilter from '../components/filters/CaseworkerAssigneeCasesFilter';
import { cantViewScope } from '../services/ScopeService';
import { QueryPageResponse, QueryHoverState } from '../shared/types/queryTypes';
import QuerySortDropdown from '../components/filters/QuerySortDropdown';
import { CaseAssignee } from '../shared/types/caseTypes';
import { useQueryState } from '../libs/useQueryState';
import SelectedFiltersDisplay from '../components/filters/SelectedFiltersDisplay';
import QueryMonthSelector from '../components/elements/QueryMonthSelector';

export interface QueryResponse extends IV2CaseResponse {
  query: QueryPageResponse;
}

interface QueryType extends QueryPageResponse {
  trend?: {
    color: string;
    dir: string;
    val: string;
  };
  cur_val?: number;
  color?: string;
}

interface MatchParams {
  queryID?: string;
}

const dataReducer = (
  state: ICasesPageDataState,
  action: Partial<ICasesPageDataState>
) => ({
  ...state,
  ...action,
});

export default function Query({
  profile,
  impersonation,
  match,
}: PageProps<MatchParams>) {
  const history = useHistory();
  const location = useLocation();
  const PAGE_SIZE = 10;
  const toast = useRef<Toast>();
  const initialLoad = useRef(true);
  const [queryID, setQueryID] = useState(match.params?.queryID || '');
  const [isLoaded, setIsLoaded] = useState(false);
  const [hoverState, setHoverState] = useState<QueryHoverState>({
    hover: false,
    chartHoverValue: '',
    chartHoverMonth: '',
  } as QueryHoverState);
  const [query, setQuery] = useState<QueryType>({} as QueryType);
  const [renderedChart, setRenderedChart] = useState<JSX.Element | null>(null);
  const [downloadingCSV, setDownloadingCSV] = useState(false);

  const queryState: FiltersQueryStateWithPagination = useQueryState<FiltersQueryStateWithPagination>(
    {
      page: 1,
      scope: 'AGENCY' as ScopeOption,
      sort: 'importance',
      groupIds: [],
      caseworkerIds: [],
      // default to previous month
      month: `${new Date().getMonth() - 1 === -1 ? 11 : new Date().getMonth() - 1}`,
      year: `${new Date().getMonth() - 1 === -1 ? new Date().getFullYear() -1 :  new Date().getFullYear()}`
    },
    {
      page: (arg: string) => Number(arg),
      groupIds: (arg: string | string[]) => mapToNumberArray(arg),
      caseworkerIds: (arg: string | string[]) => mapToNumberArray(arg)
    }
  );
  const path = `/queries/${queryID}${location.search}`

  // data is QueryResponse
  const { isLoading, data, error } = useGet<QueryResponse>({
    path,
    initialLoad: initialLoad.current,
  });
  const [dataState, dataDispatch] = useReducer(dataReducer, {});

  const assigneesPath = `/v2/cases/assignees?queryId=${queryID}&${
    location.search.split('?')[1]
  }`;
  const caseAssigneesRequest = useGet<CaseAssignee[]>({
    path: assigneesPath,
    initialLoad: initialLoad.current,
  });

  const assigneesData = cantViewScope(
    'no__caseworker_filter',
    (profile as unknown) as { user_type: string; scopes: string[] },
    (impersonation as unknown) as { scopes: string[] }
  )
    ? null
    : caseAssigneesRequest.data;

  const onHover = (e: {
    date: {
      getMonth(): number;
    };
    value: string;
    hover: boolean;
  }) => {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    if (e.hover) {
      setHoverState({
        hover: true,
        chartHoverMonth: monthNames[e.date.getMonth()],
        chartHoverValue: e.value,
      } as QueryHoverState);
    } else {
      setHoverState({
        hover: false,
        chartHoverMonth: '',
        chartHoverValue: '',
      });
    }
  };

  useEffect(() => {
    if (data && data.query && !error) {
      const queryResponse: QueryType = data.query;

      if (!isLoaded) {
        mixpanel.track('query-view', {
          query_id: queryID,
          title: data.query.title,
        });
        document.title = `${queryResponse.title} | Augintel`;
        setIsLoaded(true);
      }

      queryResponse.cur_val = queryResponse.chart
        ? queryResponse.chart[queryResponse.chart.length - 2].val
        : 0; // get the last complete month (which is the N-1'th, not the Nth)
      queryResponse.trend = calculateQueryTrend(queryResponse.chart);
      // TODO hardcoded to red until we make changes to query engine
      queryResponse.color = 'red';

      const chart = (
        <AreaChart
          keys={['query']}
          data={[queryResponse.chart]}
          colors={[
            queryResponse.color === 'red'
              ? { area: 'url(#red-gradient)', line: '#F14E4E' }
              : { area: 'url(#green-gradient)', line: '#1E7200' },
          ]}
          width='100%'
          partialLastMth
          punctuateLastValue
          showYAxis
          observeHover
          onHover={onHover}
          showXAxis
        />
      );

      setRenderedChart(chart);
      setQuery(queryResponse);

      dataDispatch({
        caseData: BuildCaseCardProps({
          totalCount: parseInt(data.metadata.totalCount, 10),
          offset: parseInt(data.metadata.offset, 10),
          cases: data.data,
        }),
        error: undefined,
      });
    } else if (error) {
      dataDispatch({
        error: {
          message: 'Error retrieving query results.',
        },
      });
      setIsLoaded(true);
    }
  }, [data, error]);

  const trackMixpanelFilterEvent = useCallback(
    (eventType: string, incomingScopeChange: object) => {
      mixpanel.track(eventType, {
        currentUrl: window.location.href,
        incomingScopeChange,
      });
    },
    []
  );

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      setUpInitialLoad(
        profile,
        impersonation,
        history,
        location,
        queryState.sort as string,
        dataDispatch,
        queryID
      )
        .then(() => null)
        .catch((e) => {
          console.error(e);
        });
    }
  }, [
    history,
    impersonation,
    location.search,
    profile?.cases_count,
    profile?.direct_report_cases_count,
  ]);

  useEffect(() => {
    if (assigneesData) {
      dataDispatch({
        caseworkerData: assigneesData
          .map((user) => ({
            label: `${user.name}`,
            value: user.profileId.toString(),
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      });
    }
  }, [assigneesData]);

  const displayToastError = (toastConfig: ToastMessage) =>
    toast.current && toast.current.show(toastConfig);

  const chartIsLoadedAndPlural = query.chart && query.chart.length >= 2;
  const chartLastDate =
    chartIsLoadedAndPlural && query.chart
      ? query.chart[query.chart.length - 2].date
      : null;
  const chartLastMonth = chartLastDate
    ? new Date(`${chartLastDate}-02`)?.toLocaleString('default', {
        month: 'short',
      })
    : '';

  /* eslint space-infix-ops: "off" */
  const queryCount = (
    <div>
      <div className='caseCount'>
        {hoverState.hover ? hoverState.chartHoverValue : query.cur_val}
      </div>
      <div className='caseLabel'>
        {hoverState.hover ? hoverState.chartHoverMonth : chartLastMonth}{' '}
        {(hoverState.hover ? hoverState.chartHoverValue : query.cur_val) === 1
          ? 'Case'
          : 'Cases'}
      </div>
    </div>
  );

  const handlePageChange = (event: PaginatorPageState) => {
    const incomingQuery = queryString.parse(location.search, {
      arrayFormat: 'comma',
    });
    const outgoingQuery = {
      ...incomingQuery,
      page: event.page + 1,
    };
    history.push({
      search: queryString.stringify(outgoingQuery, { arrayFormat: 'comma' }),
    });
  };

  const caseDataRender =
    dataState.caseData?.cases.length && !error ? (
      dataState.caseData.cases.map((item: ICaseCardProps) => (
        <a
          title={item.name}
          key={item.id}
          className='case-link block no-underline'
          href={`/cases/${item.id}?query=${queryID}`}
        >
          <CaseCard {...item} />
        </a>
      ))
    ) : (
      <h4 className={'center'}>
        {dataState.error?.message || 'No cases found.'}
      </h4>
    );

  const graphPanel = (
    <div className='lg:col-6 col-12 graphs'>
      <div className='grid'>
        <div className='col-12 left'>
          <h2>Query</h2>
          <h5>MATCHING CASES PER MONTH</h5>
        </div>
      </div>
      <div className='queryCard'>
        {queryCount}
        {renderedChart}
        <br />
        <br />
        <br />
        <QuerySortDropdown
          sort={queryState.sort}
          mixpanelEventName='query-view-change-sort'
          mixpanelInfo={{
            query_id: queryID,
            title: query.title,
          }}
          mixpanelCallback={trackMixpanelFilterEvent}
        />
        <CaseAssignmentFilter
          impersonation={impersonation}
          scope={queryState.scope}
          profile={profile}
          mixpanelCallback={trackMixpanelFilterEvent}
        />
        {dataState.groupData && (
          <AgencyOrgCasesFilter
            root={dataState.groupData}
            groupIds={queryState.groupIds || []}
            mixpanelCallback={trackMixpanelFilterEvent}
          />
        )}
        {dataState.caseworkerData && (
          <CaseworkerAssigneeCasesFilter
            caseworkerIdOptions={dataState.caseworkerData}
            caseworkerIds={queryState.caseworkerIds || []}
            mixpanelCallback={trackMixpanelFilterEvent}
          />
        )}
      </div>
    </div>
  );

  const hasNoTitle = typeof query.title === 'undefined';

  return (
    <Page
      title={query.title}
      pageTitle='Query | Augintel'
      isLoaded={isLoaded}
      toast={toast}
      error={hasNoTitle ? 'No query found' : ''}
      headerSpacing={0}
      componentClassName='query'
    >
      <div>{query.subtitle}</div>
      <div className='queryBody grid'>
        {graphPanel}
        <div className='col'>
          <div className='grid'>
            <div className='col-12'>
              <h2 style={{ paddingLeft: '0.3rem' }}>Results</h2>
              <SelectedFiltersDisplay
                loading={isLoading}
                queryState={queryState}
                groupData={dataState.groupData}
                caseworkerData={dataState.caseworkerData}
              />
            </div>
            <div className='col-6 left' style={{ padding: '0 0 0 .8rem' }}>
              <QueryMonthSelector
                loading={isLoading}
                month={queryState.month || ''}
                year={queryState.year || ''}
              />
            </div>
            <div className='col-6 right align-self-end export-container'>
              <ExportButton
                showLabel={true}
                alignLeft={true}
                downloadingCSV={downloadingCSV}
                setDownloadingCSV={setDownloadingCSV}
                searchParams={
                  {
                    queryID,
                    queryString: location.search,
                    isQuery: true,
                  } as QueryPageSearchParams
                }
                displayToastError={displayToastError}
              />
            </div>
          </div>
          <div className='col'>
            {isLoading ? (
              <ProgressBar mode={'indeterminate'} />
            ) : (
              caseDataRender
            )}
            <Paginator
              onPageChange={handlePageChange}
              first={(queryState.page - 1) * PAGE_SIZE}
              rows={PAGE_SIZE}
              totalRecords={dataState.caseData?.totalCount}
            />
          </div>
        </div>
      </div>
    </Page>
  );
}
