import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import queryString from 'query-string';
import { ScopeOption } from '../repositories/caseRepository';
import {
  FiltersQueryState,
  FiltersQueryStateWithPagination,
} from './FilterService';

type QueryState = FiltersQueryState | FiltersQueryStateWithPagination;

export function useQueryState<S extends QueryState>(
  initialState: S,
  serializer: Record<string, (arg: any) => any>
) {
  const { search } = useLocation();

  return useMemo(() => {
    const existingQuery = queryString.parse(search, {
      arrayFormat: 'comma',
    });

    const scope = existingQuery.scope as ScopeOption;
    if (scope) {
      Object.keys(existingQuery).forEach((key) => {
        if (serializer[key]) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          existingQuery[key] = serializer[key](existingQuery[key]);
        }
      });
      localStorage.setItem('filters', JSON.stringify(existingQuery));
      return (existingQuery as unknown) as S;
    }
    return initialState;
  }, [search]);
}
