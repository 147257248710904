export const canViewScope = (
  scope: string,
  profile: { user_type: string, scopes: string[] },
  impersonation: {scopes: string[]}): boolean => {
  if (impersonation)
    return impersonation.scopes && impersonation.scopes.indexOf(scope) > -1;
  if (profile.user_type && profile.user_type === 'augintel_admin')
    return true;

  return profile.scopes && profile.scopes.indexOf(scope) > -1;
};

export const cantViewScope = (
  negatedScope: string,
  profile: { user_type: string, scopes: string[] },
  impersonation: {scopes: string[]}): boolean => {
  if (!profile)
    return true;
  if (impersonation)
    return impersonation.scopes && impersonation.scopes.indexOf(negatedScope) > -1;
  if (profile.user_type && profile.user_type === 'augintel_admin')
    return false;

  return profile.scopes && profile.scopes.indexOf(negatedScope) > -1;
};