/* eslint-disable no-param-reassign */
import { DataScroller } from 'primereact/datascroller';
import React, { memo, useEffect, useState } from 'react';
import { IRiskInterface } from './CaseGraphs';
import { AreaChart } from './AreaChart';
import { FactorCount, SearchNotesFunc } from '../../shared/types/caseTypes';
import { CaseRiskBubbleChart } from './CaseRiskBubbleChart';

interface RiskPanelProps {
  factorTimeline: IRiskInterface[];
  protectiveCounts: FactorCount[];
  riskCounts: FactorCount[];
  monthSpan: number;
  newRisks: string[];
  searchNotes: SearchNotesFunc;
}

// eslint-disable-next-line prefer-arrow-callback
export const RisksPanel = memo(function RisksPanel({
  factorTimeline,
  protectiveCounts,
  riskCounts,
  monthSpan,
  newRisks,
  searchNotes,
}: RiskPanelProps) {
  const [riskBubbleHeight, setRiskBubbleHeight] = useState('');

    useEffect(() => {
        window.history.scrollRestoration = 'manual'
    }, []);

  const factorAreaChart =
    factorTimeline?.length > 0 ? (
      <AreaChart
        keys={['risks', 'strengths']}
        data={[riskCounts, protectiveCounts]}
        monthSpan={monthSpan}
        colors={[
          { area: 'url(#red-gradient)', line: '#F14E4E' },
          { area: 'url(#green-gradient)', line: '#1E7200' },
        ]}
        width='100%'
        showXAxis
      />
    ) : (
      ''
    );

  const riskBubbleTimelineCharts =
    factorTimeline?.length > 0
      ? factorTimeline
          .sort((risk1: IRiskInterface, risk2: IRiskInterface) => {
            if (!risk1.totalValues)
              risk1.totalValues = risk1.data.reduce(
                (c, d) => (d.val == null ? c : c + d.val),
                0
              );
            if (!risk2.totalValues)
              risk2.totalValues = risk2.data.reduce(
                (c, d) => (d.val == null ? c : c + d.val),
                0
              );
            return risk1.totalValues <= risk2.totalValues ? 1 : -1;
          }) // FIXME: Having factor.factor[] obfuscates the code
          .map((factor: IRiskInterface) => ({ factor }))
      : [];

  const handleResize = () => {
    const caseBody = document.getElementsByClassName('caseBody')[0];
    const caseBodyHeight = caseBody.clientHeight;

    const maxHeightRisk =
      caseBodyHeight - 300 - riskBubbleTimelineCharts.length * 56 < 0
        ? 460
        : 460 + (caseBodyHeight - 300) - riskBubbleTimelineCharts.length * 56;
    if (window.screen.width < 576) {
      setRiskBubbleHeight('calc(50vh)');
    } else {
      setRiskBubbleHeight(`calc(100vh - ${maxHeightRisk}px)`);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [riskBubbleTimelineCharts]);

  const bubbleChartRiskTemplate = (data: { factor: IRiskInterface }) => (
    <CaseRiskBubbleChart
      shown={true}
      monthSpan={monthSpan}
      factor={data.factor}
      searchNotes={searchNotes}
      key={`case-risk-${data.factor.factor.code}`}
      newRisks={newRisks}
    />
  );

    function scrollToTop() {
        const elementsByClassName = document.getElementsByClassName(
            'p-datascroller-content'
        );
        if (elementsByClassName.length > 1) elementsByClassName[1].scrollTop = 0;
        else elementsByClassName[0].scrollTop = 0;
    }

  return (
    <>
      <div className='areaChartContainer'>{factorAreaChart}</div>
      <DataScroller
        className='bubblechart-datascroller'
        value={riskBubbleTimelineCharts}
        scrollHeight={riskBubbleHeight}
        itemTemplate={bubbleChartRiskTemplate}
        inline
        rows={riskBubbleTimelineCharts.length}
      />
    </>
  );
});
