/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { callAPI } from '../../libs/API';
import { Page, LoadingContainer } from '../../components-new';
import { verifyEmail } from '../../utils/VerifyEmail';

// TODO: manager column or org view
// TODO: create a middle component to extend for simple functions like displayError
export default class AdminUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      filterLoaded: true,
      users: [],
      displayUsers: [],
      agencies: [],
      agenciesAllowEdit: [],
      groups: [],
      activeUsers: [],
      userTypes: [],
      impersonation: this.props.impersonation,
      first: 1,
      searchEmail: '',
      searchFirstName: '',
      searchLastName: '',
      searchManager: '',
      searchAgency: 'ALL',
      searchGroup: 'ALL',
      searchActive: 'ALL',
      searchStaffPosition:'',
      searchUserType: 'ALL',
      pageLimit: 100,
      sortField: 'email',
      sortOrder: 1,
      error: '',
      errorMessage: '',
      // variables for edit users
      dialogOpen: false,
      newUser: '',
      editing: false,
      // segun yo aqui tengo que definir tambien el nueveo usuario
      isAIAdmin: this.props.profile.user_type === 'augintel_admin',
      isAgencyAdmin: this.props.profile.user_type === 'agency_admin',
    };

    this.toast = React.createRef();
    this.loadUsers = this.loadUsers.bind(this);
    this.impersonateUser = this.impersonateUser.bind(this);
    this.displayError = this.displayError.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.editItem = this.editItem.bind(this);
  }

  componentDidMount() {
    this.loadUsers(true);
  }

  // close dialog
  closeDialog() {
    this.setState( {dialogOpen: false} );
  }

  // function to handle the update
  handleChange(propertyToUpdate, newValue) {
    const tempNewUser = {...this.state.newUser}
    tempNewUser[propertyToUpdate] = newValue
    this.setState({newUser: tempNewUser})
  }

  // function to update value

  updateUsersList() {
    const arrayIndex = this.state.users.findIndex(x => x.id === this.state.newUser.id);
    this.state.users[arrayIndex] = this.state.newUser;
  }



  async editItem() {

    if (await verifyEmail(this.state.newUser.agency_id, this.state.newUser.email)) {
      if (
        !this.state.editing &&
        this.state.newUser.email !== '' &&
        this.state.newUser.source_id !== '' &&
        this.state.newUser.staff_position !== '' &&
        this.state.newUser.grants.length > 0
      ) {
        try {
          this.setState({ editing: true, errorMessage: '' }, () => {
            callAPI(
              '/admin/users/edit',
              {
                email: this.state.newUser.email,
                source_id: this.state.newUser.source_id,
                first_name: this.state.newUser.first_name,
                last_name: this.state.newUser.last_name,
                staff_position: this.state.newUser.staff_position,
                grant_type: this.state.newUser.grants,
                agency_id: this.state.newUser.agency_id,
                original_email: this.state.newUser.original_email
              },
              (resp) => {
                if (resp.success) {
                  this.updateUsersList();
                  this.setState({ editing: false, dialogOpen: false});
                } else {
                  this.displayError({ message: 'User could not be updated' });
                }
              },
              (error) => {
                this.setState({
                  editing: false,
                  error: error.message
                });
              }
            );
          });
        } catch (ex) {
          this.setState({ editing: false, error: 'Unknown error...'})
        }
      } else {
        this.setState({
          editing: false,
          errorMessage: 'Please complete edit form'
        });
      }
    } else {
      this.setState({
        editing: false,
        dialogOpen: false,
        errorMessage: 'Check email domain or format'
      });
    }
  }

  loadUsers(clear) {
    if (typeof this.state.users[this.state.first] === 'undefined' || clear) {
      this.setState(
        {
          filterLoaded: false,
          searchedEmail: this.state.searchEmail,
          searchedFirstName: this.state.searchFirstName,
          searchedLastName: this.state.searchLastName,
          searchedStaffPosition: this.state.searchStaffPosition
        },
        () => {
          const offset = clear ? 0 : this.state.first - 1;
          const sortField = `&sortField=${
            this.state.sortField === 'user_type_id'
              ? 'user_type_name'
              : this.state.sortField
          }`; // FIXME...
          const sortOrder = `&sortOrder=${this.state.sortOrder}`;
          const searchEmail =
            this.state.searchEmail !== ''
              ? `&searchEmail=${this.state.searchEmail}`
              : '';
          const searchFirstName =
            this.state.searchFirstName !== ''
              ? `&searchFirstName=${this.state.searchFirstName}`
              : '';
          const searchLastName =
            this.state.searchLastName !== ''
              ? `&searchLastName=${this.state.searchLastName}`
              : '';
          const searchManager =
            this.state.searchManager !== ''
              ? `&searchManager=${this.state.searchManager}`
              : '';
          const searchAgency =
            this.state.searchAgency !== 'ALL'
              ? `&searchAgency=${this.state.searchAgency}`
              : '';
          const searchGroup =
            this.state.searchGroup !== 'ALL'
              ? `&searchGroup=${this.state.searchGroup}`
              : '';
          const searchActive =
            this.state.searchActive !== 'ALL'
              ? `&searchActive=${this.state.searchActive}`
              : '';
          const searchStaffPosition =
            this.state.searchStaffPosition !== ''
            ? `&searchStaffPosition=${this.state.searchStaffPosition}`
            : '';
          const searchUserType =
            this.state.searchUserType !== 'ALL'
              ? `&searchUserType=${this.state.searchUserType}`
              : '';

          callAPI(
            `/admin/users?limit=${this.state.pageLimit}&offset=${offset}${sortField}${sortOrder}${searchEmail}${searchFirstName}${searchLastName}${searchManager}${searchAgency}${searchGroup}${searchActive}${searchUserType}${searchStaffPosition}`,
            null,
            (resp) => {
              if (typeof resp.users !== 'undefined') {
                // TODO: event_datetime is showing +0 GMT time.  probably needs to be corrected in backend?  need to confirm date conversion in backend.
                if (clear) {
                  // only load agencies and user_type options on first load
                  this.setState({
                    isLoaded: true,
                    users: resp.users !== null ? resp.users : [],
                    displayUsers: resp.users !== null ? resp.users : [],
                    agencies: resp.agencies !== null ? resp.agencies : [],
                    agenciesAllowEdit: resp.agencies !== null ? resp.agencies.filter(agency => agency.allow_add_user) : [],
                    groups: resp.groups !== null ? resp.groups : [],
                    activeUsers:
                      resp.activeUsers !== null ? resp.activeUsers : [],
                    userTypes: resp.userTypes !== null ? resp.userTypes : [],
                    userCount: parseInt(resp.userTotal, 10),
                    agencyCount: parseInt(resp.agencyTotal, 10),
                    activeCount: parseInt(resp.activeTotal, 10),
                    filterLoaded:
                      this.state.searchedEmail === this.state.searchEmail &&
                      this.state.searchedFirstName ===
                        this.state.searchFirstName &&
                      this.state.searchedLastName === this.state.searchLastName &&
                      this.state.searchedStaffPosition === this.state.searchStaffPosition,
                  });
                } else if (
                  this.state.searchedEmail === this.state.searchEmail &&
                  this.state.searchedFirstName === this.state.searchFirstName &&
                  this.state.searchedLastName === this.state.searchLastName &&
                  this.state.searchedStaffPosition === this.state.searchStaffPosition
                ) {
                  const { users } = this.state;
                  for (
                    let insertJ = 0;
                    insertJ < resp.users.length;
                    insertJ += 1
                  ) {
                    users[insertJ + offset] = resp.users[insertJ];
                  }
                  this.setState({
                    isLoaded: true,
                    users,
                    displayUsers: resp.users,
                    filterLoaded: true,
                  });
                }
              } else {
                this.displayError({ message: 'Users not found' });
              }
            },
            (error) => {
              this.displayError(error);
            }
          );
        }
      );
    } else {
      this.setState({
        displayUsers: this.state.users.slice(
          this.state.first,
          this.state.first + this.state.pageLimit
        ),
      });
    }
  }

  loadMoreUsers() {
    this.loadUsers(false);
  }

  searchField() {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.loadUsers(true);
    }, 500);
  }

  searchAgency(e) {
    if (this.state.isLoaded && this.state.filterLoaded) {
      this.setState({ searchAgency: e.value }, this.loadUsers(true));
    }
  }

  searchGroup(e) {
    if (this.state.isLoaded && this.state.filterLoaded) {
      this.setState({ searchGroup: e.value }, this.loadUsers(true));
    }
  }

  searchActive(e) {
    if (this.state.isLoaded && this.state.filterLoaded) {
      this.setState({ searchActive: e.value }, this.loadUsers(true));
    }
  }
  
  searchStaffPosition(e) {
    if (this.state.isLoaded && this.state.filterLoaded) {
      this.setState({ searchStaffPosition: e.value}, this.loadUsers(true))
    }
  }

  searchUserType(e) {
    if (this.state.isLoaded && this.state.filterLoaded) {
      this.setState({ searchUserType: e.value }, this.loadUsers(true));
    }
  }


  // eslint-disable-next-line class-methods-use-this
  async getProfile(userInfo) {
    await new Promise((resolve, reject) => {
      callAPI(
        '/profile',
        null,
        (resp) => {
          if (resp.profile) {
            const newProfile = {
              ...resp.profile,
              ...userInfo,
            };
            localStorage.setItem('impersonation', JSON.stringify(newProfile));
            resolve();
          } else {
            reject();
          }
        },
        () => {
          reject();
        }
      );
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async impersonateUser(userInfo) {
    localStorage.removeItem('teamsAPI');
    localStorage.removeItem('filters');
    localStorage.setItem('impersonation', JSON.stringify(userInfo));
    await this.getProfile(userInfo);
    window.location = '/cases'; // need to use window.location instead of this.props.history due to race condition
  }

  displayError(error) {
    this.setState({
      isLoaded: true,
      error: error.message,
    });
    this.toast.current.show({ severity: 'error', summary: error.message });
  }

  render() {

    const grantOptions = [
      { enumCode: 'all_access', label: 'Access to all cases' },
      { enumCode: 'my_division', label: 'Access to cases in their group and child groups' },
      { enumCode: 'my_team', label: 'Access to their cases and any cases granted to members in their team' },
      { enumCode: 'my_cases', label: 'Accesss to cases directly assigned' },
      { enumCode: 'my_connected_cases', label: 'Accesss to any cases connected to their cases' },
      { enumCode: 'group_connected_cases', label: 'Access to all cases connected to cases in their group or below' }
    ];

    const emailFilter = (
      <InputText
        value={this.state.searchEmail}
        keyfilter={/^[A-Za-z0-9_.\-@+]+$/}
        onChange={(e) => {
          this.setState({ searchEmail: e.target.value });
          this.searchField();
        }}
        placeholder='Filter Email'
      />
    );

    const firstNameFilter = (
      <InputText
        value={this.state.searchFirstName}
        keyfilter={/^[a-z A-Z-]+$/}
        onChange={(e) => {
          this.setState({ searchFirstName: e.target.value });
          this.searchField();
        }}
        placeholder='Filter First Name'
      />
    );

    const lastNameFilter = (
      <InputText
        value={this.state.searchLastName}
        onChange={(e) => {
          this.setState({ searchLastName: e.target.value });
          this.searchField();
        }}
        placeholder='Filter Last Name'
      />
    );

    const agencyFilter = (
      <Dropdown
        value={this.state.searchAgency}
        optionLabel='name'
        optionValue='id'
        options={[{ id: 'ALL', name: 'ALL' }].concat(this.state.agencies)}
        onChange={(e) => {
          this.searchAgency(e);
        }}
        placeholder='Filter Agency'
      />
    );

    const groupFilter = (
      <Dropdown
        value={this.state.searchGroup}
        optionLabel='name'
        optionValue='id'
        options={[{ id: 'ALL', name: 'ALL' }].concat(this.state.groups)}
        onChange={(e) => {
          this.searchGroup(e);
        }}
        placeholder='Filter Group'
      />
    );

    const activeFilter = (
      <Dropdown
        value={this.state.searchActive}
        options={[
          { label: 'All', value: 'ALL' },
          { label: 'Active', value: 'true' },
          { label: 'Inactive', value: 'false' },
        ]}
        onChange={(e) => {
          this.searchActive(e);
        }}
        placeholder='Filter Active'
      />
    );

    const userTypeFilter = (
      <Dropdown
        value={this.state.searchUserType}
        optionLabel='name'
        optionValue='id'
        options={[{ id: 'ALL', name: 'ALL' }].concat(this.state.userTypes)}
        onChange={(e) => {
          this.searchUserType(e);
        }}
        placeholder='Filter UserType'
      />
    );

    const managerFilter = (
      <InputText
        value={this.state.searchManager}
        onChange={(e) => {
          this.setState({ searchManager: e.target.value });
          this.searchField();
        }}
        placeholder='Filter Manager'
      />
    );

    const staffPositionFilter = (
      <InputText
        value={this.state.searchStaffPosition}
        onChange={(e) => {
          this.setState({ searchStaffPosition: e.target.value });
          this.searchField();
        }}
        placeholder='Filter Staff Position'
      />
    );

    const rowExpansionTemplate = (dataRow) => {
      const impersonable =
        this.state.userTypes.filter(
          (userType) => userType.id === dataRow.user_type
        ).length === 1 && dataRow.email;
      const impersonationLink =
        impersonable && this.state.isAIAdmin ? (
          <div className='col-12'>
            <div className='grid'>
              <div className='md:col-2'>Impersonate: </div>
              <div className='md:col-10' style={{ fontWeight: 'bold' }}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  className='aLink'
                  onClick={async () => {
                    await this.impersonateUser(dataRow);
                  }}
                >
                  Impersonate User
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        );
      return (
        <div className='grid p-fluid'>
          <div className='col-12'>
            <div className='grid'>
              <div className='md:col-2'>Name: </div>
              <div className='md:col-10' style={{ fontWeight: 'bold' }}>
                {dataRow.first_name} {dataRow.last_name}
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='grid'>
              <div className='md:col-2'>Worker ID: </div>
              <div className='md:col-10' style={{ fontWeight: 'bold' }}>
                {dataRow.source_id}
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='grid'>
              <div className='md:col-2'>Grant type: </div>
              <div className='md:col-10' style={{ fontWeight: 'bold' }}>
                {dataRow.grants.join(', ')}
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='grid'>
              <div className='md:col-2'>Cases: </div>
              <div className='md:col-10' style={{ fontWeight: 'bold' }}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  className='aLink'
                  onClick={(e) => {
                    e.metaKey || e.ctrlKey
                      ? window.open(
                          `/admin/assignments/${dataRow.profile_id}`,
                          '_blank'
                        )
                      : this.props.history.push(
                          `/admin/assignments/${dataRow.profile_id}`
                        );
                  }}
                >
                  Edit Assignments
                </div>
              </div>
            </div>
          </div>
          {impersonationLink}
          <div className='col-12'>
            <div className='grid'>
              <div className='md:col-2'>Has Reports: </div>
              <div className='md:col-10' style={{ fontWeight: 'bold' }}>
                {dataRow.has_team ? 'Yes' : 'No'}
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='grid'>
              <div className='md:col-2'>Last Login: </div>
              <div className='md:col-10' style={{ fontWeight: 'bold' }}>
                {dataRow.last_login}
              </div>
            </div>
          </div>
        </div>
      );
    };
    return (
      <Page
        title='Users'
        pageTitle='Users Admin | Augintel'
        isLoaded={this.state.isLoaded}
        toast={this.toast}
        error={this.state.error}
        errorMessage={this.state.errorMessage}
        actions={[
          <Button
            label='New User'
            icon='pi pi-plus'
            onClick={() => {
              this.props.history.push('/admin/users/add');
            }}
          />,
        ]}
      >
        <div className='adminUsersBody grid'>
          <div className='kpis'>
            <span className='kpi'>
              <div className='count'>
                {this.state.userCount?.toLocaleString('default')}
              </div>
              <div className='label'>Total Users</div>
            </span>
            <span className='kpi'>
              <div className='count'>{this.state.agencyCount}</div>
              <div className='label'>Agencies</div>
            </span>
            <span className='kpi'>
              <div className='count'>
                {this.state.activeCount?.toLocaleString('default')}
              </div>
              <div className='label'>Active Users</div>
            </span>
          </div>
          <LoadingContainer
            isLoaded={this.state.filterLoaded}
            useWindowWidth={true}
          >
            <DataTable
              value={this.state.displayUsers}
              style={{ overflow: 'auto' }}
              paginator
              rows={this.state.pageLimit}
              totalRecords={this.state.userCount}
              filterDisplay='row'
              first={this.state.first}
              lazy
              onPage={(e) => {
                this.setState({ first: e.first }, this.loadMoreUsers);
              }}
              editMode='cell'
              sortField={this.state.sortField}
              sortOrder={this.state.sortOrder}
              onSort={(e) =>
                this.setState(
                  { sortField: e.sortField, sortOrder: e.sortOrder },
                  this.loadUsers(true)
                )
              }
              resizableColumns
              expandedRows={this.state.expandedRows}
              onRowToggle={(e) => this.setState({ expandedRows: e.data })}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey='profile_id'
            >
              <Column expander style={{ width: '4em' }} />
              <Column
                field='email'
                header='Email'
                filter
                placeholder='Filter Email'
                filterElement={emailFilter}
                showFilterMenu={false}
                sortable
              />
              <Column
                field='first_name'
                header='First Name'
                filter
                placeholder='Filter First Name'
                filterElement={firstNameFilter}
                showFilterMenu={false}
                sortable
                body={(rowData) => (
                  <span
                    className={
                      rowData.updating?.first_name &&
                      rowData.updating?.first_name !== rowData.first_name
                        ? 'updating'
                        : ''
                    }
                  >
                    {rowData.first_name}
                  </span>
                )}
              />
              <Column
                field='last_name'
                header='Last Name'
                filter
                placeholder='Filter Last Name'
                filterElement={lastNameFilter}
                showFilterMenu={false}
                sortable
                body={(rowData) => (
                  <span
                    className={
                      rowData.updating?.last_name &&
                      rowData.updating?.last_name !== rowData.last_name
                        ? 'updating'
                        : ''
                    }
                  >
                    {rowData.last_name}
                  </span>
                )}
              />
              <Column
                field='agency_name'
                header='Agency'
                filter
                filterElement={agencyFilter}
                showFilterMenu={false}
                sortable
              />
              <Column
                field='group_id'
                header='Group'
                filter
                filterElement={groupFilter}
                showFilterMenu={false}
                sortable
                style={{ width: '6em' }}
                body={(rowData) => (
                  <span
                    className={
                      rowData.updating?.group_name &&
                      rowData.updating?.group_name === rowData.group_name
                        ? 'updating'
                        : ''
                    }
                  >
                    {rowData.group_name}
                  </span>
                )}
              />
              <Column
                field='is_active'
                header='Active'
                filter
                filterElement={activeFilter}
                showFilterMenu={false}
                sortable
                style={{ width: '6em' }}
                body={(rowData) => (
                  <span
                    className={
                      rowData.updating?.is_active &&
                      rowData.updating?.is_active === rowData.is_active
                        ? 'updating'
                        : ''
                    }
                  >
                    {rowData.is_active ? 'Active' : 'Inactive'}
                  </span>
                )}
              />
              <Column
                field='staff_position'
                header='Position'
                filter
                filterElement={staffPositionFilter}
                showFilterMenu={false}
                sortable
                style={{ width: '6em' }}
                body={(rowData) => (
                  <span
                    className={
                      rowData.updating?.staff_position &&
                      rowData.updating?.staff_position === rowData.staff_position
                      ? 'updating'
                      : ''
                    }
                  >
                    {rowData.staff_position}
                  </span>
                )}
              />
              <Column
                field='user_type'
                header='User Type'
                filter
                filterElement={userTypeFilter}
                showFilterMenu={false}
                sortable
                body={(rowData) => (
                  <span
                    className={
                      rowData.updating?.user_type &&
                      rowData.updating?.user_type !== rowData.user_type
                        ? 'updating'
                        : ''
                    }
                  >
                    {rowData.user_type_name}
                  </span>
                )}
              />
              <Column
                field='manager_id'
                header='Manager'
                filter
                filterElement={managerFilter}
                showFilterMenu={false}
                sortable
                body={(rowData) => (
                  <span
                    className={
                      rowData.updating?.manager_id &&
                      rowData.updating?.manager_id !== rowData.manager_id
                        ? 'updating'
                        : ''
                    }
                  >
                    {rowData.updating?.manager_id && rowData.manager_name === ''
                      ? 'None'
                      : rowData.manager_name}
                  </span>
                )}
              />
              <Column
                field='edit'
                header='Edit'
                body={ (rowData) => {
                  const isAllowedAgency = this.state.agenciesAllowEdit.some(agency => agency.id === rowData.agency_id);
                  if (isAllowedAgency) {
                    return (
                      <Button
                        className='p-button-danger'
                        icon='pi pi-pencil'
                        onClick={() => {
                          this.setState(
                            {
                              dialogOpen: true,
                              newUser: rowData                        }
                          );
                        }}
                        />
                    )
                  }
                  return null;
                }}
              />
            </DataTable>
            <Dialog
              header='Edit User'
              visible={this.state.dialogOpen}
              style={{ width: '50vw' }}
              onHide={this.closeDialog}
            >
            <LoadingContainer isLoaded={!this.state.editing}>
              <div className='adminUsersUpdateBody grid adminInputForm'>
                <div className='col-12 input'>
                  <label htmlFor='firstName'>First Name:</label>
                  <InputText
                    id='firstName'
                    value={this.state.newUser.first_name}
                    onChange={(e) => {
                        this.handleChange('first_name', e.target.value)
                      }
                    }
                    placeholder={this.state.newUser.first_name}
                  />
                </div>
                <div className='col-12 input'>
                  <label htmlFor='lastName'>Last Name:</label>
                  <InputText
                    id='lastName'
                    value={this.state.newUser.last_name}
                    onChange={(e)=> {
                      this.handleChange('last_name', e.target.value)
                    }}
                  />
                </div>
                <div className='col-12 input'>
                  <label htmlFor='email'>Email Address:</label>
                  <InputText
                    id='email'
                    value={this.state.newUser.email}
                    onChange={(e)=> {
                      this.handleChange('email', e.target.value)
                    }}
                  />
                </div>
                <div className='col-12 input'>
                  <label htmlFor='sourceId'>Worker Id:</label>
                  <InputText
                    id='sourceId'
                    value={this.state.newUser.source_id}
                    onChange={(e)=> {
                      this.handleChange('source_id', e.target.value)
                    }}
                  />
                </div>
                <div className='col-12 input'>
                  <label htmlFor='staffPosition'>Staff Position:</label>
                  <InputText
                    id='staffPosition'
                    value={this.state.newUser.staff_position}
                    onChange={(e)=> {
                      this.handleChange('staff_position', e.target.value)
                    }}
                  />
                </div>
                <div className='col-12 input'>
                  <label htmlFor='grants'>Grant Type:</label>
                  <MultiSelect
                    id='grants'
                    value={this.state.newUser.grants}
                    options={grantOptions}
                    display="chip"
                    optionLabel='label'
                    optionValue='enumCode'
                    onChange={(e) => {
                      this.handleChange('grants', e.value)
                    }}
                    placeholder='Grant Type'
                  />
                </div>
              </div>
              <div className='col-12 buttonPanel right'>
              <Button
                label='Edit User'
                className='p-button-danger'
                onClick={() => {
                  this.editItem();
                }}
              />
              <Button
                label='Cancel'
                className='p-button'
                onClick={() => {
                  this.setState( {dialogOpen: false} );
                }}
              />
            </div>
            </LoadingContainer>
            </Dialog> 
          </LoadingContainer>
        </div>
      </Page>
    );
  }
}
