import { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import './SearchBox.scss';

interface SearchBoxProps {
  savedSearchPhrase: string;
  updateSearchPhrase: (phrase: string) => void;
}

export const SearchBox = ({
  savedSearchPhrase,
  updateSearchPhrase,
}: SearchBoxProps) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);
  let timeout: ReturnType<typeof setTimeout>;

  useEffect(() => {
    if (savedSearchPhrase !== searchPhrase && savedSearchPhrase === '') {
      setSearchPhrase('');
    }
  }, [savedSearchPhrase]);

  const clearSearch = () => {
    setSearchPhrase('');
    updateSearchPhrase('');
    if (timeout) clearTimeout(timeout);
    setShowPrompt(false);
  };

  const onChange = (phrase: string) => {
    setSearchPhrase(phrase);
    if (timeout) clearTimeout(timeout);
    if (phrase === '') {
      setShowPrompt(false);
      return;
    }
    if (!showPrompt) {
      timeout = setTimeout(() => {
        setShowPrompt(true);
      }, 1500);
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      updateSearchPhrase(searchPhrase);
      setShowPrompt(false);
    }
  };

  return (
    <span className='search'>
      <div className='search-div'>
        <span className='p-input-icon-left p-input-icon-right searchBox'>
          <i className='pi pi-search' />
          <InputText
            id='noteSearch'
            placeholder='Search'
            value={searchPhrase}
            onChange={(e) => onChange(e.target.value)}
            onKeyUp={(e) => {
              onKeyUp(e);
            }}
          />
          {showPrompt && <div className={'search-prompt'}>Press ENTER</div>}
          <i
            className='clearIcon pi pi-times'
            role='presentation'
            onClick={() => clearSearch()}
          />
        </span>
        <label className='accessibility' htmlFor='noteSearch'>
          Search
        </label>
      </div>
    </span>
  );
};
