/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
// TODO refactor to not have to disable no-param-reassign
import React from 'react';
import {
  guardUndefinedOrNull,
  serializeDateForDisplay,
} from '../../utils/NoteUtils';
import {
  chooseSentences,
  formatSentences,
  sentenceMatchesQuery,
  sentenceMatchesSearchEntity,
  sentenceMatchesSearchFactor,
} from '../../utils/SentenceUtils';
import {
  Note,
  NoteTextParsed,
  Paragraph,
  SearchProps,
} from '../../shared/types/noteTypes';
import { Factors } from '../../shared/types/appTypes';

interface SearchResultProps {
  note: Note;
  isExpanded: boolean;
  showTags: boolean;
  factors: Factors;
  searchProps: SearchProps;
  noteI: number;
  caseID: number;
  openCaseNote: (
    noteId: number,
    sourceId: string,
    dateString: string,
    sentences: (Element | JSX.Element)[],
    noteI: number
  ) => void;
  displaySentenceFeedback: () => void;
  displayEntityPopupByID: () => void;
  getTaggingEnabled: () => boolean;
  trackWithMixpanel: (eventType: string) => void;
}

export const SearchResult = ({
  note,
  isExpanded,
  showTags,
  factors,
  searchProps,
  noteI,
  caseID,
  openCaseNote,
  displaySentenceFeedback,
  displayEntityPopupByID,
  getTaggingEnabled,
  trackWithMixpanel,
}: SearchResultProps) => {
  const renderSearchResult = () => {
    const noteDate = new Date(note.event_datetime);
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
    });
    const [{ value: month }, , { value: day }, , { value: year }] =
      dateTimeFormat.formatToParts(noteDate);
    const dateString = `${month}/${day}/${year}`;

    const header = <div>{dateString}</div>;

    note.note_text_parsed = guardUndefinedOrNull<NoteTextParsed>(
      note.note_text_parsed,
      {} as NoteTextParsed
    );
    note.note_text_parsed.paragraphs = guardUndefinedOrNull<Paragraph[]>(
      note.note_text_parsed.paragraphs,
      [] as Paragraph[]
    );
    note.note_text_parsed.factor_counts = guardUndefinedOrNull<string[]>(
      note.note_text_parsed.factor_counts,
      []
    );
    chooseSentences(note, isExpanded, factors, searchProps);
    let sentences = formatSentences(
      note,
      factors,
      noteI,
      caseID,
      searchProps.searchQueryID,
      displaySentenceFeedback,
      displayEntityPopupByID,
      getTaggingEnabled,
      isExpanded
    );
    // TODO fix this
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (sentences.length === 0) sentences = ['-None-'];
    sentences = formatSentences(
      note,
      factors,
      noteI,
      caseID,
      searchProps.searchQueryID,
      displaySentenceFeedback,
      displayEntityPopupByID,
      getTaggingEnabled,
      isExpanded
    );
    const footerLink = (
      <div
        className='aLink'
        onClick={() => {
          trackWithMixpanel('case-notes-show-more-click-search-result');
          openCaseNote(
            note.note_id,
            note.source_id,
            serializeDateForDisplay(note.event_datetime),
            sentences,
            noteI
          );
        }}
      >
        Show More
      </div>
    );

    const resultRows: { tagText: string | null; resultText: any }[] = [];
    note.note_text_parsed.paragraphs.forEach((paragraph, paragraphI) => {
      paragraph.sentences.forEach((sentence, sentenceI) => {
        if (typeof note.token !== 'undefined') {
          const matchingSentences = note.token.sentences.filter(
            (sent) =>
              sent.paragraph_id === paragraphI && sent.sentence_id === sentenceI
          );
          if (matchingSentences.length > 0) {
            const sent = matchingSentences[0];
            resultRows.push({
              tagText: sent.phrase,
              resultText:
                note.note_text_parsed.paragraphs[sent.paragraph_id].sentences[
                  sent.sentence_id
                ].text,
            });
          }
        } else if (
          searchProps.searchFactor !== '' &&
          searchProps.searchFactor &&
          sentenceMatchesSearchFactor(sentence, searchProps.searchFactor)
        ) {
          resultRows.push({
            tagText: null,
            resultText:
              note.note_text_parsed.paragraphs[paragraphI].sentences[sentenceI]
                .text,
          });
        } else if (
          searchProps.searchPerson !== '' &&
          searchProps.searchPerson &&
          typeof searchProps.searchPerson === 'object' &&
          sentenceMatchesSearchEntity(sentence, searchProps.searchPerson.id)
        ) {
          resultRows.push({
            tagText: null,
            resultText:
              note.note_text_parsed.paragraphs[paragraphI].sentences[sentenceI]
                .text,
          });
        } else if (searchProps.searchQueryID) {
          const { query_ids } = note;
          const matchingQuery = query_ids.find(
            (query) => query.query_id === Number(searchProps.searchQueryID)
          );
          if (matchingQuery && matchingQuery.override_message) {
            if (
              !resultRows.find(
                (result) => result.resultText === matchingQuery.override_message
              )
            )
              resultRows.push({
                tagText: null,
                resultText: matchingQuery.override_message,
              });
          } else if (
            sentenceMatchesQuery(sentence, searchProps.searchQueryID)
          ) {
            resultRows.push({
              tagText: null,
              resultText:
                note.note_text_parsed.paragraphs[paragraphI].sentences[
                  sentenceI
                ].text,
            });
          }
        }
      });
    });

    return (
      <div className={`search-result ${showTags ? 'date-line' : ''}`}>
        <div className='date'>{header}</div>
        <div className='content'>
          {resultRows.map((row, rowI) => (
            <React.Fragment key={`caseNoteResult-${rowI}`}>
              <span className='contentRow'>
                {row.tagText && showTags ? (
                  <span className='tag'>{row.tagText}</span>
                ) : null}
                {row.resultText}
                {rowI < resultRows.length - 1 ? '' : footerLink}
              </span>
              {rowI < resultRows.length - 1 ? <br /> : ''}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };
  return renderSearchResult();
};
