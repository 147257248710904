import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { DataScroller } from 'primereact/datascroller';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { ProgressBar } from 'primereact/progressbar';
import { callAPIAsync } from '../../../libs/API';
import { Page } from '../../../components-new';
import PhraseLabelingCard from '../../../components/labeling/PhraseLabelingCard';
import './PhraseSearch.scss';

type Phrase = {
  phrase: string;
  score: number;
  id: string;
};

export const PhraseSearch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [scoreThreshold, setScoreThreshold] = useState<string>('2.50');
  const [phrases, setPhrases] = useState<Phrase[]>([]);
  const [buttonValues, setButtonValues] = useState<{ [key: string]: string }>(
    {}
  );
  const [offset, setOffset] = useState(0);

  const getMorePhrases = async (resetOffset: boolean) => {
    if (!isLoading) {
      setError('');
      setIsLoading(true);
      const limit = 1000;
      const request = {
        searchPhrase,
        scoreThreshold,
        limit,
        offset: resetOffset ? 0 : offset,
      };
      const response = await callAPIAsync('/admin/phraseSearch', request).catch(
        (err: unknown) => {
          setIsLoading(false);
          console.log(err);
          setError('Failed to search for phrases.');
        }
      );
      if (response.length > 0) {
        if (resetOffset) {
          setPhrases(response);
          setOffset(limit);
        } else {
          setPhrases([...phrases, ...response]);
          setOffset(offset + limit);
        }
      } else {
        setError('No phrases found.');
      }
      setIsLoading(false);
    }
  };

  const submitPhraseSearch = async () => {
    setOffset(0);
    setPhrases([]);
    setButtonValues({});
    await getMorePhrases(true);
  };

  const submitLabels = async () => {
    if (phrases.length > 0) {
      setIsLoading(true);
      setError('');
      const request = {
        phrase: searchPhrase,
        labels: buttonValues,
      };
      await callAPIAsync('/admin/exportToS3', request)
        .then(() => {
          const submittedPhrases = Object.keys(buttonValues);
          const updatedPhrases = phrases.filter(
            (phrase: Phrase) => !submittedPhrases.includes(phrase.phrase)
          );
          setPhrases(updatedPhrases);
          setButtonValues({});
          setIsLoading(false);
        })
        .catch((err: unknown) => {
          setIsLoading(false);
          console.log(err);
          setError('Failed to submit labels');
        });
    }
  };

  const phraseTemplate = (phrase: Phrase) => (
    <PhraseLabelingCard
      phrase={phrase}
      setButtonValues={setButtonValues}
      buttonValues={buttonValues}
    />
  );

  const phraseDisplay =
    phrases.length > 0 ? (
      <DataScroller
        value={phrases}
        itemTemplate={phraseTemplate}
        inline
        rows={10}
      />
    ) : (
      ''
    );

  const errorMessage = error ? (
    <p className='col-12 center errorMessage'>{error}</p>
  ) : (
    ''
  );

  return (
    <Page title='Phrase Search' pageTitle='Phrase Search Admin | Augintel'>
      <div className='phraseSearchBody'>
        <div className='p-fluid formgrid grid'>
          <div className='field col-3'>
            <label htmlFor='searchPhrase'>Search Phrase</label>
            <InputText
              id='searchPhase'
              value={searchPhrase}
              keyfilter={/^[^<>@%$]*$/}
              onChange={(e) => {
                setSearchPhrase(e.target.value);
              }}
              placeholder='Search Phrase'
            />
          </div>
          <div className='field col-3'>
            <label htmlFor='scoreThreshold'>Score Threshold</label>
            <InputMask
              mask={'9.99'}
              placeholder='Score Threshold'
              value={scoreThreshold}
              onChange={(e) => {
                setScoreThreshold(e.value);
              }}
            />
          </div>
        </div>
        <div className='buttonPanel'>
          <Button
            label='Search for phrases'
            disabled={isLoading || !(searchPhrase && scoreThreshold)}
            className='p-button'
            onClick={() => {
              void submitPhraseSearch();
            }}
          />
          <Button
            label='Submit labels'
            disabled={
              isLoading ||
              Object.keys(phrases).length === 0 ||
              Object.keys(buttonValues).length === 0
            }
            className='p-button'
            onClick={() => {
              void submitLabels();
            }}
          />
        </div>
        {errorMessage}
        {phraseDisplay}
        {isLoading ? <ProgressBar mode='indeterminate' /> : ''}
      </div>
    </Page>
  );
};
