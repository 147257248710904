import mixpanelLib from 'mixpanel-browser';

const mixpanelId = process.env.REACT_APP_MIXPANEL_ID;
const environment = process.env.REACT_APP_ENV || '';
const mixpanelEnabled = process.env.REACT_APP_MIXPANEL_ENABLE;

let mixpanel;

if (mixpanelId && mixpanelEnabled) {
  let config = {}
  if (['dev', 'develop'].includes(environment)) {
    config = {
      debug: true,
      ignore_dnt: true
    }
  }
  mixpanel = mixpanelLib.init(mixpanelId, config, 'Augintel-UI');
} else {
  console.error('Mixpanel failed to initialize');
  mixpanel = mixpanelLib;
}

const Mixpanel = mixpanel;
export default Mixpanel;
