/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { callAPI } from '../../libs/API';
import { Page, LoadingContainer } from '../../components-new';

// TODO: manager column or org view
// TODO: create a middle component to extend for simple functions like displayError
export default class AdminAgencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      filterLoaded: true,
      agencies: [],
      impersonation: this.props.impersonation,
      sortField: 'name',
      sortOrder: 1,
      error: '',
      isAIAdmin: this.props.profile.user_type === 'augintel_admin',
    };

    if (!this.state.isAIAdmin || this.state.impersonation)
      this.props.history.push('/');

    this.toast = React.createRef();
    this.loadData = this.loadData.bind(this);
    this.displayError = this.displayError.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  // not adding filters or pagination as of yet since there's a low load of agencies
  loadData() {
    this.setState({ filterLoaded: false }, () => {
      callAPI(
        '/admin/agencies',
        null,
        (resp) => {
          if (typeof resp.agencies !== 'undefined') {
            this.setState({
              isLoaded: true,
              agencies: resp.agencies !== null ? resp.agencies : [],
              filterLoaded: true,
            });
          } else {
            this.displayError({ message: 'Agencies not found' });
          }
        },
        (error) => {
          this.displayError(error);
        }
      );
    });
  }

  displayError(error) {
    this.setState({
      isLoaded: true,
      error: error.message,
    });
    this.toast.current.show({ severity: 'error', summary: error.message });
  }

  render() {
    return (
      <Page
        title='Agencies'
        pageTitle='Agencies Admin | Augintel'
        isLoaded={this.state.isLoaded}
        toast={this.toast}
        error={this.state.error}
        headerSpacing={20}
      >
        <div className='adminAgenciesBody grid'>
          <LoadingContainer isLoaded={this.state.filterLoaded}>
            <DataTable
              value={this.state.agencies}
              sortField={this.state.sortField}
              sortOrder={this.state.sortOrder}
              resizableColumns
              breakpoint='800px'
              editMode='cell'
            >
              <Column
                field='name'
                header='Name'
                sortable
                body={(rowData) => <span>{rowData.name}</span>}
              />
              <Column
                header='Domains'
                body={(rowData) => (
                  <span>
                    {rowData.domains?.map((domain) => domain.domain).join(', ')}{' '}
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <div
                      className='aLink'
                      onClick={(e) => {
                        e.metaKey || e.ctrlKey
                          ? window.open(
                              `/admin/agencies/${rowData.id}/domains`,
                              '_blank'
                            )
                          : this.props.history.push(
                              `/admin/agencies/${rowData.id}/domains`
                            );
                      }}
                    >
                      Edit
                    </div>
                  </span>
                )}
              />
            </DataTable>
          </LoadingContainer>
        </div>
      </Page>
    );
  }
}
