import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Query, QueryHoverState } from '../shared/types/queryTypes';

interface QueryCardProps {
  query: Query;
  hoverState?: QueryHoverState;
  chart?: ReactElement;
}

export default function QueryCard({
  query,
  hoverState,
  chart,
}: QueryCardProps) {
  const history = useHistory();
  const header = (
    <div className='grid justify-content-between queryHeader'>
      <div className='col title'>{query.title}</div>
    </div>
  );
  const casesLink = (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className='aLink'
      onClick={(e) => {
        e.metaKey || e.ctrlKey
          ? window.open(`/queries/${query.id}`, '_blank')
          : history.push(`/queries/${query.id}`);
      }}
    >
      See Cases
    </div>
  );

  let curVal;
  let curMonth;
  if (query.chart) {
    curVal =
      // eslint-disable-next-line no-nested-ternary
      hoverState?.hover === true
        ? hoverState.chartHoverValue
        : query.chart[query.chart.length - 2].val; // get the last complete month (which is the N-1'th, not the Nth)
    curMonth =
      // eslint-disable-next-line no-nested-ternary
      hoverState?.hover === true
        ? hoverState.chartHoverMonth
        : new Date(
            `${query.chart[query.chart.length - 2].date}-02`
          )?.toLocaleString('default', { month: 'short' });
  }
  return (
    <span
      className='queryColumn xl:col-4 lg:col-4 md:col-6 sm:col-12'
      key={`queryColumn${query.id}`}
    >
      <Card
        header={header}
        footer={casesLink}
        className='queryCard my-3'
        key={query.id}
      >
        <div className='subTitle'>{query.subtitle}</div>
        <div className='caseCount'>{curVal}</div>
        <div className='caseLabel'>
          {curMonth} {curVal === 1 ? 'Case' : 'Cases'}
        </div>
        {chart}
      </Card>
    </span>
  );
}
