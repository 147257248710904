import React from 'react';
import { Button } from 'primereact/button';
import './DateSort.scss';

type SortOption = 'relevance' | 'newest' | 'oldest';

interface SearchBoxProps {
  sortNotes: (sort: SortOption) => void;
  sort: SortOption;
  disabled: boolean;
}

export const DateSort = ({ sortNotes, sort, disabled }: SearchBoxProps) => {
  const button =
    sort === 'newest' ? (
      <Button
        label='Date: Newer'
        className='p-button-link date-sort'
        onClick={() => sortNotes('oldest')}
        icon='pi pi-angle-up'
        iconPos='right'
      />
    ) : (
      <Button
        label='Date: Older'
        className='p-button-link date-sort'
        onClick={() => sortNotes('newest')}
        icon='pi pi-angle-down'
        iconPos='right'
      />
    );

  return !disabled ? button : '';
};
