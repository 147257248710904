import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from 'primereact/button';
import { LoadingContainer } from '../atoms';
import { palette } from '../../styles';
import { capitalize } from '../../utils';

const useStyles = makeStyles(() => ({
  formError: {
    margin: 0,
    color: palette.error.dark,
    fontWeight: 600,
  },
  formInput: {
    marginBottom: 10,
  },
  action: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 20,
  },
}));

/**
 * Augintel UI Form Component
 * @param fields {[object]} - The array of field objects to render in the form
 * @param submitText {string} - (optional) The text of the submit button
 * @param subAction {JSX.Element} - (optional) The text of the submit button
 * @param onFormSubmit {function} - Callback when sumbit button is pressed  - (function)
 * @param onFormCancel {function} - Callback when cancel button is pressed - (function)
 * @param errorMessage {string} - (optional) The error message above the form - (string)
 * @param hideCancel {boolean} - (optional) Hide the cancel button - (boolean)
 * @param isLoaded {boolean} - when true, stops loading animation - (boolean)
 * @param required {boolean} - (optional) Force all fields to have a value before calling onFormSubmit - (boolean)
 */
export const Form = ({
  fields,
  submitText = 'Submit',
  subAction,
  onFormSubmit,
  onFormCancel,
  errorMessage,
  hideCancel = false,
  isLoaded = true,
  required = false,
}) => {
  const classes = useStyles();
  const initialData = {};
  const [formData, setFormData] = useState();
  const [error, setError] = useState(errorMessage);

  useEffect(() => {}, []);

  const handleFormInput = (key, value) => {
    error && setError();

    setFormData({
      ...initialData,
      ...formData,
      [key]: value,
    });
  };

  const parseField = ({
    idx,
    name, // name of input - do not camelCase - use hyphens instead: ex: email_address
    type, // type of input - default is 'text'
    _label, // label for input and accessibility
    value, // defualt value for input
    component: {
      el, // the component
      name: fieldName, // the field to save the form values to
    } = {}, // custom component
    placeholder, // placeholder text - defaults to formatted name of input
    maxLength, // maxLength of input
  }) => {
    if (el) {
      return (
        <div key={`${fieldName}-${idx}`} className={classes.formInput}>
          {React.cloneElement(el, {
            response: (currentValue) =>
              handleFormInput(fieldName, currentValue),
          })}
        </div>
      );
    }

    const formattedFieldName = capitalize(name.replace('_', ' '));
    return (
      <div key={name} className={classes.formInput}>
        <input
          className={`${
            type === 'checkbox' ? 'p-component' : 'p-inputtext p-component'
          }`}
          name={name}
          type={type || 'text'}
          placeholder={placeholder || formattedFieldName}
          maxLength={maxLength || undefined}
          defaultValue={formData?.[name] || value}
          onChange={(e) => handleFormInput(name, e.target.value)}
        />
        <label className='accessibility' htmlFor={name}>
          {formattedFieldName}
        </label>
      </div>
    );
  };

  const handleSubmitForm = () => {
    if (required) {
      if (formData) {
        let formError = false;
        Object.keys(formData).forEach((key) => {
          if (!formData[key]) {
            formError = true;
            setError('All fields are required');
          }
        });

        if (!formError) {
          onFormSubmit(formData);
        }
      } else {
        setError('All fields are required');
      }
    } else {
      onFormSubmit(formData);
    }
  };

  const handleCancelForm = () => {
    onFormCancel();
  };

  return (
    <LoadingContainer isLoaded={isLoaded}>
      {error && <p className={classes.formError}>{error}</p>}
      <div className={classes.container}>
        {[...fields]?.map((field, idx) => {
          if (field.name) {
            initialData[field.name] = field.value || '';
          }

          return parseField(field, idx);
        })}
        <div className={classes.footer}>
          {subAction && <div className={classes.action}>{subAction}</div>}
          {!hideCancel && (
            <Button
              label='Cancel'
              className='secondary'
              onClick={handleCancelForm}
            />
          )}
          <Button
            label={submitText}
            onClick={handleSubmitForm}
            style={{ marginLeft: 10 }}
          />
        </div>
      </div>
    </LoadingContainer>
  );
};
