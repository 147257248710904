import { useEffect } from 'react';
import queryString from 'query-string';
import { Checkbox } from 'primereact/checkbox';
import { useHistory, useLocation } from 'react-router-dom';
import { ScopeOption, ScopeOptionKey } from '../../repositories/caseRepository';
import './CaseAssigneeFilter.scss';
import { ImpersonationRecord, Profile } from '../../shared/types/appTypes';
import { getDefaultScope } from '../../libs/FilterService';

interface CaseAssigneeFilterProps {
  impersonation: ImpersonationRecord | null;
  profile: Profile;
  scope: ScopeOption;
  mixpanelCallback: (eventType: string, incomingScopeChange: object) => void;
}

export default function CaseAssignmentFilter({
  impersonation,
  profile,
  scope,
  mixpanelCallback,
}: CaseAssigneeFilterProps) {
  const history = useHistory();
  const location = useLocation();

  const hasOwnCases = impersonation
    ? Number(impersonation.cases_count) > 0
    : Number(profile?.cases_count) > 0;

  const hasTeamCases = impersonation
    ? Number(impersonation.direct_report_cases_count) > 0
    : Number(profile?.direct_report_cases_count) > 0;

  useEffect(() => {
    if (scope) {
      let newScope = '';
      if (!(scope === 'AGENCY' || scope === 'TEAM' || scope === 'USER')) {
        newScope = getDefaultScope(profile, impersonation);
      } else if (scope === 'USER' && !hasOwnCases) {
        newScope = 'AGENCY';
      } else if (scope === 'TEAM' && !hasTeamCases) {
        newScope = 'AGENCY';
      }
      if (newScope.length > 0) {
        const query = queryString.parse(location.search, {
          arrayFormat: 'comma',
        });
        const queryObject = {
          ...query,
          scope: newScope,
        };
        history.replace({
          search: queryString.stringify(queryObject, { arrayFormat: 'comma' }),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope]);

  const onFilterChange = (e: { value: ScopeOption; checked: boolean }) => {
    if (e.checked) {
      const query = {
        ...queryString.parse(location.search, { arrayFormat: 'comma' }),
        page: '1',
        scope: e.value,
      };
      mixpanelCallback('case-type-filter-change', query);
      history.push({
        search: queryString.stringify(query, { arrayFormat: 'comma' }),
      });
    }
  };

  const availableBoxes = [
    hasOwnCases ? (
      <div className='field-checkbox' key={0}>
        <Checkbox
          inputId='filter1'
          name='filter'
          value='USER'
          onChange={onFilterChange}
          checked={scope === 'USER'}
        />
        <label htmlFor='filter1'>
          <p>{ScopeOptionKey.USER}</p>
        </label>
      </div>
    ) : null,
    hasTeamCases ? (
      <div className='field-checkbox' key={1}>
        <Checkbox
          inputId='filter2'
          name='filter'
          value='TEAM'
          onChange={onFilterChange}
          checked={scope === 'TEAM'}
        />
        <label htmlFor='filter2'>
          <p>{ScopeOptionKey.TEAM}</p>
        </label>
      </div>
    ) : null,
  ];

  return (
    <div className={'case-assignee-filter'}>
      <fieldset className='border-none'>
        <legend>
          <h5>CASES</h5>
        </legend>
        {availableBoxes.map((box) => box)}
        <div className='field-checkbox'>
          <Checkbox
            inputId='filter3'
            name='filter'
            value='AGENCY'
            onChange={onFilterChange}
            checked={scope === 'AGENCY'}
          />
          <label htmlFor='filter3'>
            <p>{ScopeOptionKey.AGENCY}</p>
          </label>
        </div>
      </fieldset>
    </div>
  );
}
