import type { SearchDateRange } from '../shared/types/caseTypes';

export const buildSearchDate = (
  searchMonth: string | undefined,
  searchYear: string | undefined
) => {
  const searchDate =
    searchMonth !== '' &&
    typeof searchMonth !== 'undefined' &&
    searchYear !== '' &&
    typeof searchYear !== 'undefined'
      ? `&searchMonth=${searchMonth}&searchYear=${searchYear}`
      : '';
  return searchDate;
};

export const buildDateRange = (searchDateRange: SearchDateRange) => {
  const startDateRange =
    typeof searchDateRange.startMonth !== 'undefined' &&
    typeof searchDateRange.startYear !== 'undefined' &&
    searchDateRange.startMonth !== '' &&
    searchDateRange.startYear !== ''
      ? `&startMonth=${searchDateRange.startMonth}&startYear=${searchDateRange.startYear}`
      : '';
  const endDateRange =
    typeof searchDateRange.endMonth !== 'undefined' &&
    typeof searchDateRange.endYear !== 'undefined' &&
    searchDateRange.endMonth !== '' &&
    searchDateRange.endYear !== ''
      ? `&endMonth=${searchDateRange.endMonth}&endYear=${searchDateRange.endYear}`
      : '';
  return `${startDateRange}${endDateRange}`;
};

export const buildSearchPerson = (searchPerson: { id: string } | undefined) => {
  const searchPersonParam =
    typeof searchPerson !== 'undefined' && Object.keys(searchPerson).length > 0
      ? `&searchPerson=${searchPerson.id}`
      : '';

  return searchPersonParam;
};
