import React, { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Inplace, InplaceContent, InplaceDisplay } from 'primereact/inplace';
import { ProgressBar } from 'primereact/progressbar';
import styled from 'styled-components/macro';
import {
  SentenceLabels,
  LabeledSentence,
} from '../../shared/types/labelingTypes';
import ReviewSentence from './ReviewSentence';
import { callAPIAsync } from '../../libs/API';

type Props = {
  sentenceLabels: SentenceLabels;
  setSentenceLabels: Dispatch<SetStateAction<SentenceLabels>>;
  embeddingSearchError: string;
  proposalSentences: SentenceLabels;
  setProposalSentences: Dispatch<SetStateAction<SentenceLabels>>;
  getSimilarSentences: () => void;
};
const AddCategoryDiv = styled.div`
  margin: 0.5em 0em;
`;
const StyledInputText = styled(InputText)`
  max-width: 200px;
`;

export const SentenceReview = ({
  sentenceLabels,
  setSentenceLabels,
  embeddingSearchError,
  proposalSentences,
  setProposalSentences,
  getSimilarSentences,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [newLabels, setNewLabels] = useState<Record<string, string>>({});
  const [tempNewLabel, setTempNewLabel] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState<string>('');

  const addLabel = () => {
    if (tempNewLabel) {
      const tempLabels = sentenceLabels;
      tempLabels[tempNewLabel] = [];
      setSentenceLabels({ ...tempLabels });
      setTempNewLabel('');
    }
  };

  const addLabelWrapper = (e: FormEvent) => {
    addLabel();
    e.preventDefault();
  };

  const storeTempLabel = (label: string, existingLabel: string) => {
    const tempNewLabels = newLabels;
    tempNewLabels[existingLabel] = label;
    setNewLabels(tempNewLabels);
  };

  const changeLabel = (existingLabel: string) => {
    const newLabel = newLabels[existingLabel];
    if (newLabel !== existingLabel) {
      const tempLabels = sentenceLabels;
      tempLabels[newLabel] = sentenceLabels[existingLabel];
      delete tempLabels[existingLabel];
      setSentenceLabels({ ...tempLabels });
    }
  };

  const deleteLabel = (label: string) => {
    const tempLabels = sentenceLabels
    delete tempLabels[label]
    setSentenceLabels(tempLabels)
  }

  const anyLabels = () => {
    let anyLabelValues = false;
    Object.values(sentenceLabels).forEach((sentenceLabel) => {
      if (sentenceLabel.length > 0) {
        anyLabelValues = true;
      }
    });
    return Object.keys(sentenceLabels).length > 0 && anyLabelValues;
  };

  const exportToS3 = async () => {
    if (Object.keys(sentenceLabels).length > 0) {
      setIsLoading(true);
      setError('');
      const request = {
        labels: sentenceLabels,
      };
      await callAPIAsync('/admin/exportToS3', request)
        .then(() => {
          setSentenceLabels({});
          setProposalSentences({});
          setIsLoading(false);
          setSuccess('Labels successfully submitted!');
        })
        .catch((err: unknown) => {
          setIsLoading(false);
          console.log(err);
          setError('Failed to submit labels.');
        });
    }
  };

  const errorMessage =
    embeddingSearchError || error ? (
      <p className='col-12 center errorMessage'>
        {embeddingSearchError || error}
      </p>
    ) : (
      ''
    );

  const successMessage = success ? (
    <p className='col-12 center'>{success}</p>
  ) : (
    ''
  );

  const editableHeader = (label: string, i: number) => (
    <span>
      <Inplace closable onClose={(e) => changeLabel(label)}>
        <InplaceDisplay>
          {label}
          <Button
            icon='pi pi-pencil'
            className='p-button-rounded p-button-text edit-button'
          />
        </InplaceDisplay>
        <InplaceContent>
          <InputText
            value={newLabels[label]}
            onChange={(e) => storeTempLabel(e.target.value, label)}
            className='change-label-input'
          />
          <Button
              label='Delete Label'
              className='p-button'
              onClick={(e) => deleteLabel(label)}
          />
        </InplaceContent>
      </Inplace>
    </span>
  );

  return (
    <div className='sentenceReview'>
      <AddCategoryDiv className='flex align-items-center justify-content-between'>
        <form className='p-inputgroup' onSubmit={(e) => addLabelWrapper(e)}>
          <Button label='Add Label' onClick={(e) => addLabel()} />
          <StyledInputText
            placeholder='Category'
            value={tempNewLabel}
            onChange={(e) => setTempNewLabel(e.target.value)}
          />
        </form>
        <Button
          label='Refresh Proposal Sentences'
          disabled={!anyLabels()}
          className='p-button'
          icon='pi pi-refresh'
          onClick={() => {
            void getSimilarSentences();
          }}
        />
        <Button
          label='Send labels to S3'
          disabled={!anyLabels()}
          className='p-button'
          onClick={() => {
            void exportToS3();
          }}
        />
      </AddCategoryDiv>
      <div className=''>
        Key: <span className='bg-orange-100'>Proposal sentences</span>
      </div>
      {errorMessage}
      {successMessage}
      {isLoading ? <ProgressBar mode='indeterminate' /> : ''}
      {Object.keys(sentenceLabels).length !== 0 ? (
        <Accordion
          multiple
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          {Object.keys(sentenceLabels).map((label, i) => (
            <AccordionTab
              key={`accordion-${label}`}
              header={editableHeader(label, i)}
            >
              {sentenceLabels[label].map((sentence: LabeledSentence, ind) => (
                <ReviewSentence
                  sentence={{ id: sentence.id, text: sentence.text }}
                  currentLabel={label}
                  labels={sentenceLabels}
                  setLabels={setSentenceLabels}
                  isProposalSentence={false}
                  isModelProposalSentence={false}
                  key={`review-sentence-${sentence.id}`}
                />
              ))}
              {proposalSentences[label]
                ?.sort((sentA: LabeledSentence, sentB: LabeledSentence) => {
                  if (sentA.score && sentB.score) {
                    return parseFloat(sentB.score) - parseFloat(sentA.score);
                  }
                  return 0;
                })
                .map((sentence, ind) => (
                  <ReviewSentence
                    sentence={{ id: sentence.id, text: sentence.text }}
                    currentLabel={label}
                    labels={sentenceLabels}
                    setLabels={setSentenceLabels}
                    isProposalSentence={true}
                    isModelProposalSentence={false}
                    proposalSentLabels={proposalSentences}
                    setProposalSentLabels={setProposalSentences}
                    key={`review-sentence-${sentence.id}`}
                  />
                ))}
            </AccordionTab>
          ))}
        </Accordion>
      ) : (
        <div>No labels have been created.</div>
      )}
    </div>
  );
};
