import React from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { ScopeOption } from '../../repositories/caseRepository';

interface Props {
  sort: 'datetime' | 'importance' | undefined;
  mixpanelEventName: string;
  mixpanelInfo: object;
  mixpanelCallback: (eventType: string, incomingScopeChange: object) => void;
}

export default function QuerySortDropdown({
  sort,
  mixpanelEventName,
  mixpanelInfo,
  mixpanelCallback,
}: Props) {
  const history = useHistory();
  const location = useLocation();

  const handleSort = (e: { value: ScopeOption }) => {
    const mixpanelObj = { ...mixpanelInfo, sort_key: e.value };
    mixpanelCallback(mixpanelEventName, mixpanelObj);
    const url = {
      ...queryString.parse(location.search, { arrayFormat: 'comma' }),
      page: '1',
      sort: e.value,
    };
    history.push({
      search: queryString.stringify(url, { arrayFormat: 'comma' }),
    });
  };

  return (
    <div className='query-sort-dropdown'>
      <Dropdown
        className='query-case-dropdown'
        value={sort}
        options={[
          { label: 'Most Results', value: 'importance' },
          { label: 'Newest Results', value: 'datetime' },
        ]}
        onChange={(e: { value: ScopeOption }) => {
          handleSort(e);
        }}
        placeholder='Sort By'
      />
    </div>
  );
}
