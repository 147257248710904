import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Toast, ToastMessage } from 'primereact/toast';
import { datadogRum } from '@datadog/browser-rum';
import { ZendeskAPI } from 'react-zendesk';
import styled from 'styled-components';
import { callAPIAsync } from './libs/API';
import { Case, ChangePassword, ForgotPassword, Login, SSOLogin } from './pages';
import {
  AdminGroups,
  PhraseSearch,
  SentenceLabeling,
  UsageReporting,
} from './pages/admin';
import Queries from './pages/Queries';
import BroadSearchAutoComplete from './components/broadSearch/BroadSearchAutoComplete';
import Query from './pages/Query';
import AdminUsers from './pages/admin/Users';
import AdminUsersAdd from './pages/admin/UsersAdd';
import AdminAgencies from './pages/admin/Agencies';
import AdminAgencyDomains from './pages/admin/AgencyDomains';
import AdminAgencyDomainsAdd from './pages/admin/AgencyDomainsAdd';
import AdminQueryGroups from './pages/admin/QueryGroups';
import AdminQueries from './pages/admin/Queries';
import AdminAssignments from './pages/admin/Assignments';
import AdminAssignmentsAdd from './pages/admin/AssignmentsAdd';
import NotFound from './pages/NotFound';
import { EmailSettings } from './pages/EmailSettings';
import DivButtonOG from './components/utils/DivButton';
import mixpanel from './libs/Mixpanel';
import CasesPage from './pages/Cases';
import { getDefaultScope } from './libs/FilterService';
import {
  DecryptedUser,
  Factors,
  ImpersonationRecord,
  LocalStorageUser,
  Profile,
} from './shared/types/appTypes';
import { decryptToken } from './libs/CognitoTs';
import { clearStorage } from './utils/LogOutUtils';

interface MenuEvent {
  item: MenuItem;
  originalEvent: KeyboardEvent | MouseEvent;
}
const NavHeaderButton = styled(DivButtonOG)`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 16px;
  color: #5e5e5edd;
  font-weight: 600;
  box-sizing: border-box;
`;

const UserMenuToggleButton = styled(DivButtonOG)`
  box-sizing: border-box;
  text-decoration: none;
  background: #ccc;
  border: 1px solid #c1c1c1;
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 16px;
  color: #000;
  line-height: 36px;
  text-align: center;
`;

const HamburgerMenuToggle = styled(UserMenuToggleButton)`
  border: none;
  background: transparent;
`;
class NoProfileError extends Error {
  constructor() {
    super('No profile found');
    this.name = 'NoProfileError';
  }
}

export default function App() {
  const toast = useRef<Toast>(null);
  const profileMenu = useRef<Menu>(null);
  const adminMenu = useRef<Menu>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<DecryptedUser | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [impersonation, setImpersonation] =
    useState<ImpersonationRecord | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [factors, setFactors] = useState<Factors>({} as Factors);

  const clearState = () => {
    clearStorage();
    setIsAuthenticated(false);
    setUser(null);
    setProfile(null);
    setImpersonation(null);
    setIsLoaded(true);
    setFactors({});
    datadogRum.stopSessionReplayRecording();
  };

  const checkBetweenDates = (parsedDate: number) => {
    const start = Date.parse(new Date('January 5, 2024').toISOString());
    const end = Date.parse(new Date('March 6, 2024').toISOString());
    return parsedDate >= start && parsedDate <= end;
  };

  const mixpanelIdentify = (profileRecord: Profile) => {
    if (profileRecord.id === null) return;
    // do mixpanel alias if last login is null OR if date joined AND last_login is Jan 5 to March 5
    if (!profileRecord.last_login) {
      mixpanel.alias(
        profileRecord.id.toString(),
        mixpanel.get_distinct_id() as string
      );
    } else if (
      profileRecord.last_login &&
      profileRecord.date_joined &&
      checkBetweenDates(Date.parse(profileRecord.last_login)) &&
      checkBetweenDates(Date.parse(profileRecord.date_joined))
    ) {
      mixpanel.alias(
        profileRecord.id.toString(),
        mixpanel.get_distinct_id() as string
      );
    }
    mixpanel.identify(profileRecord.id.toString());
    mixpanel.people.set_once(profileRecord);
    mixpanel.people.set_once({ $email: profileRecord.email });
    mixpanel.register_once({
      agency_id: profileRecord.agency_id,
      agency_name: profileRecord.agency_name,
    });
    datadogRum.setUser({
      ...profileRecord,
      id: profileRecord.id.toString(),
    });
    if (profileRecord.agency_id !== 6) datadogRum.startSessionReplayRecording();
  };

  const displayToastMessage = (toastConfig: ToastMessage) =>
    toast.current && toast.current.show(toastConfig);

  const adminCheck = (
    isAdminPath: boolean,
    profileRecord: Profile | null,
    impersonationRecord: ImpersonationRecord | null
  ) => {
    if (isAdminPath) {
      if (impersonationRecord) {
        displayToastMessage({
          severity: 'error',
          summary: 'Admin features are unavailable for impersonated users',
          detail: '',
        });
        return true;
      }
      if (
        ['augintel_admin', 'agency_admin'].indexOf(
          profileRecord?.user_type || ''
        ) < 0
      ) {
        if (process.env.REACT_APP_MIXPANEL_ENABLE === 'false') {
          mixpanel.disable();
        }
        window.location.href = '/';
        return true;
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    try {
      const userLocalStorage = localStorage.getItem('user');
      const profileLocalStorage = localStorage.getItem('profile');
      const impersonationLocalStorage = localStorage.getItem('impersonation');
      const factorsLocalStorage = localStorage.getItem('factors');
      if (userLocalStorage) {
        // decrypt info: https://itnext.io/aws-cognito-example-using-react-ui-and-node-js-rest-apis-part-3-jwt-secured-rest-apis-e56d336ce306
        const userLocalStorageJson = JSON.parse(
          userLocalStorage
        ) as LocalStorageUser;
        const userDecrypted = JSON.parse(
          decryptToken(userLocalStorageJson.idToken.jwtToken).toString()
        ) as DecryptedUser;
        if (userDecrypted.auth_time > 0) {
          setIsAuthenticated(true);
          setUser(userDecrypted);
        } else {
          setIsAuthenticated(false);
        }
      }
      if (profileLocalStorage) {
        const profileTemp = JSON.parse(profileLocalStorage) as Profile;
        setProfile(profileTemp);
        mixpanelIdentify(profileTemp);
      }
      if (impersonationLocalStorage) {
        setImpersonation(
          JSON.parse(impersonationLocalStorage) as ImpersonationRecord
        );
      }
      if (factorsLocalStorage) {
        setFactors(JSON.parse(factorsLocalStorage) as Factors);
      }
      setIsLoaded(true);
    } catch (e) {
      clearState();
      window.location.href = '/logout';
    }
  }, [
    localStorage.getItem('user'),
    localStorage.getItem('profile'),
    localStorage.getItem('impersonation'),
    localStorage.getItem('factors'),
  ]);

  useEffect(() => {
    if (profile) {
      adminCheck(
        window.location.pathname.startsWith('/admin'),
        profile,
        impersonation
      );
    }
  }, [window.location.pathname, profile, impersonation]);

  const getProfile = async () => {
    try {
      const resp: {
        profile?: Profile;
        factors: Factors;
      } = await callAPIAsync('/profile', null);
      if (resp.profile) {
        // eslint-disable-next-line no-shadow
        const profileResponse = resp.profile;
        localStorage.setItem('factors', JSON.stringify(resp.factors));
        setFactors(resp.factors);
        localStorage.setItem('profile', JSON.stringify(profileResponse));
        setProfile(resp.profile);
        mixpanelIdentify(resp.profile);
      } else {
        throw new NoProfileError();
      }
    } catch (error) {
      datadogRum.addError(error, { email: user?.email });
      let displayMessage = 'Could not get user profile.  Please try again.';
      if (error instanceof NoProfileError) {
        displayMessage =
          'No profile found. Please contact your Customer Success Manager.';
      }
      displayToastMessage({
        severity: 'error',
        summary: displayMessage,
        detail: '',
      });
      throw new Error('Error getting profile');
    }
  };

  const handleAuthentication = async (
    authenticated: boolean,
    userRecord: DecryptedUser
  ) => {
    setIsAuthenticated(authenticated);
    setUser(userRecord);
    // this will fail locally since the gateway can only handle 1 request at a time
    if (authenticated) {
      await getProfile();
      // timeout required for localhost because of limited bandwidth of sam run
      if (window.location.href.indexOf('localhost') > -1) {
        console.log('update profile');
      } else {
        void callAPIAsync('/profile/update', null);
      }
    } else {
      clearState();
    }
  };

  const endImpersonation = () => {
    setImpersonation(null);
    localStorage.removeItem('impersonation');
    localStorage.removeItem('teamsAPI');
    localStorage.removeItem('filters');
    if (window.location.search.length > 0) {
      // remove query string when ending impersonation
      window.location.href = window.location.pathname;
    } else {
      window.location.reload();
    }
  };

  const goto = (e: MenuEvent, url: string) => {
    if (adminCheck(url.startsWith('admin'), profile, impersonation)) return;

    e.originalEvent.metaKey || e.originalEvent.ctrlKey
      ? window.open(url, '_blank')
      : (window.location.href = url);
  };

  if (!user && window.location.pathname === '/') {
    window.location.href = '/login';
    return '';
  }
  if (
    user &&
    window.location.pathname === '/' &&
    window.location.search !== ''
  ) {
    clearState();
    window.location.href = '/login';
    return '';
  }
  const scope = getDefaultScope(profile, impersonation);
  const homePage = `/cases?page=1&scope=${scope}`;
  if (user && window.location.pathname === '/') {
    window.location.href = homePage;
    return '';
  }
  // TODO: clean routing parameter passing.  this passing of props state is getting messy
  const routing = (
    <Router>
      <Switch>
        <Redirect exact from='/' to={homePage} />
        <Route
          exact
          path='/login'
          render={(props) => (
            <Login {...props} handleAuthentication={handleAuthentication} />
          )}
        />
        <Route
          exact
          path='/ssologin'
          render={(props) => (
            <SSOLogin {...props} handleAuthentication={handleAuthentication} />
          )}
        />
        <Route
          exact
          path='/forgotpassword'
          render={(props) => <ForgotPassword {...props} />}
        />
        <Route
          exact
          path='/changepassword'
          render={(props) => <ChangePassword {...props} user={user} />}
        />
        <Route
          exact
          path='/emailsettings'
          render={(props) => (
            <EmailSettings
              {...props}
              profile={profile as Profile}
              impersonation={impersonation}
            />
          )}
        />
        <Route
          exact
          path='/cases/:caseID'
          render={(props) => (
            <Case
              {...props}
              profile={profile}
              impersonation={impersonation}
              factors={factors}
            />
          )}
        />
        <Route
          exact
          path='/cases'
          render={(props) => (
            <CasesPage
              {...props}
              profile={profile as Profile}
              impersonation={impersonation}
              factors={factors}
            />
          )}
        />
        <Route
          exact
          path='/queries/:queryID'
          render={(props) => (
            <Query
              {...props}
              profile={profile as Profile}
              impersonation={impersonation}
              factors={factors}
            />
          )}
        />
        <Route
          exact
          path='/queries'
          render={(props) => (
            <Queries
              {...props}
              profile={profile as Profile}
              impersonation={impersonation}
            />
          )}
        />
        <Route
          exact
          path='/admin/users/add'
          render={(props) => (
            <AdminUsersAdd
              profile={profile}
              impersonation={impersonation}
              {...props}
            />
          )}
        />
        <Route
          exact
          path='/admin/users'
          render={(props) => (
            <AdminUsers
              profile={profile}
              impersonation={impersonation}
              {...props}
            />
          )}
        />
        <Route
          exact
          path='/admin/groups'
          render={(props) => (
            <AdminGroups
              profile={profile}
              impersonation={impersonation}
              {...props}
            />
          )}
        />
        <Route
          exact
          path='/admin/agencies/:agencyID/domains/add'
          render={(props) => (
            <AdminAgencyDomainsAdd
              profile={profile}
              impersonation={impersonation}
              {...props}
            />
          )}
        />
        <Route
          exact
          path='/admin/agencies/:agencyID/domains'
          render={(props) => (
            <AdminAgencyDomains
              profile={profile}
              impersonation={impersonation}
              {...props}
            />
          )}
        />
        <Route
          exact
          path='/admin/agencies'
          render={(props) => (
            <AdminAgencies
              profile={profile}
              impersonation={impersonation}
              {...props}
            />
          )}
        />
        <Route
          exact
          path='/admin/queryGroups'
          render={(props) => (
            <AdminQueryGroups
              profile={profile}
              impersonation={impersonation}
              {...props}
            />
          )}
        />
        <Route
          exact
          path='/admin/queries'
          render={(props) => (
            <AdminQueries
              profile={profile}
              impersonation={impersonation}
              {...props}
            />
          )}
        />
        <Route
          exact
          path='/admin/assignments/add'
          render={(props) => (
            <AdminAssignmentsAdd
              profile={profile}
              impersonation={impersonation}
              {...props}
            />
          )}
        />
        <Route
          exact
          path='/admin/assignments/:userID'
          render={(props) => (
            <AdminAssignments
              profile={profile}
              impersonation={impersonation}
              {...props}
            />
          )}
        />
        <Route
          exact
          path='/admin/assignments'
          render={(props) => (
            <AdminAssignments
              profile={profile}
              impersonation={impersonation}
              {...props}
            />
          )}
        />
        <Route
          exact
          path='/admin/phraseSearch'
          render={() => <PhraseSearch />}
        />
        <Route
          exact
          path='/admin/sentenceLabeling'
          render={(props) => (
            <SentenceLabeling
              profile={profile}
              impersonation={impersonation}
              factors={factors}
              {...props}
            />
          )}
        />
        <Route
          exact
          path='/admin/usageReporting'
          render={() => <UsageReporting />}
        />
        <Route
          exact
          path='/admin'
          render={(props) => (
            <AdminUsers
              profile={profile}
              impersonation={impersonation}
              {...props}
            />
          )}
        />
        <Route path='/NotFound' component={NotFound} />
        <Redirect to='/NotFound' />
      </Switch>
    </Router>
  );

  const isAIAdmin = isAuthenticated && profile?.user_type === 'augintel_admin';
  const isAgencyAdmin =
    isAuthenticated && profile?.user_type === 'agency_admin';
  const isImpersonatedAdmin =
    isAuthenticated && impersonation?.user_type === 'agency_admin';

  const queriesNavigation = (
    <NavHeaderButton
      className='aLink navigation p-sm-hide p-md-hide queries'
      onClick={(e: KeyboardEvent | MouseEvent) => {
        e.metaKey || e.ctrlKey
          ? window.open('/queries', '_blank')
          : (window.location.href = '/queries');
      }}
    >
      <span className='icon'>
        <svg width='16' height='16' viewBox='0 0 16 16'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM14.2222 6.4L8.88889 12.4444L5.33333 8.88889L1.77778 12.4444V9.77778L5.33333 6.22222L8.88889 9.77778L14.2222 3.73333V6.4Z'
          />
        </svg>
      </span>
      <span className='text'>Queries</span>
    </NavHeaderButton>
  );

  const adminNavigation =
    (impersonation && isImpersonatedAdmin) ||
    (!impersonation && (isAIAdmin || isAgencyAdmin)) ? (
      <NavHeaderButton
        className='aLink navigation p-sm-hide p-md-hide p-lg-hide admin'
        onClick={(e: SyntheticEvent) => adminMenu.current?.toggle(e)}
      >
        <span className='icon'>
          <svg width='16' height='16' viewBox='0 0 512 512'>
            <path
              fill='currentColor'
              d='M496 384H160v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h336c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160h-80v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h336v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h80c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160H288V48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16C7.2 64 0 71.2 0 80v32c0 8.8 7.2 16 16 16h208v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h208c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16z'
              className=''
            />
          </svg>
        </span>
        <span className='text'>Admin</span>
      </NavHeaderButton>
    ) : (
      ''
    );

  const adminMenuItems = [
    {
      label: <div className='aLink'>Users</div>,
      command: (e: MenuEvent) => {
        goto(e, '/admin/users');
      },
      className: isAIAdmin ? '' : 'hide',
    },
    {
      label: <div className='aLink'>Agencies</div>,
      command: (e: MenuEvent) => {
        goto(e, '/admin/agencies');
      },
      className: isAIAdmin ? '' : 'hide',
    },
    {
      label: <div className='aLink'>Agency Groups</div>,
      command: (e: MenuEvent) => {
        goto(e, '/admin/groups');
      },
      className: isAIAdmin ? '' : 'hide',
    },
    {
      label: <div className='aLink'>Query Groups</div>,
      command: (e: MenuEvent) => {
        goto(e, '/admin/queryGroups');
      },
      className: isAIAdmin ? '' : 'hide',
    },
    {
      label: <div className='aLink'>Queries</div>,
      command: (e: MenuEvent) => {
        goto(e, '/admin/queries');
      },
      className: isAIAdmin ? '' : 'hide',
    },
    {
      label: <div className='aLink'>Assignments</div>,
      command: (e: MenuEvent) => {
        goto(e, '/admin/assignments');
      },
      className: isAIAdmin ? '' : 'hide',
    },
    {
      label: <div className='aLink'>Phrase Search</div>,
      command: (e: MenuEvent) => {
        goto(e, '/admin/phraseSearch');
      },
      className: isAIAdmin ? '' : 'hide',
    },
    {
      label: <div className='aLink'>Sentence Labeling</div>,
      command: (e: MenuEvent) => {
        goto(e, '/admin/sentenceLabeling');
      },
      className: isAIAdmin ? '' : 'hide',
    },
    {
      label: <div className='aLink'>Usage Reporting</div>,
      command: (e: MenuEvent) => {
        goto(e, '/admin/usageReporting');
      },
    },
  ];
  const adminPopup =
    isAIAdmin || isAgencyAdmin ? (
      <Menu
        className='adminMenu'
        popup
        ref={adminMenu}
        model={adminMenuItems as unknown as MenuItem[]}
        appendTo='self'
      />
    ) : (
      ''
    );

  // TODO: ensure state is fully cleared when logged out
  const navigation = isAuthenticated ? (
    <div className='mid p-sm-hide p-md-hide'>
      <NavHeaderButton
        className='aLink navigation p-sm-hide p-md-hide cases'
        onClick={(e: KeyboardEvent | MouseEvent) => {
          e.metaKey || e.ctrlKey
            ? window.open('/cases', '_blank')
            : (window.location.href = '/cases');
        }}
      >
        <span className='icon'>
          <svg width='16' height='16' viewBox='0 0 512 512'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M320 336c0 8.84-7.16 16-16 16h-96c-8.84 0-16-7.16-16-16v-48H0v144c0 25.6 22.4 48 48 48h416c25.6 0 48-22.4 48-48V288H320v48zm144-208h-80V80c0-25.6-22.4-48-48-48H176c-25.6 0-48 22.4-48 48v48H48c-25.6 0-48 22.4-48 48v80h512v-80c0-25.6-22.4-48-48-48zm-144 0H192V96h128v32z'
            />
          </svg>
        </span>
        <span className='text'>Cases</span>
      </NavHeaderButton>
      {queriesNavigation}
      {adminPopup}
      {adminNavigation}
      <NavHeaderButton
        className='aLink navigation p-sm-hide p-md-hide p-lg-hide help'
        onClick={() => {
          ZendeskAPI('webWidget', 'open');
        }}
      >
        <span className='text'>Contact Us</span>
      </NavHeaderButton>
    </div>
  ) : (
    <div className='mid'>
      <NavHeaderButton
        className='aLink navigation'
        onClick={(e: KeyboardEvent | MouseEvent) => {
          e.metaKey || e.ctrlKey
            ? window.open('/', '_blank')
            : (window.location.href = '/');
        }}
      >
        Augintel
      </NavHeaderButton>
    </div>
  );

  const search = isAuthenticated ? (
    <BroadSearchAutoComplete toast={toast} profile={profile} />
  ) : (
    ''
  );

  const profileMenuItems = [
    {
      label: <p>{impersonation?.email || user?.email}</p>,
      className: 'user-auth',
    },
    {
      label: <div className='aLink profileLink cases'>Cases</div>,
      command: (e: MenuEvent) => {
        goto(e, '/cases');
      },
    },
    {
      label: <div className='aLink profileLink queries'>Queries</div>,
      command: (e: MenuEvent) => {
        goto(e, '/queries');
      },
      className: '',
    },
    {
      label: (
        <div className='aLink profileLink endImpersonation'>
          End Impersonation
        </div>
      ),
      command: () => {
        endImpersonation();
      },
      className: impersonation ? '' : 'hide',
    },
    {
      label: <div className='aLink profileLink help'>Contact Us</div>,
      command: () => {
        ZendeskAPI('webWidget', 'open');
      },
    },
    {
      label: (
        <div className='aLink profileLink emailSettings'>Email Settings</div>
      ),
      command: (e: MenuEvent) => {
        goto(e, '/emailsettings');
      },
      className: 'start',
    },
    {
      label: (
        <div className='aLink profileLink changePassword'>Change Password</div>
      ),
      command: (e: MenuEvent) => {
        goto(e, '/changepassword');
      },
      className: 'start',
    },
    {
      label: <div className='aLink profileLink logout'>Logout</div>,
      command: (e: MenuEvent) => {
        goto(e, '/login');
      },
      className: 'start',
    },
  ];

  const profilePopup = isAuthenticated ? (
    <Menu
      className='profileMenu'
      popup
      ref={profileMenu}
      model={profileMenuItems as unknown as MenuItem[]}
      appendTo='self'
    />
  ) : (
    ''
  );

  const profileIcon = isAuthenticated ? (
    <div className='right'>
      <UserMenuToggleButton
        className='profile p-sm-hide p-md-hide aLink'
        onClick={(e: SyntheticEvent) => profileMenu.current?.toggle(e)}
      >
        {impersonation
          ? impersonation.email.charAt(0).toUpperCase()
          : user?.email.charAt(0).toUpperCase()}
      </UserMenuToggleButton>
      <HamburgerMenuToggle
        className='menu p-lg-hide p-xl-hide'
        onClick={(e: SyntheticEvent) => profileMenu.current?.toggle(e)}
      >
        <svg width='36' height='36' viewBox='0 0 24 24'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'
          />
        </svg>
      </HamburgerMenuToggle>
      {profilePopup}
    </div>
  ) : (
    ''
  );

  const header = (
    <div className='appHeader'>
      <div className='left p-sm-hide'>
        <DivButtonOG
          className='aLink'
          onClick={() => {
            window.location.href = '/';
          }}
        >
          <img
            className='logo'
            src='/augintel_logomark_blue.svg'
            alt='Augintel logo mark (blue)'
          />
        </DivButtonOG>
      </div>
      {navigation}
      {search}
      {profileIcon}
    </div>
  );

  const isAdmin = profile?.user_type === 'augintel_admin';
  const classNames = [
    'app',
    isAdmin ? 'admin' : '',
    impersonation ? 'impersonation' : '',
  ].join(' ');

  // TODO: update import, optimize loads
  return !isLoaded ? (
    <ProgressBar mode='indeterminate' />
  ) : (
    <div className={classNames}>
      <link rel='preconnect' href={process.env.REACT_APP_API_URL} />
      <link rel='dns-prefetch' href={process.env.REACT_APP_API_URL} />
      {header}
      <Toast ref={toast} />
      {routing}
    </div>
  );
}
