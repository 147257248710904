import React, { Dispatch, SetStateAction, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import './SearchSurvey.scss';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import mixpanel from '../../libs/Mixpanel';
import client from '../../libs/AxiosClient';

interface SearchSurveyProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const SearchSurvey = ({
  show,
  setShow,
}: SearchSurveyProps) => {
  const [satisfied, setSatisfied] = useState(false);
  const [notSatisfied, setNotSatisfied] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  const onHide = () => {
    setShow(false);
    localStorage.setItem('search-survey-ack', 'true');
  };

  const markSurveyAsComplete = async () => {
    try {
      await client.setFlags({ submitted_survey: true });
      localStorage.setItem('search-survey-ack', 'true');
    } catch (reason) {
      console.error('error updating survey flag', reason);
      setError('Error saving survey.');
    }
  };

  const onClick = (isSatisfied: boolean) => {
    if (isSatisfied) {
      setSatisfied(true);
      setNotSatisfied(false);
    } else {
      setNotSatisfied(true);
      setSatisfied(false);
    }
    mixpanel.track('search-survey-satisfied', {
      isSatisfied,
    });
    void markSurveyAsComplete();
  };

  const onSubmit = () => {
    setIsSubmitted(true);
    mixpanel.track('search-survey-feedback', {
      isSatisfied: satisfied,
      feedback,
    });
    setTimeout(() => setShow(false), 3000);
  };

  const header = isSubmitted
    ? 'Thank you!'
    : "Are you satisfied with Augintel's search feature?";

  const extraFeedback = (
    <span>
      <br/>
      <InputTextarea
        value={feedback}
        placeholder='Tell us why... [optional]'
        onChange={(e) => setFeedback(e.target.value)}
      />
      <Button
        label='Submit'
        className='submit-button'
        aria-label='Submit'
        onClick={() => onSubmit()}
      />
    </span>
  );

  const submittedContent = (
    <div className='submitted-screen'>
      <div>Your feedback has been recorded.</div>
      <Button
        label='Close'
        className='close-button'
        aria-label='Close'
        onClick={() => setShow(false)}
      />
    </div>
  );

  const feedBackContent = (
    <>
      <div className='formgrid grid'>
        <div className='col-4 col-offset-2'>
          <Button
            id='not-satisfied-button'
            icon='pi pi-thumbs-down'
            className={`survey-button p-button-outlined ${
              notSatisfied ? 'not-satisfied-selected' : ''
            }`}
            aria-label='Not Satisfied'
            onClick={() => onClick(false)}
          />
          <small>Not Satisfied</small>
        </div>
        <div className='col-4'>
          <Button
            id='satisfied-button'
            icon='pi pi-thumbs-up'
            className={`survey-button p-button-outlined ${
              satisfied ? 'satisfied-selected' : ''
            }`}
            aria-label='Satisfied'
            onClick={() => onClick(true)}
          />
          <small>Satisfied</small>
        </div>
      </div>
      {error && <div className='p-error text-center'>{error}</div>}
      {satisfied || notSatisfied ? extraFeedback : ''}
    </>
  );

  return (
    <Dialog
      visible={show}
      closeOnEscape
      dismissableMask
      modal={false}
      position='bottom-right'
      onHide={() => onHide()}
      className={`search-survey-dialog ${
        isSubmitted ? 'submitted-dialog' : ''
      }`}
      header={header}
      resizable={false}
      draggable={false}
      style={{ width: '385px', backgroundColor: 'blue' }}
    >
      {isSubmitted ? submittedContent : feedBackContent}
    </Dialog>
  );
};
