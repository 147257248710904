/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import mixpanel from '../libs/Mixpanel';
import { chooseSentences } from '../utils/SentenceUtils';
import { SearchResult } from './notes/SearchResult';
import { Note } from '../shared/types/noteTypes';
import { Factors } from '../shared/types/appTypes';
import { CollapsedNoteCard } from './CollapsedNoteCard';

export interface CaseNoteProps {
  note: Note;
  factors: Factors;
  expanded: boolean;
  showTags: boolean;
  noteI: number;
  agencyId: number;
  caseID: number;
  searchQueryID?: number;
  searchFactor?: string;
  searchPerson?: { id: number } | string;
  searchPhrase?: string;
  openCaseNote: (
    noteId: number,
    sourceId: string,
    dateString: string,
    sentences: (Element | JSX.Element)[],
    noteI: number
  ) => void;
  displaySentenceFeedback: () => void;
  displayEntityPopupByID: () => void;
  getTaggingEnabled: () => boolean;
}

export const CaseNote = ({
  note,
  factors,
  expanded,
  showTags,
  noteI,
  agencyId,
  caseID,
  searchQueryID,
  searchFactor,
  searchPerson,
  searchPhrase,
  openCaseNote,
  displaySentenceFeedback,
  displayEntityPopupByID,
  getTaggingEnabled,
}: CaseNoteProps) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  useEffect(() => {
    chooseSentences(note, isExpanded, factors, {
      searchFactor,
      searchPerson,
      searchPhrase,
      searchQueryID,
    });
  }, [isExpanded]);

  const trackWithMixpanel = useCallback(
    (eventType: string) => {
      mixpanel.track(eventType, {
        currentUrl: window.location.href,
        note,
        factors,
        caseID,
        noteI,
        searchQueryID,
        searchFactor,
        searchPerson,
        searchPhrase,
      });
    },
    [
      caseID,
      factors,
      note,
      noteI,
      searchFactor,
      searchPerson,
      searchPhrase,
      searchQueryID,
    ]
  );

  return typeof note !== 'undefined' ? (
    typeof note.token !== 'undefined' ||
    searchQueryID ||
    searchFactor !== '' ||
    searchPerson !== '' ? (
      <SearchResult
        note={note}
        factors={factors}
        showTags={showTags}
        isExpanded={isExpanded}
        searchProps={{
          searchFactor,
          searchPerson,
          searchPhrase,
          searchQueryID,
        }}
        noteI={noteI}
        caseID={caseID}
        trackWithMixpanel={trackWithMixpanel}
        openCaseNote={openCaseNote}
        displaySentenceFeedback={displaySentenceFeedback}
        displayEntityPopupByID={displayEntityPopupByID}
        getTaggingEnabled={getTaggingEnabled}
      />
    ) : (
      <CollapsedNoteCard
        note={note}
        factors={factors}
        agencyId={agencyId}
        isExpanded={isExpanded}
        searchProps={{
          searchFactor,
          searchPerson,
          searchPhrase,
          searchQueryID,
        }}
        noteI={noteI}
        caseID={caseID}
        trackWithMixpanel={trackWithMixpanel}
        openCaseNote={openCaseNote}
        displaySentenceFeedback={displaySentenceFeedback}
        displayEntityPopupByID={displayEntityPopupByID}
        getTaggingEnabled={getTaggingEnabled}
      />
    )
  ) : (
    ''
  );
};
