import { datadogRum } from '@datadog/browser-rum';
import { refreshToken } from './Cognito';

export const getS3Response = (api, url, successCallback, errorCallback) => {
  try {
    fetch(url)
      .then((res) => res.json())
      .then(
        (resp) => {
          successCallback(
            api.indexOf('/csv') > 0 ? resp.body : JSON.parse(resp.body)
          );
        },
        (error) => {
          errorCallback(error);
        }
      );
  } catch (ex) {
    console.log('ex:::', ex);
    if (ex.message) {
      window.location = `/login?errorMessage=Session timed out.  Please log in.&sourcePath=${encodeURIComponent(
        window.location.pathname + window.location.search
      )}`;
    } else {
      errorCallback(ex);
    }
  }
};

export const pollS3 = (url, successCallback, errorCallback) => {
  try {
    fetch(url)
      .then((res) => {
        if (res.headers.get('content-length') > 0) {
          return res.json();
        }
        return { empty: true };
      })
      .then(
        (resp) => {
          successCallback(resp.empty ? resp : JSON.parse(resp.body));
        },
        (error) => {
          errorCallback(error);
        }
      );
  } catch (ex) {
    console.log(ex);
    if (ex.message) {
      window.location = `/login?errorMessage=Session timed out.  Please log in.&sourcePath=${encodeURIComponent(
        window.location.pathname + window.location.search
      )}`;
    } else {
      errorCallback(ex);
    }
  }
};

// TODO: cache apis here?
export const callAPI = async (api, body, successCallback, errorCallback) => {
  const user = JSON.parse(localStorage.getItem('user'));
  try {
    if (user) {
      refreshToken(
        user.username,
        user.email,
        user.idToken.jwtToken,
        user.accessToken.jwtToken,
        user.refreshToken.token,
        user.tokenExpiration,
        user.signInExpiration,
        () => {
          const refreshedUser = JSON.parse(localStorage.getItem('user'));
          const impersonatedUser = JSON.parse(
            localStorage.getItem('impersonation')
          );
          // TODO: nest SAM template configs to share urls?  or separate out all into completely individual api gateway services?
          const url =
            (api.indexOf('/admin/') === 0
              ? process.env.REACT_APP_ADMIN_API_URL
              : process.env.REACT_APP_API_URL) + api;
          const emailHeader = {
            email: refreshedUser.email,
            impersonatedEmail: impersonatedUser?.email,
          };
          const headers = {
            Authorization: refreshedUser.idToken.jwtToken,
            'X-AuthInfo': JSON.stringify(emailHeader),
          };
          if (impersonatedUser) {
            headers.impersonatedID = impersonatedUser.id;
            headers.impersonatedEmail = impersonatedUser.email;
          }
          const request = {
            headers,
          };
          if (body !== null) {
            request.method = 'POST';
            request.body = JSON.stringify(body);
          }
          fetch(url, request)
            .then((res) => {
              if (res.status === 401) {
                // try to refresh token on 401 just in case session not updated properly?
                window.location = `/login?errorMessage=Session timed out.  Please log in.&sourcePath=${encodeURIComponent(
                  window.location.pathname + window.location.search
                )}`;
                return false;
              }
              if (res.status === 201) {
                return true;
              }
              // Redirecting to S3 for response that is too large for API gateway
              const redirectLocation = res.headers.get('Location');
              if (redirectLocation !== null) {
                return redirectLocation;
              }
              if (
                res.status === 202 &&
                res.headers.get('Content-Length') === '0'
              ) {
                // Used for active learning to signify training is still running
                return res.status;
              }
              // TODO: parameterize this?
              return api.indexOf('/csv') > 0 && res.status === 200
                ? res.text()
                : res.json();
            })
            .then(
              (resp) => {
                if (
                  typeof resp === 'string' &&
                  resp.includes(process.env.REACT_APP_S3_BUCKET)
                ) {
                  getS3Response(api, resp, successCallback, errorCallback);
                } else {
                  successCallback(resp);
                }
              },
              (error) => {
                console.log('error: ', error.message);
                datadogRum.addError({
                  message: error.message,
                  authInfo: headers['X-AuthInfo'],
                });
                errorCallback(error);
              }
            )
            .catch((reason) => {
              console.log('error: ', reason.message);
              datadogRum.addError({
                message: reason.message,
                authInfo: headers['X-AuthInfo'],
              });
              errorCallback(reason);
            });
        }
      );
    } else {
      window.location = `/login?errorMessage=Session timed out.  Please log in.&sourcePath=${encodeURIComponent(
        window.location.pathname + window.location.search
      )}`;
    }
  } catch (ex) {
    console.log(ex);
    if (ex.message) {
      window.location = `/login?errorMessage=Session timed out.  Please log in.&sourcePath=${encodeURIComponent(
        window.location.pathname + window.location.search
      )}`;
    } else {
      datadogRum.addError(ex);
      errorCallback(ex);
    }
  }
};

export const callInitialApi = async (
  api,
  body,
  successCallback,
  errorCallback
) => {
  const url =
    (api.indexOf('/admin/') === 0
      ? process.env.REACT_APP_ADMIN_API_URL
      : process.env.REACT_APP_API_URL) + api;
  fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => successCallback(res))
    .catch((reason) => errorCallback(reason));
};

/**
 * @param {string} api - api endpoint
 * @param {object} [body] - body of request
 * @returns {Promise<object[]>} - promise of api response
 */
export const callAPIAsync = (api, body) =>
  new Promise((resolve, reject) => {
    body
      ? callAPI(api, body, resolve, reject)
      : callAPI(api, null, resolve, reject);
  });
