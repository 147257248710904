import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { fade, palette } from '../../styles'

const useStyles = makeStyles(() => ({
  pickerBar: {
    width: '100%',
  },
  label: {
    marginBottom: 10,
  },
  pickerContainer: {
    display: 'flex',
    borderRadius: 4,
    border: `solid 1px ${palette.blue}`,
    backgroundColor: palette.white,
    overflow: 'hidden',
  },
  optionButton: {
    display: 'block',
    flex: 1,
    textAlign: 'center',
    height: 36,
    fontSize: 16,
    color: palette.blue_text,
    borderRight: `solid 1px ${palette.blue}`,
    border: 'none',
    backgroundColor: 'unset',
    '&:not([class*="active"]):hover': {
      transition: '.2s',
      backgroundColor: fade(palette.blue, 0.17),
    },
    '&:last-of-type': {
      borderRight: 'none',
    },
    cursor: 'pointer',
  },
  active: {
    backgroundColor: palette.blue,
    color: palette.white,
    transition: '.2s',
  }
}))

/**
 * Augintel UI PickerBar Component
 * @description It takes in different options and allows the user to select one
 * @param options {[string]} - The array of options
 * @param label {[object]} - (optional) The label above the picker
 * @param initialValue {number} - (optional) The initial selected value
 * @param response {boolean} - (optional) Callback to return current value
 */
export const PickerBar = ({
  options,
  label,
  initialValue,
  response,
  callBack,
}) => {
  const classes = useStyles()
  const [optionValue, setOptionValue] = useState(initialValue || 1)

  useEffect(() => {
    if (response) {
      response(optionValue)
      callBack(optionValue)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionValue])

  return (
    <div className={classes.pickerBar}>
      {label && (
        <p className={classes.label}>{label}</p>
      )}
      <div className={classes.pickerContainer}>
        {options.map((option, idx) => {
          let active = false
          if (optionValue - 1 === idx) {
            active = true
          }

          return (
            <button className={[classes.optionButton, active && classes.active].join(' ')} key={option} type="button" onClick={() => setOptionValue(idx + 1)}>{option}</button>
          )
        })}
      </div>
    </div>
  )
}
