import { ReactElement, useEffect, useRef, useState } from 'react';
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { DataScroller } from 'primereact/datascroller';
import { RelevanceHit } from '../../shared/types/noteTypes';
import './RelevanceDisplay.scss';
import mixpanel from '../../libs/Mixpanel';

interface DisplayProps {
  timelineNotes: ReactElement[] | null;
  searchMatches: RelevanceHit[];
  getMoreNotes: () => void;
  notesLoaded: boolean;
  uniquePhraseCount: number;
  scrollHeight: string;
  phraseCounts: Record<
    string,
    { total_matches: number; unique_notes_count: number }
  >;
}

export const RelevanceDisplay = ({
  timelineNotes,
  searchMatches,
  getMoreNotes,
  notesLoaded,
  uniquePhraseCount,
  scrollHeight,
  phraseCounts,
}: DisplayProps) => {
  const ds = useRef<DataScroller>(null);
  const previousPanelCount = useRef(0);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const getCaseNotesForPhrase = (phraseSet: RelevanceHit) => {
    const uniqueIdx = new Set();
    if (timelineNotes) {
      return phraseSet.sentences.map((match) => {
        if (typeof match.index !== 'undefined' && !uniqueIdx.has(match.index)) {
          uniqueIdx.add(match.index);
          return timelineNotes[match.index];
        }
        return '';
      });
    }
    return '';
  };

  const emptyMessage = (
    <span className='pl-2'>{notesLoaded ? 'No notes found' : ''}</span>
  );

  const forceLoadMore = () => {
    if (ds.current && searchMatches.length < uniquePhraseCount) {
      ds.current.load();
      previousPanelCount.current = searchMatches.length;
    }
  };

  useEffect(() => {
    // if an accordion is collapsed, keep getting notes until next accordion is available
    if (previousPanelCount.current === searchMatches.length) {
      forceLoadMore();
    }
  }, [searchMatches, notesLoaded]);

  const handleCollapse = () => {
    previousPanelCount.current = searchMatches.length;
    setIsCollapsed(!isCollapsed);
    forceLoadMore();
  };

  const countDisplay = (counts: {
    total_matches: number;
    unique_notes_count: number;
  }) => {
    const totalMatches = counts.total_matches || 0;
    const uniqueNoteCount = counts.unique_notes_count || 0;

    const noteCount =
      uniqueNoteCount.toString() + (uniqueNoteCount === 1 ? ' Note' : ' Notes');

    const resultsCount =
      totalMatches.toString() + (totalMatches === 1 ? ' Match' : ' Matches');
    return `${resultsCount}, ${noteCount}`;
  };

  const togglePanel = (
    e: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>,
    options: PanelHeaderTemplateOptions,
    phrase: string,
    collapsed: boolean
  ) => {
    mixpanel.track('search-accordion-toggle', {
      phrase,
      toggle: collapsed ? 'Opening accordion' : 'Closing accordion',
    });
    options.onTogglerClick(e);
  };

  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const toggleIcon = options.collapsed
      ? 'pi pi-chevron-down'
      : 'pi pi-chevron-up';
    const className = `${options.className} `;
    const titleClassName = `${options.titleClassName} pl-1`;
    const headerTitle = options.props.header as string;
    const countHeader = countDisplay(phraseCounts[headerTitle]);
    return (
      <div
        className={className}
        onClick={(e) => togglePanel(e, options, headerTitle, options.collapsed)}
        tabIndex={0}
        role='button'
      >
        <span className={titleClassName} style={{ flex: 'auto' }}>
          {headerTitle}
        </span>
        <span className='panel-counts'>{countHeader}</span>
        <button
          className={options.togglerClassName}
          onClick={(e) =>
            togglePanel(e, options, headerTitle, options.collapsed)
          }
        >
          <span className={toggleIcon}></span>
        </button>
      </div>
    );
  };

  const panelTemplate = (phraseSet: RelevanceHit) => (
    <Panel
      className='phrase-panel'
      toggleable
      headerTemplate={headerTemplate}
      header={phraseSet.phrase}
      key={`${phraseSet.phrase}-panel`}
      id={`${phraseSet.phrase}-panel`}
      onCollapse={() => handleCollapse()}
      collapsed={isCollapsed}
    >
      {getCaseNotesForPhrase(phraseSet)}
    </Panel>
  );

  return (
    <DataScroller
      className='relevance-datascroller mt-1'
      ref={ds}
      value={searchMatches}
      inline
      lazy
      itemTemplate={panelTemplate}
      onLazyLoad={getMoreNotes}
      emptyMessage={emptyMessage}
      scrollHeight={scrollHeight}
    />
  );
};
