import { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import { ProgressSpinner } from 'primereact/progressspinner';
import client from '../../libs/AxiosClient';
import {
  CaseConnectedByPersons,
  ConnectedCase,
  parseConnectedCases,
  ParsedConnectedCases,
} from './ConnectedCasesDataService';
import './CaseConnections.scss';

interface ConnectedCasesProps {
  caseId: number | null;
}

interface Person {
  name: string;
  role: string;
}

const connectedCaseIds = new Set<number>();

function CaseDetails( { caseInfo }: { caseInfo: ConnectedCase }) {
  return (
    <div className='grid'>
        <div className='col-8 format-col-8'>
          <div className='subtitle-top'>Case</div>
          <div className='case-name-info'>
            <a
              href={`/cases/${caseInfo.caseId}`}
              className='no-underline text-primary case-name-format'
            >
              {caseInfo.name}
            </a>
            <span className='name-date'>{caseInfo.sourceId}</span>
          </div>
        </div>
        <div className='date-container'>
          <div className='col-2 text-right format-col-2'>
            <div className='subtitle-top'>Opened</div>
            <div className='border-round-sm name-date'>{caseInfo.startDate}</div>
          </div>
          <div className='col-2 text-right format-col-2'>
            <div className='subtitle-top'>Closed</div>
            <div className='border-round-sm name-date'>{caseInfo.closeDate}</div>
          </div>
        </div>
    </div>
  )
};

const casePeopleBody = (rowData: CaseConnectedByPersons) => (
  <div className='element-container'>
    {rowData.people.map(({ 0: name, 1: role }, index) => (
      <div key={index} className='element'>
        <div className='case-role-format'>{role}</div>
        <div className='name-date'>{name}</div>
      </div>
    ))}
</div>
);

export default function ConnectedCases({ caseId }: ConnectedCasesProps) {
  const [loading, setLoading] = useState(true);
  const [connectedCases, setConnectedCases] =
    useState<ParsedConnectedCases | null>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    caseId && client.getConnectedCases(caseId)
      .then((resp) => {
        setConnectedCases(parseConnectedCases(resp));
        setLoading(false);
      })
      .catch((reason) => {
        setError(Error(reason as string));
      });
  }, [caseId]);

  return (
    <div>
      {loading && <ProgressSpinner />}
      {error && <div>ERROR! ERROR! ${error.message}</div>}
      {connectedCases && (
        <Panel className='connected-case-panel'>
          {connectedCases.casesConnectedByPersons.length > 0 && (
            <div>
              {connectedCases.casesConnectedByPersons.map((connectedCase) => {
                connectedCaseIds.add(connectedCase.caseId);
                return (
                  <Card className='connected-case-card'>
                    {/* head of cases */}
                    <CaseDetails caseInfo={connectedCase}/>
                    {/* people section */}
                    <div className='connected-by-header'>
                      <h4 className='subtitle'>Connected by</h4>
                    </div>
                    {casePeopleBody(connectedCase)}
                  </Card>
                );
                })}
            </div>
          )}
          {connectedCases.casesConnectedByStructure.length > 0 && (
            <div>
              {connectedCases.casesConnectedByStructure.map((connectedCase) => {
                if (!connectedCaseIds.has(connectedCase.caseId)) {
                  return (
                    <Card key={connectedCase.caseId} className='connected-case-card'>
                      <div className='card-content'>
                        <CaseDetails caseInfo={connectedCase}/>
                      </div>
                    </Card>
                  );
                } 
                return null;
              })}
            </div>
          )}
        </Panel>
      )}
    </div>
  );
}
