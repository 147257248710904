import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'primereact/button';
import { ToastMessage } from 'primereact/toast';
import { saveStreamCSV } from '../../../libs/Utils';
import { callAPIAsync } from '../../../libs/API';
import mixpanel from '../../../libs/Mixpanel';
import './ExportButton.scss';
import {
  buildDateRange,
  buildSearchDate,
  buildSearchPerson,
} from '../../../utils/QueryParamBuilder';
import { SearchDateRange } from '../../../shared/types/caseTypes';

export interface QueryPageSearchParams {
  queryString: string;
  queryID: string;
  isQuery: true;
}

// passed in from CaseNotes - can export case from case page or query results from case page
export interface CasePageSearchParams {
  caseID: string;
  agencyID: string;
  queryID?: string;
  isQuery: boolean;
  sort: string;
  searchPhrase?: string;
  searchPerson?: { id: string };
  searchFactor?: string;
  searchMonth?: string;
  searchYear?: string;
  searchDateRange: SearchDateRange;
}

interface ExportButtonProps {
  showLabel: boolean;
  alignLeft: boolean;
  downloadingCSV: boolean;
  setDownloadingCSV: Dispatch<SetStateAction<boolean>>;
  searchParams: QueryPageSearchParams | CasePageSearchParams;
  displayToastError: (toastConfig: ToastMessage) => void;
}

function isQueryPageSearchParams(
  params: QueryPageSearchParams | CasePageSearchParams
): params is QueryPageSearchParams {
  return (params as QueryPageSearchParams).queryString !== undefined;
}

export default function ExportButton({
  showLabel,
  alignLeft,
  downloadingCSV,
  setDownloadingCSV,
  searchParams,
  displayToastError,
}: ExportButtonProps) {
  const buildURL = () => {
    let queryURL = '';
    if (isQueryPageSearchParams(searchParams)) {
      queryURL = `/queries/${searchParams.queryID}/csv${searchParams.queryString}`;
    } else {
      const searchPhrase =
        searchParams.searchPhrase !== '' &&
        typeof searchParams.searchPhrase !== 'undefined'
          ? `&searchPhrase=${searchParams.searchPhrase}`
          : '';
      const searchPerson = buildSearchPerson(searchParams.searchPerson);
      const searchFactor =
        searchParams.searchFactor !== '' &&
        typeof searchParams.searchFactor !== 'undefined'
          ? `&searchFactor=${searchParams.searchFactor}`
          : '';
      const searchDate = buildSearchDate(
        searchParams.searchMonth,
        searchParams.searchYear
      );
      const searchDateRange = buildDateRange(searchParams.searchDateRange);
      const searchQuery =
        typeof searchParams.queryID !== 'undefined'
          ? `&searchQuery=${searchParams.queryID}`
          : '';

      queryURL = `/cases/${
        typeof searchParams.caseID !== 'undefined' ? searchParams.caseID : ''
      }/csv?sort=${
        searchParams.sort
      }${searchPhrase}${searchPerson}${searchFactor}${searchDate}${searchQuery}&agencyId=${
        searchParams.agencyID
      }${searchDateRange}`;
    }

    return queryURL;
  };

  const getCSV = async () => {
    const queryURL = buildURL();

    await callAPIAsync(queryURL, null)
      .then((resp: string) => {
        if (typeof resp !== 'string') {
          displayToastError({
            severity: 'error',
            summary: 'Could not download CSV file',
            detail: '',
          });
        } else {
          const title = searchParams.isQuery
            ? `query_${
                typeof searchParams.queryID !== 'undefined'
                  ? searchParams.queryID
                  : ''
              }`
            : `case_${
                typeof searchParams.caseID !== 'undefined'
                  ? searchParams.caseID
                  : ''
              }`;
          saveStreamCSV(
            `export_${title}_${new Date()
              .toISOString()
              .slice(0, 19)
              .replace(/-/g, '/')
              .replace('T', ' ')}.csv`,
            resp
          );
          displayToastError({
            severity: 'success',
            summary: 'CSV downloaded',
            detail: '',
          });
        }
        setDownloadingCSV(false);
      })
      .catch(() => {
        displayToastError({
          severity: 'error',
          summary: 'Could not download CSV file',
          detail: '',
        });
        setDownloadingCSV(false);
      });
  };

  const downloadCSV = () => {
    if (!downloadingCSV) {
      setDownloadingCSV(true);

      searchParams.isQuery
        ? mixpanel.track('query-view-download-csv', {
            query_id: searchParams.queryID,
          })
        : mixpanel.track('case-view-download-csv', {
            case_id: searchParams.caseID,
          });

      displayToastError({
        severity: 'info',
        summary: 'Preparing CSV',
        detail: '',
      });

      void getCSV();
    }
  };

  return (
    <Button
      className={`export-button ${alignLeft ? 'ml-auto' : ''}`}
      icon='pi pi-download'
      onClick={() => downloadCSV()}
      label={showLabel ? 'Export Results' : ''}
      tooltip='Download a printable file'
      tooltipOptions={{ position: 'top' }}
    />
  );
}
