/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// see cognito notes in Cognito.js
import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { sendResetPasswordCode, resetPassword } from '../libs/Cognito';
import { LoadingContainer, Page } from '../components-new';

// TODO: reformat errorMessage
export const ForgotPassword = ({ history }) => {
  const [forgotPasswordForm, setForgotPasswordForm] = useState({
    email: '',
    password: '',
    passwordConfirmation: '',
    verificationCode: '',
  });
  const [loading, setLoading] = useState();
  const [showScreen, setShowScreen] = useState('requestCode');
  const [errorMessage, setErrorMessage] = useState();
  const handleRequestConfirmationCode = () => {
    const { email } = forgotPasswordForm;
    if (email.length < 4 || email.indexOf('@') < 1 || email.indexOf('.') < 3) {
      setErrorMessage('Email is invalid');
    } else {
      setLoading(true);
      sendResetPasswordCode(email, (err) => {
        if (err) {
          setErrorMessage(err.message);
          setLoading(false);
        } else {
          setErrorMessage(err.message);
          setShowScreen('enterCode');
          setLoading(false);
        }
      });
    }
  };

  const handleSubmitEnter = (e) => {
    if (e.keyCode === 13) handleRequestConfirmationCode();
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    const {
      email,
      verificationCode,
      password,
      passwordConfirmation,
    } = forgotPasswordForm;
    if (password.length < 8) {
      setErrorMessage('Password is too short');
    } else if (password !== passwordConfirmation) {
      setErrorMessage('Passwords does not match confirmation');
    } else {
      setLoading(true);
      resetPassword(email, verificationCode, password, (err) => {
        if (err) {
          setLoading(false);
          setErrorMessage(err.message);
        } else {
          setLoading(false);
          setErrorMessage('');
          setShowScreen('confirmed');
        }
      });
    }
  };

  const handleFormInput = (key, value) => {
    errorMessage && setErrorMessage('');
    setForgotPasswordForm({
      ...forgotPasswordForm,
      [key]: value,
    });
  };

  const footer = (
    <div className='grid links'>
      <div className='col center'>
        <div
          className='aLink'
          onClick={(e) => {
            e.metaKey || e.ctrlKey
              ? window.open('/login', '_blank')
              : history.push('/login');
          }}
        >
          Login
        </div>
      </div>
    </div>
  );

  const error = errorMessage ? (
    <span
      className='errorMessage'
      style={{ marginBottom: 16, display: 'block' }}
    >
      {errorMessage}
    </span>
  ) : null;

  let renderScreen = null;
  switch (showScreen) {
    case 'confirmed':
      renderScreen = (
        <div className='appBody forgotPassword'>
          <div className='grid justify-content-center'>
            <div className='lg:col-5 md:col-6 col-12'>
              <Card title='Password Reset' footer={footer}>
                {error}
                Your password has been reset. Please login.
              </Card>
            </div>
          </div>
        </div>
      );
      break;
    case 'enterCode':
      renderScreen = (
        <LoadingContainer isLoaded={!loading}>
          <div className='grid justify-content-center forgotPassword'>
            <form className='lg:col-5 md:col-6 col-12' autoComplete='off'>
              <Card title='Forgot Password' footer={footer}>
                {error || (
                  <>
                    Please check your email for verification code
                    <br />
                    <br />
                  </>
                )}
                <InputText
                  id='verificationCode'
                  placeholder='Verification Code'
                  defaultValue={forgotPasswordForm.verificationCode}
                  autoComplete='one-time-code'
                  onChange={(e) =>
                    handleFormInput('verificationCode', e.target.value)
                  }
                />
                <label className='accessibility' htmlFor='verificationCode'>
                  Verification Code
                </label>
                <br />
                <br />
                <Password
                  id='password'
                  placeholder='Password'
                  defaultValue={forgotPasswordForm.password}
                  onChange={(e) => handleFormInput('password', e.target.value)}
                />
                <label className='accessibility' htmlFor='password'>
                  Password
                </label>
                <br />
                <br />
                <Password
                  id='passwordConfirmation'
                  placeholder='Password Confirmation'
                  defaultValue={forgotPasswordForm.passwordConfirmation}
                  onChange={(e) =>
                    handleFormInput('passwordConfirmation', e.target.value)
                  }
                />
                <label className='accessibility' htmlFor='passwordConfirmation'>
                  Password Confirmation
                </label>
                <br />
                <br />
                <Button label='Reset Password' onClick={handleResetPassword} />
              </Card>
            </form>
          </div>
        </LoadingContainer>
      );
      break;
    default:
      renderScreen = (
        <LoadingContainer isLoaded={!loading}>
          <div className='grid justify-content-center forgotPassword'>
            <div className='lg:col-5 md:col-6 col-12'>
              <Card title='Forgot Password' footer={footer}>
                {error}
                <InputText
                  id='email'
                  placeholder='Email'
                  defaultValue={forgotPasswordForm.email}
                  onChange={(e) =>
                    handleFormInput('email', e.target.value.trim())
                  }
                  onKeyUp={handleSubmitEnter}
                />
                <label className='accessibility' htmlFor='email'>
                  Email
                </label>
                <br />
                <br />
                <Button
                  label='Request Reset Code'
                  onClick={handleRequestConfirmationCode}
                />
              </Card>
            </div>
          </div>
        </LoadingContainer>
      );
  }

  return <Page pageTitle='Forgot Password | Augintel'>{renderScreen}</Page>;
};

export default ForgotPassword;
