import React, { useEffect, useState } from 'react';
import { Paginator } from 'primereact/paginator';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import { callAPIAsync } from '../../../libs/API';
import { Page } from '../../../components-new';
import {
  UsageItem,
  UsageItemType,
} from '../../../components/usageReporting/UsageItem';
import mixpanel from '../../../libs/Mixpanel';
import './UsageReporting.scss';
import { Profile } from '../../../shared/types/appTypes';

type AgencyGroup = {
  label: string;
  value: number;
};

export const UsageReporting = () => {
  const [data, setData] = useState([] as UsageItemType[]);
  const [first, setFirst] = useState(0);
  const [count, setCount] = useState(0);
  const [error, setError] = useState('');
  const [profile] = useState(
    JSON.parse(localStorage.getItem('profile') as '') as Profile
  );
  const [agency, setAgency] = useState(profile.agency_id);
  const isAdmin = profile.user_type === 'augintel_admin';
  const [isLoading, setIsLoading] = useState(true);
  const [agencies, setAgencies] = useState([] as AgencyGroup[]);
  const [isDisabled, setIsDisabled] = useState(false);
  const exportHandler = (id: string) => {
    setIsDisabled(true);
    callAPIAsync('/admin/usageReporting/', { id })
      .then(
        (response: {
          streamData: { Body: { data: [] }; contentType: string };
        }) => {
          if (response.streamData) {
            const bodyData = Uint8Array.from(response.streamData.Body.data);
            const content = new Blob([bodyData.buffer], {
              type: response.streamData.contentType,
            });
            const encodedUri = window.URL.createObjectURL(content);
            const link = document.createElement('a');

            link.setAttribute('href', encodedUri);
            link.setAttribute('download', id.split('/')[2]);

            link.click();
          }
        }
      )
      .catch((err: unknown) => {
        console.log(err);
        setError('failed to download documents');
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  useEffect(() => {
    if (profile) {
      const userID = profile.id;
      callAPIAsync(`/admin/usageReporting/${agency}`)
        .then((response: { fileList: [] }) => {
          setIsLoading(false);
          if (response.fileList) {
            setData(response.fileList);
            setFirst(0);
            setCount(response.fileList.length);
          }
        })
        .catch((err: unknown) => {
          console.log(err);
          setError('Failed to retrieve documents.');
        });
    }
  }, [agency]);

  const errorMessage = error ? (
    <p className='col-12 center errorMessage'>{error}</p>
  ) : (
    ''
  );

  useEffect(() => {
    if (isAdmin) {
      const userID = profile.id;
      mixpanel.track('download-usage-report');

      callAPIAsync('/admin/agencies')
        .then((response: { agencies: { id: number; name: string }[] }) => {
          const dataSet = response.agencies.map(
            (ag: { id: number; name: string }) => ({
              label: ag.name,
              value: ag.id,
            })
          );

          const key = 'value';
          const arrayUniqueByKey = [
            ...new Map(dataSet.map((item) => [item[key], item])).values(),
          ].sort((a, b) => (a.label > b.label ? 1 : -1));
          setAgencies(arrayUniqueByKey);
        })
        .catch((err: unknown) => console.error(err));
    }
  }, [setAgencies]);

  const progressBar =
    isLoading && !error ? <ProgressBar mode='indeterminate' /> : '';

  const dropdown = isAdmin ? (
    <div className={'dropdown-box'}>
      <Dropdown
        value={agency}
        options={agencies}
        className='query-case-dropdown'
        onChange={(e: { value: number }) => setAgency(e.value)}
        placeholder='Select an Agency'
      />
    </div>
  ) : (
    ''
  );

  return (
    <Page title='Usage Reporting' pageTitle='Usage Reporting Admin | Augintel'>
      <div className={'usage-reporting'}>
        {dropdown}
        <p>
          Usage reports are run once weekly and are saved here for your
          convenience.
        </p>
        {data.slice(first * 10, (first + 1) * 10).map((item: UsageItemType) => (
          <UsageItem
            disabled={isDisabled}
            key={item.id}
            item={item}
            onClickHandler={exportHandler}
          />
        ))}
        {progressBar}
        {errorMessage}
        <div className='col-12'>
          <Paginator
            first={first * 10}
            alwaysShow={false}
            totalRecords={count}
            rows={10}
            onPageChange={(e) => setFirst(e.page)}
          ></Paginator>
        </div>
      </div>
    </Page>
  );
};
