import React from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Tooltip } from 'primereact/tooltip';
import { formatDate } from '../../libs/CaseDataService';

export type UsageItemType = {
  id: string;
  dateFrom: string;
  dateTo: string;
};

type UsageItemProps = {
  onClickHandler: (id: string) => void;
  item: UsageItemType;
  disabled: boolean;
};

export const UsageItem = ({
  onClickHandler,
  item,
  disabled,
}: UsageItemProps) => (
  <Card style={{ marginBottom: '8px' }}>
    <div className='grid flex-wrap card-container align-content-center'>
      <div
        className={
          'col flex justify-content-start flex-wrap card-container align-content-center'
        }
      >
        <h2>
          {formatDate(new Date(item.dateTo))} -{' '}
          {formatDate(new Date(item.dateFrom))}
        </h2>
        <Tooltip target='.breakdown-tooltip' position='bottom' />
        <h5>
          Usage Breakdown{' '}
          <i
            className={'pi pi-info-circle breakdown-tooltip'}
            data-pr-tooltip={
              'Cumulative view of your organization’s use of Augintel.'
            }
          ></i>
        </h5>
      </div>
      <div className={'col flex justify-content-end flex-wrap card-container'}>
        <Button disabled={disabled} onClick={() => onClickHandler(item.id)}>
          Export CSV
        </Button>
      </div>
    </div>
  </Card>
);
