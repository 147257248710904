import styled from 'styled-components';
import {
  ICaseSearchResult,
  ISearchResultTemplateProps,
} from './BroadSearchDataService';
import './BroadSearch.css';

const Wrapper = styled.div`
  width: 100%;
  background-color: #f0f3fa;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.6);
  :hover {
    color: rgba(0, 0, 0, 0.75);
    background-color: #e5e9f3;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: 'row';
  justify-content: 'stretch';
`;

const Name = styled.h6`
  margin: 0;
  font-size: 14px;
  text-transform: uppercase;
  font-family: Open Sans, sans-serif;
  font-weight: 700;
  display: flex;
  align-items: baseline;
  letter-spacing: 0.04em;
  line-height: 143%;
`;

const MyCaseFlag = styled.div<{ $myCase: boolean }>`
  background-color: ${({ $myCase }) => ($myCase ? '#0076d9' : 'transparent')};
  width: 8px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
`;

const ColWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px;
`;

const ColLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

const ColRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`;

const Row = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
`;

const RowRight = styled(Row)`
  justify-content: right;
`;

const Caption = styled.p`
  margin: 0;
  font-family: Source Serif Pro, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: baseline;
`;

const CaptionRight = styled(Caption)`
  text-align: right;
`;

export default ({
  case_name,
  results,
  case_source_id,
  start_date,
  end_date,
  my_case,
}: ISearchResultTemplateProps) => (
  <Wrapper className='ai-list-item'>
    <Container>
      <MyCaseFlag $myCase={my_case} />
      <ColWrapper>
        <ColLeft>
          <Row>
            <Name>{case_name || 'unnamed'}</Name>
          </Row>
          {results.map((result: ICaseSearchResult) => (
            <Row key={result.person_id}>
              <Caption>{result.person_name}</Caption>
            </Row>
          ))}
        </ColLeft>
        <ColRight>
          <RowRight>
            <CaptionRight>
              {start_date}
              {end_date === 'Active' ? (
                <strong>&ensp;Active</strong>
              ) : (
                <>
                  {end_date !== 'Inactive' && <>&ndash;</>}
                  {end_date}
                </>
              )}
            </CaptionRight>
          </RowRight>
          <RowRight>
            <CaptionRight>ID&nbsp;{case_source_id}</CaptionRight>
          </RowRight>
        </ColRight>
      </ColWrapper>
    </Container>
  </Wrapper>
);
