import React, { useMemo } from 'react';
import { Dropdown } from 'primereact/dropdown';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

interface QueryMonthSelectorProps {
  loading: boolean;
  month: string;
  year: string;
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const calculateMonths = (
  currentMonthValue: number,
  currentYear: number
) => {
  const currentMonth = `${monthNames[currentMonthValue]} ${currentYear}`;
  const options = [
    { label: currentMonth, value: `${currentMonthValue}-${currentYear}` },
  ];
  for (let i = 0; i < 5; i += 1) {
    let prevMonth = currentMonthValue - (i + 1);
    let thisYear = currentYear;
    let label = monthNames[prevMonth];
    if (prevMonth < 0) {
      thisYear = currentYear - 1;
      label = monthNames[12 + prevMonth];
      prevMonth = 12 + prevMonth;
    }
    options.push({
      label: `${label} ${thisYear}`,
      value: `${prevMonth}-${thisYear}`,
    });
  }
  return options;
};

export default function QueryMonthSelector({
  loading,
  month,
  year,
}: QueryMonthSelectorProps) {
  const today = new Date();
  const history = useHistory();
  const location = useLocation();
  const currentMonthValue = today.getMonth();
  const currentYear = today.getFullYear();
  const options = useMemo(
    () => calculateMonths(currentMonthValue, currentYear),
    [currentMonthValue, currentYear]
  );

  const handleMonthChange = (event: { value: string }) => {
    history.push({
      search: queryString.stringify(
        {
          ...queryString.parse(location.search, {
            arrayFormat: 'comma',
          }),
          page: 1,
          month: event.value.match(/(\d*)-(\d*)/)![1],
          year: event.value.match(/(\d*)-(\d*)/)![2],
        },
        { arrayFormat: 'comma' }
      ),
    });
  };

  return loading ? null : (
    <div className={'inline-flex'}>
      Matching cases for
      <Dropdown
        className='query-case-dropdown small'
        value={`${month}-${year}`}
        style={{ maxHeight: '300px', width: 'auto', paddingTop: '2px' }}
        optionLabel={'label'}
        options={options}
        onChange={handleMonthChange}
      />
    </div>
  );
}
