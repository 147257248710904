import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { changePassword } from '../libs/Cognito';
import { Page, LoadingContainer } from '../components-new';

export const ChangePassword = ({ history, user }) => {
  const [changePasswordForm, setChangePasswordForm] = useState({
    email: user.email,
    oldPassword: '',
    newPassword: '',
  });
  const [loading, setLoading] = useState();
  const [showScreen, setShowScreen] = useState('requestCode');
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (!user.email) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePassword = () => {
    const { email, oldPassword, newPassword } = changePasswordForm;
    if (newPassword.length < 8) {
      setErrorMessage(
        'Password is too short; should be a minimum of 8 characters.'
      );
    } else {
      setLoading(true);
      changePassword(email, oldPassword, newPassword, (err) => {
        if (err) {
          setLoading(false);
          if (err.message === 'Incorrect username or password.') {
            setErrorMessage('Old password is incorrect.');
          } else {
            setErrorMessage(err.message);
          }
        } else {
          setLoading(false);
          setErrorMessage('');
          setShowScreen('confirmed');
        }
      });
    }
  };

  const handleSubmitEnter = (e) => {
    if (e.keyCode === 13) handleChangePassword();
  };

  const handleFormInput = (key, value) => {
    errorMessage && setErrorMessage('');
    setChangePasswordForm({
      ...changePasswordForm,
      [key]: value,
    });
  };

  const error = errorMessage ? (
    <span className='errorMessage' style={{ display: 'block' }}>
      {errorMessage}
    </span>
  ) : null;

  const renderScreen =
    showScreen === 'confirmed' ? (
      <div className='appBody changePassword'>
        <div className='grid justify-content-center'>
          <div className='lg:col-5 md:col-6 col-12'>
            <Card title='Change Password'>
              {error}
              Your password has been changed.
            </Card>
          </div>
        </div>
      </div>
    ) : (
      <LoadingContainer isLoaded={!loading}>
        <div className='grid justify-content-center changePassword'>
          <div className='lg:col-5 md:col-6 col-12'>
            <Card title='Change Password'>
              {error}
              <br />
              <Password
                id='oldPassword'
                placeholder='Old Password'
                defaultValue={changePasswordForm.oldPassword}
                onChange={(e) => handleFormInput('oldPassword', e.target.value)}
                onKeyUp={handleSubmitEnter}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className='accessibility'>Old Password</label>
              <br />
              <br />
              <Password
                id='newPassword'
                placeholder='New Password'
                defaultValue={changePasswordForm.newPassword}
                onChange={(e) => handleFormInput('newPassword', e.target.value)}
                onKeyUp={handleSubmitEnter}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className='accessibility'>New Password</label>
              <br />
              <br />
              <Button
                label='Change Password'
                onClick={handleChangePassword}
                style={{ width: '100%' }}
              />
            </Card>
          </div>
        </div>
      </LoadingContainer>
    );

  return <Page pageTitle='Change Password | Augintel'>{renderScreen}</Page>;
};
