import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';

export const AlertsPopup = ({ shown, query, alertsHidden }) => {
  const [isShown, setIsShown] = useState(shown);
  const hideModal = () => {
    setIsShown(false);
    alertsHidden();
  };

  useEffect(() => {
    setIsShown(shown);
  }, [shown]);

  if (!query || !isShown) return '';
  // Dialog is a "modal" component from PrimeReact
  return (
    <Dialog
      header={query.title}
      visible={isShown}
      modal
      closeOnEscape
      dismissableMask
      onHide={hideModal}
      className='alertsPopup'
    >
      <div>{query.subtitle} </div>
    </Dialog>
  );
};
