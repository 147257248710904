import { Dispatch } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { AgencyGroup, getGroups } from '../repositories/agencyGroupRepository';
import {
  ICasesPageDataState,
  ScopeOption,
} from '../repositories/caseRepository';
import { ImpersonationRecord, Profile } from '../shared/types/appTypes';

export interface FiltersQueryState {
  scope: ScopeOption;
  groupIds?: number[];
  caseworkerIds?: number[];
  sort?: 'datetime' | 'importance';
  factor?: string;
}

export interface FiltersQueryStateWithPagination extends FiltersQueryState {
  page: number;
  month?: string;
  year?: string;
}

export interface TreeNode {
  key: string | number;
  label: string;
  data?: any;
  icon?: string;
  children?: TreeNode[];
}

export const mapToNumberArray = (arg: string | string[]) =>
  Array.isArray(arg) ? arg.map((gid) => Number(gid)) : [Number(arg)];

const groupToTree = (group: AgencyGroup): TreeNode => ({
  label: group.name || 'Unnamed',
  key: group.id,
  children: group.subGroups?.length
    ? group.subGroups
        .map(groupToTree)
        .sort((a, b) => a.label.localeCompare(b.label))
    : [],
});

export const getInitialCaseFilter = (
  profile: Profile | ImpersonationRecord
) => {
  if (
    Number(profile.direct_report_count) > 0 &&
    profile.direct_report_cases_count != null &&
    Number(profile.direct_report_cases_count) > 0
  ) {
    return 'TEAM' as ScopeOption;
  }
  if (Number(profile.cases_count) > 0) {
    return 'USER' as ScopeOption;
  }
  return 'AGENCY' as ScopeOption;
};

export const getDefaultScope = (
  profile: Profile | null,
  impersonation: ImpersonationRecord | null
) => {
  if (impersonation) {
    return getInitialCaseFilter(impersonation);
  }
  if (profile) {
    return getInitialCaseFilter(profile);
  }
  return 'AGENCY' as ScopeOption;
};

export async function setUpInitialLoad(
  profile: Profile,
  impersonation: ImpersonationRecord | null,
  history: RouteComponentProps['history'],
  location: RouteComponentProps['location'],
  sortParam: string | null,
  dataDispatch: Dispatch<Partial<ICasesPageDataState>>,
  queryId?: string
) {
  const existingQuery = queryString.parse(location.search, {
    arrayFormat: 'comma',
  });
  const [groups] = await Promise.all([getGroups(queryId)]);
  dataDispatch({
    groupData: groups?.map((group) => groupToTree(group)).flat(),
  });
  let scope = existingQuery.scope as ScopeOption;
  const page = Number(existingQuery.page || '1');
  const sort = existingQuery.sort || sortParam;
  // default to previous month
  const isJanuary = new Date().getMonth() - 1 === -1;
  const month =
    existingQuery.month || `${isJanuary ? 11 : new Date().getMonth() - 1}`;
  const year =
    existingQuery.year ||
    `${isJanuary ? new Date().getFullYear() - 1 : new Date().getFullYear()}`;

  if (!scope) {
    if (localStorage.getItem('filters')) {
      const cachedFilters = JSON.parse(
        localStorage.getItem('filters') || '{}'
      ) as FiltersQueryState | FiltersQueryStateWithPagination;
      if (cachedFilters.sort && !sortParam) {
        cachedFilters.sort = undefined;
      }
      const query = {
        month: queryId ? month : undefined,
        year: queryId ? year : undefined,
        ...existingQuery,
        ...cachedFilters,
        page: 1,
      };
      history.replace({
        search: queryString.stringify(query, { arrayFormat: 'comma' }),
      });
    } else {
      scope = getDefaultScope(profile, impersonation);
      const query = {
        ...existingQuery,
        scope,
        page,
        sort: sort === null ? undefined : sort,
        month: queryId ? month : undefined,
        year: queryId ? year : undefined,
      };
      history.replace({
        search: queryString.stringify(query, { arrayFormat: 'comma' }),
      });
    }
  }
}
