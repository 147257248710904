import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { callAPI } from '../libs/API';

const failedSubmitError = (
  <span>
    Error occurred sending feedback. Please email{' '}
    <a href='mailto:support@augintel.zendesk.com'>
      support@augintel.zendesk.com
    </a>
  </span>
);

export const NewQueryFeedbackPopup = forwardRef(({ shown }, ref) => {
  const INITIAL_STATE = {
    shown,
    sending: false,
    sent: false,
    feedback: '',
    error: '',
  };
  const [feedbackPopup, setFeedbackPopup] = useState(INITIAL_STATE);

  const hideModal = () => {
    setFeedbackPopup(INITIAL_STATE);
  };

  useImperativeHandle(ref, () => ({
    showModal() {
      setFeedbackPopup({
        shown: true,
        sending: false,
        sent: false,
        feedback: '',
        error: '',
      });
    },
  }));

  const handleSubmit = () => {
    if (feedbackPopup.feedback === '') {
      setFeedbackPopup({
        ...feedbackPopup,
        error: 'Please enter feedback.',
      });
    } else {
      setFeedbackPopup({
        ...feedbackPopup,
        sending: true,
      });
      callAPI(
        '/query/feedback',
        { feedback: feedbackPopup.feedback },
        (resp) => {
          if (resp.error) {
            setFeedbackPopup({
              ...feedbackPopup,
              error: failedSubmitError,
              sending: false,
            });
          } else {
            setFeedbackPopup({
              ...feedbackPopup,
              sent: true,
            });
          }
        },
        () => {
          setFeedbackPopup({
            ...feedbackPopup,
            sending: false,
            error: failedSubmitError,
          });
        }
      );
    }
  };

  if (!feedbackPopup.shown) return '';
  if (feedbackPopup.sent) {
    return (
      <Dialog
        header='New Query'
        footer={
          <span>
            <Button label='Close' onClick={hideModal} />
          </span>
        }
        visible={feedbackPopup.shown}
        modal
        closeOnEscape
        dismissableMask
        onHide={hideModal}
        className='queryFeedbackPopup thanks'
      >
        <div>
          Thank you. Our team will review your request and be in touch soon.
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      header='New Query'
      footer={
        <span>
          <Button label='Send Feedback' onClick={handleSubmit} />
        </span>
      }
      visible={feedbackPopup.shown}
      modal
      closeOnEscape
      dismissableMask
      onHide={hideModal}
      className='queryFeedbackPopup form'
    >
      <div>Tell us what you want to search for</div>
      {feedbackPopup.sending ? <ProgressBar mode='indeterminate' /> : ''}
      {feedbackPopup.error !== '' ? (
        <div className='error'>{feedbackPopup.error}</div>
      ) : (
        ''
      )}
      <div>
        <InputTextarea
          rows={10}
          value={feedbackPopup.feedback}
          onChange={(e) => {
            setFeedbackPopup({ ...feedbackPopup, feedback: e.target.value });
          }}
          placeholder='Feedback'
        />
      </div>
    </Dialog>
  );
});

export default NewQueryFeedbackPopup;
