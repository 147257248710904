import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';
import mixpanel from '../libs/Mixpanel';
// eslint-disable-next-line import/no-named-as-default
import NewQueryFeedbackPopup from '../components/NewQueryFeedbackPopup';
import { AreaChart } from '../components/graphs/AreaChart';
import { Page } from '../components-new';
import {
  FiltersQueryState,
  mapToNumberArray,
  setUpInitialLoad,
  TreeNode,
} from '../libs/FilterService';
import { PageProps } from '../shared/types/appTypes';
import CaseAssignmentFilter from '../components/filters/CaseAssigneeFilter';
import {
  IListBoxFilterOption,
  ScopeOption,
  ScopeOptionKey,
} from '../repositories/caseRepository';
import { QueryGroup, QueryHoverState } from '../shared/types/queryTypes';
import QueryCard from '../components/QueryCard';
import useGet from '../libs/UseGet';
import { CaseAssignee } from '../shared/types/caseTypes';
import { cantViewScope } from '../services/ScopeService';
import AgencyOrgCasesFilter from '../components/filters/AgencyOrgCasesFilter';
import CaseworkerAssigneeCasesFilter from '../components/filters/CaseworkerAssigneeCasesFilter';
import { useQueryState } from '../libs/useQueryState';
import SelectedFiltersDisplay from '../components/filters/SelectedFiltersDisplay';

interface QueriesResponse {
  queryGroups: QueryGroup[];
}

export interface PageDataState {
  queryGroups?: QueryGroup[];
  error?: {
    message: string;
  };
  groupData?: TreeNode[];
  caseworkerData?: IListBoxFilterOption[];
}

const dataReducer = (state: PageDataState, action: Partial<PageDataState>) => ({
  ...state,
  ...action,
});

export default function Queries({ profile, impersonation }: PageProps) {
  const history = useHistory();
  const location = useLocation();
  const queryFeedbackRef = useRef<{ showModal: () => void }>();
  const toast = useRef<Toast>();
  const initialLoad = useRef(true);
  const path = `/queries${location.search}`;
  const [isLoaded, setIsLoaded] = useState(false);
  const { isLoading, data, error } = useGet<QueriesResponse>({
    path,
    initialLoad: initialLoad.current,
  });

  const queryState: FiltersQueryState = useQueryState<FiltersQueryState>(
    {
      scope: 'AGENCY' as ScopeOption,
      groupIds: [],
      caseworkerIds: [],
    },
    {
      groupIds: (arg: string | string[]) => mapToNumberArray(arg),
      caseworkerIds: (arg: string | string[]) => mapToNumberArray(arg),
    }
  );
  const [dataState, dataDispatch] = useReducer(dataReducer, {});
  const [hoverStates, setHoverStates] = useState<
    Record<number, QueryHoverState>
  >([]);
  const [queryCharts, setQueryCharts] = useState<Record<number, JSX.Element>>(
    []
  );
  const assigneesPath = `/v2/cases/assignees${location.search}`;
  const caseAssigneesRequest = useGet<CaseAssignee[]>({
    path: assigneesPath,
    initialLoad: initialLoad.current,
  });

  const assigneesData = cantViewScope(
    'no__caseworker_filter',
    (profile as unknown) as { user_type: string; scopes: string[] },
    (impersonation as unknown) as { scopes: string[] }
  )
    ? null
    : caseAssigneesRequest.data;

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      setUpInitialLoad(
        profile,
        impersonation,
        history,
        location,
        null,
        dataDispatch
      )
        .then(() => null)
        .catch((e) => {
          console.error(e);
        });
    }
  }, [
    history,
    impersonation,
    location.search,
    profile,
  ]);

  useEffect(() => {
    if (data && data.queryGroups && !error) {
      if (!isLoaded) {
        mixpanel.track('queries-index');
        setIsLoaded(true);
      }
      // create state for the hover info for each query
      // eslint-disable-next-line prefer-const
      let defaultHoverStates: Record<number, any> = {};
      data.queryGroups.forEach((qg) => {
        qg.queries?.forEach((query) => {
          defaultHoverStates[query.id] = { hover: false };
        });
      });

      // create state for chart for each query
      // eslint-disable-next-line prefer-const
      let preRenderedQueryCharts: Record<number, any> = {};
      data.queryGroups.forEach((qg: QueryGroup) => {
        qg.queries?.forEach((query) => {
          const onHover = (e: {
            date: {
              getMonth(): number;
            };
            value: string;
            hover: boolean;
          }) => {
            const monthNames = [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ];
            // eslint-disable-next-line prefer-const
            let newHoverStates = JSON.parse(
              JSON.stringify(hoverStates)
            ) as Record<number, QueryHoverState>;
            newHoverStates[query.id] =
              e.hover === true
                ? {
                    hover: true,
                    chartHoverMonth: monthNames[e.date.getMonth()],
                    chartHoverValue: e.value,
                  }
                : {
                    hover: false,
                    chartHoverMonth: '',
                    chartHoverValue: '',
                  };
            setHoverStates(newHoverStates);
          };

          // TODO: work with date formatting and timezones -- date is coming in ok, parsing to cst, locale is interpreting as gmt.  toLocale also used in area chart
          const color = 'red';
          preRenderedQueryCharts[query.id] = (
            <AreaChart
              keys={['query']}
              data={[query.chart]}
              colors={[
                color === 'red'
                  ? { area: 'url(#red-gradient)', line: '#F14E4E' }
                  : { area: 'url(#green-gradient)', line: '#1E7200' },
              ]}
              forcedHeight='125'
              partialLastMth
              punctuateLastValue
              showYAxis
              observeHover
              onHover={onHover}
              showXAxis
            />
          );
        });
      });

      setHoverStates(defaultHoverStates);
      setQueryCharts(preRenderedQueryCharts);
      dataDispatch({
        queryGroups: data.queryGroups,
        error: undefined,
      });
    } else if (error) {
      dataDispatch({
        error: {
          message: '0 Queries found',
        },
      });
      setIsLoaded(true);
    }
  }, [data, error]);

  useEffect(() => {
    if (assigneesData) {
      dataDispatch({
        caseworkerData: assigneesData
          .map((user) => ({
            label: `${user.name}`,
            value: user.profileId.toString(),
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      });
    }
  }, [assigneesData]);

  const trackMixpanelFilterEvent = useCallback(
    (eventType: string, incomingScopeChange: object) => {
      mixpanel.track(eventType, {
        currentUrl: window.location.href,
        incomingScopeChange,
      });
    },
    []
  );

  const renderQueryGroup = (queryGroup: QueryGroup, groupI: number) => (
    <div className='col-12 p-0' key={`queryGroupColumn${groupI}`}>
      <h3 style={{ paddingLeft: '0.3rem' }}>{queryGroup.heading}</h3>
      <div className='grid'>
        {queryGroup.queries?.map((queryEntry) => (
          <QueryCard
            query={queryEntry}
            key={`queryCard${queryEntry.id}`}
            hoverState={hoverStates ? hoverStates[queryEntry.id] : undefined}
            chart={queryCharts ? queryCharts[queryEntry.id] : undefined}
          />
        ))}
      </div>
    </div>
  );

  const queryGroupsRender =
    dataState.queryGroups && !dataState.error ? (
      dataState.queryGroups?.map((queryGroup, groupI) =>
        renderQueryGroup(queryGroup, groupI)
      )
    ) : (
      <h4 className={'center'}>{dataState.error?.message}</h4>
    );

  const sidePanel = (
    <div className='w-18rem col-fixed h-auto mr-2 mt-3'>
      <CaseAssignmentFilter
        impersonation={impersonation}
        scope={queryState.scope}
        profile={profile}
        mixpanelCallback={trackMixpanelFilterEvent}
      />
      {dataState.groupData && (
        <AgencyOrgCasesFilter
          root={dataState.groupData}
          groupIds={queryState.groupIds || []}
          mixpanelCallback={trackMixpanelFilterEvent}
        />
      )}
      {dataState.caseworkerData && (
        <CaseworkerAssigneeCasesFilter
          caseworkerIdOptions={dataState.caseworkerData}
          caseworkerIds={queryState.caseworkerIds || []}
          mixpanelCallback={trackMixpanelFilterEvent}
        />
      )}
    </div>
  );

  // RECOMMENDATION: remove new query button.  replace with anything else (gear icon, link closer to h1?).  reasons: button looks odd and is not functional, producing an unpolished presentation of offerings.  most people won't be altering their queries and an add icon right on the page instead of in a segmented configuration section is a jarring display item.
  return (
    <Page
      title='Queries'
      pageTitle='Queries | Augintel'
      isLoaded={isLoaded}
      toast={toast}
      componentClassName='queries'
      actions={[
        <Button
          label='New Query'
          icon='pi pi-plus'
          onClick={() => {
            queryFeedbackRef.current?.showModal();
          }}
        />,
      ]}
    >
      <NewQueryFeedbackPopup ref={queryFeedbackRef} />
      <div className='grid queryBody'>
        {sidePanel}
        <div className='col'>
          <h2 style={{ paddingLeft: '0.3rem' }}>
            {ScopeOptionKey[queryState.scope]}
          </h2>
          <SelectedFiltersDisplay
            loading={isLoading}
            queryState={queryState}
            groupData={dataState.groupData}
            caseworkerData={dataState.caseworkerData}
          />
          {isLoading ? (
            <ProgressBar mode={'indeterminate'} className='mt-2' />
          ) : (
            queryGroupsRender
          )}
        </div>
      </div>
    </Page>
  );
}
