import React, { Component } from 'react';

import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { callAPI } from '../../libs/API';
import { Page, LoadingContainer } from '../../components-new';

// TODO: manager column or org view
// TODO: create a middle component to extend for simple functions like displayError
export default class AdminAssignments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      filterLoaded: true,
      impersonation: this.props.impersonation,
      agencies: [],
      groups: [],
      users: [],
      assignments: [],
      displayAssignments: [],
      assignmentCount: 0,
      first: 1,
      sortField: 'user_name',
      sortOrder: 1,
      pageLimit: 100,
      searchUser: props.match.params.userID ? props.match.params.userID : '',
      searchedUser: props.match.params.userID ? props.match.params.userID : '',
      searchAgency: 'ALL',
      searchGroup: 'ALL',
      searchCase: '',
      searchedCase: '',
      deleteCaseName: '',
      deleteUserName: '',
      error: '',
      isAIAdmin: this.props.profile.user_type === 'augintel_admin',
      isAgencyAdmin: this.props.profile.user_type === 'agency_admin',
    };

    if (
      !(this.state.isAIAdmin || this.state.isAgencyAdmin) ||
      this.state.impersonation
    )
      this.props.history.push('/');

    this.toast = React.createRef();
    this.loadData = this.loadData.bind(this);
    this.loadMoreData = this.loadMoreData.bind(this);
    this.promptDelete = this.promptDelete.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.displayError = this.displayError.bind(this);
  }

  componentDidMount() {
    this.loadData(true);
  }

  loadData(clear) {
    if (typeof this.state.users[this.state.first] === 'undefined' || clear) {
      this.setState({ filterLoaded: false }, () => {
        const offset = clear ? 0 : this.state.first - 1;
        const sortField = `&sortField=${
          this.state.sortField === 'role_id'
            ? 'role_name'
            : this.state.sortField
        }`;
        const sortOrder = `&sortOrder=${this.state.sortOrder}`;
        const searchUser =
          this.state.searchUser !== ''
            ? `&searchUser=${this.state.searchUser}`
            : '';
        const searchAgency =
          this.state.searchAgency !== 'ALL'
            ? `&searchAgency=${this.state.searchAgency}`
            : '';
        const searchGroup =
          this.state.searchGroup !== 'ALL'
            ? `&searchGroup=${this.state.searchGroup}`
            : '';
        const searchCase =
          this.state.searchCase !== ''
            ? `&searchCase=${this.state.searchCase}`
            : '';

        callAPI(
          `/admin/assignments?limit=${this.state.pageLimit}&offset=${offset}${sortField}${sortOrder}${searchUser}${searchAgency}${searchGroup}${searchCase}`,
          null,
          (resp) => {
            if (typeof resp.assignments !== 'undefined') {
              // TODO: event_datetime is showing +0 GMT time.  probably needs to be corrected in backend?  need to confirm date conversion in backend.
              if (clear) {
                // only load agencies and role options on first load
                this.setState({
                  isLoaded: true,
                  agencies: resp.agencies !== null ? resp.agencies : [],
                  groups: resp.agency_groups !== null ? resp.agency_groups : [],
                  assignments:
                    resp.assignments !== null ? resp.assignments : [],
                  displayAssignments:
                    resp.assignments !== null ? resp.assignments : [],
                  assignmentCount:
                    resp.assignmentTotal !== null
                      ? parseInt(resp.assignmentTotal, 10)
                      : 0,
                  filterLoaded:
                    this.state.searchUser === this.state.searchedUser &&
                    this.state.searchCase === this.state.searchedCase,
                });
              } else if (
                this.state.searchUser === this.state.searchedUser &&
                this.state.searchCase === this.state.searchedCase
              ) {
                const { assignments } = this.state;
                for (
                  let insertJ = 0;
                  insertJ < resp.assignments?.length;
                  insertJ += 1
                ) {
                  assignments[insertJ + offset] = resp.assignments[insertJ];
                }
                this.setState({
                  isLoaded: true,
                  assignments,
                  displayAssignments: resp.assignments,
                  filterLoaded: true,
                });
              }
            } else {
              this.displayError({ message: 'Users not found' });
            }
          },
          (error) => {
            this.displayError(error);
          }
        );
      });
    } else {
      // this.setState({displayUsers: this.state.users.slice(this.state.first, this.state.first + this.state.pageLimit)})
    }
  }

  loadMoreData() {
    this.loadData(false);
  }

  searchField(searchedValue) {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.setState(searchedValue, this.loadMoreData(true));
    }, 500);
  }

  searchAgency(e) {
    if (this.state.isLoaded && this.state.filterLoaded) {
      this.setState({ searchAgency: e.value }, this.loadMoreData(true));
    }
  }

  searchGroup(e) {
    if (this.state.isLoaded && this.state.filterLoaded) {
      this.setState({ searchGroup: e.value }, this.loadMoreData(true));
    }
  }

  promptDelete(rowData) {
    this.setState({
      deleting: false,
      deleteID: rowData.id,
      deleteCaseName: rowData.case_name,
      deleteUserName: rowData.user_name,
    });
  }

  deleteItem() {
    if (!this.state.deleting) {
      this.setState({ deleting: true }, () => {
        callAPI(
          `/admin/assignments/${this.state.deleteID}/delete`,
          {},
          (resp) => {
            if (resp.success) {
              const updatedAssignments = this.state.assignments.filter(
                (item) => item.id !== this.state.deleteID
              );
              const updatedDisplayAssignments = this.state.displayAssignments.filter(
                (item) => item.id !== this.state.deleteID
              );
              this.setState({
                assignments: updatedAssignments,
                displayAssignments: updatedDisplayAssignments,
              });
              this.setState({
                deleting: false,
                deleteID: '',
                deleteCaseName: '',
                deleteUserName: '',
              });
            } else {
              this.toast.current.show({
                severity: 'error',
                summary: 'Assignment could not be deleted',
              });
            }
          },
          (error) => {
            this.toast.current.show({
              severity: 'error',
              summary: error.message,
            });
          }
        );
      });
    }
  }

  displayError(error) {
    if (error.message.startsWith('Session')) {
      this.props.history.push(
        `/login?errorMessage=${error.message}&sourcePath=${window.location.path}`
      );
    } else {
      this.setState({
        isLoaded: true,
        error: error.message,
      });
    }
  }

  render() {
    const deleteFooter = (
      <div>
        <Button
          label='Yes'
          className='p-button-danger'
          onClick={() => {
            this.deleteItem();
          }}
        />
        <Button
          label='No'
          onClick={() => {
            this.setState({
              deleting: false,
              deleteID: '',
              deleteCaseName: '',
              deleteUserName: '',
            });
          }}
        />
      </div>
    );

    const userFilter = (
      <InputText
        value={this.state.searchUser}
        keyfilter={/^[a-z A-Z-]+$/}
        onChange={(e) => {
          this.setState({ searchUser: e.target.value });
          this.searchField({ searchedUser: e.target.value });
        }}
        placeholder='Filter User'
      />
    );

    const agencyFilter = (
      <Dropdown
        value={this.state.searchAgency}
        optionLabel='name'
        optionValue='id'
        options={[{ id: 'ALL', name: 'ALL' }].concat(this.state.agencies)}
        onChange={(e) => {
          this.searchAgency(e);
        }}
        placeholder='Filter Agency'
      />
    );

    const groupFilter = (
      <Dropdown
        value={this.state.searchGroup}
        optionLabel='name'
        optionValue='id'
        options={[{ id: 'ALL', name: 'ALL' }].concat(this.state.groups)}
        onChange={(e) => {
          this.searchGroup(e);
        }}
        placeholder='Filter Group'
      />
    );

    const caseFilter = (
      <InputText
        value={this.state.searchCase}
        keyfilter={/^[a-z A-Z-]+$/}
        onChange={(e) => {
          this.setState({ searchCase: e.target.value });
          this.searchField({ searchedCase: e.target.value });
        }}
        placeholder='Filter Case'
      />
    );

    return (
      <Page
        title='Assignments'
        pageTitle='Assignments Admin | Augintel'
        isLoaded={this.state.isLoaded}
        toast={this.toast}
        error={this.state.error}
        actions={[
          <Button
            label='New Assignment'
            icon='pi pi-plus'
            onClick={() => {
              this.props.history.push('/admin/assignments/add');
            }}
          />,
        ]}
      >
        <div className='adminAssignmentsBody grid'>
          <LoadingContainer isLoaded={this.state.filterLoaded}>
            <DataTable
              value={this.state.displayAssignments}
              paginator
              rows={this.state.pageLimit}
              totalRecords={this.state.assignmentCount}
              first={this.state.first}
              lazy
              onPage={(e) => {
                this.setState({ first: e.first }, this.loadMoreData);
              }}
              sortField={this.state.sortField}
              sortOrder={this.state.sortOrder}
              filterDisplay='row'
              onSort={(e) =>
                this.setState(
                  { sortField: e.sortField, sortOrder: e.sortOrder },
                  this.loadData(true)
                )
              }
              resizableColumns
              dataKey='id'
            >
              <Column
                field='user_name'
                header='User'
                filter
                filterElement={userFilter}
                filterPlaceholder='Filter User'
                showFilterMenu={false}
                sortable
              />
              <Column
                field='agency_name'
                header='Agency'
                filter
                filterElement={agencyFilter}
                showFilterMenu={false}
                sortable
              />
              <Column
                field='group_name'
                header='Group'
                filter
                filterElement={groupFilter}
                showFilterMenu={false}
                sortable
              />
              <Column
                field='case_name'
                header='Case'
                filter
                filterElement={caseFilter}
                filterPlaceholder='Filter Case'
                showFilterMenu={false}
                sortable
              />
              <Column
                header='Delete'
                body={(rowData) => (
                  <Button
                    className='p-button-danger'
                    icon='pi pi-minus-circle'
                    onClick={() => {
                      this.promptDelete(rowData);
                    }}
                  />
                )}
                style={{ width: '6em' }}
              />
            </DataTable>
          </LoadingContainer>
        </div>
        <Dialog
          header='Confirm Delete'
          footer={deleteFooter}
          visible={
            this.state.deleteCaseName !== '' && this.state.deleteUserName !== ''
          }
          onHide={() => {
            this.setState({
              deleting: false,
              deleteID: '',
              deleteCaseName: '',
              deleteUserName: '',
            });
          }}
          modal
          ref={(el) => {
            this.dt = el;
          }}
        >
          {this.state.deleting ? <ProgressBar mode='indeterminate' /> : ''}
          Are you sure you want to delete this case assignment{' '}
          <b>({this.state.deleteCaseName})</b> for{' '}
          <b>{this.state.deleteUserName}?</b>
        </Dialog>
      </Page>
    );
  }
}
