/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

// example: https://bl.ocks.org/interwebjill/8122dd08da9facf8c6ef6676be7da03f
// TODO: on render, redraw chart.  chart renders on parent state change.
// TODO: restructure this class
export const BubbleTimelineChart = ({
  data,
  monthSpan,
  colors,
  onClick,
  label,
}) => {
  const canvas = useRef(null);
  useEffect(() => {
    if (data) {
      // FIXME: code pulled from V1, has out of sync references
      /* eslint no-use-before-define: "off" */
      drawChart(data, monthSpan, colors, onClick, label);
      /* eslint no-use-before-define: "off" */
      window.addEventListener('resize', () => {
        drawChart(data, monthSpan, colors, onClick, label);
      });
    }
  });

  // TODO: dots slightly askew against line
  // TODO: FIX THIS SHADOW RULE
  /* eslint no-shadow: off */
  const drawChart = (data, monthSpan, colors, _onClick, label) => {
    try {
      const margin = {
        top: 10,
        right: 15,
        bottom: 20,
        left: 15,
      };
      // WARN: canvas.current is null on first render;
      const height =
        canvas.current.getClientRects()[0].height -
        margin.top -
        margin.bottom +
        (label ? 50 : 0);
      const width =
        canvas.current.getClientRects()[0].width - margin.left - margin.right;

      const svg = d3.select(canvas.current);
      svg.selectAll('*').remove();
      const parseDate = d3.timeParse('%Y-%m');

      // data is not sorted.  needs to be for min/max check.
      // eslint-disable-next-line no-param-reassign
      data = data.sort((item1, item2) => (item1.date > item2.date ? 1 : -1));

      // TODO: this is a hack that will be removed once a redesign on graphs is made
      let minDate = parseDate(data[0].date);
      const maxDate = parseDate(data[data.length - 1].date);
      const minDateCheck = parseDate(data[data.length - 1].date);
      minDateCheck.setFullYear(minDateCheck.getFullYear() - 2);
      if (minDateCheck > minDate) {
        minDate = minDateCheck;
        // eslint-disable-next-line no-param-reassign
        data = data.filter((dataV) => parseDate(dataV.date) > minDate);
      }
      if (
        data.filter(
          (dataV) => parseDate(dataV.date).getTime() === minDate.getTime()
        ).length === 0
      ) {
        data.push({ date: minDate.toISOString().slice(0, 7), val: 0 });
      }
      const dataMonthSpan =
        maxDate.getMonth() -
        minDate.getMonth() +
        12 * (maxDate.getFullYear() - minDate.getFullYear());

      if (typeof monthSpan === 'undefined' || dataMonthSpan > monthSpan) {
        // eslint-disable-next-line no-param-reassign
        monthSpan = dataMonthSpan;
      }
      const x = d3
        .scaleTime()
        .domain(d3.extent(data, (d) => parseDate(d.date)))
        .range([0, width]);
      const y = d3.scaleLinear().domain([0, 1]).range([height, 0]);

      // Setup the x axes labels  (one for year, one for month)
      //            const skipMonths = (monthSpan < 4) ? 1 : ((monthSpan < 8) ? 2 : ((monthSpan < 12) ? 3 : (monthSpan < 24) ? 4 : (monthSpan < 48) ? 6 : 12))
      const skipMonths = !monthSpan
        ? 1
        : monthSpan < 4
        ? 1
        : monthSpan < 8
        ? 1
        : monthSpan < 12
        ? 2
        : monthSpan < 24
        ? 3
        : monthSpan < 48
        ? 4
        : 12;

      if (label) {
        const xAxisMonthElems = svg
          .append('g')
          .attr('transform', `translate(${margin.left},${5 - margin.top})`)
          .attr('class', 'x-axis-months')
          .call(
            d3
              .axisBottom(x)
              .ticks(d3.timeMonth.every(1))
              .tickFormat((d, i) =>
                i === 0 || i % skipMonths === 0 || i === monthSpan
                  ? new Date(d).toLocaleString('default', { month: 'short' })
                  : ''
              )
          );
        xAxisMonthElems.selectAll('path').remove();
        xAxisMonthElems.selectAll('line').remove();

        const xAxisYearElems = svg
          .append('g')
          .attr('transform', `translate(${margin.left},${margin.top})`)
          .attr('class', 'x-axis-years')
          .call(
            d3
              .axisBottom(x)
              .ticks(d3.timeMonth.every(1))
              .tickFormat((d, i) =>
                i === 0 || i === monthSpan ? new Date(d).getFullYear() : ''
              )
          );

        xAxisYearElems.selectAll('path').remove();
        xAxisYearElems.selectAll('line').remove();
      } else {
        // TODO: this is a hack for ticks.  do ticks need to meet line?
        svg
          .append('g')
          .attr('transform', `translate(${margin.left},${margin.top})`)
          .attr('class', 'bubbleAxis')
          .call(d3.axisBottom(x).ticks(d3.timeMonth.every(1)).tickFormat(''));
        // bold the highlighted ticks
        svg
          .selectAll('.bubbleAxis g line')
          .filter((_d, i) => i === 0 || i % skipMonths === 0 || i === monthSpan)
          .attr('class', 'labeledTick');
      }

      // add data dots
      if (!label) {
        // TODO: normalized val equation can return negative numbers.
        const values = data.map((d) => ({
          val: d.val,
          normalized_val:
            d.val === 0 ? 0 : 2.0 / (1.0 + Math.exp(-0.8 * d.val)) - 1.1,
          date: parseDate(d.date),
          stroke_color: colors?.stroke,
          fill_color: colors?.fill,
        }));
        svg
          .selectAll('.dot')
          .data(values)
          .enter()
          .append('circle') // Uses the enter().append() method
          .attr('transform', `translate(${margin.left},${margin.top})`)
          .attr('date', (d, _i) => d.date)
          .attr(
            'style',
            (d, _i) =>
              `cursor: pointer; stroke-width: 2.5; stroke: ${d.stroke_color}; fill: ${d.fill_color}`
          )
          .attr('cx', (d, _i) => x(d.date))
          .attr('cy', (d) => y(d.val))
          .attr('r', (d) => Math.round(9 * d.normalized_val));
      }
    } catch (ex) {
      console.log('chart error', ex);
      d3.select(canvas.current).selectAll('*').remove();
      d3.select(canvas.current)
        .append('text')
        .attr('class', 'error')
        .attr('x', '30px')
        .attr('y', '30px')
        .text('Missing Chart');
    }
  };

  const bubbleClick = (e) => {
    e.preventDefault()
    onClick(e.target.getAttribute('date'));
  };
  const bubbleClasses = label ? 'bubbleChart labels' : 'bubbleChart bubbles';
  return (
    <svg
      ref={canvas}
      onClick={bubbleClick}
      className={bubbleClasses}
      style={{ width: '100%', height: '30px' }}
    />
  );
};
