import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Inplace, InplaceContent, InplaceDisplay } from 'primereact/inplace';
import styled from 'styled-components/macro';
import {
  SentenceLabels,
  LabeledSentence,
} from '../../shared/types/labelingTypes';
import ReviewSentence from './ReviewSentence';
import { callAPIAsync, pollS3 } from '../../libs/API';

type Props = {
  setError: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<string>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isPolling: boolean;
  setIsPolling: Dispatch<SetStateAction<boolean>>;
  setIsPrePolling: Dispatch<SetStateAction<boolean>>;
  s3Key: string;
};
const AddCategoryDiv = styled.div`
  margin: 0.5em 0em;
`;
const StyledInputText = styled(InputText)`
  max-width: 200px;
`;

export const ActiveLearningReview = ({
  setError,
  setSuccess,
  setIsLoading,
  isPolling,
  setIsPolling,
  setIsPrePolling,
  s3Key,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sentenceLabels, setSentenceLabels] = useState<SentenceLabels>({});
  const [newLabels, setNewLabels] = useState<Record<string, string>>({});
  const [tempNewLabel, setTempNewLabel] = useState('');
  const pollingCounter = useRef(0);
  const retrainCount = useRef(0);

  const pollForTrainingResults = async () => {
    let counter = pollingCounter.current;
    counter += 1;
    pollingCounter.current = counter;
    setError('');
    if (pollingCounter.current < 15 && isPolling && s3Key) {
      let s3_polling_key = s3Key;
      if (retrainCount.current > 0) {
        s3_polling_key = `${s3Key}-v${retrainCount.current}`;
      }
      await callAPIAsync('/admin/pollS3ActiveLearning', {
        key: s3_polling_key,
        isApiResponse: false,
      })
        .then((res: SentenceLabels | number) => {
          if (res === 202) {
            setTimeout(() => {
              void pollForTrainingResults();
            }, 45000);
          } else {
            if (res instanceof Object) {
              setSentenceLabels(res);
            }
            setIsPolling(false);
            pollingCounter.current = 0;
          }
        })
        .catch((err: unknown) => {
          setSuccess('');
          setError('Polling for training results failed.');
          setIsPolling(false);
          pollingCounter.current = 0;
          console.log(err);
        });
    } else {
      setSuccess('');
      setError('Polling for training results failed.');
      setIsPolling(false);
      pollingCounter.current = 0;
    }
  };

  useEffect(() => {
    if (isPolling) {
      void pollForTrainingResults();
    }
  }, [isPolling]);

  const addLabel = () => {
    if (tempNewLabel) {
      const tempLabels = sentenceLabels;
      tempLabels[tempNewLabel] = [];
      setSentenceLabels({ ...tempLabels });
      setTempNewLabel('');
    }
  };

  const addLabelWrapper = (e: FormEvent) => {
    addLabel();
    e.preventDefault();
  };

  const storeTempLabel = (label: string, existingLabel: string) => {
    const tempNewLabels = newLabels;
    tempNewLabels[existingLabel] = label;
    setNewLabels(tempNewLabels);
  };

  const changeLabel = (existingLabel: string) => {
    const newLabel = newLabels[existingLabel];
    if (newLabel !== existingLabel) {
      const tempLabels = sentenceLabels;
      tempLabels[newLabel] = sentenceLabels[existingLabel];
      delete tempLabels[existingLabel];
      setSentenceLabels({ ...tempLabels });
    }
  };

  const anyLabels = () => {
    let anyLabelValues = false;
    Object.values(sentenceLabels).forEach((sentenceLabel) => {
      if (sentenceLabel.length > 0) {
        anyLabelValues = true;
      }
    });
    return Object.keys(sentenceLabels).length > 0 && anyLabelValues;
  };

  const exportToS3 = async () => {
    if (Object.keys(sentenceLabels).length > 0) {
      setIsLoading(true);
      setError('');
      const request = {
        labels: sentenceLabels,
      };
      await callAPIAsync('/admin/exportToS3', request)
        .then(() => {
          setSentenceLabels({});
          setIsLoading(false);
          setSuccess('Labels successfully submitted!');
        })
        .catch((err: unknown) => {
          setIsLoading(false);
          console.log(err);
          setError('Failed to submit labels.');
        });
    }
  };

  const retrainModel = async () => {
    if (Object.keys(sentenceLabels).length > 0) {
      const request = {
        key: s3Key,
        sentenceLabels,
        retrainCount: retrainCount.current + 1,
      };
      setIsLoading(true);
      await callAPIAsync('/admin/activeLearningRetrain', request)
        .then((res: number) => {
          setIsLoading(false);
          if (res === 202) {
            setSuccess('Retraining kicked off successfully');
            let counter = retrainCount.current;
            counter += 1;
            retrainCount.current = counter;
            setIsPrePolling(true);
            // wait 5 min to being polling
            setTimeout(() => {
              setIsPolling(true);
              setIsPrePolling(false);
            }, 300000);
          } else {
            setError('Error with kicking off retraining.');
          }
        })
        .catch((err: unknown) => {
          setIsLoading(false);
          console.log(err);
          setError('Error with kicking off retraining.');
        });
    }
  };

  const editableHeader = (label: string, i: number) => (
    <span>
      <Inplace closable onClose={(e) => changeLabel(label)}>
        <InplaceDisplay>
          {label}
          <Button
            icon='pi pi-pencil'
            className='p-button-rounded p-button-text edit-button'
          />
        </InplaceDisplay>
        <InplaceContent>
          <InputText
            value={newLabels[label]}
            onChange={(e) => storeTempLabel(e.target.value, label)}
            className='change-label-input'
          />
        </InplaceContent>
      </Inplace>
    </span>
  );

  return (
    <div className='activeLearningReview'>
      {Object.keys(sentenceLabels).length !== 0 ? (
        <>
          <AddCategoryDiv className='flex align-items-center justify-content-between'>
            <form className='p-inputgroup' onSubmit={(e) => addLabelWrapper(e)}>
              <Button label='Add Label' onClick={(e) => addLabel()} />
              <StyledInputText
                placeholder='Category'
                value={tempNewLabel}
                onChange={(e) => setTempNewLabel(e.target.value)}
              />
            </form>
            <Button
              label='Re-train model'
              disabled={!anyLabels()}
              className='p-button'
              onClick={() => {
                void retrainModel();
              }}
            />
            <Button
              label='Send labels to S3'
              disabled={!anyLabels()}
              className='p-button'
              onClick={() => {
                void exportToS3();
              }}
            />
          </AddCategoryDiv>

          <Accordion
            multiple
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            {Object.keys(sentenceLabels).map((label, i) => (
              <AccordionTab
                key={`accordion-${label}`}
                header={editableHeader(label, i)}
              >
                {sentenceLabels[label].map((sentence: LabeledSentence, ind) => (
                  <ReviewSentence
                    sentence={{ id: sentence.id, text: sentence.text }}
                    currentLabel={label}
                    labels={sentenceLabels}
                    setLabels={setSentenceLabels}
                    isProposalSentence={false}
                    isModelProposalSentence={true}
                    key={`review-sentence-${sentence.id}`}
                  />
                ))}
              </AccordionTab>
            ))}
          </Accordion>
        </>
      ) : (
        ''
      )}
    </div>
  );
};
