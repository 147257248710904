import React, { ReactElement } from 'react';
import { parseDates } from '../../libs/CaseDataService';

interface ConnectedCaseDetailResponse {
  connectionType: ('person' | 'structured') | null;
  connectingPerson?: {
    firstName?: string;
    lastName?: string;
    caseRoles?: string;
  };
}

export interface ConnectedCaseResponseRecord {
  caseId: number;
  connectedCase: {
    id: number;
    source_id: string;
    name: string;
    start_date: string | null;
    end_date: string | null;
    is_active: boolean;
  };
  details: ConnectedCaseDetailResponse[];
}

export interface ConnectedCase {
  caseId: number;
  sourceId: string;
  name: string;
  displayDateRange: string;
  startDate: string | null;
  closeDate: string | null;
}

export interface CaseConnectedByPersons extends ConnectedCase {
  people: Array<[string, string | null]>;
}

export interface ParsedConnectedCases {
  casesConnectedByPersons: CaseConnectedByPersons[],
  casesConnectedByStructure: ConnectedCase[]
}

const sortConnectedCasesByStartDate = (a: ConnectedCase, b: ConnectedCase) => {
  if (
    a.displayDateRange.split('–')[1] === 'Active' &&
    b.displayDateRange.split('–')[1] !== 'Active'
  ) {
    return -1;
  }
  if (
    a.displayDateRange.split('–')[1] !== 'Active' &&
    b.displayDateRange.split('–')[1] === 'Active'
  ) {
    return 1;
  }
  if (
    new Date(a.displayDateRange.split('–')[0]) >
    new Date(b.displayDateRange.split('–')[0])
  ) {
    return -1;
  }
  if (
    new Date(a.displayDateRange.split('–')[0]) <
    new Date(b.displayDateRange.split('–')[0])
  ) {
    return 1;
  }
  return 0;
};

export function parseConnectedCases(
  connectionRecords: ConnectedCaseResponseRecord[]
): ParsedConnectedCases {
  const formatDateRange = ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
    // The dash in the next line is an endash, &#8211; not a hyphen.
  }) => `${startDate}–${endDate}`;

  const casesConnectedByPersons: CaseConnectedByPersons[] = connectionRecords
  .map((record) => ({
    caseId: record.connectedCase.id,
    sourceId: record.connectedCase.source_id,
    name: record.connectedCase.name,
    displayDateRange: formatDateRange(parseDates(record.connectedCase.start_date,
                                                 record.connectedCase.end_date,
                                                 record.connectedCase.is_active)),
    startDate: parseDates(record.connectedCase.start_date,
                          record.connectedCase.end_date,
                          record.connectedCase.is_active).startDate,
    closeDate: parseDates(record.connectedCase.start_date,
                          record.connectedCase.end_date,
                          record.connectedCase.is_active).endDate,
    people: record.details
      .filter((detail) => detail.connectionType === 'person')
      .map((detail): [string, string | null] => {
        const fullName = `${detail.connectingPerson?.firstName ?? ''} ${detail.connectingPerson?.lastName ?? ''}`.trim();
        const role = detail.connectingPerson?.caseRoles ?? null;
        return [fullName, role];
      }),
  }))
  .filter((record) => record.people.length)
  .sort(sortConnectedCasesByStartDate);

  const casesConnectedByStructure: ConnectedCase[] = connectionRecords
    .filter((record) =>
      record.details.length === 0 || record.details.some((detail) => detail.connectionType === 'structured')
    )
    .map((record) => ({
      caseId: record.connectedCase.id,
      sourceId: record.connectedCase.source_id,
      name: record.connectedCase.name,
      startDate: parseDates(record.connectedCase.start_date,
                            record.connectedCase.end_date,
                            record.connectedCase.is_active).startDate,
      closeDate: parseDates(record.connectedCase.start_date,
                            record.connectedCase.end_date,
                            record.connectedCase.is_active).endDate,
      displayDateRange: formatDateRange(parseDates(record.connectedCase.start_date,
                                                   record.connectedCase.end_date,
                                                   record.connectedCase.is_active)),
    }))
    .sort(sortConnectedCasesByStartDate);

  return { casesConnectedByPersons, casesConnectedByStructure };
}