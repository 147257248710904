/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

// example: https://bl.ocks.org/interwebjill/8122dd08da9facf8c6ef6676be7da03f
// TODO: on render, redraw chart.  chart renders on parent state change.
export const AreaChart = ({
  keys,
  data,
  monthSpan,
  colors,
  forcedWidth,
  forcedHeight,
  partialLastMth,
  punctuateLastValue,
  showYAxis,
  observeHover,
  onHover,
  showXAxis,
}) => {
  const canvas = useRef(null);
  useEffect(() => {
    if (data) {
      // FIXME: code pulled from V1, has out of sync references
      /* eslint no-use-before-define: "off" */
      drawChart(
        keys,
        data,
        monthSpan,
        colors,
        forcedWidth,
        forcedHeight,
        partialLastMth,
        punctuateLastValue,
        showYAxis,
        observeHover,
        onHover,
        showXAxis
      );
      /* eslint no-use-before-define: "off" */
      window.addEventListener('resize', () => {
        drawChart(
          keys,
          data,
          monthSpan,
          colors,
          forcedWidth,
          forcedHeight,
          partialLastMth,
          punctuateLastValue,
          showYAxis,
          observeHover,
          onHover,
          showXAxis
        );
      });
    }
  });

  // TODO: add bold line on top of gradient
  // TODO: FIX THIS SHADOW RULE
  /* eslint no-shadow: off */
  const drawChart = (
    keys,
    data,
    monthSpan,
    colors,
    forcedWidth,
    forcedHeight,
    partialLastMth,
    punctuateLastValue,
    showYAxis,
    observeHover,
    onHover,
    showXAxis
  ) => {
    try {
      const strokeWidth = 2.5;
      const svg = d3.select(canvas.current);
      svg.selectAll('*').remove();
      const margin = {
        top: punctuateLastValue ? 6 : 0,
        right: 15,
        bottom: 20,
        left: showYAxis ? 50 : 15,
      };
      const height =
        (typeof forcedHeight !== 'undefined'
          ? parseInt(forcedHeight, 10)
          : canvas.current.getClientRects()[0].height) -
        margin.top -
        margin.bottom;
      const width =
        canvas.current.getClientRects()[0].width - margin.left - margin.right;
      const g = svg
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      const parseDate = d3.timeParse('%Y-%m');

      const formatDate = (rawDate) => {
        const formattedDateTimeFormat = new Intl.DateTimeFormat('en', {
          month: '2-digit',
          year: 'numeric',
        });
        const [
          { value: formattedMonth },
          ,
          { value: formattedYear },
        ] = formattedDateTimeFormat.formatToParts(new Date(rawDate));
        return `${formattedYear}-${formattedMonth}`;
      };

      const sources = keys.map((keyV, keyI) => ({
        line: colors[keyI].line,
        area: colors[keyI].area,
        values: data[keyI],
      }));

      if (typeof data !== 'undefined') {
        data.forEach((source, _sourceI) => {
          source.map((point) => {
            // TODO: why is this if needed?  rendering twice on case?
            // eslint-disable-next-line no-param-reassign
            point.parsedDate =
              typeof point.date === 'string'
                ? parseDate(point.date)
                : point.date;
            // NOTE: query graphs aren't normalized, but strengths and weaknesses are.
            // RECOMMENDATION: keep data representation consistent.  Normalize both or not at all.
            // RECOMMENDATION: don't normalize area charts.  normalization may make sense for bubble charts where a minimum click size is required, but this will give a false visual count display
            // eslint-disable-next-line no-param-reassign
            if (keys.length === 1) point.normalized_val = point.val;
            else
              point.normalized_val = // eslint-disable-line no-param-reassign
                2.0 / (1.0 + Math.exp(-0.3 * point.val)) - 1.0;

            return point;
          });
        });
      }

      // TODO: sorting twice.  optimize this
      const refCheck = sources[0].values.sort((item1, item2) =>
        item1.date > item2.date ? 1 : -1
      );
      let minDate = new Date(refCheck[0].parsedDate);
      const minDateFormatted = minDate.toISOString().slice(0, 7);
      const maxDate = new Date(
        refCheck[sources[0].values.length - 1].parsedDate
      );
      const minDateCheck = new Date(
        refCheck[sources[0].values.length - 1].parsedDate
      );
      minDateCheck.setFullYear(minDateCheck.getFullYear() - 2);
      if (minDateCheck > minDate) {
        minDate = minDateCheck;
        // eslint-disable-next-line no-param-reassign
        data = data.map((source, _sourceI) =>
          source.filter((sourceData) => sourceData.parsedDate >= minDate)
        );
      }
      // needed for keeping timeline in synch with other graphs
      // TODO: if not using last2 years, need to find common start date
      // eslint-disable-next-line no-param-reassign
      data = data
        .map((source, _sourceI) => {
          if (
            source.filter((sourceData) => sourceData.date === minDateFormatted)
              .length === 0
          ) {
            source.push({
              date: minDate.toISOString().slice(0, 7),
              val: 0,
              parsedDate: minDate,
            });
          }
          //                if (sources[sourceI].values.filter((sourceData) => parseDate(sourceData.date) === minDate).length === 0) {
          //                    sources[sourceI].values.push({date: minDate.toISOString().slice(0,7), val: 0, parsedDate: minDate})
          //                }
          //                sources[sourceI].values = sources[sourceI].values.sort((item1, item2) => {
          //                    return (item1.date > item2.date) ? 1 : -1
          //                })
          return source;
        })
        .sort((item1, item2) => (item1.date > item2.date ? 1 : -1));
      const dataMonthSpan =
        maxDate.getMonth() -
        minDate.getMonth() +
        12 * (maxDate.getFullYear() - minDate.getFullYear());
      //            if (typeof (monthSpan) === 'undefined' || dataMonthSpan > monthSpan) {
      // eslint-disable-next-line no-param-reassign
      monthSpan = dataMonthSpan;
      //            }
      // Setup the x axes labels  (one for year, one for month)
      // const skipMonths = (!monthSpan) ? 1 : (monthSpan < 4) ? 1 : ((monthSpan < 8) ? 2 : ((monthSpan < 12) ? 3 : (monthSpan < 24) ? 4 : (monthSpan < 48) ? 6 : 12))
      let skipMonths;
      if (showXAxis) {
        skipMonths = !monthSpan
          ? 1
          : monthSpan < 4
          ? 1
          : monthSpan < 8
          ? 1
          : monthSpan < 12
          ? 2
          : monthSpan < 24
          ? 3
          : monthSpan < 48
          ? 4
          : 12;
      }
      const x = d3
        .scaleTime()
        .domain(d3.extent(data[0], (d) => d.parsedDate))
        .range([0, width]);

      // TODO: consolidate zeros. backend fills in 0s where corresponding month is out, but does not fill in for empty months.  this is done on front end.
      // fill in blank months with 0s
      if (typeof data !== 'undefined') {
        data.forEach((source, _sourceI) => {
          x.ticks().forEach((pointDate) => {
            const formattedDate = formatDate(pointDate);
            let point = source.filter(
              (pointI) => pointI.date === formattedDate
            );
            point = point.length === 1 ? point[0] : {};
            // TODO: why is this if needed?  rendering twice on case?
            if (!point.date) {
              point.date = formattedDate;
              point.val = 0;
              point.parsedDate = pointDate;
              point.normalized_val = 0;
              source.push(point);
            }
          });
        });
      }

      const y = d3
        .scaleLinear()
        .domain([
          0,
          d3.max(sources, (c) => d3.max(c.values, (d) => d.normalized_val)),
        ])
        .range([height - 15, 0]);
      // const y = d3.scaleLinear().domain([0, 1]).range([height, 0])
      const areaColors = d3.scaleOrdinal().domain(keys).range(colors);
      const areaZ = areaColors.domain(sources.map((c) => c.area));

      const lineColors = d3.scaleOrdinal().domain(keys).range(colors);
      const lineZ = lineColors.domain(sources.map((c) => c.line));

      const area = d3
        .area()
        .curve(d3.curveMonotoneX)
        .x((d) => x(d.parsedDate))
        .y0(y(0))
        .y1((d) => y(d.normalized_val));

      const line = d3
        .line()
        .curve(d3.curveMonotoneX)
        .x((d) => x(d.parsedDate))
        .y((d) => y(d.normalized_val));

      // TODO: show last month if space
      if (showXAxis) {
        const xAxisMonthElems = g
          .append('g')
          .attr('transform', `translate(0,${height - 15})`)
          .attr('class', 'x-axis-months')
          .call(
            d3
              .axisBottom(x)
              .ticks(d3.timeMonth.every(1))
              .tickFormat((d, i) =>
                i === 0 || i % skipMonths === 0 || i === monthSpan
                  ? new Date(d).toLocaleString('default', { month: 'short' })
                  : ''
              )
          );
        xAxisMonthElems.selectAll('path').remove();
        xAxisMonthElems.selectAll('line').remove();

        // TODO: confirm the year labels still cooperate with with larger amounts of data past 2 years
        const xAxisYearElems = g
          .append('g')
          .attr('transform', `translate(0,${height})`)
          .attr('class', 'x-axis-years')
          .call(
            d3
              .axisBottom(x)
              .ticks(d3.timeMonth.every(1))
              .tickFormat((d, i) =>
                i === 0 || i === monthSpan ? new Date(d).getFullYear() : ''
              )
          );

        xAxisYearElems.selectAll('path').remove();
        xAxisYearElems.selectAll('line').remove();
      }

      if (showYAxis) {
        const yAxis = d3
          .axisLeft()
          .scale(y)
          .ticks(2)
          .tickFormat((x) =>
            Math.floor(x)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          );
        svg
          .append('g')
          .attr(
            'transform',
            `translate(${margin.left - 2}, ${margin.top + strokeWidth - 2})`
          )
          .attr('class', 'y-axis-ticks')
          .call(yAxis);
      }

      // TODO: look into stackinghttps://www.d3-graph-gallery.com/graph/stackedarea_template.html
      // add areas

      // TODO: change method of using url with id as they're not unique.  reconfigure with line logic
      // Red Gradient
      // RECOMMENDATION, don't alter bottom color based on 1 or multiple values
      svg.selectAll('linearGradient').remove();
      svg.selectAll('path').remove();
      svg.selectAll('stop').remove();
      svg.selectAll('circle').remove();
      svg
        .append('linearGradient')
        .attr('id', 'red-gradient')
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0)
        .attr('y1', 110)
        .attr('x2', 0)
        .attr('y2', 0)
        .selectAll('stop')
        .data([
          { offset: '0%', color: keys.length === 1 ? '#FFFCFC' : '#FFF0F0' },
          { offset: '100%', color: '#FFCACA' },
        ])
        .enter()
        .append('stop')
        .attr('offset', (d) => d.offset)
        .attr('stop-color', (d) => d.color);

      // Green Gradient
      svg
        .append('linearGradient')
        .attr('id', 'green-gradient')
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0)
        .attr('y1', 110)
        .attr('x2', 0)
        .attr('y2', 0)
        .selectAll('stop')
        .data([
          { offset: '0%', color: keys.length === 1 ? '#FFFCFC' : '#F0FFF0' },
          { offset: '100%', color: '#CAFFCA' },
        ])
        .enter()
        .append('stop')
        .attr('offset', (d) => d.offset)
        .attr('stop-color', (d) => d.color);

      // RECOMMENDATION: add lines on top of areas so we can clearly see the line trajectories
      sources.forEach((dataV, _dataI) => {
        // eslint-disable-next-line no-param-reassign
        dataV.values = dataV.values.filter(
          (entry) => entry.parsedDate >= minDate
        );
        if (
          dataV.values.filter((entry) => entry.date === minDateFormatted)
            .length === 0
        ) {
          dataV.values.push({
            date: minDate.toISOString().slice(0, 7),
            val: 0,
            parsedDate: minDate,
            normalized_val: 0,
          });
        }
        // eslint-disable-next-line no-param-reassign
        dataV.values = dataV.values.sort((item1, item2) =>
          item1.date > item2.date ? 1 : -1
        );
        if (partialLastMth && dataV.values.length >= 2) {
          svg
            .append('path')
            .datum(dataV)
            .attr('d', (d) => area(d.values.slice(0, d.values.length - 1)))
            .attr('fill-opacity', '0.7')
            .attr('fill', (d) => areaZ(d.area).area)
            .attr(
              'transform',
              `translate(${margin.left}, ${margin.top + strokeWidth})`
            );
          svg
            .append('path')
            .datum(dataV)
            .attr('d', (d) => area(d.values.slice(d.values.length - 2)))
            .attr('fill-opacity', '0.7')
            .attr('fill', (d) => areaZ(d.area).area)
            .attr(
              'transform',
              `translate(${margin.left}, ${margin.top + strokeWidth})`
            );

          svg
            .append('path')
            .datum(dataV)
            .attr('d', (d) => line(d.values.slice(0, d.values.length - 1)))
            .attr('stroke-width', `${strokeWidth}px`)
            .attr('fill', 'none')
            .attr('stroke-opacity', '0.7')
            .attr('stroke', (d) => lineZ(d.line).line)
            .attr(
              'transform',
              `translate(${margin.left}, ${margin.top + strokeWidth / 2})`
            );

          svg
            .append('path')
            .datum(dataV)
            .attr('d', (d) => line(d.values.slice(d.values.length - 2)))
            .attr('stroke-width', `${strokeWidth}px`)
            .attr('fill', 'none')
            .attr('stroke-opacity', '0.7')
            .attr('stroke', (d) => lineZ(d.line).line)
            .attr(
              'transform',
              `translate(${margin.left}, ${margin.top + strokeWidth / 2})`
            )
            .style('stroke-dasharray', '4 4');
        } else {
          svg
            .append('path')
            .datum(dataV)
            .attr('d', (d) => area(d.values))
            .attr('fill-opacity', '0.7')
            .attr('fill', (d) => areaZ(d.area).area)
            .attr(
              'transform',
              `translate(${margin.left}, ${margin.top + strokeWidth})`
            );

          svg
            .append('path')
            .datum(dataV)
            .attr('d', (d) => line(d.values))
            .attr('stroke-width', `${strokeWidth}px`)
            .attr('fill', 'none')
            .attr('stroke-opacity', '0.7')
            .attr('stroke', (d) => lineZ(d.line).line)
            .attr(
              'transform',
              `translate(${margin.left}, ${margin.top + strokeWidth / 2})`
            );
        }

        // this is a circle we can re-display and move around in order to highlight on hover
        const firstValue = dataV.values[0]; // a new value will get swapped in when we draw this later, on hover
        const hoverCircle = svg
          .append('circle')
          .attr('transform', `translate(${margin.left},${margin.top})`)
          .style('display', 'none')
          .data([firstValue])
          .attr('r', 4)
          .attr('cx', x(firstValue.parsedDate))
          .attr('cy', y(firstValue.normalized_val))
          .attr('stroke', (d) => lineZ(d.line).line)
          .attr('stroke-width', `${strokeWidth}px`)
          .attr('fill', (d) => areaZ(d.area).area);

        // throw dot on last value?
        let punctuationCircle = null;
        if (punctuateLastValue && partialLastMth && dataV.values.length >= 2) {
          const lastValue = dataV.values[dataV.values.length - 2];
          punctuationCircle = svg
            .append('circle')
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .data([lastValue])
            .attr('r', 4)
            .attr('cx', x(lastValue.parsedDate))
            .attr('cy', y(lastValue.normalized_val))
            .attr('stroke', (d) => lineZ(d.line).line)
            .attr('stroke-width', `${strokeWidth}px`)
            .attr('fill', (d) => areaZ(d.area).area);
        } else if (punctuateLastValue && !partialLastMth) {
          const lastValue = dataV.values[dataV.values.length - 1];
          punctuationCircle = svg
            .append('circle')
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .data([lastValue])
            .attr('r', 4)
            .attr('cx', x(lastValue.parsedDate))
            .attr('cy', y(lastValue.normalized_val))
            .attr('stroke', (d) => lineZ(d.line).line)
            .attr('stroke-width', `${strokeWidth}px`)
            .attr('fill', (d) => areaZ(d.area).area);
        }

        if (observeHover) {
          const bisectDate = d3.bisector((d) => d.parsedDate).left;
          const mousemove = (e) => {
            const dim = e.target.getBoundingClientRect();
            if (dim.width === 0) return; // the browser is in some weird state...
            // eslint-disable-next-line no-unused-vars
            const [x0, y0] = d3.pointer(e);
            if (x0 < 0) return;
            const parsedDate0 = x.invert(x0);
            const i1 = bisectDate(data[0], parsedDate0);
            const d0 = data[0][i1 - 1];
            const d1 = data[0][i1];
            const [d] =
              d0 && !d1
                ? [d0, i1 - 1]
                : !d0 && d1
                ? [d1, i1]
                : parsedDate0 - d0.parsedDate > d1.parsedDate - parsedDate0
                ? [d1, i1]
                : [d0, i1 - 1];
            if (punctuationCircle) punctuationCircle.style('display', 'none');
            hoverCircle
              .style('display', 'block')
              .attr('cx', x(d.parsedDate))
              .attr('cy', y(d.normalized_val));
            onHover({ hover: true, date: d.parsedDate, value: d.val });
          };
          const mouseOverOrOut = () => {
            hoverCircle.style('display', 'none');
            if (punctuationCircle) punctuationCircle.style('display', 'block');
            onHover({ hover: false });
          };

          svg
            .append('rect')
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .attr('class', 'overlay')
            .attr('width', width)
            .attr('height', height)
            .on('mouseover', mouseOverOrOut)
            .on('mouseout', mouseOverOrOut)
            .on('mousemove', mousemove);
        }
      });
    } catch (ex) {
      console.log('chart error', ex);
      d3.select(canvas.current).selectAll('*').remove();
      d3.select(canvas.current)
        .append('text')
        .attr('class', 'error')
        .attr('x', '30px')
        .attr('y', '30px')
        .text('Missing Chart');
    }
  };
  return <svg ref={canvas} style={{ width: '100%', height: forcedHeight }} />;
};
