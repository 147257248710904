/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { callAPI } from '../../libs/API';
import { Page, LoadingContainer } from '../../components-new';

// TODO: manager column or org view
// TODO: create a middle component to extend for simple functions like displayError
export default class AdminQueryGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      filterLoaded: true,
      queryGroups: [],
      //            profile: this.props.profile,
      impersonation: this.props.impersonation,
      sortField: 'name',
      sortOrder: 1,
      error: '',
      isAIAdmin: this.props.profile.user_type === 'augintel_admin',
    };

    if (!this.state.isAIAdmin || this.state.impersonation)
      this.props.history.push('/');

    this.toast = React.createRef();
    this.loadData = this.loadData.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.displayError = this.displayError.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  // not adding filters or pagination as of yet since there's a low load of query groups
  loadData() {
    this.setState({ filterLoaded: false }, () => {
      callAPI(
        '/admin/queryGroups',
        null,
        (resp) => {
          if (typeof resp.queryGroups !== 'undefined') {
            this.setState({
              isLoaded: true,
              queryGroups: resp.queryGroups !== null ? resp.queryGroups : [],
              filterLoaded: true,
            });
          } else {
            this.displayError({ message: 'Query Groups not found' });
          }
        },
        (error) => {
          this.displayError(error);
        }
      );
    });
  }

  updateItem(itemID, fieldName, newValue) {
    const queryGroup = this.state.queryGroups.filter(
      (item) => item.id === itemID
    )[0];
    const updated =
      typeof queryGroup.updating !== 'undefined' &&
      queryGroup.updating[fieldName] !== newValue;
    if (updated) {
      const updatedValues = {};
      updatedValues[fieldName] = newValue;
      callAPI(
        `/admin/queryGroups/${itemID}/update`,
        updatedValues,
        (resp) => {
          if (resp.success) {
            this.setState({
              queryGroups: this.state.queryGroups.map((item) => {
                if (item.id === itemID) {
                  delete item.updating[fieldName];
                }
                return item;
              }),
            });
          } else {
            this.displayError({ message: 'Query Group could not be updated' });
          }
        },
        (error) => {
          this.displayError(error);
        }
      );
    }
  }

  updateValue(itemID, fieldName, newValue) {
    this.setState({
      queryGroups: this.state.queryGroups.map((item) => {
        if (item.id === itemID) {
          if (typeof item.updating === 'undefined') item.updating = [];
          if (typeof item.updating[fieldName] === 'undefined')
            item.updating[fieldName] = item[fieldName];
          item[fieldName] = newValue;
        }
        return item;
      }),
    });
  }


  displayError(error) {
    this.setState({
      isLoaded: true,
      error: error.message,
    });
    this.toast.current.show({ severity: 'error', summary: error.message });
  }

  render() {

    const headingValidator = (props) => {
      const { newValue, field, rowData } = props
      const isValid =
        newValue.length > 0 &&
        newValue.replace(/[a-z A-Z0-9-]*/, '') === '' &&
        newValue.trim() !== '';
      if (isValid) {
        this.updateValue(
          rowData.id,
          field,
          newValue
        );
        this.updateItem(
          rowData.id,
          field,
          newValue
        );
      }
      return isValid;
    };

    const headingEditor = (options) => (
      <InputText
        value={options.value}
        keyfilter={/^[a-z A-Z0-9-]+$/}
        onChange={(e) => {
          options.editorCallback(e.target.value)
          this.updateValue(options.rowData.id, 'heading', e.target.value);
        }}
      />
    );

    const sortOrderValidator = (props) => {
      const { newValue, field, rowData } = props
      const isValid =
        newValue.length > 0 &&
        newValue.replace(/[0-9]*/, '') === '' &&
        newValue.trim() !== '';
      if (isValid) {
        this.updateValue(
          rowData.id,
          field,
          newValue
        );
        this.updateItem(
          rowData.id,
          field,
          newValue
        );
      }
      return isValid;
    };

    const sortOrderEditor = (options) => (
      <InputText
        value={options.value}
        keyfilter={/^[0-9]+$/}
        onChange={(e) => {
          options.editorCallback(e.target.value)
          this.updateValue(options.rowData.id, 'sort_order', e.target.value);
        }}
      />
    );

    return (
      <Page
        title='Query Groups'
        pageTitle='Query Groups Admin | Augintel'
        isLoaded={this.state.isLoaded}
        toast={this.toast}
        error={this.state.error}
        headerSpacing={20}
      >
        <div className='adminQueryGroupsBody grid'>
          <LoadingContainer isLoaded={this.state.filterLoaded}>
            <DataTable
              value={this.state.queryGroups}
              sortField={this.state.sortField}
              sortOrder={this.state.sortOrder}
              filterDisplay='row'
              resizableColumns
              editMode='cell'
            >
              <Column
                field='heading'
                header='Heading'
                filter
                filterMatchMode='contains'
                filterPlaceholder='Filter Headers'
                showFilterMenu={false}
                editor={headingEditor}
                cellEditValidator={headingValidator}
                sortable
                body={(rowData) => (
                  <span
                    className={
                      rowData.updating?.heading &&
                      rowData.updating?.heading !== rowData.heading
                        ? 'updating'
                        : ''
                    }
                  >
                    {rowData.heading}
                  </span>
                )}
              />
              <Column
                field='agency_name'
                header='Agency'
                filter
                filterMatchMode='contains'
                filterPlaceholder='Filter Agencies'
                showFilterMenu={false}
                sortable
              />
              <Column
                field='sort_order'
                header='Sort Order'
                filter
                filterMatchMode='equals'
                filterPlaceholder='Filter Sort Order'
                showFilterMenu={false}
                editor={sortOrderEditor}
                cellEditValidator={sortOrderValidator}
                sortable
                body={(rowData) => (
                  <span
                    className={
                      rowData.updating?.sort_order &&
                      rowData.updating?.sort_order !== rowData.sort_order
                        ? 'updating'
                        : ''
                    }
                  >
                    {rowData.sort_order}
                  </span>
                )}
                style={{ width: '9em' }}
              />
            </DataTable>
          </LoadingContainer>
        </div>
      </Page>
    );
  }
}
