/* eslint-disable no-param-reassign */
import React, { Component } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { callAPI } from '../../libs/API';
import { Page } from '../../components-new';

// TODO: manager column or org view
// TODO: create a middle component to extend for simple functions like displayError
export default class AdminQueries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      filterLoaded: true,
      queries: [],
      impersonation: this.props.impersonation,
      sortField: 'name',
      sortOrder: 1,
      deleteName: '',
      error: '',
      isAIAdmin: this.props.profile.user_type === 'augintel_admin',
    };

    if (!this.state.isAIAdmin || this.state.impersonation)
      this.props.history.push('/');

    this.loadData = this.loadData.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.displayError = this.displayError.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  // not adding filters or pagination as of yet since there's a low load of queries
  loadData() {
    this.setState({ filterLoaded: false }, () => {
      callAPI(
        '/admin/queries',
        null,
        (resp) => {
          if (typeof resp.queries !== 'undefined') {
            const queries = resp.queries !== null ? resp.queries : [];
            // .replace(/[\/\(\)\']/g, "\\$&");
            queries.forEach((query) => {
              query.definitionString = JSON.stringify(query.definition);
            });
            this.setState({
              isLoaded: true,
              queries,
              filterLoaded: true,
            });
          } else {
            this.displayError({ message: 'Queries not found' });
          }
        },
        (error) => {
          this.displayError(error);
        }
      );

      this.queryGroupsTimer = setTimeout(
        () => {
          callAPI(
            '/admin/queryGroups',
            null,
            (resp) => {
              if (typeof resp.queryGroups !== 'undefined') {
                this.setState({
                  queryGroups:
                    resp.queryGroups !== null ? resp.queryGroups : [],
                });
              } else {
                this.displayError({ message: 'Query Groups not found' });
              }
            },
            (error) => {
              this.displayError(error);
            }
          );
        },
        window.location.href.indexOf('localhost') > -1 ? 3000 : 0
      ); // timeout required for login
    });
  }

  updateItem(itemID, fieldName, newValue) {
    const query = this.state.queries.filter((item) => item.id === itemID)[0];
    const updated =
      typeof query.updating !== 'undefined' &&
      query.updating[fieldName] !== newValue;
    if (updated) {
      const updatedValues = {};
      updatedValues[fieldName] = newValue;
      callAPI(
        `/admin/queries/${itemID}/update`,
        updatedValues,
        (resp) => {
          if (resp.success) {
            this.setState({
              queries: this.state.queries.map((item) => {
                if (item.id === itemID) {
                  delete item.updating[fieldName];
                  if (fieldName === 'definition')
                    delete item.updating.definitionString;
                  if (fieldName === 'query_group_id')
                    delete item.updating.heading;
                }
                return item;
              }),
            });
          } else {
            this.displayError({ message: 'Query could not be updated' });
          }
        },
        (error) => {
          this.displayError(error);
        }
      );
    }
  }

  // TODO: don't update if not different
  updateValue(itemID, fieldName, newValue) {
    this.setState({
      queries: this.state.queries.map((item) => {
        if (item.id === itemID) {
          if (typeof item.updating === 'undefined') item.updating = [];
          if (typeof item.updating[fieldName] === 'undefined')
            item.updating[fieldName] = item[fieldName];
          if (fieldName === 'query_group_id') {
            const heading = this.state?.queryGroups.filter(
              (qgItem) => qgItem.id === newValue
            )[0].heading;
            item.updating.heading = item.heading;
            item.heading = heading;
          }
          item[fieldName] = newValue;
        }
        return item;
      }),
    });
  }


  displayError(error) {
    this.setState({
      isLoaded: true,
      error: error.message,
    });
  }

  render() {
    const titleValidator = (props) => {
      const { newValue, field, rowData } = props
      const isValid =
        newValue.length > 0 &&
        newValue.replace(/[a-z A-Z0-9-&]*/, '') === '' &&
        newValue.trim() !== '';
      if (isValid) {
        this.updateValue(
          rowData.id,
          field,
          newValue
        );
        this.updateItem(
          rowData.id,
          field,
          newValue
        );
      }
      return isValid;
    };

    const titleEditor = (options) => (
      <InputText
        value={options.value}
        keyfilter={/^[a-z A-Z0-9-&]+$/}
        onChange={(e) => {
          options.editorCallback(e.target.value)
          this.updateValue(
            options.rowData.id,
            options.field,
            e.target.value
          );
        }}
      />
    );

    const queryGroupEditor = (options) => {
      const { rowData } = options
      const queryGroupOptions = this.state.queryGroups
        ? this.state.queryGroups.filter(
            (qgItem) => qgItem.agency_id === rowData.agency_id
          )
        : [{ id: '', heading: 'Query Groups loading' }];
      return (
        <Dropdown
          value={rowData.query_group_id}
          options={queryGroupOptions}
          optionLabel='heading'
          optionValue='id'
          onChange={(e) => {
            this.updateValue(rowData.id, 'query_group_id', e.value);
            this.updateItem(rowData.id, 'query_group_id', e.value);
          }}
        />
      );
    };

    const isAlertEditor = (options) => {
      const { rowData } = options
      const isAlertOptions = [
        { label: 'Alert', value: true },
        { label: 'Power Search', value: false },
      ];
      return (
        <Dropdown
          value={rowData.is_alert}
          options={isAlertOptions}
          onChange={(e) => {
            this.updateValue(rowData.id, 'is_alert', e.value);
            this.updateItem(rowData.id, 'is_alert', e.value);
          }}
        />
      );
    };

    const sortOrderValidator = (props) => {
      const { newValue, field, rowData } = props
      const isValid =
        newValue.length > 0 &&
        newValue.replace(/[0-9]*/, '') === '' &&
        newValue.trim() !== '';
      if (isValid) {
        this.updateValue(
          rowData.id,
          field,
          newValue
        );
        this.updateItem(
          rowData.id,
          field,
          newValue
        );
      }
      return isValid;
    };

    const sortOrderEditor = (options) => (
      <InputText
        value={options.value}
        keyfilter={/^[0-9]+$/}
        onChange={(e) => {
          options.editorCallback(e.target.value)
          this.updateValue(options.rowData.id, 'sort_order', e.target.value);
        }}
      />
    );

    const definitionValidator = (props) => {
      try {
        const { newValue, field, rowData } = props
        JSON.parse(newValue);
        this.updateValue(
          props.rowData.id,
          field,
          newValue
        );
        this.updateValue(rowData.id, 'definition', newValue);
        this.updateItem(rowData.id, 'definition', newValue);
        return true;
      } catch (ex) {
        this.toast.show({
          severity: 'error',
          summary: 'Invalid JSON',
          detail: '',
        });
        return false;
      }
    };

    const definitionEditor = (options) => (
      <InputTextarea
        rows={5}
        cols={30}
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value)
          this.updateValue(
            options.rowData.id,
            'definitionString',
            e.target.value
          );
        }}
      />
    );

    const onEditorInit = () => {
      try {
        document
          .getElementsByClassName('p-cell-editing')[0]
          .getElementsByClassName('p-dropdown-trigger')[0]
          .click();
      } catch (ex) {
        // do nothing
      }
    };

    const onIsAlertEditorInit = () => {
      try {
        document
          .getElementsByClassName('p-cell-editing')[0]
          .getElementsByClassName('p-dropdown-trigger')[0]
          .click();
      } catch (ex) {
        // do nothing
      }
    };

    return (
      <Page
        title='Queries'
        pageTitle='Queries | Augintel'
        isLoaded={this.state.isLoaded}
        error={this.state.error}
      >
        <div className='adminQueriesBody grid'>
          <DataTable
            value={this.state.queries}
            loading={!this.state.filterLoaded}
            sortField={this.state.sortField}
            sortOrder={this.state.sortOrder}
            filterDisplay='row'
            resizableColumns
            editMode='cell'
          >
            <Column
              field='title'
              header='Title'
              filter
              filterMatchMode='contains'
              filterPlaceholder='Filter Title'
              showFilterMenu={false}
              editor={titleEditor}
              cellEditValidator={titleValidator}
              sortable
              body={(rowData) => (
                <span
                  className={
                    rowData.updating?.title &&
                    rowData.updating?.title !== rowData.title
                      ? 'updating'
                      : ''
                  }
                >
                  {rowData.title}
                </span>
              )}
            />
            <Column
              field='subtitle'
              header='Subtitle'
              filter
              filterMatchMode='contains'
              filterPlaceholder='Filter Subtitle'
              showFilterMenu={false}
              editor={titleEditor}
              cellEditValidator={titleValidator}
              sortable
              body={(rowData) => (
                <span
                  className={
                    rowData.updating?.subtitle &&
                    rowData.updating?.subtitle !== rowData.subtitle
                      ? 'updating'
                      : ''
                  }
                >
                  {rowData.subtitle}
                </span>
              )}
            />
            <Column
              field='agency_name'
              header='Agency'
              filter
              filterMatchMode='contains'
              filterPlaceholder='Filter Agencies'
              showFilterMenu={false}
              sortable
            />
            <Column
              field='heading'
              header='Query Group'
              filter
              filterMatchMode='contains'
              filterPlaceholder='Filter Query Group'
              showFilterMenu={false}
              editor={queryGroupEditor}
              onCellEditInit={onEditorInit}
              sortable
              body={(rowData) => (
                <span
                  className={
                    rowData.updating?.heading &&
                    rowData.updating?.heading !== rowData.heading
                      ? 'updating'
                      : ''
                  }
                >
                  {rowData.heading}
                </span>
              )}
            />
            <Column
              field='sort_order'
              header='Sort Order'
              filter
              filterMatchMode='equals'
              filterPlaceholder='Filter Sort Order'
              showFilterMenu={false}
              editor={sortOrderEditor}
              cellEditValidator={sortOrderValidator}
              sortable
              body={(rowData) => (
                <span
                  className={
                    rowData.updating?.sort_order &&
                    rowData.updating?.sort_order !== rowData.sort_order
                      ? 'updating'
                      : ''
                  }
                >
                  {rowData.sort_order}
                </span>
              )}
              style={{ width: '9em' }}
            />
            <Column
              field='is_alert'
              header='Alert'
              sortable
              editor={isAlertEditor}
              onCellEditInit={onIsAlertEditorInit}
              body={(rowData) => (
                <span
                  className={
                    rowData.updating?.is_alert &&
                    rowData.updating?.is_alert !== rowData.is_alert
                      ? 'updating'
                      : ''
                  }
                >
                  {rowData.is_alert ? 'Alert' : 'Power Search'}
                </span>
              )}
              style={{ width: '9em' }}
            />
            <Column
              field='definitionString'
              header='Definition'
              filter
              filterMatchMode='contains'
              filterPlaceholder='Filter Definition'
              showFilterMenu={false}
              editor={definitionEditor}
              cellEditValidator={definitionValidator}
              sortable={false}
              body={(rowData) => (
                <span
                  className={
                    rowData.updating?.definitionString &&
                    rowData.updating?.definitionString !==
                      rowData.definitionString
                      ? 'updating'
                      : ''
                  }
                >
                  {rowData.definitionString}
                </span>
              )}
            />
          </DataTable>
        </div>
      </Page>
    );
  }
}
