import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import './DateRange.scss';
import { Slider } from 'primereact/slider';
import type { SearchDateRange } from '../../shared/types/caseTypes';
import { useDebounce } from '../../libs/UseDebounce';
import mixpanel from '../../libs/Mixpanel';
import useWindowDimensions from '../../utils/useWindowDimensions';

interface DateRangeProps {
  searchDateRange: SearchDateRange;
  noteDates: { startDate: string | null; endDate: string | null };
  setSearchDateRange: (searchDateRange: SearchDateRange) => void;
}

export function getMonthRange(startDate: Date, endDate: Date) {
  const yearsDifference = endDate.getFullYear() - startDate.getFullYear();
  const monthsDifference = endDate.getMonth() - startDate.getMonth();
  const monthCorrection = 0;
  return yearsDifference * 12 + monthsDifference + monthCorrection;
}

export const DateRange = forwardRef(
  ({ noteDates, searchDateRange, setSearchDateRange }: DateRangeProps, ref) => {
    const notesStartDate = noteDates.startDate
      ? new Date(noteDates.startDate)
      : new Date();
    const notesEndDate = noteDates.endDate
      ? new Date(noteDates.endDate)
      : new Date();
    const totalNumMonths = getMonthRange(notesStartDate, notesEndDate);
    const [dateRange, setDateRange] = useState([0, totalNumMonths] as [
      number,
      number
    ]);
    const [startDateDisplay, setStartDateDisplay] = useState(
      notesStartDate.toLocaleString('default', {
        month: 'short',
        year: 'numeric',
      })
    );
    const [endDateDisplay, setEndDateDisplay] = useState(
      notesEndDate.toLocaleString('default', {
        month: 'short',
        year: 'numeric',
      })
    );
    const [isSliderActive, setIsSliderActive] = useState<[boolean, boolean]>([
      false,
      false,
    ]);
    const debouncedDateRange = useDebounce<[number, number]>(dateRange, 300);
    // NOTE: I know this is not good react but doing this for now because I did not want to add more things to CaseNotes and haven't broken that one up yet
    const controlledFromParent = useRef(false);
    const { height } = useWindowDimensions();
    const [isOpen, setIsOpen] = useState(height > 800);

    const getSelectedDate = (numMonths: number, isLabel: boolean) => {
      if (numMonths === totalNumMonths) {
        return notesEndDate.toLocaleString('default', {
          month: isLabel ? 'short' : 'numeric',
          year: 'numeric',
        });
      }
      const newDate = new Date(notesStartDate);
      newDate.setMonth(newDate.getMonth() + numMonths);
      return newDate.toLocaleString('default', {
        month: isLabel ? 'short' : 'numeric',
        year: 'numeric',
      });
    };

    useEffect(() => {
      if (
        searchDateRange.startMonth !== '' &&
        searchDateRange.endMonth !== ''
      ) {
        // if a bubble is selected in timelines (people, risks, etc), update the date range here
        // start and end date will be the same if bubble is clicked
        controlledFromParent.current = true;
        if (
          searchDateRange.startMonth === searchDateRange.endMonth &&
          searchDateRange.startYear === searchDateRange.endYear
        ) {
          const newDate = new Date();
          newDate.setFullYear(Number(searchDateRange.startYear));
          newDate.setMonth(Number(searchDateRange.startMonth) - 1);
          const rangeFromStart = getMonthRange(notesStartDate, newDate);
          setStartDateDisplay(getSelectedDate(rangeFromStart, true));
          setEndDateDisplay(getSelectedDate(rangeFromStart, true));
          setDateRange([rangeFromStart, rangeFromStart]);
        }
      }
    }, [searchDateRange]);

    const clearDateRange = () => {
      const newSearchDateRange = {
        startMonth: '',
        startYear: '',
        endMonth: '',
        endYear: '',
      };
      setSearchDateRange(newSearchDateRange);
      setDateRange([0, totalNumMonths]);
      setStartDateDisplay(getSelectedDate(0, true));
      setEndDateDisplay(getSelectedDate(totalNumMonths, true));
    };

    useImperativeHandle(ref, () => ({
      clearDate() {
        clearDateRange();
      },
    }));

    const applyDateRange = () => {
      const newSearchDateRange = {
        startMonth: '',
        startYear: '',
        endMonth: '',
        endYear: '',
      };
      // send empty params if min/max are chosen
      if (debouncedDateRange[0] !== 0) {
        const newStartDate = getSelectedDate(debouncedDateRange[0], false);
        [newSearchDateRange.startMonth, newSearchDateRange.startYear] =
          newStartDate.split('/');
        newSearchDateRange.startMonth = newSearchDateRange.startMonth.padStart(
          2,
          '0'
        );
      }
      if (debouncedDateRange[1] !== totalNumMonths) {
        const newEndDate = getSelectedDate(debouncedDateRange[1], false);
        [newSearchDateRange.endMonth, newSearchDateRange.endYear] =
          newEndDate.split('/');
        newSearchDateRange.endMonth = newSearchDateRange.endMonth.padStart(
          2,
          '0'
        );
      }
      setSearchDateRange(newSearchDateRange);
    };

    useEffect(() => {
      if (debouncedDateRange && !controlledFromParent.current) {
        applyDateRange();
      }
    }, [debouncedDateRange]);

    const onSliderChange = (value: [number, number]) => {
      setIsSliderActive([true, true]);
      controlledFromParent.current = false;
      setStartDateDisplay(getSelectedDate(value[0], true));
      setEndDateDisplay(getSelectedDate(value[1], true));
      setDateRange(value as [number, number]);
    };

    const onSlideEnd = () => {
      setIsSliderActive([false, false]);
    };

    const collapseSlider = (openStatus: boolean) => {
      mixpanel.track('toggle-date-range', {
        toggle: openStatus ? 'Opening date range' : 'Closing date range',
      });
      setIsOpen(openStatus);
    };

    const header = (
      <span className='flex'>
        <div>Filter Results by Date</div>
        <Button
          label='Clear'
          className='p-button-link'
          onClick={() => clearDateRange()}
        />
        <Button
          icon={`pi ${isOpen ? 'pi-chevron-up' : 'pi-chevron-down'}`}
          className={'date-range-toggler date-range-button p-button-text'}
          onClick={() => collapseSlider(!isOpen)}
          aria-label='Toggle Date Range Display'
          iconPos='right'
          label={`${isOpen ? 'Collapse' : 'Expand'}`}
        />
      </span>
    );

    return (
      <Card className='date-range-card' header={header}>
        {isOpen ? (
          <>
            <Slider
              value={dateRange}
              min={0}
              max={totalNumMonths}
              onChange={(e) => onSliderChange(e.value as [number, number])}
              range
              className='date-slider'
              onSlideEnd={() => onSlideEnd()}
            />

            <span className='flex justify-content-between label-container'>
              <small
                className={`date-range-labels ${
                  isSliderActive[0] ? 'active' : 'inactive'
                }`}
              >
                {startDateDisplay}
              </small>
              <small
                className={`date-range-labels ${
                  isSliderActive[1] ? 'active' : 'inactive'
                }`}
              >
                {endDateDisplay}
              </small>
            </span>
          </>
        ) : (
          ''
        )}
      </Card>
    );
  }
);
