import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import styled from 'styled-components/macro';
import { Sentence, SentenceLabels } from '../../shared/types/labelingTypes';
import LabelingOverlay from './LabelingOverlay';

const StyledButton = styled(Button)`
  padding: 0.5em;
  overflow: visible;
`;

const SentenceDiv = styled.div`
  padding: 0.5em 0.5em 0.5em 0em;
`;

type SentenceProps = {
  sentence: Sentence;
  phrases: string[];
  labels: SentenceLabels;
  setLabels: Dispatch<SetStateAction<SentenceLabels>>;
  getSimilarSentencesForLabel: (label: string) => void;
};

export default function LabelingSentence({
  sentence,
  phrases,
  labels,
  setLabels,
  getSimilarSentencesForLabel,
}: SentenceProps) {
  const overlay = useRef<OverlayPanel>(null);
  const [isLabeled, setIsLabeled] = useState(false);

  useEffect(() => {
    let sentenceIsLabeled = false;
    Object.values(labels).forEach((labeledSentences) => {
      labeledSentences.forEach((labeledSentence) => {
        if (labeledSentence.id === sentence.id) {
          sentenceIsLabeled = true;
        }
      });
    });
    setIsLabeled(sentenceIsLabeled);
  }, [labels]);

  const text = sentence.text.split(' ').map((word: string, index) => {
    let matchesPhrase = false;
    phrases.forEach((phrase) => {
      if (word.toLowerCase().includes(phrase.toLowerCase())) {
        matchesPhrase = true;
      }
    });
    if (matchesPhrase) {
      return (
        <span key={index} className='phraseMatch'>
          {index ? ' ' : ''}
          {word}
          &nbsp;
        </span>
      );
    }
    return (index ? ' ' : '') + word;
  });

  return (
    <SentenceDiv
      key={sentence.id}
      className='flex align-content-start justify-content-start'
    >
      <span className='align-items-center flex'>
        <span className={`showInline ${isLabeled ? 'bg-cyan-100' : ''}`}>
          {text}
        </span>
      </span>
      <StyledButton
        type='button'
        label='Label'
        onClick={(e) => overlay.current?.toggle(e)}
        className='p-button-text label-button'
      />
      <LabelingOverlay
        reference={overlay}
        sentence={sentence}
        labels={labels}
        setLabels={setLabels}
        getSimilarSentencesForLabel={getSimilarSentencesForLabel}
      />
    </SentenceDiv>
  );
}
