import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'

// TODO: elegant way of adding popup as a whole here instead of just contents?
export const RelationPopup = ({ shown, person, relationHidden }) => {
  const [isShown, setIsShown] = useState()
  const hideModal = () => {
    setIsShown(false)
    relationHidden()
  }

  useEffect(() => {
    setIsShown(shown)
  }, [shown])

  if (!person || !isShown) return ''
  return person.relations?.case_role.length > 0 || person?.mentions.length > 0 ? (
    <Dialog
      header={person.name}
      visible={isShown}
      modal
      closeOnEscape
      dismissableMask
      onHide={hideModal}
      className="entityPopup"
    >
      {person.relations?.case_role?.length > 0 ? (
        <div>
          Role in Case:{' '}
          {person.relations.case_role.map((role, roleI) => (
            <div className="entityLabel highlight" key={`person-${person.id}-role-${roleI}`}>
              {role.label}
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
      {person.relations?.age?.length > 0 ? (
        <div>
          Age:{' '}
          {person.relations.age.map((role, roleI) => (
            <div className="entityLabel highlight" key={`person-${person.id}-role-${roleI}`}>
              {role.label}
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
      {person.relations?.phone_number?.length > 0 ? (
        <div>
          Phone Number:{' '}
          {person.relations.phone_number.map((role, roleI) => (
            <div className="entityLabel highlight" key={`person-${person.id}-role-${roleI}`}>
              {role.label}
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
      {person?.mentions.length > 0 ? (
        <div>
          Aliases:{' '}
          {person.mentions.map((mention, mentionI) => (
            <div className="entityLabel highlight" key={`person-${person.id}-mention-${mentionI}`}>
              {mention}
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </Dialog>
  ) : (
    ''
  )
}
