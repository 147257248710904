import React, { Component } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { callAPI } from '../../libs/API';
import { Page, LoadingContainer } from '../../components-new';

// TODO: manager column or org view
// TODO: create a middle component to extend for simple functions like displayError
export default class AdminAssignmentsAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: this.props.profile,
      impersonation: this.props.impersonation,
      isLoaded: false,
      adding: false,
      agency_id: '',
      profile_id: '',
      case_id: '',
      //            name: '',
      agencies: [],
      //            users: [],
      //            cases: [],
      error: '',
      errorMessage: '',
      isAIAdmin: this.props.profile.user_type === 'augintel_admin',
      isAgencyAdmin: this.props.profile.user_type === 'agency_admin',
    };

    if (
      !(this.state.isAIAdmin || this.state.isAgencyAdmin) ||
      this.state.impersonation
    )
      this.props.history.push('/');

    this.toast = React.createRef();
    this.loadAgencies = this.loadAgencies.bind(this);
    this.searchUsers = this.searchUsers.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.loadCases = this.loadCases.bind(this);
    this.addItem = this.addItem.bind(this);
    this.displayError = this.displayError.bind(this);
  }

  componentDidMount() {
    if (this.state.isAIAdmin) this.loadAgencies();
    else {
      this.setState({
        agency_id: this.state.profile.agency_id,
        isLoaded: true,
      });
    }
  }

  loadAgencies() {
    callAPI(
      '/admin/agencies',
      null,
      (resp) => {
        if (typeof resp.agencies !== 'undefined') {
          this.setState({
            isLoaded: true,
            agencies: resp.agencies !== null ? resp.agencies : [],
          });
        } else {
          this.setState({ error: 'Agency information not found' });
        }
      },
      (error) => {
        this.setState({ isLoaded: true, error: error.message });
      }
    );
  }

  searchUsers() {
    clearTimeout(this.searchUserTimer);
    this.searchUserTimer = setTimeout(() => {
      this.loadUsers();
    }, 500);
  }

  loadUsers() {
    if (this.state.agency_id !== '') {
      callAPI(
        `/admin/users?usersOnly=true&searchActive=true&searchAgency=${this.state.agency_id}&searchFullName=${this.state.user_name}`,
        null,
        (resp) => {
          if (typeof resp.users !== 'undefined') {
            this.setState({
              isLoaded: true,
              userSuggestions: resp.users,
            });
          } else {
            this.displayError({ message: 'User information not found' });
          }
        },
        (error) => {
          this.displayError(error);
        }
      );
    }
  }

  searchCases() {
    clearTimeout(this.searchCaseTimer);
    this.searchCaseTimer = setTimeout(() => {
      this.loadCases();
    }, 500);
  }

  loadCases() {
    if (this.state.agency_id !== '') {
      callAPI(
        `/cases/all?searchAgency=${this.state.agency_id}&searchPhrase=${this.state.case_name}`,
        null,
        (resp) => {
          if (typeof resp.cases !== 'undefined') {
            this.setState({
              caseSuggestions: resp.cases,
            });
          } else {
            this.setState({
              isLoaded: true,
              error: 'Case information not found',
            });
          }
        },
        (error) => {
          this.setState({ isLoaded: true, error: error.message });
        }
      );
    }
  }

  addItem() {
    if (
      !this.state.adding &&
      this.state.agency_id !== '' &&
      this.state.profile_id !== '' &&
      this.state.case_id !== ''
    ) {
      this.setState({ adding: true, errorMessage: '' }, () => {
        callAPI(
          '/admin/assignments/add',
          {
            profile_id: this.state.profile_id,
            case_id: this.state.case_id,
          },
          (resp) => {
            if (resp.success) {
              this.props.history.push('/admin/assignments');
            } else {
              this.displayError({
                message: resp?.message || 'Assignment could not be added',
              });
            }
          },
          (error) => {
            this.setState({ adding: false, error: error.message });
          }
        );
      });
    } else {
      this.setState({ errorMessage: 'Please complete add form' });
    }
  }

  displayError(error) {
    this.setState({
      isLoaded: true,
      adding: false,
      errorMessage: error.message,
    });
    this.toast.current.show({ severity: 'error', summary: error.message });
  }

  render() {
    const agencyDropdown = this.state.isAIAdmin ? (
      <div className='col-12 input'>
        <Dropdown
          id='assignmentAgency'
          value={this.state.agency_id}
          options={this.state.agencies}
          optionLabel='name'
          optionValue='id'
          onChange={(e) => {
            this.setState({ agency_id: e.value });
          }}
          placeholder='Agency'
        />
        <label className='accessibility' htmlFor='assignmentAgency'>
          Agency
        </label>
      </div>
    ) : (
      ''
    );

    // TODO: filtering out inactive entities.  do we want to make this configurable?
    return (
      <Page
        title='Add Assignment'
        pageTitle='Assignments Admin | Augintel'
        isLoaded={this.state.isLoaded}
        toast={this.toast}
        error={this.state.error}
        errorMessage={this.state.errorMessage}
        maxWidth={600}
      >
        <LoadingContainer isLoaded={!this.state.adding}>
          <div className='adminAssignmentsAddBody grid adminInputForm'>
            {agencyDropdown}
            <div className='col-12 input'>
              <AutoComplete
                id='assignmentAgencyUser'
                value={this.state.user_name}
                onChange={(e) => this.setState({ user_name: e.value })}
                onSelect={(e) => {
                  this.setState({
                    profile_id: e.value.profile_id,
                    user_name: `${e.value.first_name} ${e.value.last_name}`,
                  });
                }}
                suggestions={this.state.userSuggestions}
                itemTemplate={(searchEntry) =>
                  `${searchEntry?.first_name} ${searchEntry?.last_name} (${
                    searchEntry?.email
                  })${searchEntry.is_active ? '' : ' (INACTIVE)'}`
                }
                selectedItemTemplate={(searchEntry) =>
                  `${searchEntry?.first_name} ${searchEntry?.last_name} (${
                    searchEntry?.email
                  })${searchEntry.is_active ? '' : ' (INACTIVE)'}`
                }
                completeMethod={() => {
                  this.searchUsers();
                }}
                placeholder='User'
              />
              <label className='accessibility' htmlFor='assignmentAgencyUser'>
                Case
              </label>
            </div>
            <div className='col-12 input'>
              <AutoComplete
                id='assignmentAgencyCase'
                value={this.state.case_name}
                onChange={(e) => this.setState({ case_name: e.value })}
                onSelect={(e) => {
                  this.setState({
                    case_id: e.value.case_id,
                    case_name: e.value.case_name,
                  });
                }}
                suggestions={this.state.caseSuggestions}
                itemTemplate={(searchEntry) =>
                // eslint-disable-next-line no-unsafe-optional-chaining
                  searchEntry?.case_name +
                  (searchEntry.is_active ? '' : ' (INACTIVE)')
                }
                selectedItemTemplate={(searchEntry) =>
                  searchEntry.case_name +
                  (searchEntry.is_active ? '' : ' (INACTIVE)')
                }
                completeMethod={() => {
                  this.searchCases();
                }}
                placeholder='Case'
              />
              <label className='accessibility' htmlFor='assignmentAgencyCase'>
                Case
              </label>
            </div>
            <div className='col-12 buttonPanel right'>
              <Button
                label='Add Assignment'
                className='p-button-danger'
                onClick={() => {
                  this.addItem();
                }}
              />
              <Button
                label='Cancel'
                className='p-button'
                onClick={() => {
                  this.props.history.push('/admin/assignments');
                }}
              />
            </div>
          </div>
        </LoadingContainer>
      </Page>
    );
  }
}
