import { Paginator, PaginatorPageState } from 'primereact/paginator';
import { ProgressBar } from 'primereact/progressbar';
import queryString from 'query-string';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Page } from '../components-new';
import CaseCard from '../components/caseCard/CaseCard';
import CaseCountAndSort from '../components/elements/CaseCountAndSort';
import AgencyOrgCasesFilter from '../components/filters/AgencyOrgCasesFilter';
import CaseAssignmentFilter from '../components/filters/CaseAssigneeFilter';
import CaseworkerAssigneeCasesFilter from '../components/filters/CaseworkerAssigneeCasesFilter';
import useGet from '../libs/UseGet';
import {
  ICasesPageDataState,
  IV2CaseResponse,
  ScopeOption,
  ScopeOptionKey,
} from '../repositories/caseRepository';
import { BuildCaseCardProps } from '../services/CasePreviewDataService';
import './Cases.scss';
import mixpanel from '../libs/Mixpanel';
import { cantViewScope } from '../services/ScopeService';
import { DisplayFactor, PageProps } from '../shared/types/appTypes';
import {
  FiltersQueryStateWithPagination,
  mapToNumberArray,
  setUpInitialLoad,
} from '../libs/FilterService';
import { CaseAssignee } from '../shared/types/caseTypes';
import { useQueryState } from '../libs/useQueryState';
import SelectedFiltersDisplay from '../components/filters/SelectedFiltersDisplay';
import FactorFilter from '../components/filters/FactorFilter';

const dataReducer = (
  state: ICasesPageDataState,
  action: Partial<ICasesPageDataState>
) => ({
  ...state,
  ...action,
});

export default function CasesPage({ profile, impersonation }: PageProps) {
  const history = useHistory();
  const location = useLocation();
  const PAGE_SIZE = 10;
  const CASES = '/v2/cases';
  const initialLoad = useRef(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const queryState: FiltersQueryStateWithPagination =
    useQueryState<FiltersQueryStateWithPagination>(
      {
        scope: 'AGENCY' as ScopeOption,
        page: 1,
        groupIds: [],
        caseworkerIds: [],
        factor: '',
      },
      {
        page: (arg: string) => Number(arg),
        groupIds: (arg: string | string[]) => mapToNumberArray(arg),
        caseworkerIds: (arg: string | string[]) => mapToNumberArray(arg),
        factor: (arg: string) => arg,
      }
    );

  const [dataState, dataDispatch] = useReducer(dataReducer, {});

  const path = `${CASES}${location.search}`;
  const { isLoading, data, error } = useGet<IV2CaseResponse>({
    path,
    initialLoad: initialLoad.current,
  });
  const assigneesPath = `/v2/cases/assignees${location.search}`;
  const caseAssigneesRequest = useGet<CaseAssignee[]>({
    path: assigneesPath,
    initialLoad: initialLoad.current,
  });

  const factorsRequest = useGet<{ data: DisplayFactor[] }>({
    path: '/getFactors',
    initialLoad: initialLoad.current,
  });

  const assigneesData = cantViewScope(
    'no__caseworker_filter',
    profile as unknown as { user_type: string; scopes: string[] },
    impersonation as unknown as { scopes: string[] }
  )
    ? null
    : caseAssigneesRequest.data;

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      setUpInitialLoad(
        profile,
        impersonation,
        history,
        location,
        null,
        dataDispatch
      )
        .then(() => null)
        .catch((e) => {
          console.error(e);
        });
    }
  }, [
    history,
    impersonation,
    location.search,
    profile?.cases_count,
    profile?.direct_report_cases_count,
  ]);

  useEffect(() => {
    if (assigneesData) {
      dataDispatch({
        caseworkerData: assigneesData
          .map((user) => ({
            label: `${user.name}`,
            value: user.profileId.toString(),
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      });
    }
  }, [assigneesData]);

  useEffect(() => {
    if (factorsRequest.data) {
      dataDispatch({
        factorData: factorsRequest.data.data,
      });
    }
  }, [factorsRequest.data]);

  useEffect(() => {
    if (data) {
      dataDispatch({
        caseData: BuildCaseCardProps({
          totalCount: parseInt(data.metadata.totalCount, 10),
          offset: parseInt(data.metadata.offset, 10),
          cases: data.data,
        }),
      });
      setIsLoaded(true);
    } else if (error) {
      dataDispatch({
        error: {
          message: error,
        },
      });
      setIsLoaded(true);
    }
  }, [data, error]);

  const handlePageChange = (event: PaginatorPageState) => {
    const incomingQuery = queryString.parse(location.search, {
      arrayFormat: 'comma',
    });
    const outgoingQuery = {
      ...incomingQuery,
      page: event.page + 1,
    };
    history.push({
      search: queryString.stringify(outgoingQuery, { arrayFormat: 'comma' }),
    });
  };

  const trackMixpanelFilterEvent = useCallback(
    (eventType: string, incomingScopeChange: object) => {
      mixpanel.track(eventType, {
        currentUrl: window.location.href,
        incomingScopeChange,
      });
    },
    []
  );

  const factorQueryString =
    queryState.factor && queryState.factor.length > 0
      ? `?factor=${queryState.factor}`
      : '';

  const caseDataRender = dataState.caseData?.cases.length ? (
    dataState.caseData.cases.map((item) => (
      <a
        title={item.name}
        key={item.id}
        className='case-link block no-underline'
        href={`/cases/${item.id}${factorQueryString}`}
      >
        <CaseCard {...item} />
      </a>
    ))
  ) : (
    <h4 className={'center'}>No cases found.</h4>
  );

  return (
    <Page
      title='Cases'
      pageTitle='Cases | Augintel'
      componentClassName='cases-page-container px-5'
      isLoaded={isLoaded}
    >
      <div className='grid lg:mr-4'>
        <div className='hidden lg:block col-fixed w-18rem h-auto mt-3'>
          <div className='left-auto h-full'>
            <div className='mb-4'>
              <CaseAssignmentFilter
                impersonation={impersonation}
                scope={queryState.scope}
                profile={profile}
                mixpanelCallback={trackMixpanelFilterEvent}
              />
              {dataState.groupData && (
                <AgencyOrgCasesFilter
                  root={dataState.groupData}
                  groupIds={queryState.groupIds || []}
                  mixpanelCallback={trackMixpanelFilterEvent}
                />
              )}
              {dataState.caseworkerData && (
                <CaseworkerAssigneeCasesFilter
                  caseworkerIdOptions={dataState.caseworkerData}
                  caseworkerIds={queryState.caseworkerIds || []}
                  mixpanelCallback={trackMixpanelFilterEvent}
                />
              )}
              {dataState.factorData && (
                <FactorFilter
                  factors={dataState.factorData}
                  selectedFactor={queryState.factor}
                  mixpanelCallback={trackMixpanelFilterEvent}
                />
              )}
            </div>
          </div>
        </div>
        <div className='col'>
          <h2 style={{ paddingLeft: '0.3rem' }}>
            {ScopeOptionKey[queryState.scope]}
          </h2>
          <SelectedFiltersDisplay
            loading={isLoading}
            queryState={queryState}
            groupData={dataState.groupData}
            caseworkerData={dataState.caseworkerData}
            factorData={dataState.factorData}
          />
          <CaseCountAndSort
            count={dataState.caseData?.totalCount ?? 0}
            loading={isLoading}
            countTag='h5'
          />
          {isLoading ? (
            <ProgressBar
              mode={'indeterminate'}
              className='mt-2'
              style={{ paddingLeft: '0.3rem' }}
            />
          ) : (
            caseDataRender
          )}
        </div>
        <div className='col-12'>
          <Paginator
            onPageChange={handlePageChange}
            first={(queryState.page - 1) * PAGE_SIZE}
            rows={PAGE_SIZE}
            totalRecords={dataState.caseData?.totalCount}
          />
        </div>
      </div>
    </Page>
  );
}
