import styled from 'styled-components/macro';

export default styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
`;

export const DivButton = styled.button`
  font-size: inherit;
  border: none;
  background: transparent;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  text-decoration: none;
  font-family: sans-serif;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
`;
//  text-align: center;

