import {
  Tree,
  TreeCheckboxSelectionKeys,
  TreeSelectionParams,
} from 'primereact/tree';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TreeNode } from '../../libs/FilterService';
import './AgencyOrgCasesFilter.scss';

export default function AgencyOrgCasesFilter({
  root,
  groupIds,
  mixpanelCallback,
}: {
  root: TreeNode[];
  groupIds: number[];
  mixpanelCallback: (eventType: string, incomingScopeChange: object) => void;
}) {
  const keys: TreeCheckboxSelectionKeys = useMemo(
    () =>
      groupIds
        ?.map((gid) => gid.toString())
        .reduce((res, gid) => {
          res[gid] = { checked: true };
          return res;
        }, {} as TreeCheckboxSelectionKeys),
    [groupIds]
  );
  const history = useHistory();
  const location = useLocation();
  const [selections, setSelections] = useState<TreeCheckboxSelectionKeys>(keys);

  useEffect(() => {
    setSelections(keys);
  }, [groupIds, keys]);

  const selectionChange = (e: TreeSelectionParams) => {
    const query = queryString.parse(location.search, { arrayFormat: 'comma' });
    const queryObject = {
      ...query,
      page: '1',
      groupIds: Object.entries(e.value as TreeCheckboxSelectionKeys)
        .filter((key) => key[1].checked)
        .map((entry) => entry[0]),
    };
    history.push({
      search: queryString.stringify(queryObject, { arrayFormat: 'comma' }),
    });
    setSelections(e.value as TreeCheckboxSelectionKeys);
    mixpanelCallback('agency-org-filter-change', queryObject);
  };

  const expandedSelectedKeys = Object.keys(selections).reduce(
    (result, selectionKey) => ({
      ...result,
      ...{ [selectionKey]: selections[selectionKey].checked ?? false },
    }),
    {}
  );

  const initiallyExpandedKeys = {
    ...expandedSelectedKeys,
    ...root.reduce(
      (acc, cur) => ({
        ...acc,
        ...{ [cur.key]: true },
      }),
      {}
    ),
  };

  const countSelected = Object.values(selections).filter(
    (selection) => selection.checked
  ).length;

  return (
    <div className={'case-org-filter'}>
      <fieldset className='border-none'>
        <legend>
          <h5>ORGANIZATION {countSelected > 0 ? `(${countSelected})` : ''}</h5>
        </legend>
        <Tree
          value={root}
          selectionMode='checkbox'
          selectionKeys={selections}
          onSelectionChange={selectionChange}
          filter
          filterMode='lenient'
          propagateSelectionUp={false}
          className='text-sm p-0'
          expandedKeys={initiallyExpandedKeys}
        />
      </fieldset>
    </div>
  );
}
