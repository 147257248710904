import React from 'react';
import ReactDOM from 'react-dom';
import { datadogRum } from '@datadog/browser-rum';
import './index.css';
import 'primeflex/primeflex.css'; // https://www.npmjs.com/package/primeflex
import './theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import App from './App.tsx';

if (process.env.REACT_APP_ENV === "prod") {
  datadogRum.init({
    applicationId: "a4467ad0-fa7d-499a-b563-84f69509daf4",
    clientToken: "pub6e09545382120abe3e3870b3ce2fba0b",
    site: "datadoghq.com",
    service: "augintel",
    env: process.env.REACT_APP_ENV,
    sampleRate: 100,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 50,
    trackInteractions: true,
    version: process.env.REACT_APP_NEXT_VERSION,
    forwardErrorsToLogs: process.env.REACT_APP_ENV
  });
}

try {
  ReactDOM.render(<App />, document.getElementById('root'));
} catch (ex) {
  console.log('Error: ', ex);
  // TODO: stylize
  ReactDOM.render(
    <div>
      <div className='appHeader'>
        <div className='left'>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className='aLink'
            onClick={() => {
              window.location = '/';
            }}
          >
            <svg
              preserveAspectRatio='none'
              className='logo'
              width='32'
              height='20'
            >
              <g
                transform='translate(0,26) scale(0.012, -0.012)'
                fill='#284BF0'
                stroke='none'
              >
                <path d='M816 2165 c-84 -30 -130 -99 -130 -195 0 -95 42 -159 126 -194 78 -33 166 -14 229 49 156 155 -15 415 -225 340z' />
                <path d='M2345 2156 c-39 -18 -80 -58 -102 -99 -23 -42 -23 -150 0 -194 26 -49 84 -91 144 -103 168 -35 305 132 234 285 -22 47 -74 99 -114 114 -39 15 -126 13 -162 -3z' />
                <path d='M2394 1614 c-12 -3 -32 -16 -45 -30 -24 -26 -24 -26 -27 -321 l-3 -295 -34 -33 c-18 -19 -36 -48 -40 -64 l-7 -31 -253 0 -254 0 -20 44 c-35 80 -140 128 -232 106 -141 -34 -204 -199 -124 -320 84 -126 286 -119 353 13 l25 47 254 0 c253 0 253 0 263 -22 40 -97 171 -138 276 -88 92 45 131 169 83 266 -13 26 -34 53 -46 62 -23 14 -23 14 -23 308 l0 294 -27 30 c-28 31 -73 44 -119 34z' />
                <path d='M385 1532 c-108 -59 -138 -218 -58 -313 33 -39 116 -79 163 -79 45 0 131 40 157 74 l21 26 222 0 222 0 19 -24 c25 -32 115 -76 155 -76 117 0 214 96 214 212 0 116 -78 199 -194 206 -72 5 -123 -15 -172 -68 l-29 -30 -217 0 -218 0 -16 26 c-10 14 -39 36 -65 50 -67 34 -138 32 -204 -4z' />
                <path d='M202 991 c-126 -32 -196 -185 -137 -301 20 -37 75 -86 114 -99 74 -24 167 -7 220 42 76 70 80 218 8 295 -52 55 -134 80 -205 63z' />
              </g>
            </svg>
          </div>
        </div>
        <div className='mid'>
          <div className='aLink navigation'>Augintel</div>
        </div>
      </div>
      <div style={{ margin: 40 }}>
        An unexpected error occurred. Please try refreshing the page.
      </div>
    </div>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
