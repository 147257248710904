/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Toast, ToastMessage } from 'primereact/toast';
import mixpanel from '../libs/Mixpanel';
import { callAPI } from '../libs/API';
import { CaseNotes, AlertsPopup, RelationPopup } from '../components';
import CaseGraphs from '../components/graphs/CaseGraphs';
import { Page } from '../components-new';
import './Case.scss';
import {
  QualityAlertsSection,
  getQualityAlertsHeader,
} from '../components/AlertGraph';
import { PageProps } from '../shared/types/appTypes';
import { Query, PowerSearch } from '../shared/types/queryTypes';
import type { CaseData, ICase, PersonEntity } from '../shared/types/caseTypes';
import { notePageLimit } from '../utils/NoteUtils';
import useWindowDimensions from '../utils/useWindowDimensions';
import { SearchSurvey } from '../components/notes/SearchSurvey';

interface Error {
  message: string;
}

interface MatchParams {
  caseID?: string;
}

interface RelationState {
  relationPopupShown: boolean;
  relationPopupPerson: PersonEntity | null;
}

interface AlertsQueryState {
  alertsPopupShown: boolean;
  alertsQuery: Query | null;
}

export const Case = ({
  profile,
  factors,
  impersonation,
  location,
  match,
}: PageProps<MatchParams>) => {
  const toast = useRef<Toast>();
  const { width } = useWindowDimensions();
  const caseNotesRef = useRef<ICaseNotes>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [pageError, setPageError] = useState('');
  const query = location.search.startsWith('?')
    ? location.search.substring(1).split('&')
    : [];
  const queryID = query
    .filter((queryString) => queryString.startsWith('query='))[0]
    ?.split('=')[1];
  const factor = query
    .find((queryString) => queryString.startsWith('factor='))
    ?.split('=')[1];
  const [theCase, setCase] = useState<ICase | null>(null);
  const [caseData, setCaseData] = useState<CaseData>({
    caseID: match.params?.caseID || '',
    searchQueryID: queryID || '',
    searchFactor: factor || '',
    expandGraph: true,
    maxRiskChartDisplay: 3,
    expandRisks: false,
    maxPeopleChartDisplay: 5,
    expandPeople: false,
    activeIndex: typeof queryID !== 'undefined' ? 2 : 0,
  });
  const [relation, setRelation] = useState<RelationState>({
    relationPopupShown: false,
    relationPopupPerson: null,
  });
  const [alerts, setAlerts] = useState<AlertsQueryState>({
    alertsPopupShown: false,
    alertsQuery: null,
  });
  const [showSearchSurvey, setShowSearchSurvey] = useState(false);

  const displayError = (error: Error) => {
    setIsLoaded(true);
    setPageError(error.message);
  };

  const displayToastError = (toastConfig: ToastMessage) =>
    toast.current && toast.current.show(toastConfig);

  const updateShowSearchSurvey = () => {
    const storedAck = localStorage.getItem('search-survey-ack') || 'false';
    const show =
      profile.flags &&
      !profile.flags.submitted_survey &&
      storedAck.toLowerCase() === 'false';
    setTimeout(() => setShowSearchSurvey(show), 4000);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getQueryNotes = () => {
    if (caseNotesRef.current && theCase) {
      caseNotesRef.current.getQueryNotes(
        theCase.id,
        parseInt(theCase.note_total, 10),
        theCase.notes,
        caseData.searchQueryID,
        theCase?.queries
      );
    }
  };

  const getFactorNotes = () => {
    if (caseNotesRef.current && theCase) {
      caseNotesRef.current.getFactorNotes(
        theCase.id,
        caseData.searchFactor,
        factors
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadInitialNoteState = () => {
    if (caseNotesRef.current?.loadInitialNoteState) {
      caseNotesRef.current.loadInitialNoteState(
        theCase?.id ?? 0,
        parseInt(theCase?.note_total ?? '0', 10),
        theCase?.notes,
        caseData.searchQueryID,
        caseData.searchFactor,
        theCase?.queries
      );
    }
  };

  const searchNotes = useCallback(
    (
      wait: boolean,
      searchMonthYear: string,
      searchFactor: { code: string; label: string } | string,
      searchPerson: string,
      searchQuery?: {
        id: number | string;
        name: string;
        hideResults: boolean;
        hideResultsMessage?: string;
      }
    ) => {
      if (caseNotesRef.current) {
        caseNotesRef.current.filterNotes(
          wait,
          searchMonthYear,
          searchFactor,
          searchPerson,
          searchQuery
        );
      }
    },
    [theCase?.id]
  );

  const displayEntityPopup = (entity: PersonEntity) => {
    setRelation({
      relationPopupShown: true,
      relationPopupPerson: entity,
    });
  };

  const displayEntityPopupByID = (entityID: number) => {
    if (theCase) {
      setRelation({
        relationPopupShown: true,
        relationPopupPerson: theCase.peopleTimeline.filter(
          (entity) => entity.id === entityID
        )[0],
      });
    }
  };

  const hideRelationPopup = () => {
    setRelation({
      ...relation,
      relationPopupShown: false,
    });
  };

  const displayAlertsPopup = (alert: Query | PowerSearch) => {
    setAlerts({
      alertsPopupShown: true,
      alertsQuery: alert,
    });
  };

  const hideAlertsPopup = () => {
    setAlerts({
      ...alerts,
      alertsPopupShown: false,
    });
  };

  const switchTab = (index: number) => {
    setCaseData({
      ...caseData,
      activeIndex: index,
    });
  };

  const renderCase = () => {
    if (!isLoaded || pageError !== '') {
      return false;
    }

    let monthSpan = 0;

    if (theCase) {
      if (theCase.riskCounts?.length > 0) {
        let minDate = new Date(theCase.riskCounts?.[0]?.date) || false;
        const maxDate =
          new Date(theCase.riskCounts?.[theCase.riskCounts.length - 1]?.date) ||
          false;
        const minDateCheck =
          minDate && maxDate
            ? new Date(theCase.riskCounts[theCase.riskCounts.length - 1]?.date)
            : false;
        if (minDateCheck) {
          minDateCheck.setFullYear(minDateCheck.getFullYear() - 2);
          if (minDate < minDateCheck) minDate = minDateCheck;
          monthSpan =
            maxDate.getMonth() -
            minDate.getMonth() +
            12 * (maxDate.getFullYear() - minDate.getFullYear());
        }
      }

      const powerSearches =
        theCase.power_searches?.length > 0
          ? theCase.power_searches?.map((queryGroup) => (
              <div key={`querygroup-${queryGroup.query_group_id}`}>
                <span className='alert-query-group'>
                  {' '}
                  {queryGroup.heading}:{' '}
                </span>
                {queryGroup.power_search?.map((powerSearch) => (
                  <span
                    key={`powerSearch-${powerSearch.id}`}
                    className='alert-list'
                  >
                    <div
                      className='aLink entityLink'
                      onClick={() => {
                        mixpanel.track('case-filter-saved-search', {
                          query_id: powerSearch.id,
                          power_search_title: powerSearch.title,
                        });

                        searchNotes(false, '', '', '', {
                          id: powerSearch.id,
                          name: powerSearch.title,
                          hideResults: powerSearch.hide_results,
                          hideResultsMessage: powerSearch.hide_results_message,
                        });
                      }}
                    >
                      {powerSearch.title}
                    </div>
                    <div
                      className='aLink iconLink entityLabel infoIcon'
                      onClick={() => displayAlertsPopup(powerSearch)}
                    >
                      <svg viewBox='0 0 13 13'>
                        <path d='M5.85 9.75H7.15V5.85H5.85V9.75ZM6.5 0C2.925 0 0 2.925 0 6.5C0 10.075 2.925 13 6.5 13C10.075 13 13 10.075 13 6.5C13 2.925 10.075 0 6.5 0ZM6.5 11.7C3.64 11.7 1.3 9.36 1.3 6.5C1.3 3.64 3.64 1.3 6.5 1.3C9.36 1.3 11.7 3.64 11.7 6.5C11.7 9.36 9.36 11.7 6.5 11.7ZM5.85 4.55H7.15V3.25H5.85V4.55Z' />
                      </svg>
                    </div>
                  </span>
                ))}
              </div>
            ))
          : 'None';

      const caseNotes = (
        <CaseNotes
          profile={profile}
          impersonation={impersonation}
          displayToastError={displayToastError}
          displayEntityPopup={displayEntityPopup}
          displayEntityPopupByID={displayEntityPopupByID}
          powerSearches={theCase.power_searches}
          switchTab={switchTab}
          factors={factors}
          noteDates={{
            startDate: theCase.firstNoteDate,
            endDate: theCase.lastNoteDate,
          }}
          agencyId={theCase.agency_id}
          ref={caseNotesRef}
          showSearchSurvey={updateShowSearchSurvey}
        />
      );

      const relationPopup = (
        <RelationPopup
          person={relation.relationPopupPerson}
          shown={relation.relationPopupShown}
          relationHidden={hideRelationPopup}
        />
      );

      const alertsPopup = (
        <AlertsPopup
          query={alerts.alertsQuery}
          shown={alerts.alertsPopupShown}
          alertsHidden={hideAlertsPopup}
        />
      );

      return (
        <div className='case'>
          {relationPopup}
          {alertsPopup}
          <div className='caseHeader'>
            {Object.keys(theCase.bannerElements || {}).map((elementKey, i) => {
              const elementValue = theCase.bannerElements[elementKey];

              function getElement() {
                if (width > 800) {
                  return (
                    <React.Fragment key={elementKey}>
                      <span style={{ marginLeft: i === 0 ? '0px' : '24px' }}>
                        {elementKey}:{' '}
                      </span>{' '}
                      {}
                      {elementValue}
                    </React.Fragment>
                  );
                }

                return (
                  <React.Fragment key={elementKey}>
                    <div>
                      <span>{elementKey}: </span> {}
                      {elementValue}
                    </div>
                  </React.Fragment>
                );
              }

              return getElement();
            })}
          </div>
          <div className='caseBody grid'>
            {caseNotes}
            <SearchSurvey
              show={showSearchSurvey}
              setShow={setShowSearchSurvey}
            />
            <CaseGraphs
              caseData={caseData}
              handleSetCaseData={setCaseData}
              qualityAlerts={QualityAlertsSection({
                queries: theCase.queries || [],
                searchNotes,
                displayAlertsPopup,
              })}
              qualityAlertsHeader={getQualityAlertsHeader(theCase)}
              powerSearches={powerSearches}
              displayEntityPopup={displayEntityPopup}
              displayEntityPopupByID={displayEntityPopupByID}
              monthSpan={monthSpan}
              searchNotes={searchNotes}
              theCase={theCase}
            />
          </div>
        </div>
      );
    }
    return <></>;
  };

  useEffect(() => {
    void Promise.all([
      mixpanel.track('case-view', { case_id: caseData.caseID }),
      callAPI(
        `/cases/${caseData.caseID}?limit=${notePageLimit}`,
        null,
        (resp: { case: ICase }) => {
          if (typeof resp.case !== 'undefined') {
            const currentCase = resp.case;
            if (caseData.searchQueryID !== '' || caseData.searchFactor !== '')
              currentCase.notes = [];
            if (!currentCase.protectiveCounts)
              currentCase.protectiveCounts = [];
            if (!currentCase.riskCounts) currentCase.riskCounts = [];
            if (!currentCase.notes) currentCase.notes = [];
            document.title = `${
              currentCase.name || 'Unnamed Client'
            } | Augintel`;

            if (typeof currentCase.name !== 'undefined') {
              setIsLoaded(true);
              setCase(currentCase);
            } else {
              displayError({ message: 'Case not found' });
            }
          } else {
            displayError({ message: 'Case not found' });
          }
        },
        (error: Error) => {
          displayError(error);
        }
      ),
    ]);
  }, [caseData.caseID, caseData.searchQueryID, caseData.searchFactor]);

  useEffect(() => {
    if (theCase !== undefined) {
      if (caseData.searchQueryID !== '') {
        getQueryNotes();
      } else if (caseData.searchFactor !== '') {
        getFactorNotes();
      } else {
        loadInitialNoteState();
      }
    }
  }, [theCase]);

  const title = pageError ? 'Case' : theCase?.name || 'Unnamed Client';
  return (
    <Page
      title={title}
      pageTitle='Cases | Augintel'
      isLoaded={isLoaded}
      toast={toast}
      error={pageError}
      componentClassName='case'
    >
      {theCase && renderCase()}
    </Page>
  );
};
