import { ICaseCardProps } from '../components/caseCard/CaseCard';
import { TreeNode } from '../libs/FilterService';
import { CaseFactorView } from '../shared/types/caseTypes';
import { DisplayFactor } from '../shared/types/appTypes';

export interface StaffMember {
  name: string;
  profileId: number;
  email: string;
  position: string;
  assignmentType?: string;
}

export interface TimeSeriesPoint {
  date: string;
  value: number;
}

export interface CaseQueryResults {
  queryId: number;
  title: string;
  totalSalience: number;
}

export interface CasePreview {
  caseId: number;
  name: string;
  description: string;
  newRisks: string[];
  queryResults: CaseQueryResults[];
  staff?: StaffMember[];
  riskCounts: TimeSeriesPoint[];
  protectiveCounts: TimeSeriesPoint[];
  sourceId: string;
  start_date: string | null;
  end_date: string | null;
  lastContactDate: string | null;
  isActive: boolean;
  newRiskFactors: CaseFactorView[];
}

export interface IV2CaseResponse {
  metadata: {
    totalCount: string;
    offset: string;
  };
  data: CasePreview[];
}

export interface CasesInfo {
  totalCount: number;
  offset: number;
  cases: CasePreview[];
}

// This aligns with existing case_filter enum
// Once we transfer entire app to Typescript, we should use ScopeOption instead
export type ScopeOption = 'USER' | 'TEAM' | 'AGENCY';

export const ScopeOptionKey = {
  USER: 'My Cases',
  TEAM: "My Team's Cases",
  AGENCY: 'All Cases',
};
export interface IListBoxFilterOption {
  label: string;
  value: string;
  items?: IListBoxFilterOption[];
}

export interface ICasesPageDataState {
  caseData?: {
    totalCount: number;
    cases: ICaseCardProps[];
  };
  error?: {
    message: string;
  };
  groupData?: TreeNode[];
  caseworkerData?: IListBoxFilterOption[];
  factorData?: DisplayFactor[];
}
