import React, { Component } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { callAPI } from '../../libs/API';
import { Page, LoadingContainer } from '../../components-new';

// TODO: manager column or org view
// TODO: create a middle component to extend for simple functions like displayError
export default class AdminAgencyDomains extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agencyID: props.match.params.agencyID,
      isLoaded: false,
      filterLoaded: true,
      domains: [],
      impersonation: this.props.impersonation,
      sortField: 'name',
      sortOrder: 1,
      deleteName: '',
      error: '',
      isAIAdmin: this.props.profile.user_type === 'augintel_admin',
    };

    if (!this.state.isAIAdmin || this.state.impersonation)
      this.props.history.push('/');

    this.toast = React.createRef();
    this.loadData = this.loadData.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.promptDelete = this.promptDelete.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.displayError = this.displayError.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  // not adding filters or pagination as of yet since there's a low load of agencies
  loadData() {
    this.setState({ filterLoaded: false }, () => {
      callAPI(
        `/admin/agencies/${this.state.agencyID}/domains`,
        null,
        (resp) => {
          if (typeof resp.domains !== 'undefined') {
            this.setState({
              isLoaded: true,
              domains: resp.domains !== null ? resp.domains : [],
              filterLoaded: true,
            });
          } else {
            this.displayError({ message: 'Domains not found' });
          }
        },
        (error) => {
          this.displayError(error);
        }
      );
    });
  }

  updateItem(itemID, fieldName, newValue) {
    const domain = this.state.domains.filter((item) => item.id === itemID)[0];
    const updated =
      typeof domain.updating !== 'undefined' &&
      domain.updating[fieldName] !== newValue;
    if (updated) {
      const updatedValues = {};
      updatedValues[fieldName] = newValue;
      callAPI(
        `/admin/agencies/${this.state.agencyID}/domains/${itemID}/update`,
        updatedValues,
        (resp) => {
          if (resp.success) {
            this.setState({
              domains: this.state.domains.map((item) => {
                if (item.id === itemID) {
                  // eslint-disable-next-line no-param-reassign
                  delete item.updating[fieldName];
                }
                return item;
              }),
            });
            this.toast.current.show({
              severity: 'success',
              summary: 'Domain updated!',
            });
          } else {
            this.toast.current.show({
              severity: 'error',
              summary: 'Domain could not be updated',
            });
          }
        },
        (error) => {
          this.displayError(error);
        }
      );
    }
  }

  // TODO: show pending and update after success
  updateValue(itemID, fieldName, newValue) {
    this.setState({
      domains: this.state.domains.map((item) => {
        if (item.id === itemID) {
          // eslint-disable-next-line no-param-reassign
          if (typeof item.updating === 'undefined') item.updating = [];
          if (typeof item.updating[fieldName] === 'undefined')
            // eslint-disable-next-line no-param-reassign
            item.updating[fieldName] = item[fieldName];
          // eslint-disable-next-line no-param-reassign
          item[fieldName] = newValue;
        }
        return item;
      }),
    });
  }

  promptDelete(rowData) {
    this.setState({
      deleting: false,
      deleteID: rowData.id,
      deleteName: rowData.domain,
    });
  }

  deleteItem() {
    if (!this.state.deleting) {
      this.setState({ deleting: true }, () => {
        callAPI(
          `/admin/agencies/${this.state.agencyID}/domains/${this.state.deleteID}/delete`,
          {},
          (resp) => {
            if (resp.success) {
              this.setState({
                domains: this.state.domains.filter(
                  (domain) => domain.id !== this.state.deleteID
                ),
              });
              this.setState({ deleting: false, deleteID: '', deleteName: '' });
              this.toast.current.show({
                severity: 'success',
                summary: 'Domain deleted!',
              });
            } else {
              this.toast.current.show({
                severity: 'error',
                summary: 'Domain could not be deleted',
              });
            }
          },
          (error) => {
            // TODO: change to a toast notification?
            this.displayError(error);
          }
        );
      });
    }
  }

  displayError(error) {
    this.setState({
      isLoaded: true,
      error: error.message,
    });
    this.toast.current.show({ severity: 'error', summary: error.message });
  }

  render() {
    const domainValidator = (props) => {
      const { newValue, field, rowData } = props
      const isValid =
        newValue.length > 0 &&
        // eslint-disable-next-line prefer-regex-literals
        new RegExp(
          /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g
        ).test(newValue);
      if (isValid) {
        this.updateValue(
          rowData.id,
          field,
          newValue
        );
        this.updateItem(
          rowData.id,
          field,
          newValue
        );
      }
      return isValid;
    };

    const domainEditor = (options) => (
      <InputText
        value={options.rowData.domain}
        keyfilter={/^[a-z A-Z0-9-_.]+$/}
        onChange={(e) => {
          options.editorCallback(e.target.value)
          this.updateValue(options.rowData.id, 'domain', e.target.value);
        }}
      />
    );

    const deleteFooter = (
      <div>
        <Button
          label='Yes'
          className='p-button-danger'
          onClick={() => {
            this.deleteItem();
          }}
        />
        <Button
          label='No'
          onClick={() => {
            this.setState({ deleting: false, deleteID: '', deleteName: '' });
          }}
        />
      </div>
    );

    return (
      <Page
        title='Agency Domains'
        pageTitle='Agency Domains Admin | Augintel'
        isLoaded={this.state.isLoaded}
        toast={this.toast}
        error={this.state.error}
        actions={[
          <Button
            label='New Domain'
            icon='pi pi-plus'
            onClick={() => {
              this.props.history.push(
                `/admin/agencies/${this.state.agencyID}/domains/add`
              );
            }}
          />,
        ]}
      >
        <div className='adminAgenciesBody grid'>
          <LoadingContainer isLoaded={this.state.filterLoaded}>
            <DataTable
              value={this.state.domains}
              sortField={this.state.sortField}
              sortOrder={this.state.sortOrder}
              resizableColumns
              editMode='cell'
            >
              <Column
                field='domain'
                header='Domain'
                editor={domainEditor}
                cellEditValidator={domainValidator}
                sortable
                body={(rowData) => (
                  <span
                    className={
                      rowData.updating?.domain &&
                      rowData.updating?.domain !== rowData.domain
                        ? 'updating'
                        : ''
                    }
                  >
                    {rowData.domain}
                  </span>
                )}
              />
              <Column
                header='Delete'
                body={(rowData) => (
                  <Button
                    className='p-button-danger'
                    icon='pi pi-minus-circle'
                    onClick={() => {
                      this.promptDelete(rowData);
                    }}
                  />
                )}
                style={{ width: '6em' }}
              />
            </DataTable>
          </LoadingContainer>
        </div>
        <Dialog
          header='Confirm Delete'
          footer={deleteFooter}
          visible={this.state.deleteName !== ''}
          onHide={() => {
            this.setState({ deleting: false, deleteID: '', deleteName: '' });
          }}
          modal
        >
          {this.state.deleting ? <ProgressBar mode='indeterminate' /> : ''}
          Are you sure you want to delete this domain?
          <br />
          <br />
          <b>{this.state.deleteName}</b>
        </Dialog>
      </Page>
    );
  }
}
