import React, { ReactElement, useEffect, useState } from 'react';
import { DataScroller } from 'primereact/datascroller';
import { RelevanceDisplay } from './RelevanceDisplay';

import './DateRange.scss';
import { RelevanceHit } from '../../shared/types/noteTypes';

interface DisplayProps {
  timelineNotes: ReactElement[] | null;
  searchMatches: RelevanceHit[];
  getMoreNotes: () => void;
  notesLoaded: boolean;
  uniquePhraseCount: number;
  disableRelevance: boolean;
  phraseCounts: {
    string: { total_matches: number; unique_notes_count: number };
  };
}

export const NoteDisplay = ({
  timelineNotes,
  searchMatches,
  notesLoaded,
  uniquePhraseCount,
  disableRelevance,
  getMoreNotes,
  phraseCounts,
}: DisplayProps) => {
  const [datascrollerHeight, setDatascrollerHeight] = useState(
    'calc(100vh - 30rem)'
  );

  const handleResize = () => {
    const noteFilters = document.getElementById('note-filters');
    const noteFiltersHeight = noteFilters?.offsetHeight;

    const heightToRemove = noteFiltersHeight
      ? `${noteFiltersHeight + 280}px`
      : '30rem';
    const heightCalc = `calc(100vh - ${heightToRemove})`;
    setDatascrollerHeight(heightCalc);
  };

  useEffect(() => {
    window.addEventListener('click', handleResize);
    return () => window.removeEventListener('click', handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [timelineNotes]);

  const emptyMessage = (
    <span className='pl-2'>{notesLoaded ? 'No notes found' : ''}</span>
  );

  const noteDisplay = disableRelevance ? (
    <DataScroller
      value={timelineNotes || []}
      inline
      lazy
      onLazyLoad={getMoreNotes}
      emptyMessage={emptyMessage}
      scrollHeight={datascrollerHeight}
      className='notes-datascroller'
    />
  ) : (
    <RelevanceDisplay
      timelineNotes={timelineNotes}
      searchMatches={searchMatches}
      getMoreNotes={getMoreNotes}
      notesLoaded={notesLoaded}
      uniquePhraseCount={uniquePhraseCount}
      scrollHeight={datascrollerHeight}
      phraseCounts={phraseCounts}
    />
  );

  return noteDisplay;
};
