export const palette = {
  text: {
    lightest: '#9a9a9a',
    lighter: '#767676',
    light: '#666666',
    normal: '#444444',
    dark: '#333333',
    darker: '#2a2a2a',
    darkest: '#1a1a1a',
  },
  grey: {
    lightest: '#fafafa',
    lighter: '#f4f4f4',
    light: '#f2f2f2',
    normal: '#e5e5e5',
    dark: '#dedede',
    darker: '#c5c5c5',
    darkest: '#bebebe',
  },
  success: {
    lightest: '#cae8cb',
    lighter: '#83c985',
    light: '#4caf50',
    normal: '#2e7d32',
    dark: '#1b5e20',
  },
  white: '#ffffff',
  blue: '#3d7ec0',
  blue_text: '#14589c',
  error: {
    lightest: '#fbd6d5',
    lighter: '#f38381',
    light: '#ef5350',
    normal: '#f14e4e',
    dark: '#d32f2f',
    darker: '#b72020',
    darkest: '#971414',
  }
}
