import React, { Dispatch, SetStateAction } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { InputMask } from 'primereact/inputmask';
import styled from 'styled-components/macro';
import { InputText } from 'primereact/inputtext';

const StyledDiv = styled.div`
  display: grid;
`;
type SearchParams = {
  useScoreThreshold: boolean;
  scoreThreshold: string;
  numSimPhrases: string;
};
type FilterProps = {
  searchParams: SearchParams;
  setSearchParams: Dispatch<SetStateAction<SearchParams>>;
};

export default function PhraseFilters({
  searchParams,
  setSearchParams,
}: FilterProps) {
  const selectRadioButton = (e: { value: boolean }) => {
    const tempSearchParams = { ...searchParams };
    tempSearchParams.useScoreThreshold = e.value;
    setSearchParams(tempSearchParams);
  };

  const updateParams = (value: string, key: string) => {
    if (key === 'scoreThreshold' || key === 'numSimPhrases') {
      const tempSearchParams = { ...searchParams };
      tempSearchParams[key] = value;
      setSearchParams(tempSearchParams);
    }
  };

  return (
    <StyledDiv className='filters'>
      <div className='inline-flex align-items-center'>
        <RadioButton
          name='ScoreThreshold'
          value={true}
          checked={searchParams.useScoreThreshold}
          onChange={(e: { value: boolean }) => selectRadioButton(e)}
          className='margin'
        />
        <label htmlFor='scoreThreshold'>Score Threshold</label>
        <InputMask
          mask={'9.99'}
          placeholder='Score Threshold'
          value={searchParams.scoreThreshold}
          onChange={(e) => {
            updateParams(e.value, 'scoreThreshold');
          }}
          className='margin'
        />
      </div>
      <div className='inline-flex align-items-center'>
        <RadioButton
          name='NumPhrase'
          value={false}
          checked={!searchParams.useScoreThreshold}
          onChange={(e: { value: boolean }) => selectRadioButton(e)}
          className='margin'
        />
        <label htmlFor='numberSimilarPhrases'>Number of similar phrases</label>
        <InputText
          placeholder='Number of Phrases'
          value={searchParams.numSimPhrases}
          keyfilter={/^[0-9]+$/}
          onChange={(e) => {
            updateParams(e.target.value, 'numSimPhrases');
          }}
          className='margin'
        />
      </div>
    </StyledDiv>
  );
}
