import { useEffect, useReducer } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { refreshTokenAsync } from './Cognito';
import { ExpiredSessionError } from '../shared/Error';
import { forceSignOut } from './Utils';
import {
  ImpersonationRecord,
  LocalStorageUser,
} from '../shared/types/appTypes';

export interface UseGetHookState<T> {
  isLoading: boolean;
  error: string | null;
  data: T | null;
}

export interface UseGetHookReducerAction<T> {
  type: string;
  data?: T;
  error?: unknown;
}

interface ErrorResponse {
  error: string;
}

export const REQUEST_STARTED = 'REQUEST_STARTED';
export const REQUEST_SUCCESSFUL = 'REQUEST_SUCCESSFUL';
export const REQUEST_FAILED = 'REQUEST_FAILED';
export const RESET_REQUEST = 'RESET_REQUEST';
export const UNKNOWN_ERROR = 'Unknown error';

export const requestSuccessful = <T>({ data }: { data: T }) => ({
  type: REQUEST_SUCCESSFUL,
  data,
});
export const requestStarted = () => ({
  type: REQUEST_STARTED,
});
export const requestFailed = ({ error }: { error: unknown }) => ({
  type: REQUEST_FAILED,
  error,
});

function isErrorResponse(response: any): response is ErrorResponse {
  return (response as ErrorResponse).error !== undefined;
}

const fetchFromAPI = async (path: string, options: RequestInit) => {
  const user = JSON.parse(
    localStorage.getItem('user') || '{}'
  ) as LocalStorageUser;

  if (!user || Object.keys(user).length === 0) {
    forceSignOut();
  }

  await refreshTokenAsync(
    user.username,
    user.email,
    user.idToken.jwtToken,
    user.accessToken.jwtToken,
    user.refreshToken.token,
    user.tokenExpiration,
    user.signInExpiration
  );

  const refreshedUser = JSON.parse(
    localStorage.getItem('user') || '{}'
  ) as LocalStorageUser;

  const url = `${
    (path.indexOf('/admin/') === 0
      ? process.env.REACT_APP_ADMIN_API_URL
      : process.env.REACT_APP_API_URL) || 'localhost:3001'
  }${path}`;

  const impersonatedUser = JSON.parse(
    localStorage.getItem('impersonation') ?? 'null'
  ) as ImpersonationRecord;

  const emailHeader = {
    email: refreshedUser.email,
    impersonatedEmail: impersonatedUser?.email,
  };
  const headers = {
    Authorization: refreshedUser.idToken.jwtToken,
    'X-AuthInfo': JSON.stringify(emailHeader),
  } as Record<string, string>;

  if (impersonatedUser) {
    headers.impersonatedID = JSON.stringify(impersonatedUser.id);
    headers.impersonatedEmail = impersonatedUser.email;
  }

  return {
    response: await fetch(url, {
      headers,
      ...options,
    }),
    requestAuthInfo: headers['X-AuthInfo'],
  };
};

export default function useGet<T>({
  path,
  initialLoad,
}: {
  path: string;
  initialLoad?: boolean;
}) {
  const reducer = (
    state: UseGetHookState<T>,
    action: UseGetHookReducerAction<T>
  ): UseGetHookState<T> => {
    switch (action.type) {
      case REQUEST_STARTED:
        return { ...state, isLoading: true };
      case REQUEST_SUCCESSFUL:
        return {
          ...state,
          isLoading: false,
          error: null,
          data: action.data ?? null,
        };
      case REQUEST_FAILED:
        return {
          ...state,
          isLoading: false,
          error:
            action.error instanceof Error
              ? action.error.message
              : UNKNOWN_ERROR,
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, {
    isLoading: true,
    error: null,
    data: null,
  });

  useEffect(() => {
    if (!initialLoad) {
      const abortController = new AbortController();

      const fetchData = async (ac: AbortController) => {
        dispatch(requestStarted());

        try {
          const { response, requestAuthInfo } = await fetchFromAPI(path, {
            signal: ac.signal,
          });

          if (response.status === 401) {
            forceSignOut();
          }

          const body: unknown = await response.json();
          if (!response.ok) {
            let errorMessage = `Request Error: ${response.status}`;
            if (isErrorResponse(body)) {
              errorMessage = body.error;
            }
            datadogRum.addError({
              message: errorMessage,
              authInfo: requestAuthInfo,
            });
            throw new Error(errorMessage);
          }

          dispatch(
            requestSuccessful({
              data: body as T,
            })
          );
        } catch (e: any | ExpiredSessionError) {
          if (e instanceof ExpiredSessionError && e.forceSignOut) {
            console.log(e.message);
            forceSignOut();
          }
          if (!abortController.signal.aborted) {
            dispatch(requestFailed({ error: e }));
          }
        }
      };

      fetchData(abortController).catch(() => null);

      return () => {
        abortController.abort();
      };
    }
    return () => null;
  }, [initialLoad, path]);

  return state;
}
