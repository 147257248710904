import { ReactElement } from 'react';
import { Factors } from '../../shared/types/appTypes';
import './SearchTags.scss';

interface SearchBoxProps {
  factors: Factors;
  searchQuery: { id: string; name: string } | undefined;
  clearSearch: () => void;
  searchFactor: { code: string; name: string } | undefined;
  searchPerson: string | undefined;
  searchDateDisplay: string | undefined;
}
export const SearchTags = ({
  factors,
  searchQuery,
  clearSearch,
  searchFactor,
  searchPerson,
  searchDateDisplay,
}: SearchBoxProps) => {
  let tags: ReactElement[] = [];
  if (typeof searchFactor !== 'undefined' && searchFactor.code !== '') {
    const factorLookup = factors[searchFactor.code]
      ? factors[searchFactor.code]
      : { type: '', code: searchFactor.code, label: searchFactor.name };
    const cssClassName =
      // eslint-disable-next-line no-nested-ternary
      factorLookup.type === 'negative'
        ? 'riskSentence'
        : factorLookup.type === 'positive'
        ? 'protectiveSentence'
        : '';
    tags = [
      ...tags,
      <div
        className={['searchLabel', cssClassName].join(' ')}
        key='searchLabelFactor'
      >
        {factorLookup.label}
      </div>,
    ];
  }
  if (typeof searchPerson !== 'undefined' && searchPerson !== '') {
    tags = [
      ...tags,
      <div className='searchLabel' key='searchLabelPerson'>
        {searchPerson}
      </div>,
    ];
  }
  if (typeof searchDateDisplay !== 'undefined' && searchDateDisplay !== '') {
    tags = [
      ...tags,
      <div className='searchLabel' key='searchLabelDate'>
        {searchDateDisplay}
      </div>,
    ];
  }
  if (typeof searchQuery !== 'undefined' && searchQuery.name !== '') {
    tags = [
      ...tags,
      <div className='searchLabel' key='searchLabelQueryName'>
        {searchQuery.name}
      </div>,
    ];
  }
  if (tags.length > 0) {
    tags = [
      ...tags,
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div className='aLink clear' key='searchClear' onClick={clearSearch}>
        Clear
      </div>,
    ];
  }

  return <span className='tags'>{tags}</span>;
};
