/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
// TODO refactor to not have to disable no-param-reassign
import { Card } from 'primereact/card';
import { Tooltip } from 'primereact/tooltip';
import {
  guardUndefinedOrNull,
  serializeDateForDisplay,
} from '../utils/NoteUtils';
import {
  Note,
  NoteTextParsed,
  Paragraph,
  SearchProps,
} from '../shared/types/noteTypes';
import { chooseSentences, formatSentences } from '../utils/SentenceUtils';
import { Factors } from '../shared/types/appTypes';

interface CollapsedNoteProps {
  note: Note;
  agencyId: number;
  isExpanded: boolean;
  factors: Factors;
  searchProps: SearchProps;
  noteI: number;
  caseID: number;
  openCaseNote: (
    noteId: number,
    sourceId: string,
    dateString: string,
    sentences: (Element | JSX.Element)[],
    noteI: number
  ) => void;
  displaySentenceFeedback: () => void;
  displayEntityPopupByID: () => void;
  getTaggingEnabled: () => boolean;
  trackWithMixpanel: (eventType: string) => void;
}

export const CollapsedNoteCard = ({
  note,
  agencyId,
  isExpanded,
  factors,
  searchProps,
  noteI,
  caseID,
  openCaseNote,
  displaySentenceFeedback,
  displayEntityPopupByID,
  getTaggingEnabled,
  trackWithMixpanel,
}: CollapsedNoteProps) => {
  const renderNoteCard = () => {
    const dateString = serializeDateForDisplay(note.event_datetime);

    // TODO: double check date times.  utc? {hour} {dayPeriod}
    const header = (
      <div className='grid justify-content-between noteHeader'>
        <div className='col-7 left'>{dateString}</div>
        <Tooltip target='.note-author-name' position='top' />
        <div
          className='note-author-name col-5 right'
          data-pr-tooltip={
            [8, 20, 22].includes(agencyId) ? note.event_type : note.author_name
          }
          data-pr-at='right+1 top'
          data-pr-my='right'
        >
          {[8, 20, 22].includes(agencyId) ? note.event_type : note.author_name}
        </div>
      </div>
    );

    note.note_text_parsed = guardUndefinedOrNull<NoteTextParsed>(
      note.note_text_parsed,
      {} as NoteTextParsed
    );
    note.note_text_parsed.paragraphs = guardUndefinedOrNull<Paragraph[]>(
      note.note_text_parsed.paragraphs,
      [] as Paragraph[]
    );
    note.note_text_parsed.factor_counts = guardUndefinedOrNull<string[]>(
      note.note_text_parsed.factor_counts,
      {} as string[]
    );

    chooseSentences(note, isExpanded, factors, searchProps);

    let sentences = formatSentences(
      note,
      factors,
      noteI,
      caseID,
      searchProps.searchQueryID,
      displaySentenceFeedback,
      displayEntityPopupByID,
      getTaggingEnabled,
      isExpanded
    );
    if (sentences.length === 0) {
      // TODO fix this ts-ignore
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sentences = ['-None-'];
    }
    const cardBody = sentences;
    // TODO: history: history.push('/cases/' + caseID + '?query=' + searchQueryID)>
    const footerLink = (
      <div
        className='aLink'
        onClick={() => {
          trackWithMixpanel('case-notes-show-more-click');
          openCaseNote(
            note.note_id,
            note.source_id,
            dateString,
            sentences,
            noteI
          );
        }}
      >
        Show More
      </div>
    );

    return (
      <div>
        <Card
          header={header}
          footer={footerLink}
          className={['caseNoteCard', 'collapsed'].join(' ')}
        >
          {cardBody}
        </Card>
      </div>
    );
  };

  return renderNoteCard();
};
