import { callAPIAsync } from '../../libs/API';
import { parseDates } from '../../libs/CaseDataService';
import { Profile } from '../../shared/types/appTypes';

export interface ICaseSearchResult {
  result_type: 'person' | 'case';
  case_id: number;
  case_source_id: string;
  case_name: string;
  agency_id: number;
  is_active: boolean;
  sim: number;
  person_id: number | null;
  person_name: string | null;
  start_date: string | null;
  end_date: string | null;
  assigned_user_ids: number[] | null;
}

export interface ISearchResultTemplateProps {
  case_id: number;
  case_name: string;
  case_source_id: string;
  start_date: string;
  end_date: string;
  my_case: boolean;
  referral: boolean;
  results: ICaseSearchResult[];
}

class SearchResultTemplateProps implements ISearchResultTemplateProps {
  case_id: number;
  case_source_id: string;
  case_name: string;
  results: ICaseSearchResult[];
  start_date: string;
  end_date: string;
  my_case: boolean;
  referral = false;

  constructor(result: ICaseSearchResult, user: Profile | null) {
    this.case_id = result.case_id;
    this.case_name = result.case_name;
    this.case_source_id = result.case_source_id;
    this.my_case =
      result.assigned_user_ids?.includes(user?.id as number) ?? false;
    const { startDate, endDate } = parseDates(
      result.start_date,
      result.end_date,
      result.is_active
    ) as { startDate: string; endDate: string };
    this.start_date = startDate;
    this.end_date = endDate;
    if (result.person_id) {
      this.results = [result];
    } else {
      this.results = [];
    }
  }
}

export const TransformSearchResultsToProps = (
  caseData: ICaseSearchResult[],
  profile: Profile | null
): ISearchResultTemplateProps[] =>
  caseData
    .reduce(
      (
        accumulator: ISearchResultTemplateProps[],
        currentValue: ICaseSearchResult
      ) => {
        const existingCaseIdIndex = accumulator.findIndex(
          (props) => props.case_id === currentValue.case_id
        );

        if (existingCaseIdIndex === -1) {
          accumulator.push(
            new SearchResultTemplateProps(currentValue, profile)
          );
        }
        if (currentValue.result_type === 'person') {
          accumulator[existingCaseIdIndex]?.results.push(currentValue);
        }
        return accumulator;
      },
      []
    )
    .sort((a, b) => (a.my_case && !b.my_case ? -1 : 0));

export const searchCases = async (searchTerm: string) => {
  const { cases }: { cases: ICaseSearchResult[] } = await callAPIAsync(
    `/cases/all?searchPhrase=${searchTerm}`
  );
  return cases;
};
