interface ICaseCountAndSortProps {
  count: number;
  loading: boolean;
  countTag: keyof JSX.IntrinsicElements;
  displayPhrase?: string;
}
export default function CaseCountAndSort({
  count,
  loading,
  countTag,
  displayPhrase,
}: ICaseCountAndSortProps) {
  const Tag = countTag;
  return (
    <div className='case-count-and-sort flex flex-row align-items-center'>
      <div>
        {loading ? (
          <div></div>
        ) : (
          <Tag
            data-testid='count'
            className='mb-0 mt-1'
            style={{ paddingLeft: '0.3rem' }}
          >
            {count?.toLocaleString('default')} Case{count === 1 ? '' : 's'}{' '}
            {displayPhrase}
          </Tag>
        )}
      </div>
    </div>
  );
}
