// Creating CognitoTs as temporary place to begin moving over functions from Cognito.js and using Typescript
import { Buffer } from 'buffer/';
import { DecryptedUser, LocalStorageUser } from '../shared/types/appTypes';

export const decryptToken = (jwtToken: string) =>
  Buffer.from(jwtToken.split('.')[1], 'base64');

/**
 * Takes cognito response, builds LocalStorageUser, stores in local storage and returns decrypted user
 * @param cognitoResponse
 */
export const getDecryptedUserAndStoreCachedUser = (cognitoResponse: {
  accessToken: { jwtToken: string; payload: Record<string, any> };
  idToken: { jwtToken: string; payload: Record<string, any> };
  refreshToken: { token?: string; refreshToken?: string };
}) => {
  let userStorage = { } as LocalStorageUser;
  userStorage = {...cognitoResponse, ...userStorage};
  const userInfo = JSON.parse(
    decryptToken(cognitoResponse.idToken.jwtToken).toString()
  ) as DecryptedUser;

  userStorage.tokenExpiration = userInfo.exp;
  userStorage.email = userInfo.email;
  userStorage.username = userInfo['cognito:username'];
  userStorage.signedIn = Math.floor(new Date().getTime() / 1000);
  userStorage.signInExpiration = 10 * 60 * 60 + userStorage.signedIn; // force timeout after 10 hours of use if not active
  if (cognitoResponse.refreshToken.refreshToken) {
    // SSO returns {refreshToken : { refreshToken: string;}} vs password login returns {refreshToken : { token: string;}}
    userStorage.refreshToken.token = cognitoResponse.refreshToken.refreshToken;
  }
  localStorage.setItem('user', JSON.stringify(userStorage));
  return userInfo;
};
