import React from 'react';

export const RiskLabel = ({
  addIcons,
  factors,
  riskKey,
  iClick,
  highLightColorClass,
}) => {
  const iIcon = addIcons ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className='aLink iconLink infoIcon' onClick={iClick}>
      <svg className='infoIcon' viewBox='0 0 13 13'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.85 9.75H7.15V5.85H5.85V9.75ZM6.5 0C2.925 0 0 2.925 0 6.5C0 10.075 2.925 13 6.5 13C10.075 13 13 10.075 13 6.5C13 2.925 10.075 0 6.5 0ZM6.5 11.7C3.64 11.7 1.3 9.36 1.3 6.5C1.3 3.64 3.64 1.3 6.5 1.3C9.36 1.3 11.7 3.64 11.7 6.5C11.7 9.36 9.36 11.7 6.5 11.7ZM5.85 4.55H7.15V3.25H5.85V4.55Z'
          fillOpacity='0.87'
        />
      </svg>
    </div>
  ) : (
    ''
  );

  const hlColorClass = highLightColorClass ?? [];
  if (factors[riskKey]) {
    // eslint-disable-next-line no-nested-ternary
    if (hlColorClass.indexOf('queryHighlight') > -1) {
      hlColorClass.push('queryHighlightLabel');
    } else if (factors[riskKey].type === 'negative')
      hlColorClass.push('riskLabel');
    else if (factors[riskKey].type === 'positive')
      hlColorClass.push('protectiveLabel');

    return (
      <span className={hlColorClass.join(' ')} key={`riskKey${riskKey}`}>
        {factors[riskKey].label}
        {iIcon}
      </span>
    );
  }

  return '';
};
