/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  TabPanel,
  TabPanelHeaderTemplateOptions,
  TabView,
} from 'primereact/tabview';
import { DataScroller } from 'primereact/datascroller';
import './CaseGraphs.css';
import mixpanel from '../../libs/Mixpanel';
import {
  CaseData,
  CaseFactor,
  ICase,
  PersonEntity,
  SearchNotesFunc,
} from '../../shared/types/caseTypes';
import ConnectedCases from '../connectedCases/ConnectedCases';
import { RisksPanel } from './RisksPanel';
import { CasePeopleBubbleChart } from './CasePeopleBubbleChart';
import { BubbleTimelineChart } from './BubbleTimelineChart';

interface ICaseGraphProps {
  caseData: {
    caseID: any;
    searchQueryID: string;
    searchFactor: string;
    expandGraph: boolean;
    maxRiskChartDisplay: number;
    expandRisks: boolean;
    maxPeopleChartDisplay: number;
    expandPeople: boolean;
    activeIndex: number;
  };
  handleSetCaseData: Dispatch<SetStateAction<CaseData>>;
  theCase: ICase;
  monthSpan: number;
  qualityAlerts: any;
  qualityAlertsHeader: (options: TabPanelHeaderTemplateOptions) => ReactElement;
  powerSearches: any;
  displayEntityPopup: (entity: PersonEntity) => void;
  displayEntityPopupByID: (entityID: number) => void;
  searchNotes: SearchNotesFunc;
}

export interface IRiskInterface {
  factor: CaseFactor;
  totalValues: number;
  data: { date: string; val: number }[];
}
export default function CaseGraphs(props: ICaseGraphProps) {
  const [peopleBubbleHeight, setPeopleBubbleHeight] = useState('');

  const peopleBubbleTimelineCharts =
    props.theCase.peopleTimeline?.length > 0
      ? props.theCase.peopleTimeline
          .sort((person1: IRiskInterface, person2: IRiskInterface) => {
            if (!person1.totalValues)
              person1.totalValues = person1.data.reduce(
                (c, d) => (d.val == null ? c : c + d.val),
                0
              );
            if (!person2.totalValues)
              person2.totalValues = person2.data.reduce(
                (c, d) => (d.val == null ? c : c + d.val),
                0
              );
            return person1.totalValues <= person2.totalValues ? 1 : -1;
          })
          .map((person: { id: number }) => ({ person }))
      : [];

  const bubbleChartPeopleTemplate = (data: { person: { id: string } }) => (
    <CasePeopleBubbleChart
      shown={true}
      displayEntityPopup={props.displayEntityPopup}
      displayEntityPopupByID={props.displayEntityPopupByID}
      monthSpan={props.monthSpan}
      person={data.person}
      searchNotes={props.searchNotes}
      key={`case-people-${data.person.id}`}
    />
  );

  const handleResize = () => {
    const caseBody = document.getElementsByClassName('caseBody')[0];
    const caseBodyHeight = caseBody.clientHeight;

    const maxHeightPeople =
      caseBodyHeight - 170 - peopleBubbleTimelineCharts.length * 56 < 0
        ? 350
        : 350 + (caseBodyHeight - 170) - peopleBubbleTimelineCharts.length * 56;
    if (window.screen.width < 576) {
      setPeopleBubbleHeight('calc(50vh)');
    } else {
      setPeopleBubbleHeight(`calc(100vh - ${maxHeightPeople}px)`);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [peopleBubbleTimelineCharts]);

  const peoplePanel = (
    <TabPanel header='People' key='people-panel'>
      <div className='bubbleTimeline' key='person-header'>
        <BubbleTimelineChart
          data={props.theCase.riskCounts}
          monthSpan={props.monthSpan}
          colors=''
          onClick=''
          label
          width='100%'
        />
      </div>
      <DataScroller
        className='bubblechart-datascroller'
        value={peopleBubbleTimelineCharts}
        scrollHeight={peopleBubbleHeight}
        itemTemplate={bubbleChartPeopleTemplate}
        inline
        rows={peopleBubbleTimelineCharts.length}
      ></DataScroller>
    </TabPanel>
  );
  const alertsPanel = (
    <TabPanel
      header='Alerts'
      headerTemplate={props.qualityAlertsHeader}
      key='alerts-panel'
    >
      {props.qualityAlerts}
    </TabPanel>
  );
  const powerSearchPanel = (
    <TabPanel header='Power Search' key='power-search-panel'>
      {props.powerSearches}
    </TabPanel>
  );
  const connectedCasesPanel = (
    <TabPanel header='Connected Cases' key='connected-cases-panel'>
      {props.theCase && <ConnectedCases caseId={props.theCase.id} />}
    </TabPanel>
  );

  const risksPanel = (
    <TabPanel
      header='Risks & Strengths'
      key='risks-panel'
      className='risks-panel'
    >
      <RisksPanel
        factorTimeline={props.theCase.factorTimeline}
        protectiveCounts={props.theCase.protectiveCounts}
        riskCounts={props.theCase.riskCounts}
        monthSpan={props.monthSpan}
        newRisks={props.theCase.newRisks}
        searchNotes={props.searchNotes}
      />
    </TabPanel>
  );

  const tabConfig = [
    { index: 0, panel: risksPanel, mixpanelString: 'case-risks-tab-open' },
    { index: 1, panel: peoplePanel, mixpanelString: 'case-people-tab-open' },
    { index: 2, panel: alertsPanel, mixpanelString: 'case-alerts-tab-open' },
    {
      index: 3,
      panel: powerSearchPanel,
      mixpanelString: 'case-power-search-tab-open',
    },
    {
      index: 4,
      panel: connectedCasesPanel,
      mixpanelString: 'case-connected-cases-tab-open',
    },
  ];

  return (
    <div className='lg:col-6 col-12 graphs'>
      <TabView
        activeIndex={props.caseData.activeIndex}
        className='case-tab-panels border-round'
        onTabChange={(e) => {
          const mixpanelString = tabConfig.find(
            (value) => value.index === e.index
          )?.mixpanelString;
          mixpanelString
            ? mixpanel.track(mixpanelString)
            : console.log('Illegal Tab Index');
          props.handleSetCaseData({ ...props.caseData, activeIndex: e.index });
        }}
      >
        {tabConfig
          .sort((a, b) => a.index - b.index)
          .map((value) => value.panel)}
      </TabView>
    </div>
  );
}
