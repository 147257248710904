import React from 'react';

export const CaseNoteTable = ({ layout, sentences }) => {
  const getSentencesForCell = (rowIdx, colIdx) => {
    let sentencesForCell = [];
    const paragraphI = layout.rows[rowIdx].cols[colIdx]?.paragraphs;
    if (paragraphI) {
      sentencesForCell = sentences.filter(
        (sentence) =>
          paragraphI.includes(sentence.props.paragraphI) ||
          paragraphI.includes(sentence.props['data-paragraphi'])
      );
    }
    return sentencesForCell;
  };

  return (
    <>
      <table className='caseNoteTable'>
        <tbody>
          {layout.rows.map((row, rowIdx) => (
            <tr key={['row-', rowIdx, '-', row.cols.length].join('')}>
              {[...Array(layout.num_cols)].map((col, colIdx) => (
                <td key={['cell-', rowIdx, '-', colIdx].join('')}>
                  {getSentencesForCell(rowIdx, colIdx)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <br className='caseNoteTableBreak' />
    </>
  );
};
