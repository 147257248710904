import React, { Dispatch, SetStateAction } from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { Card } from 'primereact/card';
import { Phrase } from '../../shared/types/labelingTypes'

type PhraseLabelingCardProps = {
  phrase: Phrase;
  buttonValues: { [key: string]: string };
  setButtonValues: Dispatch<SetStateAction<{ [key: string]: string }>>;
};

export default function PhraseLabelingCard({
  phrase,
  buttonValues,
  setButtonValues,
}: PhraseLabelingCardProps) {
  const buttonOptions = [
    {
      icon: 'pi pi-thumbs-up',
      value: 'positive',
      className: 'p-button-success phrase-select-button',
    },
    {
      icon: 'pi pi-thumbs-down',
      value: 'negative',
      className: 'p-button-danger phrase-select-button',
    },
    {
      icon: 'pi pi-question',
      value: 'similar',
      className: 'p-button-secondary phrase-select-button',
    },
  ];

  const buttonTemplate = (option: { icon: string; value: string }) => (
    <i className={option.icon}></i>
  );

  const handlePhraseLabel = (
    e: { value: string; originalEvent: object },
    phraseLabel: string
  ) => {
    setButtonValues({ ...buttonValues, [phraseLabel]: e.value });
  };

  return (
    <Card key={`phrase-${phrase.phrase}`}>
      <div className='flex flex-wrap justify-content-between'>
        <div className=''>{phrase.phrase}</div>
        <div className=''>{phrase.score.toFixed(2)}</div>
        <SelectButton
          id={`select-button-${phrase.id}`}
          options={buttonOptions}
          onChange={(e) => handlePhraseLabel(e, phrase.phrase)}
          value={buttonValues[phrase.phrase]}
          itemTemplate={buttonTemplate}
          optionLabel='selection'
          className='phraseSelectButtons'
        />
      </div>
    </Card>
  );
}
